export default {
  股息调整: "Điều chỉnh cổ tức",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "Hiểu về cơ chế điều chỉnh và phân bổ cổ tức trong giao dịch CFD cổ phiếu và chỉ số",
  了解指数差价合约: "Hiểu về CFD chỉ số",
  了解股票差价合约: "Tìm hiểu về Share CFDs",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "Cổ tức là khoản tiền được phân phối định kỳ từ lợi nhuận hoặc lợi nhuận giữ lại của các công ty niêm yết công khai cho cổ đông. Khi cổ phiếu trả cổ tức, các sản phẩm CFD cổ phiếu và chỉ số liên quan cũng sẽ có điều chỉnh cổ tức tương ứng.",
  除息日与交易优化: "Ngày giao dịch không hưởng Cổ tức và tối ưu hóa giao dịch",
  指数差价合约的除息日由指数提供商根据成分股设定:
    "Ngày giao dịch không hưởng cổ tức cho CFD chỉ số được xác định bởi nhà cung cấp chỉ số dựa trên thời điểm các cổ phiếu cơ sở không còn hưởng cổ tức. Nếu bạn giữ vị thế mua, bạn sẽ nhận được cổ tức; nếu bạn giữ vị thế bán, bạn sẽ phải trả cổ tức. Nắm bắt trước các ngày này có thể giúp bạn quản lý lợi tức một cách hiệu quả.",
  股息调整明细: "Chi tiết điều chỉnh Cổ tức",
  差价合约交易中相关产品的股息数据:
    "Dữ liệu cổ tức trong giao dịch CFD được hiển thị riêng biệt cho cổ tức từ vị thế mua (long) và bán (short)",
  交易产品: "Trading Products",
  多头股息: "Long Dividends",
  空头股息: "Short Dividends",
  除息日: "Ex-dividend Dates",
  即刻交易: "GIAO DỊCH NGAY",
  股息的常见问题: "Câu hỏi thường gặp của Cổ tức",
  qaList: [
    {
      q: "1. Cổ tức và ngày giao dịch không hưởng cổ tức là gì?",
      a: [
        "Cổ tức là khoản tiền được phân phối định kỳ cho cổ đông từ lợi nhuận hoặc lợi nhuận giữ lại của các công ty niêm yết công khai. Khi cổ phiếu trả cổ tức, các sản phẩm CFD cổ phiếu và chỉ số liên quan cũng sẽ có điều chỉnh cổ tức tương ứng.",
        "Ngày giao dịch không hưởng cổ tức cho CFD chỉ số được nhà cung cấp chỉ số xác định dựa trên ngày không hưởng cổ tức của các cổ phiếu thành phần, đây cũng là ngày thanh toán cổ tức.",
      ],
    },
    {
      q: "2. Thời gian điều chỉnh cổ tức",
      a: [
        'Điều chỉnh cổ tức cho CFD chỉ số thường sẽ hiển thị trên tài khoản giao dịch MT4/MT5 của bạn trong khoảng từ 05:00 đến 06:00 (GMT+3) vào ngày giao dịch không hưởng cổ tức, được ghi nhận dưới dạng "Dividend".',
      ],
    },
    {
      q: "3. Ảnh hưởng của cổ tức đến giao dịch chỉ số",
      a: [
        "Nắm giữ vị thế mua: Điều chỉnh cổ tức sẽ được cộng vào tài khoản MT4/MT5 của bạn.",
        "Nắm giữ vị thế bán: Điều chỉnh cổ tức sẽ bị trừ vào tài khoản MT4/MT5 của bạn",
      ],
    },
    {
      q: "4. Công thức tính điều chỉnh cổ tức",
      a: [
        "Vị thế mua: Lots × Khối lượng Hợp đồng × Số tiền cổ tức vị thế Mua (tính theo đơn vị tiền tệ của chỉ số).",
        "Vị thế mua: Lots × Khối lượng Hợp đồng × Số tiền cổ tức vị thế Mua (tính theo đơn vị tiền tệ của chỉ số).",
      ],
    },
    {
      q: "5. Ví dụ về cách tính cổ tức",
      a: [
        "Ví dụ: Với chỉ số SPXUSD, giả sử ngày giao dịch không hưởng cổ tức là 11 tháng 10 năm 2024, số tiền cổ tức cho vị thế mua là $0.050 và cho vị thế bán là -$0.050.",
        "Nếu bạn nắm giữ vị thế mua (mua) 1 lot tiêu chuẩn của SPXUSD vào cuối ngày trước ngày giao dịch không hưởng cổ tức (10 tháng 10), công thức tính điều chỉnh cổ tức sẽ như sau:",
        "Điều chỉnh Cổ tức = 1 (lot tiêu chuẩn) × 100 (kích thước hợp đồng) × $0.050 (số tiền cổ tức vị thế mua) = $5.",
        'Vào ngày giao dịch không hưởng cổ tức, trong khoảng 05:00 đến 06:00 (GMT+3), $5 sẽ được cộng vào tài khoản MT4/MT5 của bạn, với ghi chú là "Dividend".',
        'Nếu bạn nắm giữ vị thế bán (bán), $5 sẽ bị trừ khỏi tài khoản MT4/MT5 của bạn, cũng với ghi chú là "Dividend".',
      ],
    },
    {
      q: "6. Thông tin quan trọng",
      a: [
        "Điều chỉnh cổ tức chỉ áp dụng cho giao dịch CFD cổ phiếu và chỉ số, và sẽ điều chỉnh số dư tài khoản theo loại vị thế (mua hoặc bán). Số tiền cổ tức và mức ảnh hưởng có thể thay đổi tùy thuộc vào các chỉ số cụ thể và điều kiện thị trường.",
        "Nếu có thắc mắc, vui lòng liên hệ quản lý tài khoản của bạn hoặc truy cập trang web chính thức của chúng tôi để biết thêm chi tiết.",
      ],
    },
  ],
};
