export default {
  leftNav: [
    { id: "#Trading", name: "Giao dịch" },
    { id: "#Regulation", name: "Giám sát hàng đầu" },
    { id: "#Security", name: "Bảo vệ" },
    { id: "#Fintech", name: "Công nghệ tài chính" },
    { id: "#Black-Box", name: "Black Box" },
    { id: "#Private-Room", name: "Phòng riêng" },
    { id: "#Liquidity", name: "Tính thanh khoản" },
    { id: "#Honor", name: "Giải thưởng quốc tế" },
  ],
  pages1: {
    h1: "Trust & Respect",
    title: "Mỗi một nhà giao dịch nghiêm túc đều xứng đáng được coi trọng",
    btn: " XEM VIDEO ĐẦY ĐỦ ",
  },
  pages2: {
    title: "Kết nối những sản phẩm giao dịch hot trên toàn thế giới",
    title1:
      "Khám phá một thế giới của các cơ hội và lợi thế cạnh tranh, nắm giữ được giá cả thị trường một cách ổn định và chân thực nhất.",
    btn: " Sản phẩm giao dịch ",
    text: " ngoại hối ",
    text1: " Hàng hóa ",
    text2: " Chỉ số CFD ",
    text3: " Chia sẻ CFD ",
  },
  pages3: {
    title: "Giám sát hàng đầu",
    title1:
      "Các công ty con thuộc EBC Financial Group đều được quản lý chặt chẽ và nhận giấy phép hàng đầu trong các khu vực pháp lý tương ứng.",
    btn: " Tìm hiểu về giấy phép của EBC ",
    text: " Cơ quan Quản lý Tài Chính (FCA) ",
    text1:
      " EBC Financial Group (UK) Ltd được cấp phép và quản lý bởi Cơ quan Quản lý Tài Chính (FCA). Số giấy phép: 927552",
    text2: " Ủy ban Chứng khoán và Đầu tư (ASIC) ",
    text3:
      "EBC Financial Group (Australia) Pty Ltd được cấp phép và quản lý bởi Ủy ban Chứng khoán và Đầu tư (ASIC). Số giấy phép: 500991",
    text4: " Cơ quan tiền tệ Quần đảo Cayman (CIMA) ",
    text5:
      "EBC Financial Group (Cayman) Ltd được cấp phép và quản lý bởi Cơ quan tiền tệ Quần đảo Cayman (CIMA). Số giấy phép: 2038223",
  },
  pages4: {
    title: "Công hệ hỗ trợ trong giao dịch",
    title1:
      "Được triển khai tại London LD5, New York NY4, Singapore SG1, Tokyo TY3 và Hong Kong HK2, các máy chủ độc lập được kết nối bằng các đường chuyên dụng tốc độ cực cao cung cấp khả năng tổng hợp độ trễ cực thấp, định tuyến đơn hàng thông minh và công cụ tối ưu hóa báo giá, đồng thời xây dựng một hệ sinh thái tài chính tốc độ cực cao toàn cầu.",
    btn: "Khám phá công nghệ nền tảng",
    text: " Lệnh được khớp mỗi giây ",
    text1: " Tốc độ khớp lệnh ",
    text2: " Tốc độ ổn định của số liệu ",
    text3: " Chênh lệch RAW ECN cấp liên ngân hàng",
  },
  pages5: {
    title: "Để 85% lệnh trở lên được chốt với giá tốt hơn",
    title1:
      "EBC Trading Black-Box, tối ưu hóa chính xác thuật toán lệnh bán. Xét về tỷ lệ tối ưu hóa giá, tỷ lệ giao dịch của lệnh bán và độ ổn định của thuật toán, EBC Trading Black-Box vượt trội hơn nhiều so với hệ thống giao dịch truyền thống.",
    btn: " Tìm hiểu EBC Trading Black-Box",
  },
  pages6: {
    title: "Không gian giao dịch EBC",
    title1: `Cho dù bạn là người giao dịch tần suất cao, hay là nhà giao dịch lệnh lớn, Private Room của EBC đều sẽ xây dựng kênh giao dịch riêng dành cho bạn dựa trên cơ sở khớp thanh khoản, thậm chí là ẩn một phần thông tin giao dịch của bạn để bảo vệ "con Át chủ" của bạn, điều này cũng có nghĩa là đơn hàng của bạn không chỉ nhận được báo giá tổng thể hơn, mà còn giúp tránh bị đánh úp trong quá trình giao dịch.`,
    btn: " Tìm hiểu không gian giao dịch",
  },
  pages7: {
    title: "Tính thanh khoản hàng đầu quốc tế",
    title1:
      "EBC có mối quan hệ hợp tác lâu dài và ổn định với nhiều ngân hàng quốc tế hàng đầu trên thế giới, kết hợp với báo cáo thanh khoản chất lượng cao, cung cấp mốc chênh lệch thị trường có tính cạnh tranh, giúp nhà đầu tư dễ dàng tận hưởng chi phí giao dịch cực thấp.",
    btn: " Tìm hiểu tính thanh khoản của EBC",
  },
  pages8: {
    btn: "Thêm tin tức",
  },
  pages9: {
    title: "Bảo vệ toàn diện An tâm giao dịch",
    title1:
      "EBC coi an ninh quỹ là nguyên tắc đầu tiên. Tiền của khách hàng được lưu trữ độc lập trong tài khoản lưu ký của Ngân hàng Barclays và thông qua hợp tác với FCA, Ủy ban Tài chính, Lloyds of London và AON, EBC đã thiết lập một cơ chế bảo mật toàn diện và đa cấp.",
    btn: "Bảo vệ",
  },
  lang: "en",
  连接全球热门交易产品: "Truy cập các sản phẩm giao dịch hàng đầu toàn cầu",
  探索一个充满机遇和竞争优势的世界:
    "Khám phá một thế giới của các cơ hội và lợi thế cạnh tranh, nắm giữ được giá cả thị trường một cách ổn định và chân thực nhất.",
  衍生品交易: "Ngoại hối",
  期货CFDs: "Hàng hoá",
  全球股票指数CFDs: "Index CFD",
  美国股票CFDs: "Stock CFDs",
  "欧元/美元": "EURUSD",
  "黄金/美元": "XAUUSD",
  道琼斯工业指数: "U30USD",
  了解交易产品: "Tìm hiểu sản phẩm giao dịch",
  全球顶级监管: "Giám sát hàng đầu",
  EBC金融集团旗下各公司:
    "Các công ty con thuộc EBC Financial Group đều được quản lý chặt chẽ và nhận giấy phép hàng đầu trong các khu vực pháp lý tương ứng.",
  授权及监管于: "Được cấp phép và quản lý bởi",
  监管号: "Số giấy phép",
  英国FCA监管: "Cơ quan Quản lý Tài Chính (FCA)",
  "英国金融行为监管局 (FCA)": "Cơ quan Quản lý Tài Chính (FCA)",
  开曼CIMA监管: "Cơ quan tiền tệ Quần đảo Cayman (CIMA)",
  "开曼群岛金融管理局(CIMA)": "Cơ quan tiền tệ Quần đảo Cayman (CIMA)",
  澳洲ASIC监管: "Ủy ban Chứng khoán và Đầu tư (ASIC)",
  澳大利亚证券和投资委员会: "Ủy ban Chứng khoán và Đầu tư (ASIC)",
  了解EBC官方: "Tìm hiểu thêm về EBC",
  "全面保障 安心以致": "Bảo vệ toàn diện - An tâm giao dịch",
  EBC将资金安全视为首要原则:
    "EBC coi an ninh quỹ là nguyên tắc đầu tiên. Tiền của khách hàng được lưu trữ độc lập trong tài khoản lưu ký của Ngân hàng Barclays và thông qua hợp tác với FCA, Ủy ban Tài chính, Lloyds of London và AON, EBC đã thiết lập một cơ chế bảo mật toàn diện và đa cấp.",
  了解安全保障: "Tìm hiểu về bảo vệ an toàn quỹ",
  "连接全球 信赖首选": "Kết nối toàn cầu - Lựa chọn đáng tin cậy",
  EBC构建全方位服务生态:
    "EBC đã xây dựng một hệ sinh thái dịch vụ toàn diện, được hỗ trợ bởi đội ngũ chuyên gia với hơn 30 năm kinh nghiệm. Với phạm vi hoạt động toàn cầu cùng lượng khách hàng rộng lớn và tích cực, EBC là một trong những thương hiệu hàng đầu trên thị trường quốc tế.",
  全球注册用户数: "Số lượng khách hàng toàn cầu",
  日均交易订单量: "Khối lượng giao dịch trung bình mỗi ngày",
  "2024年第三季度为交易订单优化增益": "Tăng trưởng lợi ích - Quý 3 năm 2024",
  多个: "+",
  "业务覆盖国家/地区": "Quốc gia/Khu vực hoạt động",
  了解全球视野: "Tìm hiểu thêm về tầm nhìn toàn cầu",
  "85%以上": "Để 85% lệnh trở lên được khớp với giá tốt hơn",
  的订单成交于更优价格: "",
  EBC交易黑盒:
    "Hệ thống giao dịch Black Box của EBC tối ưu hóa thuật toán bán ra một cách chính xác. Về khả năng tối ưu giá, tỷ lệ thực hiện lệnh và tính ổn định của thuật toán, hệ thống Black Box của EBC vượt trội hơn hẳn so với các hệ thống giao dịch truyền thống.",

  每秒聚合订单: "Lệnh được khớp mỗi giây",
  极速订单执行: "Tốc độ khớp lệnh",
  数据传输稳定高达: "Tốc độ ổn định của số liệu",
  "行级RAW ECN点差": "Chênh lệch RAW ECN cấp liên ngân hàng",
  了解金融科技: "Khám phá công nghệ tài chính",
  EBC全球合作伙伴: "Đối tác toàn cầu EBC",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "EBC duy trì mối quan hệ hợp tác lâu dài và ổn định với hơn 25 ngân hàng và tổ chức phi ngân hàng danh tiếng quốc tế, cung cấp thanh khoản cấp độ tổ chức thực sự và mức chênh lệch từ 0.0 pips. Ngoài các dịch vụ tài chính, EBC còn tích cực thực hiện trách nhiệm xã hội thông qua các sáng kiến với Đại học Oxford, hợp tác với WAGGGS và phối hợp cùng Quỹ Liên Hợp Quốc nhằm xây dựng một xã hội bình đẳng và phát triển bền vững.",
  了解更多: "Tìm hiểu thêm",
  十大最受欢迎券商: "Top 10 Broker được hoan nghênh nhất",
  最透明交易券商: "Nhà môi giới minh bạch nhất",
  最受好评券商: "Nhà môi giới được đánh giá cao nhất",
  卓越执行券商: "Nhà môi giới thực thi giao dịch xuất sắc nhất",
  全球最佳交易体验奖: "Giải thưởng trải nghiệm giao dịch tốt nhất toàn cầu",
  最受信赖券商: "Nhà môi giới đáng tin cậy nhất",
  最佳CFD券商: "Nhà môi giới CFD tốt nhất",
  全球最佳ECN券商: "Nhà môi giới ECN tốt nhất",
  全球新锐券商: "Nhà môi giới mới của năm",
  tabList: [
    `Forex`,
    `Commodities`,
    `Index CFDs`,
    `Stock CFDs`,
  ],
  出售: "Ask",
  买入: "Bid",
  点差: "Spread",
  立即交易: "Trade",
  致力于让交易者成为盈家: "Nỗ lực giúp các nhà giao dịch trở thành những nhà đầu tư thành công",
  EBC每个引以为傲的数据背后:
    "Tại EBC, mỗi thành tựu đều phản ánh niềm tin từ nhà giao dịch từ hơn 100 quốc gia. Chúng tôi cam kết đồng hành cùng hành trình giao dịch của bạn, giúp những nhà giao dịch nghiêm túc kết nối với cơ hội toàn cầu bằng một cú nhấp chuột.",
  月均交易量: "Khối lượng giao dịch trung bình hàng tháng",
  全球注册用户数: "Số lượng khách hàng toàn cầu",
  "2024年第四季度为交易订单优化增益": "Tăng trưởng lợi ích - Quý 4 năm 2024",
  "业务覆盖国家/地区": "Quốc gia/Khu vực hoạt động",
  number1: "177.5",
  number1Text: "177.5",
  number2: "4",
  number2Text: "4",
  number3: "273",
  number3Text: "2.73",
  number4: "100",
  number4Text: "100",
  langFlag: "en",
  亿: "B+",
  万: "M+",
  "+": "+",
  全球百万美金交易大赛Ⅱ:"Cuộc Thi Giao Dịch Triệu Đô EBC II",
  免费复制冠军收益:`Miễn phí sao chép giao dịch nhà vô địch`,
  免费复制:"Sao Chép Ngay",
  立即参赛:"THAM GIA NGAY",
  该页面仅展示收益表现排名前5的交易账号:"* Trang này chỉ hiển thị 5 tài khoản giao dịch có hiệu suất lợi nhuận cao nhất, xếp hạng được tính bằng đơn vị tiền tệ USD và được cập nhật mỗi giờ."
};
