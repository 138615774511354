export default {
  "box-name": "en-style2",
  "public-btn-text": "口座開設",
  "screen0": {
    "title": "EBCが 機関投資家の方たちに提供<br>するのは 世界トップクラスの流動性です",
    "enTitle": "EBC THE FUTURE",
    "list": [{
        "title": "安全性",
        "desc": "P2Pで世界中のエクイニクスと専用光回線で結び約定まで0.02秒という超高速取引を実現しました。。"
      },
      {
        "title": "規則遵守",
        "desc": "当社の資金は世界一流の銀行で明確に隔離され信託保全されます。"
      },
      {
        "title": "流动性",
        "desc": "EBCは国際的な多くの一流銀行と長年にわたるパートナー関係を築いておりお客様に超低価格な取引サービスが提供することができます。"
      }
    ]
  },
  "screen1": {
    title:"流動性の深さ",
    "trList": [
      "区分",
      "買付単価",
      "購入数量",
      "スプレッド",
      "約定単価",
      "約定数量"
    ],
    "mobileTrList": [
      "区分",
      "買付単価",
      "スプレッド",
      "約定単価"
    ]
  },
  "screen2": {
    "pageEnTitle": "Clearing System",
    "pageTitle": "決済システム",
    "title": "EBCの安全で、透明性が高く、信頼できる決済システム"
  },
  "screen3": {
    "pageEnTitle": "EBC Private Room",
    "pageTitle": "独自の約定アルゴリズム",
    "title": "EBC はお客様の注文状況に応じて最適なLPをマッチさせています。",
    "desc": "大口取引に対して、EBCは銀行系LPよりも流動性が優れているノンバンク系のLPにマッチさせているため、リクォートやスリッページが起こりにくい取引環境を実現できます。"
  },
  "screen4": {
    "title": "あなただけの流動性をナビゲートして<span>グローバル市場を自在に操る</span>",
    "desc": "プライベートルームでは、お客様の取引情報の一部が非表示にできます。ラストルック(Last Look)による注文遅延や拒否がなく、お客様は大口注文をより良い価格で約定することができます。図に示すように、プライベートルームで取引された大口注文の40%がネガティブスリッページでした。"
  },
  "screen5": {
    "it": "時間だけ、私をもっとよく知ってもらいましょう",
    "ct": "*取引にはスリッページや遅延、様々な不確定要素が生じる可能性があります。ベテラントレーダーであれば、これらの小さな要素を気に留めないかもしれませんが、お客様に不都合が生じた場合はご意見をお寄せください。EBCは高品質と徹底主義を提唱し、不確実な未来を積極的に受け入れています。私たちは、無駄がなく、研ぎ澄まされ、そして快適な新しい取引体験をお客様にお届けできるよう努力しています。",
    "title": "いままでなかった新たな取引体験を創造していく",
    "desc": "我々は積極的にお客様の意見に耳を傾け、お客様個別の取引体験を向上させることをとても大切にしています。EBCの開発チームは、お客様のオンラインでの取引体験をベストなものにするために、常に研究開発と新性能、新技術の開発に取り組んでいます。",
    "btn": "EBCのメリットについて詳しく見る"
  }
}
