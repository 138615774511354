export default {
  股息调整: "股息调整",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "了解您在股票和指数差价合约交易中的股息调整和分配机制",
  了解指数差价合约: "了解指数差价合约",
  了解股票差价合约: "了解股票差价合约",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "股息是上市公司从利润或保留资金中定期分配给股东的资金，当股票派发股息时，相关股票和指数差价合约产品也会进行相应的股息调整。",
  除息日与交易优化: "除息日与交易优化",
  指数差价合约的除息日由指数提供商根据成分股设定:
    "指数差价合约的除息日由指数提供商根据成分股设定，美股差价合约则依据公司股息公告，持多头将获得股息，空头需支付股息，提前了解除息日有助于优化收益管理。",
  股息调整明细: "股息调整明细",
  差价合约交易中相关产品的股息数据:
    "差价合约交易中相关产品的股息数据，按多头（买入）股息和空头（卖出）股息分开显示",
  交易产品: "交易产品",
  多头股息: "多头股息",
  空头股息: "空头股息",
  除息日: "除息日",
  即刻交易:"即刻交易",
  股息的常见问题:"股息的常见问题",
  qaList: [
    {
      q: "1、什么是股息和除息日？",
      a: [
        "股息是上市公司从利润或保留资金中定期分配给股东的资金，当股票派发股息时，相关股票和指数差价合约产品也会进行相应的股息调整。",
        "指数差价合约的除息日是由指数提供商根据成分股的除息日设定的，也是股息发放日。",
      ],
    },
    {
      q: "2. 股息调整的时间",
      a: [
        "指数差价合约股息调整通常会在除息日的05:00-06:00（GMT+3）反映在您的MT4/MT5交易账号中，并标注为“Dividend”。",
      ],
    },
    {
      q: "3. 股息对指数交易的影响",
      a: [
        "持有多头仓位（买入）：股息调整将存入您的MT4/MT5账号。",
        "持有空头仓位（卖出）：股息调整将从您的MT4/MT5账号扣除。",
      ],
    },
    {
      q: "4. 股息调整的计算公式",
      a: [
        "多头仓位（买入）计算公式：手数 × 合约规格 × 多头股息金额（按指数计价货币计算）",
        "空头仓位（卖出）计算公式：手数 × 合约规格 × 空头股息金额（按指数计价货币计算）",
      ],
    },
    {
      q: "5. 股息调整计算示例",
      a: [
        "以SPXUSD为例，假设其除息日为2024年10月11日，多头股息金额为 0.050美元，空头股息金额为-0.050美元。如果您在除息日前一天(10月10日)收盘时持有1标准手的SPXUSD多头仓位（买入），则股息调整的计算如下：",
        "股息调整 = 1（标准手） × 100（合约规格） × 0.050美元（多头股息金额） = 5美元",
        "在除息日的05:00-06:00（GMT+3），5美元将存入您的MT4/MT5账号，备注为“Dividend”。",
        "如果您持有空头仓位（卖出），5美元将从您的MT4/MT5账号中扣除，备注为“Dividend”。",
      ],
    },
    {
      q: "6. 注意事项",
      a: [
        "股息调整仅适用于指数和股票差价合约交易，并会根据持有的仓位类型（多头或空头）相应调整账号余额，股息金额及其影响可能因具体指数和市场条件而异。",
        "如有任何疑问，请联系您的客户经理或查看我们的官方网站获取更多信息。",
      ],
    },
  ],
};
