export default {
  在EBC交易赢梅西签名限量球衣PC: `<h1>Trade with EBC & Win a Limited<br/> Edition Messi Signed Jersey</h1>`,
  在EBC交易赢梅西签名限量球衣Mobile: `<h1>Trade with EBC & Win a Limited<br/> Edition Messi Signed Jersey</h1>`,
  立即参与: "Register Now",
  亲笔签名并装裱: "Hand-Signed & Framed",
  "莱昂内尔·梅西": "Lionel Messi",
  巴塞罗那官方: "Official 2018-19 FC Barcelona Home Jersey",
  赛季主场球衣: "",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "This exclusive jersey bears Lionel Messi’s authentic signature, hand-signed at a private event in Paris on 28 January 2023. It includes an official certificate of authenticity from FC Barcelona, ensuring the signature’s legitimacy. This piece of football history is available on a first-come, first-served basis—do not miss your chance to own it!",
  亲笔签名: "Hand-Signed (Collector’s Edition – Framed)",
  "莱昂内尔·梅西、": "Lionel Messi & ",
  "路易斯·苏亚雷斯": "Luis Suárez",
  "巴萨2016-17赛季主场球衣": "Official 2016-17 FC Barcelona Home Jersey",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名: ` This iconic jersey has been personally signed by two football
              legends, Lionel Messi and Luis Suárez, at private signing events.
              Messi signed it first on 29 September 2023 in Buenos Aires,
              followed by Suárez on 12 October 2023 in Montevideo.
              <br />
              Accompanied by an official certificate of authenticity from
              Icons.com, it guarantees you a prized piece of football history.
              Limited quatities available.`,
  "拉明·亚马尔": "Lamine Yamal",
  "巴萨2024-25赛季主场球衣": "Official 2024-25 FC Barcelona Home Jersey",
  这件巴萨球衣印有亚马尔的名字和球号: `This Barcelona jersey, featuring Yamal’s name and number, represents
            the emergence of a new generation of footballing talent. At just 16
            years old, Yamal has already shattered multiple club records,
            embodying both the rich heritage and the future of Barcelona
            football.
            <br />
            More than a collector’s item, this jersey is a tribute to the rise
            of a new Barça legend.`,
  活动时间: "Event Period:",
  "活动时间:": "Event Period:",
  "2025年3月1日": "1 March 2025 - 31 March 2025",
  活动规则: "Rules:",
  交易量PK:
    "Trading Volume: The trading account with the highest monthly trading volume (excluding US stocks) will receive a Messi signed jersey.",
  入金量PK:
    "Deposit Amount: The trading account with the highest net deposit in a single month will receive a Yamal jersey.",
  活动条款: "Terms & Conditions: ",
  list: [
    "EBC traders will be automatically enrolled in this event beginning in March. This is a global event.",
    "Each account is only eligible for one prize.",
    "Winners will be announced on EBC's official website and EBC's official X account within seven business days from the event end date. Winners will also receive an email.",
    "The EBC team will thoroughly review all participating accounts. Any account not meeting security policies will be disqualified from receiving rewards. This includes multiple accounts created on the same device, which will be considered duplicates and ineligible for rewards.",
    "EBC Financial Group reserves the right to modify the event terms without prior notice.",
    "EBC Financial Group reserves the right to make any final decisions. For any inquiries, please contact customer support.",
  ],
};
