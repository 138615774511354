export default {
	"pages1":{
		"title":"EBC智慧跟單社區",
		"tips":"每個交易員心中都有一個英雄夢",
		"text":"跟單社區優勢",
		"text1":"信號源展示",
		"text2":"交易領薪計劃",
		"text3":"增值豪禮",
		"text4":"登入社區"
	},
	"pages2":{
		"title":"跟單社區優勢",
		"list": [  
		{  
		"tit": "「更靈活」<br/>跟單模式",  
		"tips": "無需使用EA外掛實現雙向跟隨<br/>靈活調整跟單管道<br/>玩轉客製化交易"  
		},  
		{  
		"tit": "「毫秒級」<br/>跟單速度",  
		"tips": "基於MT底層通訊協定<br/>有效連接交易帳戶和訊號方<br/>即刻響應如影隨形"  
		},  
		{  
		"tit": "「更專業」<br/>五維數據分析",  
		"tips": "引入獨家五維圖指標<br/>基金評級體系邁向新高度<br/>優質訊號呼之欲出"  
		},  
		{  
		"tit": "「更智慧」<br/>AI選擇助手",  
		"tips": "根據跟單偏好<br/>一鍵篩選目標訊號<br/>全球頂級交易策略盡收囊中"  
		},  
		{  
		"tit": "「更透明」<br/>交易展示",  
		"tips": "訊號源歷史訂單可追溯<br/>操作細節一覽無遺<br/>領略學習交易大師手筆"  
		}  
	],
		"btn":"登入跟單社區"
	},
	"pages3":{
		"title":"信號源展示",
		"text":"收益率",
		"text1":"最大回撤",
		"text2":"夏普比率",
		"text3":"獲利係數",
		// "text3":"交易勝率",
		"text4":"免費",
		"text5":"即刻訂閱",
		"btn":"查看更多訊號"
	},
	"pages4":{
		"title":"交易領薪計劃",
		"num":"獎勵$100/月",
		"num1":"奖励  $300/月",
		"num2":"奖励  $1,000/月",
		"num3":"奖励  $5,000/月",
		"num4":"奖励  $10,000/月",
		"text1":"管理資金",
		"text2":"跟隨用戶數",
		"name":"新銳交易員",
		"name1":"精英交易員",
		"name2":"冠軍交易員",
		"name3":"明星交易員",
		"name4":"傳奇交易員",
		"tips":"+1%管理資金/年（按月付）",
		"btn":"即刻加入計畫"
	},
	"pages5":{
		"title":"增值豪禮",
		"text":"7X24客服",
		"text1":"EBC行銷互動",
		"text2":"VPS贊助",
		"text3":"交易書籍贊助",
		"text4":"訂單流使用權",
		"text5":"EBC大禮包",
		"text6":"訊號置頂展示",
		"text7":"訊號置頂展示",
		"text8":"媒體扶持",
		"text9":"戰略合作",
		"text10":"海外基金產品<br/>發行顧問服務",
		"btn":"登入跟單社區"
	},
	"QA": {  
		"title": "跟單社區常見問題",  
		"Q1": "1.什麼是跟單交易？",  
		"A1": "跟單交易是指透過跟單系統自動複製其他交易者的策略。您可以藉鏡經驗豐富的交易者決策，減少市場監控壓力，提高潛在收益。",  
		"Q2": "2.跟單交易如何運作？",  
		"A2": "跟單交易是一種可以自動複製專家投資者交易的方式。透過跟單交易平台，您可以直接借助他們的專業知識，潛在地提升回報，而無需自己時刻監控市場行情。",  
		"Q3": "3. 使用跟單交易平台有哪些優點？",  
		"A3": "跟隨優秀交易員的策略，無需自己分析市場。只要設定好跟單策略，便可複製表現優異的交易者的訂單，提升收益的穩定性。",  
		"Q4": "4.跟單交易者可以使用哪些工具？",  
		"A4": "平台提供交易訊號、五維資料分析、歷史訂單和交易員等級等資料工具，幫助您了解市場動態，分享策略，優化交易。",  
		"Q5": "5.跟單交易有哪些風險？",  
		"A5": "跟單交易存在市場波動風險及跟隨不佳交易者的風險。選擇合適的交易者並使用風險管理工具，有助於減少潛在損失。",  
		"Q6": "6.我可以客製我的跟單交易策略嗎？",  
		"A6": "可以，EBC平台支援用戶客製化個人化的跟單策略。"  
	},
	"box-name" : "copytrading-body cn",
}