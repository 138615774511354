export default {
  logo:require("@/assets/image/win/logo_jp.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "第2回EBC100万ドル取引大会",
  免费复制冠军交易: "上位の取引を無料でコピー",
  共赴巴萨颁奖盛典: "バルセロナでの授賞式",
  EBC全球百万美元交易大赛: `EBC 100万ドル取引大会がパワーアップして帰ってきました！<br/>ベテランレーダーから初心者トレーダーまで、コピトレシステムを活かした公平・透明な取引環境で取引スキルを競います。<br/>さらに、優秀なトレーダーを無料でフォローし、取引をコピー可能です。<br/>大会優勝者はFCバルセロナの本拠地で行われる授賞式に招待いたします。`,
  大赛奖池: "賞金",
  距开赛: "取引大会開始まであと",
  距结束:"終了まであと",
  一键跟单: "コピートレード",
  免费复制冠军收益: "参加者の取引シグナルを<br/>コピー可能",
  公开透明: "公正・透明",
  观摩冠军交易技巧: "参加者の取引履歴を閲覧可能",
  推荐有礼: "紹介キャンペーン",
  推荐有奖上不封顶: "大会参加者を紹介して報酬を無制限に獲得",
  双赛道机制: "2つのコースを選択可能",
  梦之队: "100万ドルチャレンジコース",
  梦之队HTML: "100万ドルチャレンジコース",
  以交易场的传控技术角逐百万: "1万ドル以上の入金で100万ドルの賞金を目指す",
  青训营: "3万ドルチャレンジコース",
  青训营HTML: "3万ドルチャレンジコース",
  "像天才新星释放天赋「一球成名」": "500ドル以上の入金で3万ドルの賞金を目指す",
  冠军: "1位",
  亚军: "2位",
  季军: "3位",
  第四至五名: "4～5位",
  第六至十名: "6～10位",
  "$1,000,000交易账号或$200,000现金":
    "20万ドルの賞金または100万ドル分の取引口座 ",
  立即参赛: "今すぐ口座開設",
  巴萨荣耀之旅: "優勝者特典 - FCバルセロナへの招待",
  每笔订单都是攻守博弈的艺术: `大会優勝者はFCバルセロナの本拠地であるカンプノウへご招待いたします。`,
  邀您赴巴萨训练营: "バルサアカデミークリニックの見学",
  亲身体验冠军的致胜之旅: "サッカーの帝王学にふれる",
  于巴萨博物馆为您加冕: "FCバルセロナ博物館の訪問",
  巴萨颁奖典礼: "栄光の歴史に触れ、未来に思いを馳せる",
  巴萨绿茵场现场观赛: "FCバルセロナのホームゲームを観戦",
  亲临VIP坐席见证球星逐梦诺坎普: "カンプノウのVIP席で白熱の試合を観戦",
  大赛推荐官: "取引大会紹介キャンペーン",
  交易即享推荐奖励: "取引して紹介報酬を獲得!上限なし!",
  "/人": "/名",
  "3步推荐": "3つの簡単なステップ: 紹介、取引、獲得!",
  推荐好友: "知り合いを紹介",
  好友交易: "被紹介者が取引",
  赢得奖金: "報酬を獲得",
  立即推荐: "今すぐ紹介",
  实时赛况: "最新ランキング",
  排名: "ランキング",
  昵称: "ニックネーム",
  账号: "取引口座番号",
  收益额: "利益額",
  收益率: "利益率",
  奖金: "賞金",
  跟单: "フォロー",
  暂未开赛: "近日公開予定",
  该页面仅展示收益表现排名前10的交易账号: `*このページでは、実績トップ10の取引口座のみを表示します。米ドルで表示され、1時間毎に自動更新されます。<br/>
*ご自身のランキング順位はマイページにログインしたあとに、ご確認いただけます。`,
  赛事进程: "大会スケジュール",
  推荐开始: "紹介開始",
  "2025年2月22日":"2025年2月22日（土）",
  比赛开始: "大会開始",
  "2025年3月1日":"2025年3月1日（土）",
  推荐结束: "紹介期間終了",
  "2025年5月23日":"2025年5月24日（土）",
  比赛结束: "大会終了",
  "2025年5月30日":"2025年5月31日（土）",
  颁奖典礼: "授賞式",
  "2025年":"2025年内予定",
  赛组规则: "大会ルール",
  类别: "コース",
  账号要求: "参加資格",
  "新交易账号，且账号余额≥$500": "口座残高が500ドル以上の新規取引口座",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "口座残高が1万ドル以上20万ドル以下の<br/>新規取引口座",
  排名规则: "順位決定方法",
  以收益率排名为评判标准: "利益率でランキングされます。",
  "收益率=期末净值/账号累积入金":
    "利益率 = (純利益額 / 累積入金額)*100％",
  以收益额排名为评判标准: "純利益額でランキングされます。",
  "收益额=期末净值+出金-入金": "純利益額 = 最終残高額 + 出金額 - 入金額",
  持仓限制: "ポジションの制限",
  每个参赛账号最多持有100手仓位:
    "各アカウントの最大オープンポジションは<br/>100 lotsとなります。",
  无持仓限制: "ポジション無制限",
  参赛条款: "",
  参赛须知: "大会概要",
  section6List: [
    {
      t: "1.開催期間  ",
      c: [
        "大会期間：2025年3月1日（土）08:00:00～2025年5月31日（土）05:59:59（日本時間）  ",
      ],
    },
    {
      t: "2.参加資格  ",
      c: [
        "大会参加に使用する取引口座（大会用口座）はEBCのコピートレードコミュニティーでシグナル提供者として登録する必要があります。ただし、大会用口座のシグナル公開設定（公開または未公開）に指定はありません。",
        "参加者は複数の大会用口座で参加できますが、最も成績の良い口座のみがランキングに表示されます。",
        "公平性を期すため、大会用口座はSTD口座およびPRO口座のみとされます。PAMM口座やスワップフリー口座では参加できません。",
      ],
    },
    {
      t: "3.賞金について",
      c: [
        "1位入賞者は20万ドルの賞金または100万ドル分の入金済取引口座のいずれかを選ぶことができます。100万ドルの取引口座を選択した場合、当該口座における最大損失限度額は20万ドルとなります。また、当該口座から出金される場合は、20万ドルと実現損益金額の合計となります。 ",
        "その他の賞金は順位に応じて授与されます。 ",
      ],
    },
    {
      t: "4.禁止事項 ",
      c: [
        "口座間のアービトラージ取引や、複数の口座によるクロスポジション、ヘッジ取引などの不公正取引行為を行った場合は、参加資格が剥奪されます。",
        "サーバー故障や価格配信エラーなどのような不可抗力による注文は無効とします。",
      ],
    },
    {
      t: "5.本イベントの最終解釈権は当社に帰属します。",
      c: [],
    },
  ],

  参赛流程: "参加方法",
  注册开户: "新規登録",
  注册并开设新交易账号: "新規取引口座の開設",
  报名参赛: "参加登録",
  在用户中心报名参赛: "ログインして参加登録",
  参赛成功: "参加登録完了",
  参赛成功查看实时赛况: "ランキングを確認可能",
  往届回顾: "過去の大会の様子",
  查看更多: "もっと見る",
  常见问题: "よくある質問",
  qaList: [
    {
      t: "1. 「EBC口座」と「取引口座」について",
      c: [
        "EBC口座：EBCのマイページにログインするアカウントを指します。お客様は本人確認認証後、1つのEBC口座（UID）しか持つことができません。",
        "取引口座：MT4/MT5の取引口座を指します。各EBC口座の下に複数の取引口座を作成することができます。",
      ],
    },
    {
      t: "2.大会への登録状況はどのように確認すればよいですか？",
      c: ["登録が完了すると、マイページの大会ページでお客様ご自身のランキングを確認することができます。"],
    },
    {
      t: "3. 複数の取引口座で参加できますか？",
      c: [
        "各チャレンジコースに複数の取引口座で参加することはできますが、ランキングには最も成績の良い取引口座のみが表示されます。また、同一の取引口座は一つのコースのみ参加が可能です。",
      ],
    },
    {
      t: "4.二つのコースから賞金を同時に獲得することはできますか？",
      c: ["できます。大会ルールに沿って参加した場合、二つのコースから賞金を獲得することができます。"],
    },
    {
      t: "5.すでにMT4口座を持っていますが、大会に参加できますか?",
      c: [
        "取引口座は、2025年2月22日08:00:00（日本時間）以降に作成された新規取引口座で、取引履歴がないことが必要です。",
      ],
    },
    {
      t: "6. 3万ドルチャレンジコースに参加した後、1万ドル以上を入金した場合、コースは変更できますか?",
      c: ["いいえ、コースは登録時に選択されるため、その後の残高変動がコースの選択に影響することはありません。"],
    },
    {
      t: "7. 大会ランキングはどのように確認できますか？",
      c: [
        "ランキングは1時間ごとに更新され（毎正時ではありません）、大会ページで確認できます。",
      ],
    },
    {
      t: "8. 大会期間中に入出金はできますか？",
      c: [
        "入出金は可能です。大会期間中の出金はランキングには影響しませんが、100万ドルチャレンジコースの累積入金限度額は20万ドルとなります。この金額を超えると失格となりますのでご注意ください。",
      ],
    },
    {
      t: "9. 紹介報酬の受け取り方法は？",
      c: [
        "紹介報酬は、被紹介者が有効な大会用口座で初回取引を行った30日後に紹介者の口座残高に反映されます。出金は申請承認後、ご指定の出金方法で出金いただけます。",
      ],
    },
    {
      t: "10.なぜ被紹介者は別のパートナー（IBや紹介者）に紐つけることができないのですか？",
      c: [
        "公平性を確保するためです。そのため、他のパートナー（IBや紹介者）に紐つけることはできません。",
      ],
    },
    {
      t: "11. 大会取引口座の情報は公開されますか？",
      c: [
        "はい、大会参加者の取引状況はコピートレードコミュニティーで公開されます。大会に参加していないユーザーは、公開された取引戦略をコピーすることができます。",
      ],
    },
    {
      t: "12. 大会用口座を使ってコピートレードを行うことはできますか？",
      c: [
        "公平性を期すため、他の大会参加者の取引をコピーして大会に参加することはできません。大会に参加しない取引口座で取引をコピーしてください。",
      ],
    },
    {
      t: "13. 2人の参加者が同じ利益率または利益額を得た場合はどうなりますか？",
      c: [
        "2人の参加者が同じ利益率または利益額を得た場合、取引量によって順位が決定され、取引量の多い参加者が上位となります。",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000:"$30,000",
  $10000:"$10,000",
  $5000:"$5,000",
  $3000:"$3,000",
  $2000:"$2,000",
  $1000:"$1,000",
  rankValueList: [
    "20万ドルの賞金または100万ドル分の取引口座 ",
    "$30,000",
    "$10,000",
    "$5,000",
    "$5,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
  ],
  rankValueList2: [
    "$30,000",
    "$5,000",
    "$3,000",
    "$2,000",
    "$2,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
  ],
  ebclink:"https://www.ebc.com/jp/win1",
  第一届大赛冠军:"前回大会1位",
  第一届大赛亚军:"前回大会2位",
  第一届大赛季军:"前回大会3位"
};
