export default {
  logo: require("@/assets/image/win/logo_th.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "แข่งเทรด EBC Million Dollar Trading Challenge II",
  免费复制冠军交易: "Copy Trade แชมป์ฟรี",
  共赴巴萨颁奖盛典: "ฉลองแชมป์ที่ Barcelona",
  EBC全球百万美元交易大赛: `EBC Million Dollar Trading Challenge รวบรวมนักเทรดจากทั่วโลก ร่วมทดสอบทักษะและแสดงศักยภาพกลยุทธ์การเทรดในสนามแข่งขัน ในฐานะพันธมิตรของ FC Barcelona เรานำปรัชญาฟุตบอลและการเทรดผสมผสานเข้าด้วยกัน เปิดโอกาสปลุกความเป็นแชมป์เปี้ยนในตัวคุณ ไม่ว่าคุณจะเทรดเก่งหรือไม่ ในการแข่งนี้คุณจะได้เรียนรู้เคล็ดลับการทำกำไรได้แบบแชมป์ ผู้ชนะจะได้รับเชิญร่วมพิธีรับรางวัลที่ FC Barcelona สัมผัสประสบการณ์การเทรดและความยิ่งใหญ่ของสโมสรฟุตบอลในตำนาน`,
  大赛奖池: "เงินรางวัลรวม",
  距开赛: "นับถอยหลัง",
  距结束: "สิ้นสุดการแข่งขัน",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "ติดตามสัญญาณฟรี",
  公开透明: "เปิดเผยอย่างโปร่งใส",
  观摩冠军交易技巧: "ศึกษากลยุทธ์ของแชมป์",
  推荐有礼: "รางวัลผู้แนะนำ",
  推荐有奖上不封顶: "แนะนำได้ไม่จำกัด",
  双赛道机制: "รายการแข่งขัน",
  梦之队: "All Star Challenge",
  梦之队HTML: "All Star Challenge",
  以交易场的传控技术角逐百万: "ทดสอบกลยุทธ์เทรด ชิงรางวัล $1,000,000",
  青训营: "New Star Challenge",
  青训营HTML: "New Star Challenge",
  "像天才新星释放天赋「一球成名」": "ปลดล็อคศักยภาพ เทรดแบบมือโปร ",
  冠军: "รางวัลอันดับ 1",
  亚军: "รางวัลอันดับ 2",
  季军: "รางวัลอันดับ 3",
  第四至五名: "รางวัลอันดับ 4-5",
  第六至十名: "รางวัลอันดับ 6-10",
  "$1,000,000交易账号或$200,000现金":
    "บัญชีเทรดมูลค่า $1,000,000  หรือ เงินสด $200,000 ",
  立即参赛: "เข้าร่วมแข่งขัน",
  巴萨荣耀之旅: "เส้นทางแชมป์สู่ FC Barcelona",
  每笔订单都是攻守博弈的艺术: `เกมรุกที่เฉียบคม แนวรับที่แข็งแกร่ง คือ กุญแจสู่ชัยชนะ`,
  邀您赴巴萨训练营: "เข้าชมสนามคัมป์นู",
  亲身体验冠军的致胜之旅:
    "ดูการฝึกซ้อมของนักเตะดาวรุ่งเส้นทางสู่นักเตะระดับโลก",
  于巴萨博物馆为您加冕: "ชมพิพิธภัณฑ์ FC Barcelona",
  巴萨颁奖典礼:
    "ย้อนรอยประวัติศาสตร์ ชมความยิ่งใหญ่ของสโมสร ร่วมเฉลิมฉลองในพิธีรับรางวัล",
  巴萨绿茵场现场观赛: "ชมการแข่งขันติดขอบสนาม",
  亲临VIP坐席见证球星逐梦诺坎普:
    "เชียร์นักเตะเวิลด์คลาสแบบ VIP ที่สนามที่คัมป์นู",
  大赛推荐官: "โปรแกรมผู้แนะนำ",
  交易即享推荐奖励: "รับรางวัลแนะนำได้ไม่จำกัด",
  "/人": "/คน",
  "3步推荐": "แนะนำง่ายๆ 3 ขั้นตอน รับเงินรางวัลทันที",
  推荐好友: "แนะนำเพื่อน",
  好友交易: "เพื่อนเข้าร่วมแข่งขัน",
  赢得奖金: "รับรางวัลแนะนำ",
  立即推荐: "แนะนำทันที",
  实时赛况: "ตารางจัดอันดับเรียลไทม์",
  排名: "อันดับ",
  昵称: "ชื่อผู้แข่งขัน",
  账号: "หมายเลขบัญชี",
  收益额: "Profit",
  收益率: "Profit Rate (%)",
  奖金: "เงินรางวัล",
  跟单: "Copy Trading",
  暂未开赛: "เริ่มต้นแข่งขันเร็วๆ นี้ โปรดติดตาม ",
  该页面仅展示收益表现排名前10的交易账号:
    "*หน้านี้จะแสดงอันดับบัญชีที่มีผลกำไรสูงสุด 10 อันดับแรก หน่วยเงินที่ใช้ในการจัดอันดับ คือ ดอลลาร์สหรัฐ (USD) ตารางจะมีการอัปเดตรายชั่วโมง",
  赛事进程: "ไทม์ไลน์การแข่งขัน",
  推荐开始: "เริ่มต้นแนะนำ",
  "2025年2月22日": "22 ก.พ. 2025",
  比赛开始: "เริ่มต้นแข่งขัน",
  "2025年3月1日": "1 มี.ค. 2025",
  推荐结束: "สิ้นสุดการแนะนำ",
  "2025年5月23日": "23 พ.ค. 2025",
  比赛结束: "สิ้นสุดการแข่งขัน",
  "2025年5月30日": "30 พ.ค. 2025",
  颁奖典礼: "พิธีรับรางวัล",
  "2025年": "ภายในปี 2025",
  赛组规则: "กติกาการแข่งขัน",
  类别: "ประเภทการแข่งขัน",
  账号要求: "ข้อกำหนดบัญชี",
  "新交易账号，且账号余额≥$500": "บัญชีเทรดใหม่และมียอดเงินคงเหลือ ≥ $500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "บัญชีเทรดใหม่และมียอดเงินคงเหลือในบัญชีตั้งแต่ $10,000 ≤ $200,000",
  排名规则: "เกณฑ์จัดอันดับ",
  以收益率排名为评判标准: "การจัดอันดับวัดจาก Profit Rate",
  "收益率=期末净值/账号累积入金":
    "Profit Rate (%) = (Net Profit / ยอดฝากรวมสุทธิ) x 100%",
  以收益额排名为评判标准: "การจัดอันดับวัดจาก Profit",
  "收益额=期末净值+出金-入金": "Profit = Final Equity + เงินถอน - เงินฝาก",
  持仓限制: "ข้อจำกัดการออกออเดอร์",
  每个参赛账号最多持有100手仓位: "ทุกประเภทบัญชีออกล็อตได้สูงสุด 100 ล็อต",
  无持仓限制: "ออกออเดอร์ได้ไม่จำกัด",
  参赛条款: "เงื่อนไขการแข่งขัน",
  参赛须知: "ข้อควรระวัง",
  section6List: [
    {
      t: "1. ระยะเวลาแข่งขัน",
      c: [
        "1 มี.ค. 2025 เวลา 01:00:00 (เวลา MT4/MT5) - 30 พ.ค. 2025 เวลา 23:59:59 (เวลา MT4/MT5)",
      ],
    },
    {
      t: "2. ข้อกำหนดบัญชี",
      c: [
        "บัญชีที่เข้าร่วมต้องแสดงสัญญาณในชุมชน Copy trade และเปิดให้นักเทรดคนอื่นคัดลอกสัญญาณได้ฟรี",
        "ผู้เข้าแข่งขันสามารถใช้หลายบัญชีในการแข่งขัน แต่จะเลือกเฉพาะบัญชีที่มีผลการเทรดดีที่สุดในการจัดอันดับเท่านั้น",
        "เพื่อความเป็นธรรมในการแข่งขัน อนุญาตให้ใช้บัญชี STD และ PRO ในการแข่งขันเท่านั้น ส่วนบัญชี PAMM และบัญชีอิสลามจะไม่มีสิทธิ์เข้าร่วมในการแข่งขันครั้งนี้",
      ],
    },
    {
      t: "3. รายละเอียดรางวัล",
      c: [
        "รางวัลอันดับ 1 : สามารถเลือกรับเป็นเงินสด $200,000 หรือบัญชีเทรดมูลค่า $1,000,000 ซึ่งหากเลือกรับรางวัลเป็นบัญชีเทรด สามารถขาดทุนสูงสุด (drawdown) ไม่เกิน $200,000",

        "รางวัลอื่นๆ จะมอบตามอันดับการแข่งขัน",
      ],
    },
    {
      t: "4. ข้อห้ามการแข่งขัน",
      c: [
        "ห้ามใช้บัญชีเทรดอื่นๆ ในทางมิชอบ เพื่อให้บัญชีที่เข้าแข่งขันได้ผลกำไรที่มากขึ้น เช่น การเทรดอาร์บิทราจระหว่างบัญชี เพื่อเพิ่มผลกำไร การเปิดสถานะข้ามบัญชี การเทรดแบบป้องกันความเสี่ยง หรือการใช้ประโยชน์จากการเสนอราคา  ซึ่งจะส่งผลให้ถูกตัดสิทธิ์จากการแข่งขันทันที",
        "คำสั่งซื้อขายที่เกิดจากปัญหาของเซิร์ฟเวอร์ หรือข้อผิดพลาดของราคาจะถือเป็นโมฆะ",
      ],
    },
    {
      t: "5. คำชี้แจงผู้ถือสิทธิ์",
      c: [
        "EBC Financial Group มีสิทธิ์ในการตีความและตัดสิน ทุกกรณีที่เกี่ยวข้องกับการแข่งขันครั้งนี้",
      ],
    },
  ],

  参赛流程: "ขั้นตอนเข้าร่วมแข่งขัน",
  注册开户: "ลงทะเบียนเปิดบัญชี",
  注册并开设新交易账号: "ลงทะเบียนเปิดบัญชีเทรดใหม่",
  报名参赛: "สมัครเข้าร่วมแข่งขัน",
  在用户中心报名参赛: "ลงทะเบียนเข้าร่วมที่ศูนย์ผู้ใช้",
  参赛成功: "เข้าร่วมแข่งขันสำเร็จ",
  参赛成功查看实时赛况:
    "เข้าร่วมแข่งขันสำเร็จ คุณสามารถดูผลการแข่งขันได้เรียลไทม์",
  往届回顾: "รีวิวการแข่งขันครั้งที่ผ่านมา",
  查看更多: "ดูเพิ่มเติม",
  常见问题: "คำถามที่พบบ่อย",
  qaList: [
    {
      t: `1. "บัญชี" กับ "หมายเลขบัญชี"`,
      c: [
        "บัญชี: หมายถึงบัญชีที่คุณใช้เข้าสู่ระบบในศูนย์ผู้ใช้ EBC (UID) ซึ่งคุณสามารถมีได้เพียงบัญชีเดียวหลังจากที่คุณทำการยืนยันตัวตนเสร็จเรียบร้อยแล้ว",
        "หมายเลขบัญชี: หมายถึงหมายเลขบัญชีการเทรดในระบบ MT (MT trading account) ที่สามารถเปิดได้หลายบัญชีเทรดภายใต้บัญชีผู้ใช้ EBC เดียวกัน เพื่อใช้ในการเทรดจริง",
      ],
    },
    {
      t: "2. วิธีการตรวจสอบว่า คุณเข้าร่วมการแข่งขันสำเร็จแล้วหรือไม่?",
      c: [
        "หากคุณเข้าร่วมแข่งขันสำเร็จ หน้าศูนย์ผู้ใช้งานจะแสดงบัญชีเทรดที่เข้าร่วมการแข่งขันและอันดับปัจจุบัน พร้อมทั้งจะได้รับอีเมลยืนยันการเข้าร่วมแข่งขัน",
      ],
    },
    {
      t: "3. สามารถใช้หลายบัญชีในการเข้าร่วมการแข่งขันได้หรือไม่?",
      c: [
        "ผู้เข้าแข่งขันสามารถใช้หลายบัญชีเทรดในการแข่งขัน และในแต่ละบัญชีเทรด สามารถเข้าร่วมได้เพียงรายการเดียวเท่านั้น",
      ],
    },
    {
      t: "4. หากได้รับรางวัลทั้ง 2 รายการ สามารถรับรางวัลได้ทั้ง 2 รางวัลเลยหรือไม่?",
      c: [
        "สามารถรับรางวัลได้ทั้ง 2 รายการ หากเข้าร่วมการแข่งขันทั้ง 2 รายการ และจะได้รับรางวัลตามข้อกำหนดที่ระบุ",
      ],
    },
    {
      t: "5. หากมีบัญชี MT4 อยู่แล้ว สามารถเข้าร่วมการแข่งขันได้หรือไม่?",
      c: [
        "ได้ หากบัญชี MT4 สร้างขึ้นในหรือหลังวันที่ 22 ก.พ. 2025 เวลา 01:00:00 (เวลา MT4/MT5) และต้องไม่มีประวัติการเทรดมาก่อน",
      ],
    },
    {
      t: "6. หลังจากเข้าร่วมรายการ New Star Challenge หากฝากเงินเข้าบัญชีเกิน $10,000 จะเปลี่ยนรายการแข่งขันหรือไม่?",
      c: [
        "รายการแข่งขันที่คุณเลือกจะไม่เปลี่ยนแปลง หลังจากที่คุณลงทะเบียนเข้าร่วมการแข่งขันในครั้งแรก แม้ยอดเงินในบัญชีจะมีการเปลี่ยนแปลงภายหลังก็จะไม่กระทบต่อรายการการที่คุณเลือก",
      ],
    },
    {
      t: "7. วิธีการตรวจสอบอันดับการแข่งขัน?",
      c: [
        "การจัดอันดับการแข่งขันจะอัปเดตทุกชั่วโมง (โดยประมาณ) และสามารถดูได้ในหน้ากิจกรรมการแข่งขัน",
      ],
    },
    {
      t: "8.ในระหว่างการแข่งขันสามารถฝากหรือถอนเงินได้หรือไม่?",
      c: [
        "ระหว่างการแข่งขัน สามารถฝากและถอนเงินได้ปกติ โดยการถอนเงินจะไม่กระทบต่ออันดับการแข่งขัน แต่การฝากเงินจะมีผลกระทบต่อรายการ All Star Challenge ซึ่งมีข้อกำหนดฝากเงินสูงสุดไม่เกิน $200,000 หากฝากเงินเกินจะถูกตัดสิทธิ์ออกจากการแข่งขันทันที",
      ],
    },
    {
      t: "9. วิธีการเงินรับรางวัลจากการโปรแกรมแนะนำเพื่อน?",
      c: [
        "รางวัลโปรแกรมแนะนำจะถูกโอนเข้าบัญชีของคุณภายใน 30 วันหลังจากที่ผู้ถูกเชิญทำการเทรดครั้งแรกเสร็จสิ้น จากนั้นคุณสามารถยื่นคำขอถอนเงินได้ และรางวัลจะถูกดำเนินการเมื่อสอดคล้องกับเงื่อนไข",
      ],
    },
    {
      t: "10. ทำไมผู้ที่ได้รับเชิญไม่สามารถโอนย้ายตัวแทน IB ได้?",
      c: [
        "เพื่อความยุติธรรม ผู้ที่ได้รับเชิญต้องเข้าร่วมผ่านลิงก์แนะนำและไม่สามารถเลือกตัวแทน IB ได้",
      ],
    },
    {
      t: "11.ข้อมูลบัญชีเทรดที่เข้าร่วมการแข่งขันจะถูกเปิดเผยหรือไม่?",
      c: [
        "บัญชีเทรดที่เข้าร่วมแข่งขัน จะต้องเข้าร่วมชุมชน Copy Trade และเปิดสัญญาณให้ติดตาม โดยบัญชีเทรดที่ไม่ได้เข้าร่วมการแข่งขันสามารถคัดลอกบัญชีเทรดที่เข้าร่วมการแข่งขันได้",
      ],
    },
    {
      t: "12. บัญชีเทรดที่เข้าร่วมแข่งขันสามารถติดตามสัญญาณคนอื่นได้หรือไม่?",
      c: [
        "เพื่อความยุติธรรม บัญชีเทรดที่เข้าร่วมการแข่งขันไม่สามารถติดตามสัญญาณคนอื่นได้ แต่คุณสามารถสร้างบัญชีเทรดใหม่ เพื่อติดตามสัญญาณของนักเทรดคนอื่นๆได้",
      ],
    },
    {
      t: "13. หากผู้เข้าร่วมการแข่งขันมี Profit Rate หรือ Profit เท่ากัน การจัดอันดับจะคำนวณอย่างไร?",
      c: [
        "หากผู้เข้าร่วมการแข่งขันมี Profit Rate หรือ Profit เท่ากัน จะจัดอันดับตามปริมาณการเทรดจากมากไปน้อย โดยผู้ที่มีปริมาณการเทรดสูงกว่าจะได้รับอันดับที่สูงกว่า",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000: "$30,000",
  $10000: "$10,000",
  $5000: "$5,000",
  $3000: "$3,000",
  $2000: "$2,000",
  $1000: "$1,000",
  rankValueList: [
    "บัญชีเทรดมูลค่า $1,000,000  หรือ เงินสด $200,000 ",
    "$30,000",
    "$10,000",
    "$5,000",
    "$5,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
  ],
  rankValueList2: [
    "$30,000",
    "$5,000",
    "$3,000",
    "$2,000",
    "$2,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
  ],
  ebclink: "https://www.ebc.com/th/win1",
  第一届大赛冠军:"แชมป์การแข่งขันครั้งที่ 1",
  第一届大赛亚军:"อันดับ 2 การแข่งขันครั้งที่ 1",
  第一届大赛季军:"อันดับ 3 การแข่งขันครั้งที่ 1"
};
