export default {
  在EBC交易赢梅西签名限量球衣PC: `在<span class="ebc">EBC</span>交易贏梅西簽名限量球衣`,
  在EBC交易赢梅西签名限量球衣Mobile: `<p class="p1">在<span class="ebc">EBC</span>交易</p>
          <p class="p2">贏梅西簽名</p>
          <p class="p3">限量球衣</p>`,
  立即参与: "立即參與",
  亲笔签名并装裱: "親筆簽名並裱框",
  "莱昂内尔·梅西": "萊昂內爾·梅西 ",
  巴塞罗那官方: "巴塞隆納足球俱樂部",
  赛季主场球衣: "2018-19賽季官方主場球衣",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "這件球衣由梅西於2023年1月28日在巴黎的一場私人簽名會上親筆簽名，球衣附帶巴塞隆納俱樂部官方認證證書，確保簽名100%真實可靠。限量珍藏，先到先得，它將是您收藏的一件珍貴體育文化遺產。",
  亲笔签名: "親筆簽名（英雄典藏框裝裱版）",
  "莱昂内尔·梅西、": "萊昂內爾·梅西、",
  "路易斯·苏亚雷斯": "路易斯·蘇亞利斯",
  "巴萨2016-17赛季主场球衣": "巴塞隆納足球俱樂部2016-17賽季官方主場球衣",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名:
    "這件球衣由兩位傳奇球星梅西和蘇亞雷斯親筆簽名，他們分別在不同的私人簽名會上為這件球衣簽名。梅西於2023年9月29日在邁阿密先簽名，隨後蘇亞雷斯於2023年10月12日在巴塞隆納完成簽名。球衣附帶Icons.com官方認證證書，確保簽名100%真實可靠。世界獨特的運動珍品，限量發售，先到先得。",
  "拉明·亚马尔": "拉明·亞馬爾",
  "巴萨2024-25赛季主场球衣": "巴塞隆納足球俱樂部2024-25賽季官方主場球衣",
  这件巴萨球衣印有亚马尔的名字和球号:
    "這件巴薩球衣印有亞馬爾的名字和球號，代表巴薩新一代天才的崛起。 16歲就打破多項俱樂部紀錄的亞馬爾，不僅傳承了巴薩的足球基因，更像徵巴薩的未來。它不僅是球迷收藏品，更見證巴薩新傳奇的誕生。",
  活动时间: "活動時間：",
  "活动时间:": "活動時間：",
  "2025年3月1日": "2025年3月1日 - 2025年3月31日",
  活动规则: "活動規則：",
  交易量PK: `交易量PK:單月交易量最大的用戶（不含美股）即可獲得梅西親簽球衣一件`,
  入金量PK: "入金量PK:單月淨入金（存款）最高用戶即可獲得亞馬爾球衣一件",
  活动条款: "活動條款：",
  list: [
    "EBC投資者3月開始自動參與此活動，本活動針對全球開放。",
    "此活動獎品不可重複疊加，一個帳戶最多只能獲取一次獎勵。",
    "獎勵將於活動結束後的7個工作天在EBC官網跟EBCX官方帳號公佈，我們將透過電子郵件通知獲獎客戶。",
    "EBC團隊將對所有參加活動帳戶進行全面審查，不符合安全政策的帳戶將不計入獎勵計算。違規行為包括但不限於在同一設備上開設多個帳戶，這些帳戶將被視為重複帳戶，不具備獎勵資格。",
    "EBC 金融集團有權修改本次活動的條款。若有更改，恕不另行通知。",
    "EBC 金融集團保留本次活動的最終解釋權。如有任何疑問，請聯絡客服團隊。",
  ],
};
