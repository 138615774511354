export default {
  leftNav: [
    { id: "#Trading", name: "交易" },
    { id: "#Regulation", name: "頂級監管" },
    { id: "#Security", name: "安全保障" },
    { id: "#Fintech", name: "金融科技" },
    { id: "#Black-Box", name: "交易黑盒" },
    { id: "#Private-Room", name: "交易空間" },
    { id: "#Liquidity", name: "流動性" },
    { id: "#Honor", name: "國際榮譽" },
    //{ "id": "#News", "name": "新聞" }
  ],
  pages1: {
    h1: "Trust & Respect",
    title: "每個認真交易的人,都值得被認真對待",
    btn: " 觀看全視頻 ",
  },
  pages2: {
    title: "EBC連接全球熱門交易產品",
    title1: "探索一個充滿機遇和競爭優勢的世界，掌握最真實和穩定的市場價格",
    btn: " 了解交易產品 ",
    text: " 貨幣交易 ",
    text1: " 大宗商品 ",
    text2: " 現貨指數差價合約 ",
    text3: " 股票差價合約 ",
  },
  pages3: {
    title: "全球頂級監管",
    title1:
      "EBC金融集團旗下各公司，在對應司法管轄區域內，均受到嚴格監管並獲得頂級資格許可。",
    btn: "了解EBC官方",
    text: "英國 FCA 監管",
    text1:
      "EBC Financial Group (UK) Ltd<br class='regulation-br'> 英國金融行為監管局 (FCA) 授權和監管。<br class='regulation-br'> 監管號: 927552 ",
    text2: "澳大利亞 ASIC 監管",
    text3:
      "EBC Financial Group (Australia) Pty Ltd<br class='regulation-br'>澳大利亞證券和投資委員會 (ASIC) 授權和監管。<br class='regulation-br'>監管號: 500991",
    text4: "開曼 CIMA 監管",
    text5:
      "EBC Financial Group (Cayman) Ltd<br class='regulation-br'>由開曼金融管理局（CIMA）授權和監管。<br class='regulation-br'>監管號: 2038223",
  },
  pages4: {
    title: "科技賦能交易",
    title1:
      "部署於倫敦LD4、紐約NY4、新加坡SG1、東京TY3、香港HK2的超高速專線連接的獨立服務器，提供超低延遲聚合、智能訂單路由和報價優化引擎，構建全球超高速金融生態體。",
    btn: " 探索底層科技",
    text: "每秒最高聚合訂單 ",
    text1: "極速訂單執行 ",
    text2: "資料傳輸穩定高達 ",
    text3: "同業銀行級別的RAW ECN點差",
  },
  pages5: {
    title: "讓85%以上的訂單成交於更優的價格",
    title1:
      "EBC交易黑盒，精準優化拋單算法，在價格優化率、訂單成交率以及算法穩定性方面， EBC交易黑盒遠遠優於傳統交易系統。",
    btn: " 了解交易黑盒",
  },
  pages6: {
    title: "EBC交易空間",
    title1:
      "無論您是高頻交易者，還是大單交易家，EBC 的 Private Room 都將在流動性匹配基礎上，搭建您的專屬的交易通道，甚至隱藏您的部分交易信息，保護您的“底牌”，這意味著您的訂單不僅能獲得更好的聚合報價，也可以在成交過程中免於狙擊。",
    btn: " 了解交易空間",
  },
  pages7: {
    title: "國際頂級流動性",
    title1:
      "EBC與多家國際頂級銀行建立了長期穩定的合作關係，整合多家優質流動性報價，提供極具競爭力的市場點差，讓您輕鬆享有超低交易成本。",
    btn: "了解EBC合作模式",
  },
  pages8: {
    btn: "瀏覽更多新聞",
  },
  pages9: {
    title: "全面保障 安心以致",
    title1:
      "EBC將資金安全視為首要原則，客戶資金獨立存放於巴克萊銀行的託管帳戶中，並透過與FCA、Financial Commission、Lloyds of London、AON合作，建立了全面、多層次的安全保障機制。",
    btn: "了解安全保障",
  },
  lang: "tw",

  连接全球热门交易产品: "連結全球熱門交易商品",
  探索一个充满机遇和竞争优势的世界:
    "探索一個充滿機會和競爭優勢的世界，掌握最真實和穩定的市場價格",
  衍生品交易: "外匯交易",
  期货CFDs: "貴金屬及能源",
  全球股票指数CFDs: "全球股票指數CFDs",
  美国股票CFDs: "美國股票CFDs",
  "欧元/美元": "歐元/美元",
  "黄金/美元": "黃金/美元",
  道琼斯工业指数: "道瓊斯工業指數",
  了解交易产品: "了解交易產品",
  全球顶级监管: "全球頂級監管",
  EBC金融集团旗下各公司:
    "EBC金融集團旗下各公司，在對應司法管轄區域內，均受到嚴格監管並獲得頂級資格許可。",
  授权及监管于: "授權及監理於",
  监管号: "監理號",
  英国FCA监管: "英國FCA監管",
  "英国金融行为监管局 (FCA)": "英國金融行為監理局 (FCA)",
  开曼CIMA监管: "開曼 CIMA 監管",
  "开曼群岛金融管理局(CIMA)": "開曼群島金融管理局(CIMA)",
  澳洲ASIC监管: "澳洲 ASIC 監管",
  澳大利亚证券和投资委员会: "澳洲證券和投資委員會 (ASIC)",
  了解EBC官方: "了解EBC官方",
  "全面保障 安心以致": "全面保障 安心以致",
  EBC将资金安全视为首要原则:
    "EBC將資金安全視為首要原則，客戶資金獨立存放於巴克萊銀行的託管帳戶中，並透過與FCA、Financial Commission、Lloyds of London、AON合作，建立了全面、多層次的安全保障機制。",
  了解安全保障: "了解安全保障",
  "连接全球 信赖首选": "連結全球 信賴首選",
  EBC构建全方位服务生态:
    "EBC建構全方位服務生態，專家團隊具有逾30年經驗，業務遍佈全球，擁有廣泛的活躍用戶，在國際舞台上備受讚譽。",
  全球注册用户数: "全球註冊用戶數",
  日均交易订单量: "日均交易訂單量",
  "2024年第三季度为交易订单优化增益": "2024年第三季度為交易訂單優化增益",
  多个: "+",
  "业务覆盖国家/地区": "業務涵蓋國家/地區",
  了解全球视野: "了解全球視野",
  "85%以上": "85%以上",
  的订单成交于更优价格: "的訂單成交於更優價格",
  EBC交易黑盒:
    "EBC交易黑盒，精準優化拋單演算法，在價格優化率、訂單成交率以及演算法穩定性方面， EBC交易黑盒遠優於傳統交易系統。",
  每秒聚合订单: "每秒最高聚合訂單",
  极速订单执行: "極速訂單執行",
  数据传输稳定高达: "資料傳輸穩定高達",
  "行级RAW ECN点差": "同業銀行級別的RAW ECN點差",
  了解金融科技: "了解金融科技",
  EBC全球合作伙伴: "EBC全球合作夥伴",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "EBC與25+國際權威銀行及非銀機構建立長期穩定的合作關係，提供真正的機構流動性深度和 0.0 Pips同業等級原始點差 。此外，EBC聯合牛津大學、攜手 WAGGGS 、與聯合國基金會合力，積極實踐企業責任，建構平等、永續發展的社會。",
  了解更多: "了解更多",
  十大最受欢迎券商: "十大最受歡迎券商",
  最透明交易券商: "最透明交易券商",
  最受好评券商: "最受好評券商",
  卓越执行券商: "卓越執行券商",
  全球最佳交易体验奖: "全球最佳交易體驗獎",
  最受信赖券商: "最受信賴券商",
  最佳CFD券商: "最佳CFD券商",
  全球最佳ECN券商: "全球最佳ECN券商",
  全球新锐券商: "全球新銳券商",
  tabList: [
    `外匯<br class="mobile" />交易`,
    `貴金屬<br class="mobile" />及能源`,
    `全球股指CFDs`,
    `美國股票CFDs`,
  ],
  出售: "出售",
  买入: "買入",
  点差: "點差",
  立即交易: "立即交易",
  致力于让交易者成为盈家: "致力於讓交易者成為全球盈家",
  EBC每个引以为傲的数据背后:
    "EBC每個引以為傲的數據背後，是全球100多個國家投資者的信任。我們努力滿足您在交易旅程中的需求，不負熱愛與夢想，幫助每個認真交易的人，一鍵連結全球機會。",
  月均交易量: "月均交易量",
  "2024年第四季度为交易订单优化增益": "2024-Q4為交易訂單優化增益",
  number1: "1775",
  number1Text: "1775",
  number2: "400",
  number2Text: "400",
  number3: "273",
  number3Text: "273",
  number4: "100",
  number4Text: "100",
  langFlag: "cn",
  亿: "億+",
  万: "萬+",
  "+": "+",
  全球百万美金交易大赛Ⅱ: "全球百萬美金交易大賽Ⅱ",
  免费复制冠军收益: `免費複製 冠軍交易`,
  免费复制:"免費複製",
  立即参赛:"立即參賽",
  该页面仅展示收益表现排名前5的交易账号:"*該頁面僅展示收益表現排名前五的交易帳號，排名貨幣單位為美金（USD），每小時更新"
};
