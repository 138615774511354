<template>
    <div class="page-footer-wrapper " :class="[$t('PageNavigate.classLang'), $t('common.fontFamily')]">
        <div class="page-footer-navigate-box-bg">
            <div class="page-footer-navigate-box">
                <div class="page-footer-logo-box">
                    <div class="page-footer-logo pc">
                        <img class="page-footer-logo-EBC" src="@/assets/image/navigate/footer_logo_EBC.svg" alt="" />
                        <div class="page-footer-logo-line"></div>
                        <img v-if="$t('common.requestLang') == 'zh_CN'" class="page-footer-logo-BARCELONA"
                            src="@/assets/image/navigate/footer_logo_BARCELONA_cn.svg" alt="" />
                        <img v-if="$t('common.requestLang') != 'zh_CN'" class="page-footer-logo-BARCELONA"
                            src="@/assets/image/navigate/footer_logo_BARCELONA.svg" alt="" />
                    </div>
                    <div class="page-footer-entry">
                        <!-- <a class="page-footer-entry-link" href="https://www.instagram.com/ebcfinancialgroup/"
                            target="_blank" rel="nofollow">
                            <span class="iconfont icon-link-ins"></span>
                        </a>

                        <a class="page-footer-entry-link" href="https://www.linkedin.com/company/ebcgroup/"
                            target="_blank" rel="nofollow">
                            <span class="iconfont icon-link-linkedin"></span>
                        </a>

                        <a class="page-footer-entry-link" href="https://www.facebook.com/ebcfinancialgroup/"
                            target="_blank" rel="nofollow">
                            <span class="iconfont icon-link-facebook"></span>
                        </a>

                        <a class="page-footer-entry-link" href="https://x.com/EBCGROUP_Global" target="_blank"
                            rel="nofollow">
                            <span class="iconfont icon-link-x"></span>
                        </a>
                        <a class="page-footer-entry-link"
                            href="https://www.youtube.com/channel/UCsW_7BTWzZxGvn99mlfAHXg" target="_blank"
                            rel="nofollow">
                            <span class="iconfont icon-link-youtube"></span>
                        </a> -->
                        <a href="javascript:;" class="pc to-top" @click="toUp()"><img
                                src="@/assets/image/navigate/footer_icon_top.svg" alt="" /></a>
                    </div>
                    <a href="javascript:;" class="mobile to-top" @click="toUp()"><img
                            src="@/assets/image/navigate/footer_icon_top.svg" alt="" /></a>
                </div>
                <div class="page-footer-links pc">
                    <div class="page-footer-menu-list" v-for="(list, index) in $t('PageFooter.linkList')" :key="index">
                        <div class="page-footer-menu-item-top-link">{{ list.title }}</div>
                        <a :href="item.link" v-for="(item, key) in list.list" :key="key"> {{ item.label }} </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="page-footer-intro-bg">
            <div class="page-footer-intro" v-html="$t('PageFooter.context')">

            </div>
        </div>
        <div class="page-footer-bottom-box-bg">
            <div class="page-footer-bottom-box">
                <div class="page-footer-bottom">
                    <div>© 2024, EBC Financial Group (SVG) LLC. All Rights Reserved.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { method } from 'lodash';


export default {
    data() {
        return {

        }
    },
    mounted() {
        // let toTopBtn = document.getElementsByClassName("to-top");
        // for (let i = 0; i < toTopBtn.length; i++) {
        //     toTopBtn[i].addEventListener("click", function () {
        //         window.scrollTo({
        //             top: 0,
        //             left: 0,
        //             behavior: "smooth",
        //         });
        //     });
        // }
        window.dataLayer = window.dataLayer || [];

        // function gtag() {
        // 	dataLayer.push(arguments);
        // }
        // gtag('js', new Date());
        // gtag('config', 'G-TJ431PDHZX');
        window.__lc = window.__lc || {};
        window.__lc.license = 12340599;
        (function (n, t, c) {
            function i(n) {
                return e._h ? e._h.apply(null, n) : e._q.push(n)
            }
            var e = {
                _q: [],
                _h: null,
                _v: "2.0",
                on: function () {
                    i(["on", c.call(arguments)])
                },
                once: function () {
                    i(["once", c.call(arguments)])
                },
                off: function () {
                    i(["off", c.call(arguments)])
                },
                get: function () {
                    if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                    return i(["get", c.call(arguments)])
                },
                call: function () {
                    i(["call", c.call(arguments)])
                },
                init: function () {
                    var n = t.createElement("script");
                    n.async = !0, n.type = "text/javascript", n.src =
                        "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n)
                }
            };
            !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e
        }(window, document, [].slice))
    },
    methods: {
        toUp() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            this.$emit('up')
        }
    }
}
</script>
<style lang="scss" scoped>
@import url("../../assets/css/page-footer.css");
</style>