export default {
  logo: require("@/assets/image/win/logo_en.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "EBC's Million Dollar Trading Challenge II",
  免费复制冠军交易: "Copy the Champion's Trades for Free",
  共赴巴萨颁奖盛典: "Celebrate Your Victory in Barcelona",
  EBC全球百万美元交易大赛: `EBC's Million Dollar Trading Challenge returns to unite the world's top traders in a battle of skill and strategy. As an official partner of FC Barcelona, we are proud to bring the spirit of football to the trading arena, celebrating boundless passion and true excellence. In addition to the biggest prize pool yet, winners will also receive an exclusive invitation to an awards ceremony at the legendary FC Barcelona grounds to enjoy a once-in-a-lifetime experience.`,
  大赛奖池: "Prize Pool",
  距开赛: "Countdown",
  距结束: "Ends In",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replicate Top Trades with Just One-Click",
  公开透明: "Transparent",
  观摩冠军交易技巧: "Master the Trading Techniques of Champions",
  推荐有礼: "Refer for Rewards",
  推荐有奖上不封顶: "Earn $300 Per Referral with No Limits",
  双赛道机制: "Two Different Challenge Categories",
  梦之队: "Dream Squad",
  梦之队HTML: "Dream Squad",
  以交易场的传控技术角逐百万:
    "Showcase Your Trading Mastery and Earn $1,000,000",
  青训营: "Rising Stars",
  青训营HTML: "Rising Stars",
  "像天才新星释放天赋「一球成名」": "Trade Like a Star, Unleash Your Talent.",
  冠军: "Champion",
  亚军: "Runner-up",
  季军: "Third Place",
  第四至五名: "4th to 5th Place",
  第六至十名: "6th to 10th Place",
  "$1,000,000交易账号或$200,000现金":
    "$1 Million Trading Account; or $200,000 in Cash",
  立即参赛: "Join Now",
  巴萨荣耀之旅: "The Champion's Road to FC Barcelona",
  每笔订单都是攻守博弈的艺术: `Every trade is a delicate balance of attack and defence. Every decision you make can be a game-changing goal in the markets. Pride, glory, and an unforgettable FC Barcelona experience await the victors of EBC's Million Dollar Trading Challenge.`,
  邀您赴巴萨训练营: "Play Like the Pros",
  亲身体验冠军的致胜之旅:
    "Experience a Barça Academy Clinic and learn what it takes to become a football champion.",
  于巴萨博物馆为您加冕: "Write Your Legend",
  巴萨颁奖典礼:
    "Step into Barça's history with an immersive museum tour, then celebrate your trading legacy at an awards ceremony in the museum. ",
  巴萨绿茵场现场观赛: "VIP Home Game Experience",
  亲临VIP坐席见证球星逐梦诺坎普:
    "Watch FC Barcelona's stars chase victory and dominate the league, live and up close.",
  大赛推荐官: "Referral Programme",
  交易即享推荐奖励: "Trade & Earn $300 Per Referral with No Limits.",
  "/人": " / person",
  "3步推荐": "3 Simple Steps: Refer, Trade, and Earn!",
  推荐好友: "Refer Friends",
  好友交易: "Friend Trades",
  赢得奖金: "Win Rewards",
  立即推荐: "Refer Now",
  实时赛况: "Real-Time Leaderboard",
  排名: "Rank",
  昵称: "Nickname",
  账号: "Account Number",
  收益额: "Profit",
  收益率: "Profit Rate (%)",
  奖金: "Prize",
  跟单: "Copy Trading",
  暂未开赛: "Coming Soon, Stay Tuned ",
  该页面仅展示收益表现排名前10的交易账号:
    "*This page only displays the top ten trading accounts ranked by profit performance, with the leaderboard in USD and updated every hour",
  赛事进程: "Event Timeline",
  推荐开始: "Referral Period Begins",
  "2025年2月22日": "2025.2.22",
  比赛开始: "Challenge Begins",
  "2025年3月1日": "2025.3.1",
  推荐结束: "Referral Period Ends",
  "2025年5月23日": "2025.5.23",
  比赛结束: "Challenge Ends",
  "2025年5月30日": "2025.5.30",
  颁奖典礼: "Awards Ceremony",
  "2025年": "2025",
  赛组规则: "Event Rules",
  类别: "Categories",
  账号要求: "Account Requirements",
  "新交易账号，且账号余额≥$500":
    "A new trading account with a starting balance ≥ $500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "A new trading account with a starting balance of $10,000 ≤ $200,000 ",
  排名规则: "Leaderboard Rules",
  以收益率排名为评判标准: "Rankings are determined based on the Profit Rate",
  "收益率=期末净值/账号累积入金":
    "Profit Rate = (Net Profit / Cumulative Deposit)*100%",
  以收益额排名为评判标准: "Rankings are determined based on the Net Profit",
  "收益额=期末净值+出金-入金": "Profit = Final Equity + Withdrawal - Deposit",
  持仓限制: "Position Limits",
  每个参赛账号最多持有100手仓位:
    "Each participating account may hold a maximum position of 100 lots",
  无持仓限制: "No position limits",
  参赛条款: "Event Terms",
  参赛须知: "Event Guidelines",
  section6List: [
    {
      t: "1. Event Duration",
      c: [
        "1 March 2025, 01:00:00 (MT4/MT5 time) - 30 May 2025, 23:59:59 (MT4/MT5 time)",
      ],
    },
    {
      t: "2. Account Requirements",
      c: [
        "The account must be listed as a signal account via EBC's Copy Trading tool to allow others to copy trades for free.",
        "Participants may use multiple accounts to compete, but only the account with the best performance will be considered for ranking.",
        "To ensure the fairness of the event, participation is open exclusively to STD and PRO accounts; PAMM and Islamic accounts are not eligible.",
      ],
    },
    {
      t: "3. Reward Rules",
      c: [
        "Prizes: If the champion chooses the 1,000,000 USD trading account reward, the maximum loss limit is 200,000 USD.",
        "Other prizes will be awarded based on rankings.",
      ],
    },
    {
      t: "4. Prohibited Actions",
      c: [
        "Arbitrage trading between accounts to boost profits is strictly prohibited. Any violations, including cross-account positions, hedging trades, or abuse of quotes, will lead to disqualification.",
        "Orders caused by uncontrollable factors, such as server hardware failure or pricing errors, will be considered invalid.",
      ],
    },
    {
      t: "5. Disclaimer",
      c: [
        "EBC Financial Group reserves the right to make the final decision of this event.",
      ],
    },
  ],

  参赛流程: "Event Process",
  注册开户: "Account Registration",
  注册并开设新交易账号: "Register and Open A New Trading Account",
  报名参赛: "Event Registration",
  在用户中心报名参赛: "Register for the Challenge in the User Portal",
  参赛成功: "Successful Registration",
  参赛成功查看实时赛况: "Once Registered, Track Your Performance in Real-Time",
  往届回顾: "Previous Edition",
  查看更多: "View More",
  常见问题: "Frequently Asked Questions",
  qaList: [
    {
      t: `1. "Account" vs "Account Number"`,
      c: [
        "Account: Refers to the EBC User Portal Account (UID). Each user can only have one account after completing their real-name authentication.",
        "Account Number: Refers to the MT trading account. Multiple trading accounts can be created under the same EBC account for trading purposes.",
      ],
    },
    {
      t: "2. How Can I Confirm My Registration Status for the Event?",
      c: [
        "After successfully registering, the participant account and current ranking will be displayed on the event page in the user portal. You will also receive a confirmation email.",
      ],
    },
    {
      t: "3. Can I Join the Event with Multiple Accounts?",
      c: [
        "You can join the event with multiple accounts, but each of these accounts can only be registered for one category.",
      ],
    },
    {
      t: "4. Can I Claim Prizes from Both Categories?",
      c: [
        "Yes, you can claim rewards from both categories as long as you comply with the rules and complete both challenges successfully.",
      ],
    },
    {
      t: "5. Can I Join the Challenge If I Already Have an MT4 Account?",
      c: [
        "Yes, provided the MT4 account was created on or after 22 February 2025, at 01:00:00 (MT4/MT5 time) and has no previous trading history.",
      ],
    },
    {
      t: "6. After Joining the Rising Stars Category, Will Depositing Over $10,000 Change My Competition Category?",
      c: [
        "No, your category is determined at the time of registration. Subsequent fund changes will not affect the category.",
      ],
    },
    {
      t: "7. How Do I Check My Ranking?",
      c: [
        "Rankings are updated hourly (not on the hour) and can be viewed on the event activity page in the user portal.",
      ],
    },
    {
      t: "8. Can I Deposit or Withdraw Funds During the Event?",
      c: [
        "Yes, you can deposit and withdraw funds. Withdrawals will not affect your ranking; however, total deposits for the Dream Squad are capped at $200,000. Exceeding this limit will lead to disqualification.",
      ],
    },
    {
      t: "9. How Do I Claim My Referral Reward?",
      c: [
        "The referral reward will be credited to your bonus balance 30 days after the invited user completes their first trade. You may then request a withdrawal, and the reward will be processed upon approval.",
      ],
    },
    {
      t: "10. Why Can't Invited Users Transfer Their Agents?",
      c: [
        "To ensure fairness, invited users can only join through the referral link and are not assigned a specific agent.",
      ],
    },
    {
      t: "11. Will my Contest Trading Account Information be Made Public?",
      c: [
        "Yes, your trades will be visible in the copy trading community. Other users who are not in the challenge can follow and copy your trading strategies.",
      ],
    },
    {
      t: "12. My Trading Account Is a Provider Account in the Competition. Can I Use This Account to Copy Trades from Others?",
      c: [
        "To ensure fairness, the provider trading accounts are not allowed to use the copy trade function. However, you may create a new Trading account to access this feature",
      ],
    },
    {
      t: "13. What if Two Participate Have the Same Profit Rate and Profit?",
      c: [
        "If two participants achieve the same profit rate or profit amount, the ranking will be determined by trading volume, with the participant who has a higher trading volume ranking higher.",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000: "$30,000",
  $10000: "$10,000",
  $5000: "$5,000",
  $3000: "$3,000",
  $2000: "$2,000",
  $1000: "$1,000",
  rankValueList: [
    "$1 Million Trading Account; or $200,000 in Cash",
    "$30,000",
    "$10,000",
    "$5,000",
    "$5,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
  ],
  rankValueList2: [
    "$30,000",
    "$5,000",
    "$3,000",
    "$2,000",
    "$2,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
  ],
  ebclink:"https://www.ebc.com/win1",
  第一届大赛冠军:"Champion of the First Edition",
  第一届大赛亚军:"Runner-up of the First Edition",
  第一届大赛季军:"Third Place of the First Edition"
};
