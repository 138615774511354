export default {
  股息调整: "การปรับเงินปันผล",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "ทำความเข้าใจกลไกการปรับและการจัดสรรเงินปันผลของการเทรดหุ้นและดัชนี CFD",
  了解指数差价合约: "เรียนรู้เพิ่มเติมดัชนี CFD",
  了解股票差价合约: "เรียนรู้เพิ่มเติมหุ้น CFD",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "เงินปันผล คือ เงินที่บริษัทแจกจ่ายให้กับผู้ถือหุ้นเป็นประจำจากกำไรหรือกำไรสะสมของบริษัท เมื่อหุ้นจ่ายเงินปันผล ผลิตภัณฑ์ CFD ของหุ้นและดัชนีที่เกี่ยวข้องก็จะมีการปรับเงินปันผลตามที่กำหนดด้วย",
  除息日与交易优化: "วันหยุดจ่ายเงินปันผลและการเพิ่มประสิทธิภาพการเทรด",
  指数差价合约的除息日由指数提供商根据成分股设定:
    "วันหยุดจ่ายเงินปันผลสำหรับดัชนี CFD ถูกกำหนดโดยผู้ให้บริการดัชนีตามวันที่หุ้นพื้นฐานจะหยุดจ่ายเงินปันผล หากคุณถือคำสังซื้อคุณจะได้รับเงินปันผล แต่ถ้าคุณถือคำสั่งขายคุณจะต้องจ่ายเงินปันผล การรู้วันที่เหล่านี้ล่วงหน้าจะช่วยให้คุณจัดการผลตอบแทนได้อย่างมีประสิทธิภาพ",
  股息调整明细: "รายละเอียดการปรับเงินปันผล",
  差价合约交易中相关产品的股息数据:
    "ข้อมูลเงินปันผลสำหรับผลิตภัณฑ์ที่เกี่ยวข้องในการเทรด CFD จะถูกแสดงแยกกันระหว่างเงินปันผลของคำสั่งซื้อ และคำสั่งขาย",
  交易产品: "Trading Products",
  多头股息: "Long Dividends",
  空头股息: "Short Dividends",
  除息日: "Ex-dividend Dates",
  即刻交易: "เริ่มเทรด",
  股息的常见问题: "คำถามที่พบบ่อย",
  qaList: [
    {
      q: "1.เงินปันผลคืออะไร และวันหยุดเงินปันผลคืออะไร?",
      a: [
        "เงินปันผล คือ เงินที่บริษัทแจกจ่ายให้กับผู้ถือหุ้นเป็นประจำจากกำไรหรือกำไรสะสมของบริษัท เมื่อหุ้นจ่ายเงินปันผล ผลิตภัณฑ์ CFD ของหุ้นและดัชนีที่เกี่ยวข้องก็จะมีการปรับเงินปันผลตามที่กำหนดด้วย",
        "วันหยุดจ่ายเงินปันผลสำหรับดัชนี CFD จะถูกกำหนดโดยผู้ให้บริการดัชนีตามวันหยุดเงินปันผลของหุ้นในกลุ่ม ซึ่งจะเป็นวันจ่ายเงินปันผลด้วยเช่นกัน",
      ],
    },
    {
      q: "2.เวลาในการปรับเงินปันผล",
      a: [
        "การปรับเงินปันผลสำหรับดัชนี CFD มักจะแสดงในบัญชี MT4 และ MT5 ของคุณ ในช่วงเวลา 05:00 ถึง 06:00 (GMT+3) ของวันหยุดเงินปันผล โดยจะมีข้อความระบุว่า 'เงินปันผล'",
      ],
    },
    {
      q: "3.เงินปันผลส่งผลกระทบต่อการเทรดดัชนีอย่างไร",
      a: [
        "หากถือคำสั่งซื้อ: การปรับเงินปันผลจะถูกฝากเข้าบัญชี MT4 และ MT5 ของคุณ",
        "หากถือคำสั่งขาย: การปรับเงินปันผลจะถูกหักออกจากบัญชี MT4 และ MT5 ของคุณ",
      ],
    },
    {
      q: "4.สูตรการคำนวณการปรับเงินปันผล",
      a: [
        "สูตรการคำนวณสำหรับคำสั่งซื้อ: จำนวนล็อต × ขนาดสัญญา × จำนวนเงินปันผลสถานะซื้อ (คำนวณเป็นสกุลเงินดัชนี)",
        "สูตรการคำนวณสำหรับคำสั่งขาย: จำนวนล็อต × ขนาดสัญญา × จำนวนเงินปันผลสถานะขาย (คำนวณเป็นสกุลเงินดัชนี)",
      ],
    },
    {
      q: "5.ตัวอย่างการคำนวณการปรับเงินปันผล",
      a: [
        "จำนวน 1 สแตนดาร์ดล็อตในช่วงปิดตลาดก่อนวันหยุดเงินปันผล (วันที่ 10 ตุลาคม) การคำนวณการปรับเงินปันผลจะเป็นดังนี้:",

        "การปรับเงินปันผล = 1 (สแตนดาร์ดล็อต) × 100 (ขนาดสัญญา) × 0.050$ (จำนวนเงินปันผลคำสั่งซื้อ) = 5$",

        "วันหยุดเงินปันผลในช่วงเวลา 05:00 ถึง 06:00 (GMT+3) จำนวน 5$ จะถูกโอนเข้าสู่บัญชี MT4 และ MT5 ของคุณ โดยข้อความระบุว่า 'เงินปันผล'",

        'หากคุณถือคำสั่งขาย จำนวน 5$ จะถูกหักออกจากบัญชี MT4 และ MT5 ของคุณ โดยข้อความระบุว่า "เงินปันผล"',
      ],
    },
    {
      q: "6.รายละเอียดสำคัญ",
      a: [
        "การปรับเงินปันผลใช้เฉพาะสำหรับการเทรด CFD ของดัชนีและหุ้น และจะปรับยอดคงเหลือในบัญชีตามประเภทคำสั่ง (ซื้อหรือขาย) จำนวนเงินปันผลและผลกระทบของมันอาจแตกต่างกันไปตามดัชนีและสภาวะตลาดที่เฉพาะเจาะจง หากมีข้อสงสัยใดๆ รบกวนติดต่อผู้จัดการบัญชีของคุณหรือเยี่ยมชมเว็บไซต์ของเราเพื่อรับข้อมูลเพิ่มเติม",
      ],
    },
  ],
};
