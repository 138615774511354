export default {
  fontFamily: "vi-font-family",
  classLang: "vi",
  headlist: [
    {
      text: "Trang chủ",
      url: "/",
    },
    {
      text: "Giao dịch",
      menu: [
        {
          tit: "Tài khoản & Điều kiện",
          list: [
            {
              text: "Tài khoản giao dịch",
              url: "/TradingAccount",
            },
            {
              text: "Sản phẩm giao dịch",
              url: "/tradingproduct",
            },
            {
              text: "Quỹ đảm bảo/ đòn bẩy",
              url: "/leverage-margin",
            },
            {
              text: "Nạp/Rút tiền",
              url: "/funding",
            },
            {
              text: "Cổ tức",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "Công cụ giao dịch",
          list: [
            {
              text: "Tải xuống MT4",
              url: "/MT4",
            },
            {
              text: "Tải xuống MT5",
              url: "/MT5",
            },
            {
              text: "Công cụ giao dịch MT4",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "Phân tích thị trường",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "Viện nghiên cứu EBC",
      menu: [
        {
          tit: "",
          list: [
            {
              text: "Trung tâm học tập",
              url: "/LearningCenter",
            },
          ],
        },
      ],
    },
    
   
    {
      text: "Về EBC",
      menu: [
        {
          tit: "Về EBC",
          list: [
            {
              text: "WHY EBC",
              url: "/about-us",
            },
            {
              text: "Trách nhiệm xã hội",
              url: "/ESG",
            },
            {
              text: "Các câu hỏi thường gặp",
              url: "https://support.ebchelp.com/hc/en-001",
            },
            {
              text: "Hoạt động gần đây",
              url: "/activity",
            },
          ],
        },
        {
          tit: "Mẫu hợp tác",
          list: [
            {
              text: "FC Barcelona",
              url: "/FCB",
            },
            {
              text: "Quan hệ đối tác",
              url: "/affiliate-programmes",
            },
            {
              text: "Cơ chế phục vụ",
              url: "/service",
            },
            {
              text: "Công nghệ nền tảng",
              url: "/technology",
            },
          ],
        },
      ],
    },
    {
      text: "Trading Challenge",
      url: "million-dollar-challenge-2",
    },
  ],
  FCA开户: "Tài khoản FCA",
  立即开户: "Đăng ký",
  登录: "Đăng nhập",
  官方验证: "Xác minh kênh chính thức",
  帮助中心: "FAQ",
  帮助中心Url: "https://support.ebchelp.com/hc/en-001",
};
