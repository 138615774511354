<template>
    <div class="product-box">
        <div class="product-tab-list">
            <div class="product-tab-item" v-for="(tab, index) in tabList"
                :class="{ 'active-product-tab': index == tabIndex }" @click="changeHandle(index)">
                {{ tab }}
            </div>
        </div>
        <div class="product-table" :class="{ 'grow-product-table': tabIndex == 3 }">
            <div class="product-table-th">
                <div class="product-table-td">Market</div>
                <div class="product-table-td">Bid</div>
                <div class="product-table-td" v-if="tabIndex != 3">Bid Volume</div>
                <div class="product-table-td">Spread</div>
                <div class="product-table-td">Ask</div>
                <div class="product-table-td" v-if="tabIndex != 3">Ask Volume</div>
                <div class="product-table-td-place-hodler"></div>
            </div>
            <div class="product-table-row" v-for="(item, index) in showData" :key="index">
                <div class="product-table-tr" @click="changeInnerTable(index)">
                    <div class="product-table-td">{{ item.symbol }}</div>
                    <div class="product-table-td" :class="[item.tick[0].bidClassName]">
                        {{ item.tick[0].bid_price }}
                    </div>
                    <div class="product-table-td" v-if="tabIndex != 3">{{ item.tick[0].bid_size ? item.tick[0].bid_size
                        : "-"
                        }}</div>
                    <div class="product-table-td">{{ item.tick[0].spread }}</div>
                    <div class="product-table-td" :class="[item.tick[0].askClassName]">
                        {{ item.tick[0].ask_price }}
                    </div>
                    <div class="product-table-td" v-if="tabIndex != 3">{{ item.tick[0].ask_size ? item.tick[0].ask_size
                        : "-"
                        }}</div>
                    <div class="product-table-td-place-hodler">
                        <span class="iconfont icon-a-down" :class="{ 'rotate180': index == dataIndex }"></span>
                    </div>
                </div>
                <div class="product-innertable" v-show="index == dataIndex">
                    <div class="product-innertable-th">
                        <div class="product-innertable-td">layer</div>
                        <div class="product-innertable-td">Bid</div>
                        <div class="product-innertable-td" v-if="tabIndex != 3">Bid Volume</div>
                        <div class="product-innertable-td">Spread</div>
                        <div class="product-innertable-td">Ask</div>
                        <div class="product-innertable-td" v-if="tabIndex != 3">Ask Volume</div>
                    </div>
                    <div class="product-innertable-tr" v-for="(el, i) in item.tick">
                        <div class="product-innertable-td">{{ i + 1 }}</div>
                        <div class="product-innertable-td" :class="[el.bidClassName]">
                            {{ el.bid_price }}
                        </div>
                        <div class="product-innertable-td" v-if="tabIndex != 3">{{ el.bid_size ? el.bid_size : "-" }}
                        </div>
                        <div class="product-innertable-td">{{ el.spread }}</div>
                        <div class="product-innertable-td" :class="[el.askClassName]">
                            {{ el.ask_price }}
                        </div>
                        <div class="product-innertable-td" v-if="tabIndex != 3">{{ el.ask_size ? el.ask_size : "-" }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import md5 from 'js-md5';
export default {
    name: "product-module",
    data() {
        return {
            msg: "测试",
            tabList: ["Forex", "Indices", "Commodities", "Stock CFDs"],
            tableData: [
                {
                    tabName: "Forex",
                    datas: [
                        { symbol: "EURUSD", tick: [] },
                        { symbol: "AUDUSD", tick: [] },
                        { symbol: "GBPUSD", tick: [] },
                        { symbol: "NZDUSD", tick: [] },
                        { symbol: "USDCAD", tick: [] },
                        { symbol: "USDJPY", tick: [] },
                        { symbol: "USDCHF", tick: [] },
                        { symbol: "GBPAUD", tick: [] },
                        { symbol: "GBPJPY", tick: [] },
                    ],
                },
                {
                    tabName: "Indices",
                    datas: [
                        { symbol: "100GBP", tick: [] },
                        { symbol: "200AUD", tick: [] },
                        { symbol: "225JPY", tick: [] },
                        { symbol: "D30EUR", tick: [] },
                        { symbol: "E50EUR", tick: [] },
                        { symbol: "F40EUR", tick: [] },
                        { symbol: "HSIHKD", tick: [] },
                        { symbol: "NASUSD", tick: [] },
                        { symbol: "SPXUSD", tick: [] },
                        { symbol: "U30USD", tick: [] },
                    ],
                },
                {
                    tabName: "Commodities",
                    datas: [
                        { symbol: "XAUUSD", tick: [] },
                        { symbol: "XAGUSD", tick: [] },
                        { symbol: "XBRUSD", tick: [] },
                        { symbol: "XTIUSD", tick: [] },
                        { symbol: "XNGUSD", tick: [] },
                    ],
                },
                {
                    tabName: "Stock CFDs",
                    datas: [
                        { symbol: "AAPL", tick: [] },
                        { symbol: "ADBE", tick: [] },
                        { symbol: "AMGN", tick: [] },
                        { symbol: "AMZN", tick: [] },
                        { symbol: "CMCSA", tick: [] },
                        { symbol: "CSCO", tick: [] },
                        { symbol: "GOOGL", tick: [] },
                        { symbol: "HON", tick: [] },
                        { symbol: "INTC", tick: [] },
                        { symbol: "META", tick: [] },
                        { symbol: "MSFT", tick: [] },
                        { symbol: "NFLX", tick: [] },
                        { symbol: "NVDA", tick: [] },
                        { symbol: "PEP", tick: [] },
                        { symbol: "PYPL", tick: [] },
                        { symbol: "TSLA", tick: [] },
                        { symbol: "TXN", tick: [] },
                        { symbol: "ABT", tick: [] },
                        { symbol: "BA", tick: [] },
                        { symbol: "BAC", tick: [] },
                        { symbol: "BMY", tick: [] },
                        { symbol: "BRKb", tick: [] },
                        { symbol: "CAT", tick: [] },
                        { symbol: "CRM", tick: [] },
                        { symbol: "CVX", tick: [] },
                        { symbol: "DIS", tick: [] },
                        { symbol: "GE", tick: [] },
                        { symbol: "GS", tick: [] },
                        { symbol: "HD", tick: [] },
                        { symbol: "IBM", tick: [] },
                        { symbol: "JNJ", tick: [] },
                        { symbol: "JPM", tick: [] },
                        { symbol: "KO", tick: [] },
                        { symbol: "LLY", tick: [] },
                        { symbol: "LMT", tick: [] },
                        { symbol: "MA", tick: [] },
                        { symbol: "MCD", tick: [] },
                        { symbol: "MDT", tick: [] },
                        { symbol: "MMM", tick: [] },
                        { symbol: "MRK", tick: [] },
                        { symbol: "MS", tick: [] },
                        { symbol: "NEE", tick: [] },
                        { symbol: "PFE", tick: [] },
                        { symbol: "PG", tick: [] },
                        { symbol: "RTX", tick: [] },
                        { symbol: "UNH", tick: [] },
                        { symbol: "V", tick: [] },
                        { symbol: "VZ", tick: [] },
                        { symbol: "WMT", tick: [] },
                        { symbol: "XOM", tick: [] },
                    ],
                },
            ],
            tabIndex: 0,
            dataIndex: 0,
        };
    },
    computed: {
        showData() {
            return this.tableData[this.tabIndex].datas.filter((item) => {
                return item.tick && item.tick.length;
            });
        },
    },
    created() {
        this.getListData();
        let time = new Date().getMilliseconds()
        this.$http('/ebc/api/getToken/run', {
            date: time,
            CheckSum: md5(time + "YISENCE")
        }).then(res => {
            let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
            this.createWebSocket(url)
        })
        // ;
    },
    methods: {
        changeInnerTable(index) {
            if (this.dataIndex == index) {
                this.dataIndex = -1;
            } else {
                this.dataIndex = index;
            }
        },
        changeHandle(index) {
            this.dataIndex = 0;
            this.tabIndex = index;
        },
        // 传入ask,bid,返回点差Spread
        getSpread(t, e) {
            var o = /^\+?[1-9][0-9]*$/,
                r = 0,
                d = 0,
                c = 0;
            return (
                o.test(t) || (d = t.toString().split(".")[1].length),
                o.test(e) || (c = e.toString().split(".")[1].length),
                d > c
                    ? 1 === d
                        ? (r = 10 * t - 10 * e)
                        : 2 === d
                            ? (r = 100 * t - 100 * e)
                            : 3 === d
                                ? (r = 1e3 * t - 1e3 * e)
                                : 4 === d
                                    ? (r = 1e4 * t - 1e4 * e)
                                    : 5 === d && (r = 1e5 * t - 1e5 * e)
                    : 1 === c
                        ? (r = 10 * t - 10 * e)
                        : 2 === c
                            ? (r = 100 * t - 100 * e)
                            : 3 === c
                                ? (r = 1e3 * t - 1e3 * e)
                                : 4 === c
                                    ? (r = 1e4 * t - 1e4 * e)
                                    : 5 === c && (r = 1e5 * t - 1e5 * e),
                r.toFixed(0)
            );
        },
        // 比较大小返回对应类名
        compareValues(value1, value2, className = "") {
            // 尝试将两个值转换为数字
            const num1 = Number(value1);
            const num2 = Number(value2);

            // 检查转换后的值是否都是有效的数字
            if (!isNaN(num1) && !isNaN(num2)) {
                if (num1 >= num2) {
                    return "product-green";
                } else if (num1 < num2) {
                    return "product-red";
                } else {
                    // 两者相等的情况
                    return className;
                }
            }

            // 如果转换后不是有效数字，则返回空字符串
            return "";
        },
        updateTick(symbolToUpdate, newTickValue) {
            newTickValue = newTickValue.map((el) => {
                return {
                    ...el,
                    spread: this.getSpread(el.ask_price, el.bid_price),
                };
            });
            // 遍历tableData中的每个tab
            this.tableData.some((tab) => {
                // 查找datas数组中匹配symbol的项
                const itemIndex = tab.datas.findIndex(
                    (item) => item.symbol === symbolToUpdate
                );

                if (itemIndex !== -1) {
                    // 如果找到了匹配的项
                    // 使用Vue.set确保响应式更新
                    // console.log("tab", tab.datas, itemIndex, newTickValue);
                    newTickValue = newTickValue.map((tickItem, index) => {
                        let t = {
                            askClassName: this.compareValues(tickItem.ask_price),
                            bidClassName: this.compareValues(tickItem.bid_price),
                            ...tickItem,
                        };
                        if (tab.datas[itemIndex].tick[index]) {
                            t = {
                                askClassName: this.compareValues(
                                    tickItem.ask_price,
                                    tab.datas[itemIndex].tick[index].ask_price,
                                    tab.datas[itemIndex].tick[index].askClassName
                                ),
                                bidClassName: this.compareValues(
                                    tickItem.bid_price,
                                    tab.datas[itemIndex].tick[index].ask_price,
                                    tab.datas[itemIndex].tick[index].bidClassName
                                ),
                                ...tickItem,
                            };
                        }

                        return t;
                    });
                    this.$set(tab.datas, itemIndex, {
                        ...tab.datas[itemIndex], // 保留原有数据
                        tick: newTickValue, // 更新tick值
                    });
                    return true; // 找到后退出some循环
                }
            });
        },
        createWebSocket(url) {
            let _this = this;
            var ws = new WebSocket(
                url
            );

            ws.onopen = function () {
                ws.send("连接成功");
            };
            let f = true;
            ws.onmessage = function (event) {
                try {
                    var data = JSON.parse(event.data);
                } catch (e) {
                    return false;
                }
                _this.updateTick(data.symbol, data.tick);
            };

            ws.onerror = function (event) { };

            ws.onclose = function () { };
        },
        // 获取历史数据列表
        getListData() {
            fetch("https://www.ebccfd.com/portal/api/getSymbolAll", {
                method: "get",
            })
                .then((response) => {
                    // 检查请求是否成功
                    if (!response.ok) {
                        throw new Error(
                            "Network response was not ok " + response.statusText
                        );
                    }
                    // 将 Response 对象转换为 JSON 对象
                    return response.json();
                })
                .then((response) => {
                    let data = response.rows;
                    console.log("getSymbolAll data", data);
                    data.map((item) => {
                        let t = {
                            symbol: item.displaySymbol,
                            time: new Date(item.updateTime).getTime(),
                            tick: [
                                {
                                    ask_price: item.ask,
                                    ask_size: "",
                                    bid_price: item.bid,
                                    bid_size: "",
                                    spread: this.getSpread(item.ask, item.bid),
                                },
                            ],
                        };
                        this.updateTick(t.symbol, t.tick);
                    });
                })
                .catch((err) => { });
        },
    },
}
</script>

<style lang="scss" scoped>
.product-box {
    width: 1200rem;
    margin: 0 auto;
    font-size: 16rem;
    font-family: Gilroy;

    .product-tab-list {
        display: flex;
        height: 50rem;
        background: #b2afad;
    }

    .product-tab-item {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        /* font-size: 16rem; */
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 180rem;
        height: 100%;
        border-right: 1px solid #fff;
        cursor: pointer;
    }

    .active-product-tab {
        background: #a52933;
    }

    .product-table-th {
        display: flex;
        height: 64rem;
        background-color: #fff;
    }

    .product-table-th .product-table-td {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 16.66%;
        color: #333;
        font-style: normal;
        font-weight: 700;
        line-height: 1em;
    }

    .grow-product-table .product-table-th .product-table-td {
        width: 23.99%;
    }

    .product-table-tr {
        display: flex;
        height: 64rem;
        background: #f9f6f2;
        cursor: pointer;
    }

    .product-table-tr:hover {
        background: #f4eee7;
    }

    .product-table-tr .product-table-td {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 16%;
        color: #333;
        font-style: normal;
        font-weight: 400;
        line-height: 1em;
    }

    .grow-product-table .product-table-tr .product-table-td {
        width: 23.99%;
    }

    .product-table-td-place-hodler {
        width: 4%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-table-td-place-hodler .icon-a-down {
        font-size: 24rem;
        transition: all 0.3s;
    }

    .rotate180 {
        transform: rotate(180deg);
    }

    .product-innertable {
        padding: 13rem 36rem;
        background-color: #fff;
    }

    .product-innertable-th {
        display: flex;
        height: 46rem;
        background: #b2afad;
    }

    .product-innertable-th .product-innertable-td {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 16.66%;
        color: #fff;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
    }

    .grow-product-table .product-innertable-th .product-innertable-td {
        width: 23.99%;
    }

    .product-innertable-tr {
        display: flex;
        height: 46rem;
        background: #fff;
    }

    .product-innertable-tr:hover {
        background: #f2f2f2;
    }

    .product-innertable-tr .product-innertable-td {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 16.66%;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
    }

    .grow-product-table .product-innertable-tr .product-innertable-td {
        width: 23.99%;
    }

    .product-red {
        color: #cd2626 !important;
    }

    .product-green {
        color: #0eb565 !important;
    }
}

@media (max-width: 996px) {
    .product-box {
        width: 100%;
        font-size: 18rem;

        .product-tab-item {
            width: 25%;
        }

        .product-innertable {
            padding: 0;
        }
    }
}
</style>