export default {
  fontFamily: "en-font-family",
  classLang: "en",
  headlist: [
    {
      text: "Início",
      url: "/",
    },
  ],
  // FCA开户: "FCA开户",
  立即开户: "Registrar-se",
  登录: "Acesso a conta",
};
