export default {
  多元活动连接全球市场: "世界中で様々なイベントを実施",
  共享全球顶级资源与学术支持:
    "世界トップクラスのリソースとアカデミック・サポートを使い、自信を持って取引を始めましょう。",
  了解更多: "詳細はこちら",
  敬请期待: "乞うご期待！",
  每个认真交易的人: "すべてのトレーダーに、誠実な対応を。",
  activityListMetaData: [
    {
      label: "キャンペーン",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_jp.png"),
          title: "第2回EBC100万ドル取引大会",
          subtitle: "上位の取引を無料でコピー",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        // {
        //   id: "2-10",
        //   img: require("@/assets/image/activityList/messi_jp.png"),
        //   title: "メッシの直筆サイン入りユニフォームを獲得しよう！",
        //   subtitle: "キャンペーン期間：2025年3月1日（土）- 2025年3月31日（月）",
        //   url: "/trade-for-messi",
        //   time: "",
        // },
        {
          id: "2-7",
          img:require("@/assets/image/activityList/banner2_7_jp.png"),
          title: "TradingView会員特典期間限定キャンペーン",
          subtitle: "グローバル市場の動向をリアルタイムで把握できるTradingViewのプレミアム会員機能を無料体験！",
          url: "/TradingView",
          time: "",
        },
        

        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_jp.png"),
          title: "ハッピーパートナープログラム",
          subtitle:
            "オリジナルな金融コンテンツを作ることで、様々な報酬が得られます。",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-4",
          img: require("@/assets/image/activityList/banner2_4_jp.png"),
          title: "シグナル報酬プログラム",
          subtitle:
            "シグナル提供者様向けのお得な報酬プログラムです。報酬は、フォロワー資金額に基づいて毎月計算され、損益にかかわらず年率最高18%の報酬を獲得いただけます。",
          url: "/pay",
          time: "",
        },
        {
          id: "2-1",
          img: require("@/assets/image/activityList/banner2_1_jp.png"),
          title: "EBC 100万ドル取引大会",
          subtitle: "勝者には賞金100万ドルを進呈します。",
          url: "/win1",
          time: "",
        },
      ],
    },
    {
      label: "イベント",
      list: [
        {
          id: "3-4",
          img: require("@/assets/image/activityList/banner3_4_jp.png"),
          title: "EBC、iFX EXPO LATAM 2025に参加",
          subtitle:
            "EBC Financial Groupは、iFX EXPO LATAM 2025にダイヤモンドスポンサーとして参加しています。市場動向、投資機会、取引の未来を探る機会になりますので、ご来場をお待ちしております。",
          url: "",
          time: "9-10 April 2025",
        },
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_jp.png"),
          title: "メキシコ・マネーエキスポ2025に出展",
          subtitle:
            "EBCはメキシコ・マネーエキスポ2025にプラチナスポンサーとして参加しました。現地では業界の専門家とともに、フィンテックの革新と新たな取引機会について意見交換をしました。",
          url: "/LearningCenterInfo?id=LEARN-782-60B",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_jp.png"),
          title: "気候経済学に基づいて投資チャンスを発見する方法",
          subtitle: `「経済学者の研究公開」で政策改善と経済成長を推進するためのタイムリーな議論をご視聴いただけます。`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_jp.png"),
          title: "EBCとオックスフォード大学との共催セミナー",
          subtitle: `本セミナーは2024年11月14日23時30分（日本時間）にオックスフォード大学クライスト・チャーチ・カレッジにて開かれ、オンラインでも世界中の投資家に中継されました。`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
