export default {
  logo: require("@/assets/image/win/logo.svg"),
  EBC: "EBC",
  全球百万美金交易大赛Ⅱ: "全球百万美金交易大赛Ⅱ",
  免费复制冠军交易: "免费复制冠军交易",
  共赴巴萨颁奖盛典: "共赴巴萨颁奖盛典",
  EBC全球百万美元交易大赛: `EBC全球百万美金交易大赛，汇聚全球顶尖交易者。<br class="mobile" />作为巴塞罗那足球俱乐部官方合作伙伴，<br
                        class="mobile" />我们将足球哲学融入金融赛场，传承巴萨卓越、热爱的力量。<br class="mobile" />无论您是操盘巨星，还是交易新秀，都可以免费复制冠军收益。<br
                        class="mobile" />大赛优胜者将受邀前往巴萨参加颁奖典礼，<br class="mobile" />见证交易赛场与绿茵圣地的传奇。`,
  大赛奖池: "大赛奖池",
  距开赛: "距开赛",
  距结束:"距结束",
  一键跟单: "一键跟单",
  免费复制冠军收益: "免费复制冠军收益",
  公开透明: "公开透明",
  观摩冠军交易技巧: "观摩冠军交易技巧",
  推荐有礼: "推荐有礼",
  推荐有奖上不封顶: "推荐有奖上不封顶",
  双赛道机制: "大赛首创双赛道机制",
  梦之队: "梦之队",
  梦之队HTML: "<span>梦</span><span>之</span><span>队</span>",
  以交易场的传控技术角逐百万: "以交易场的传控技术角逐百万",
  青训营: "青训营",
  青训营HTML: "<span>青</span><span>训</span><span>营</span>",
  "像天才新星释放天赋「一球成名」": "像天才新星释放天赋「一球成名」",
  冠军: "冠军",
  亚军: "亚军",
  季军: "季军",
  第四至五名: "第四至五名",
  第六至十名: "第六至十名",
  "$1,000,000交易账号或$200,000现金": "$1,000,000交易账号或$200,000现金",
  立即参赛: "立即参赛",
  巴萨荣耀之旅: "巴萨荣耀之旅",
  每笔订单都是攻守博弈的艺术: `每笔订单都是攻守博弈的艺术，<br class="mobile" />每个决策都将缔造资本市场的史诗级射门。`,
  邀您赴巴萨训练营: "邀您赴巴萨训练营",
  亲身体验冠军的致胜之旅: "亲身体验冠军的致胜之旅",
  于巴萨博物馆为您加冕: "于巴萨博物馆为您加冕",
  巴萨颁奖典礼: "巴萨颁奖典礼，见证交易荣光",
  巴萨绿茵场现场观赛: "巴萨绿茵场现场观赛",
  亲临VIP坐席见证球星逐梦诺坎普: "亲临VIP坐席见证球星逐梦诺坎普",
  大赛推荐官: "大赛推荐官",
  交易即享推荐奖励: "交易即享推荐奖励 上不封顶",
  "/人": "/人",
  "3步推荐": "3步推荐 交易立享",
  推荐好友: "推荐好友",
  好友交易: "好友交易",
  赢得奖金: "赢得奖金",
  立即推荐: "立即推荐",
  实时赛况: "实时赛况",
  排名: "排名",
  昵称: "昵称",
  账号: "账号",
  收益额: "收益额",
  收益率: "收益率",
  奖金: "奖金",
  跟单: "跟单",
  暂未开赛: "暂未开赛，敬请期待。",
  该页面仅展示收益表现排名前10的交易账号:
    "*该页面仅展示收益表现排名前10的交易账号，排名货币单位为美金USD，每小时同步更新。",
  赛事进程: "赛事进程",
  推荐开始: "推荐开始",
  "2025年2月22日": "2025.2.22",
  比赛开始: "比赛开始",
  "2025年3月1日": "2025.3.1",
  推荐结束: "推荐结束",
  "2025年5月23日": "2025.5.23",
  比赛结束: "比赛结束",
  "2025年5月30日": "2025.5.30",
  颁奖典礼: "颁奖典礼",
  "2025年": "2025",
  赛组规则: "赛组规则",
  类别: "类别",
  账号要求: "账号要求",
  "新交易账号，且账号余额≥$500": "新交易账号，且账号余额≥$500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "新交易账号，且$10,000≤账号余额≤$200,000",
  排名规则: "排名规则",
  以收益率排名为评判标准: "以收益率排名为评判标准",
  "收益率=期末净值/账号累积入金": "收益率=(收益额/累计入金)*100%",
  以收益额排名为评判标准: "以收益额排名为评判标准",
  "收益额=期末净值+出金-入金": "收益额=期末净值+出金-入金",
  持仓限制: "持仓限制",
  每个参赛账号最多持有100手仓位: "每个参赛账号最多持有100手仓位",
  无持仓限制: "无持仓限制",
  参赛条款: "参赛条款",
  参赛须知: "参赛须知",
  section6List: [
    {
      t: "1.活动时间",
      c: [
        "2025年3月1日 01:00:00(MT4/MT5时间)-2025年5月30日 23:59:59(MT4/MT5时间)",
      ],
    },
    {
      t: "2.账号要求",
      c: [
        "参赛账号需在交易社区发布信号，允许被免费跟随。",
        "参赛者可以使用多个账号参赛，但仅按表现最优的账号计入排名。",
        "为确保比赛公平，仅限STD与PRO账号参赛，PAMM账号和伊斯兰账号不具备参赛资格。",
      ],
    },
    {
      t: "3.奖励规则",
      c: [
        "冠军奖励： 若冠军选择100万美金交易账号奖励，最大亏损限额为20万美金。",
        "其他奖项均按照排名进行奖励。",
      ],
    },
    {
      t: "4.禁止行为",
      c: [
        "严禁通过账号间套利交易提高收益，任何通过多账号交叉持仓、对冲交易及滥用报价等违规行为，都将被取消参赛资格。",
        "因服务器硬件故障，报价错误等不可控因素导致的订单视为无效。",
      ],
    },
    {
      t: "5.最终解释权",
      c: ["EBC金融集团拥有本次活动最终解释权。"],
    },
  ],

  参赛流程: "参赛流程",
  注册开户: "注册开户",
  注册并开设新交易账号: "注册并开设新交易账号",
  报名参赛: "报名参赛",
  在用户中心报名参赛: "在用户中心报名参赛",
  参赛成功: "参赛成功",
  参赛成功查看实时赛况: "参赛成功查看实时赛况",
  往届回顾: "往届回顾",
  查看更多: "查看更多",
  常见问题: "常见问题",
  qaList: [
    {
      t: "1.“账户”与“账号”的区别",
      c: [
        "账户：指EBC用户中心账户（UID），每位用户经过实名认证后只能拥有一个账户。",
        "账号：MT交易账号，每个EBC账户可创建多个交易账号用于入金和交易。",
      ],
    },
    {
      t: "2.如何确认参赛成功？",
      c: [
        "参赛成功后，用户中心大赛活动页面会显示参赛账号及当前排名，同时您将收到参赛成功的邮件。",
      ],
    },
    {
      t: "3.是否可以多账号参赛？",
      c: [
        "同一用户可分别在两个赛组使用多个账号参赛，但每个账号只能参加一个赛组。",
      ],
    },
    {
      t: "4.两个赛组同时获奖是否可同时领奖？",
      c: ["可同时领取两个赛组的奖励，前提是两个组别均合规完成比赛并获奖。"],
    },
    {
      t: "5.已有MT4账号可否参赛？",
      c: [
        "2025年2月22日 01:00:00(MT4/MT5时间)后开设的，且没有历史交易记录的MT账号。",
      ],
    },
    {
      t: "6.参赛青训营组后，再次入金超1万美金会修改参赛组别吗？",
      c: ["不会，参赛组别以参赛报名时为准，后续资金变动不影响组别。"],
    },
    {
      t: "7.如何查看比赛排名？",
      c: [
        "排名每小时更新（非整点更新），可在用户中心大赛活动页看到自己的排名。",
      ],
    },
    {
      t: "8.比赛期间能否出入金？",
      c: [
        "可以出入金，出金不影响排名。梦之队累计入金上限$200,000，超限将取消参赛资格。",
      ],
    },
    {
      t: "9.如何申领邀请奖励？",
      c: [
        "邀请奖励将在被邀请人符合条件的账号完成第一笔开仓交易30天后发放至奖金余额。届时，您可以申请提现，审核通过后奖励到账。",
      ],
    },
    {
      t: "10.为何“被邀请”者不能转移代理？",
      c: [
        "为确保公平，“被邀请”者需通过推荐链接参与且不能指定代理。",
      ],
    },
    {
      t: "11.参赛账号的交易信息是否会被公开？",
      c: [
        "参赛账号将作为公开信号在跟单社区进行展示，未参赛账号可复制其交易策略。",
      ],
    },
    {
      t: "12.参赛账号可否使用跟单功能？",
      c: ["为确保公平，参赛账号不可使用跟单；您可新建账号体验此功能。"],
    },
    {
      t: "13.参赛者盈利率或盈利额相同，排名如何计算？",
      c: ["若参赛者盈利率或盈利额相同，按交易量高低排序。"],
    },
  ],
  天: "天",
  时: "时",
  分: "分",
  秒: "秒",
  $30000: "$30,000",
  $10000: "$10,000",
  $5000: "$5,000",
  $3000: "$3,000",
  $2000: "$2,000",
  $1000: "$1,000",
  rankValueList: [
    "$1,000,000交易账号或$200,000现金",
    "$30,000",
    "$10,000",
    "$5,000",
    "$5,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
  ],
  rankValueList2: [
    "$30,000",
    "$5,000",
    "$3,000",
    "$2,000",
    "$2,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
  ],
  ebclink:"https://www.ebc.com/zh-cn/win1",
  第一届大赛冠军:"第一届大赛冠军",
  第一届大赛亚军:"第一届大赛亚军",
  第一届大赛季军:"第一届大赛季军"
};
