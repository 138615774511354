export default {
  交易领薪计划: "シグナル報酬プログラム",
  为了鼓励交易者提升交易水平:
    "シグナル提供者様向けのお得な報酬プログラムです。報酬は、フォロワー資金額に基づいて毎月計算され、損益にかかわらず年率最高18%の報酬を獲得いただけます。",
  加入交易社区: "",
  即刻发起交易信号: "取引シグナルを公開",
  无限奖励月月拿: "無上限の月間報酬",
  优享11个专属大礼: "11種類の特典",
  即刻加入: "今すぐ参加",
  交易员等级奖励机制: `プログラム詳細`,
  EBC基于跟随人数和资金提供五档奖励:
    "報酬は取引回数の制限や損益にかかわらず、フォロワー数と運用資産に基づいて5段階に分かれています。",
  "/月": "/月",
  初級トレーダー: "初級トレーダー",
  管理资金量: "運用資産（AUM）",
  跟随账户数: "フォロワー数",
  大师交易员: "中級トレーダー",
  冠军交易员: "上級トレーダー",
  明星交易员: "スタートレーダー",
  资金管理费: "+1％の管理料",
  传奇交易员: "レジェンドトレーダー",
  多层次的增值服务: "各段階別サービス",
  EBC提供多达11项增值服务:
    "本プログラムでは11種類の特典を提供し、シグナルの宣伝に協力します。",
  "精英/大师交易员": "初級/中級",

  "7×24小时优先客服": "24時間日本語サポート",
  金融投资经典书籍礼遇: "投資関連書籍の無料進呈",
  专属VPS赞助: "専用VPSスポンサーシップ",
  订单流软件1年使用权: "オーダーフロー分析ツールへの無料アクセス（1年間）",
  EBC定制大礼包: "EBC オリジナルギフト",
  信号优先置顶展示: "シグナル優先表示",
  受邀参观海外办公室: "海外オフィス訪問",
  巴萨球星联名礼包: "FCバルセロナギフトセット",
  巴萨赛场传奇之旅: "FCバルセロナスタジアムへの訪問",
  品牌战略合作: "戦略的ブランドパートナーシップ",
  全球金融资源管家服务: "グローバル・ファイナンシャル・マネジメント・サービス",
  五维指标评价系统: "5次元データ分析モデル",
  为交易员信号提供公开透明的评估:
    "トレーダーのシグナルに対して明瞭かつ透明性のある評価を行い、トレーディングスタイルを明らかにすることで、より多くのフォロワーを集めます。",
  收益率: "収益率",
  历史收益率:
    "現時点までの投資リターンを指します。数値が高いほど優れています。",
  最大回撤: "最大ドローダウン",
  衡量风控能力:
    "シグナルのリスク管理能力を示す指標で、低いほどリスクが低いことを意味します。",
  获利系数: "プロフィットファクター",
  利润因子:
    "総利益と総損失の比率を表す指標で、総利益を総損失で割った数字になります。",
  夏普比率: "シャープレシオ",
  每份风险的收益:
    "リスクとリターンの関係を測る指標で、同じリスクで得られる超過リターンを比較するのに役立ちます。",
  标准差: "標準偏差",
  收益波动率:
    "収益のボラティリティを表します。低いほど、安定した利益率と、低リスクであることを示します。",
  以上数据仅供参考: "*上記のデータは参考のみです。",
  活动须知: "注意事項",
  list: [
    {
      label: "1. レベル評価",
      value: [
        "運用資産（AUM）＝フォロワー口座の残高＋シグナル口座の残高。",
        "フォロワー数：シグナルをフォローしているEBCアカウントの数。同じEBCアカウント下にある複数の取引口座が同じシグナルをフォローしている場合、1フォロワーとしてカウントされます。",
        "ランクは毎日更新されます。カレンダー月のすべての日において最低基準を達成すると、報酬が有効になります。",
      ],
    },
    {
      label: "2. フォロワーについて",
      value: [
        "フォロワー金額500ドル以上の取引口座は１つの有効口座としてカウントします。",
        "各取引口座がフォローできるシグナル数は10までとなります。",
        "取引口座のフォロワー金額＝フォロワー口座残高/フォローシグナル数",
      ],
    },
    {
      label: "3. 報酬制度",
      value: [
        "毎月末を締め日とし、翌月の第一週に前月の報酬を計算して支給します。基準を満たした最低レベルに基づいて報酬を計算します。",
        "報酬は取引口座に直接入金されます。入金後はいつでも出金いただけます。",
        "月間報酬＝（日次平均運用資産×年間管理報酬率）÷12",
        `免責事項：
本プログラムの最終解釈権はEBCに帰属します。`,
      ],
    },
  ],
  年化资金管理费: "年間管理報酬",
};
