export default {
  logo: require("@/assets/image/win/logo_kr.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "EBC 글로벌 실거래 대회 Ⅱ",
  免费复制冠军交易: "챔피언의 트레이딩 무료로 카피하기",
  共赴巴萨颁奖盛典: "바르셀로나에서의  승리를 축하합니다.",
  EBC全球百万美元交易大赛: `다시 돌아온 EBC 백만 달러 실거래 대회! 베테랑도 초보자도 전 세계 트레이더와 함께, 투명하고 공정한 거래 환경에서 여러분의 전략을 시험해 보세요. EBC는 FC 바르셀로나의 공식 파트너로서 그 열기와 무한한 가능성을 이어받아 트레이더의 열정과 실력을 겨룰 수 있는 필드를 마련했습니다. 역대 최대 규모 상금부터 FC 바르셀로나의 홈구장 시상식 초대권까지, 지금 도전하세요!`,
  大赛奖池: "상금  풀",
  距开赛: "대회 시작까지",
  距结束:"종료까지",
  一键跟单: "원-클릭 카피트레이딩",
  免费复制冠军收益: "챔피언의 트레이딩 무료로 카피하기",
  公开透明: "공정하고 투명하게",
  观摩冠军交易技巧: "챔피언의 거래 기술 배우기",
  推荐有礼: "초대 이벤트",
  推荐有奖上不封顶: "제한 없는 이벤트 보상받기",
  双赛道机制: "두 개의 카테고리 선택 가능",
  梦之队: "루키 팀",
  梦之队HTML: "루키 팀",
  以交易场的传控技术角逐百万:
    "거래 전략을 시험하고 100만 달러를 손에 넣으세요!",
  青训营: "베테랑 팀",
  青训营HTML: "베테랑 팀",
  "像天才新星释放天赋「一球成名」":
    "여러분의 거래 실력을 마음껏 발휘하고 최고의 자리에 도전하세요!",
  冠军: "1위",
  亚军: "2위",
  季军: "3위",
  第四至五名: "4위 ~ 5위",
  第六至十名: "6위 ~ 10위",
  "$1,000,000交易账号或$200,000现金": "100만 달러 거래 계좌 or 20만 달러 현금",
  立即参赛: "참가신청",
  巴萨荣耀之旅: "FC 바르셀로나로 향하는 챔피언의 길",
  每笔订单都是攻守博弈的艺术: `대회 우승자는 FC 바르셀로나의 홈구장인 캄프 누에 초대됩니다.`,
  邀您赴巴萨训练营: "바르사 아카데미 견학",
  亲身体验冠军的致胜之旅: "바르사의 역사를 직접 체험하다",
  于巴萨博物馆为您加冕: "FC 바르셀로나 박물관 방문",
  巴萨颁奖典礼: "전설을 눈으로 확인하고, 영광의 역사를 확인하다",
  巴萨绿茵场现场观赛: "FC 바르셀로나 홈경기 관람",
  亲临VIP坐席见证球星逐梦诺坎普: "캄프 누의 VIP석에서 열띤 경기 관람하기",
  大赛推荐官: "초대 이벤트",
  交易即享推荐奖励: "한도 없이 거래하고 추천 보상을 획득하세요!",
  "/人": "/인당",
  "3步推荐": "간단한 3단계: 추천, 거래, 수익 창출!",
  推荐好友: "친구 초대",
  好友交易: "회원가입",
  赢得奖金: "보상 받기",
  立即推荐: "지금 초대하기",
  实时赛况: "대회 실황",
  排名: "랭킹",
  昵称: "닉네임",
  账号: "계좌 번호",
  收益额: "순수익",
  收益率: "수익률",
  奖金: "상금",
  跟单: "카피 트레이딩",
  暂未开赛: "대회가 곧 시작됩니다, 많은 관심 부탁드려요!",
  该页面仅展示收益表现排名前10的交易账号:
    "*해당 페이지는 10위 내 계좌만 표시합니다. 금액 단위는 USD입니다. 랭킹은 매 1시간마다 갱신됩니다. ",
  赛事进程: "대회 절차",
  推荐开始: "초대 이벤트 시작",
  "2025年2月22日": "2025.2.22",
  比赛开始: "대회 시작",
  "2025年3月1日": "2025.3.1",
  推荐结束: "초대 이벤트 종료",
  "2025年5月23日": "2025.5.23",
  比赛结束: "대회 종료",
  "2025年5月30日": "2025.5.30",
  颁奖典礼: "결과 발표",
  "2025年": "2025",
  赛组规则: "대회 규정",
  类别: "카테고리",
  账号要求: "계좌 요건",
  "新交易账号，且账号余额≥$500": "계좌 잔액이 500달러 이상인 신규 개설 계좌",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "계좌 잔액이 1만 달러 이상, 20만 달러 이하인 신규 개설 계좌",
  排名规则: "랭킹 규정",
  以收益率排名为评判标准: "순위는 수익률을 기준으로 결정됩니다. ",
  "收益率=期末净值/账号累积入金": "수익률 = (순이익 / 누적 예금)*100%",
  以收益额排名为评判标准: "순위는 순이익을 기준으로 결정됩니다.",
  "收益额=期末净值+出金-入金": "순이익 = 최종 자본금 + 출금 - 예금",
  持仓限制: "포지션 제한",
  每个参赛账号最多持有100手仓位:
    "각 참여 계좌가 보유할 수 있는 최대 포지션은 100랏입니다.",
  无持仓限制: "포지션 제한 없음",
  参赛条款: "대회 규정",
  参赛须知: "주의 사항",
  section6List: [
    {
      t: "1. 기간",
      c: [
        "대회 기간: 2025년 3월 1일 01:00:00 ~ 2025년 5월 30일 23:59:59(MT4/MT5시간)",
      ],
    },
    {
      t: "2. 계좌 요건",
      c: [
        "참가 계좌는 거래 커뮤니티에 시그널 등록을 해야합니다. 단, 타인의 시그널을 팔로우 할 수 없으며, 본인의 팔로우 허용은 선택 사항입니다.",
        "참가 계좌 개수에 제한은 없으나, 순위에 반영되는 계좌는 성과가 가장 우수한 계좌에 한합니다. ",
        "공평성을 위해 대회용 계좌는 STD 계좌와 PRO 계좌만 사용할 수 있으며, PAMM 계좌와 이슬람 계좌는 참가할 수 없습니다. ",
      ],
    },
    {
      t: "3. 보상 규정",
      c: [
        "1위 보상: 100만 달러의 거래 계좌를 선택한 경우, 해당 계좌에 최대 20만 달러 손실액이 있을 수 있습니다.",
        "그 외의 상금은 순위에 따라 차등 지급됩니다.",
      ],
    },
    {
      t: "4. 금지 사항",
      c: [
        "수익을 올리기 위한 계좌 간 차익 거래는 엄격히 금지됩니다. 계좌 간 포지션, 헤지 거래 또는 호가 남용을 포함한 모든 위반 행위는 발각 시 참가 자격이 취소됩니다.",
        "서버 하드웨어 오류나 가격 오류 등 불가피한 상황으로 발생한 주문은 무효로 처리됩니다.",
      ],
    },
    {
      t: "5. 최종 해석권",
      c: ["본 대회의 최종 해석권은 EBC에 있습니다."],
    },
  ],

  参赛流程: "참가 방법",
  注册开户: "계좌 개설",
  注册并开设新交易账号: "회원가입 후 새로운 거래 계좌를 개설하기",
  报名参赛: "참가 신청",
  在用户中心报名参赛: "고객포털에서 대회 참가 신청하기",
  参赛成功: "신청 완료",
  参赛成功查看实时赛况: "대회에 참가해 실황 확인하기",
  往届回顾: "이전 대회",
  查看更多: "더 보기",
  常见问题: "FAQ",
  qaList: [
    {
      t: `1. "계정"과 "계좌"의 차이가 무엇인가요?`,
      c: [
        "계정: EBC 고객포털에서 계정(UID)을 의미하며, 각 사용자는 실명 인증을 거쳐야만 계정을 만들 수 있습니다. ",
        "계좌: MT 거래 계좌를 의미하며, EBC 계정 하나당 여러 거래 계좌를 개설할 수 있습니다. ",
      ],
    },
    {
      t: "2. 대회에 신청됐는지 어떻게 확인하나요?",
      c: [
        "대회 신청이 완료되면 고객포털 이벤트 페이지에 참가 계좌 및 현 랭킹이 표시됩니다.",
      ],
    },
    {
      t: "3. 여러 계좌로 참가할 수 있나요?",
      c: [
        "여러 계좌로 두 개의 팀에 참가할 수 있으나, 각 계좌 당 참가할 수 있는 팀은 하나입니다.",
      ],
    },
    {
      t: "4. 동시에 두 팀에서 수상할 수 있나요?",
      c: [
        "가능합니다. 단, 두 팀에서 모두 정식으로 대회를 완료하고 순위권에 들어야 합니다.",
      ],
    },
    {
      t: "5. 이미 있는 MT5 계좌로 참가할 수 있나요?",
      c: [
        "대회에 참가할 계좌는 반드시 2025년 2월 22일 01:00:00(MT4/MT5시간) 전에 개설했으며 거래 이력이 없는 계좌여야 합니다. ",
      ],
    },
    {
      t: "6. 루키 팀으로 대회에 참가한 뒤, 1만 달러를 추가 입금하고 참가 팀을 바꿀 수 있나요?",
      c: [
        "아니오. 참가 팀은 신청 시점에 결정되며 이후 자금 변동은 팀에 영향을 주지 않습니다. ",
      ],
    },
    {
      t: "7. 순위는 어떻게 보나요?",
      c: [
        "랭킹은 1시간(정시가 아닌 매시간)마다 갱신되며, 고객포털 대회 페이지에서 확인할 수 있습니다. ",
      ],
    },
    {
      t: "8. 대회 진행 중에 자금 입출금 할 수 있나요?",
      c: [
        "입출금이 가능하며, 출금은 순위에 영향을 미치지 않습니다. 드림팀의 누적 입금 상한은 $200,000이며, 초과 시 대회 참가 자격이 취소됩니다.",
      ],
    },
    {
      t: "9. 초대 이벤트 보상은 어떻게 받나요?",
      c: [
        "이벤트 보상은 초대한 사용자가 첫 거래를 완료한 후 30일이 지나면 보너스 잔액에 적립됩니다. 그 후 출금을 요청할 수 있으며, 승인 후 보상이 처리됩니다.",
      ],
    },
    {
      t: "10. 왜 대회 기간에 초대된 참가자 에이전트를 이전할 수 없나요?",
      c: [
        `공정성을 보장하기 위해 "초대받은 사람"은 추천 링크를 통해 참여해야 하며 아이비를 지정할 수 없습니다.`,
      ],
    },
    {
      t: "11. 콘테스트 거래 계좌 정보를 공개하나요?",
      c: [
        "참여 계정은 카피 트레이딩 커뮤니티에서 공개 신호로 표시되며, 참여하지 않은 계정은 거래 전략을 복사할 수 있습니다.",
      ],
    },
    {
      t: "12. 참여 계정에서 카피 트레이딩 기능을 사용할수 있습니까?",
      c: [
        "공정성을 보장하기 위해 참여 계정은 카피트레이딩기능을 사용할 수 없습니다. 새 계정을 만들어 체험할수 있습니다.",
      ],
    },
    {
      t: "13. 참가자의 수익성 또는 이익 금액이 동일한 경우 순위는 어떻게 계산됩니까?",
      c: ["참가자의 수익율 또는 수익 금액이 동일하면 거래량별로 정렬됩니다."],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000: "$30,000",
  $10000: "$10,000",
  $5000: "$5,000",
  $3000: "$3,000",
  $2000: "$2,000",
  $1000: "$1,000",
  rankValueList: [
    "100만 달러 거래 계좌 or 20만 달러 현금",
    "$30,000",
    "$10,000",
    "$5,000",
    "$5,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
  ],
  rankValueList2: [
    "$30,000",
    "$5,000",
    "$3,000",
    "$2,000",
    "$2,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
  ],
   ebclink:"https://www.ebc.com/kr/win1",
   第一届大赛冠军:"지난 대회 우승자",
  第一届大赛亚军:"지난 대회 준우승자",
  第一届大赛季军:"지난 대회 3등"
};
