export default {
	"pages1":{
		"title":"コピートレード",
		"tips":"トレードの達人と、ここで出会う",
		"text":"選ばれる理由",
		"text1":"シグナル紹介",
		"text2":"シグナル提供者向け報酬プログラム",
		"text3":"サービスプラン",
		"text4":"コミュニティにログイン"
	},
	"pages2":{
		"title":"選ばれる理由",
		"list":[{
			"tit":"柔軟なコピートレード設定",
			"tips":"EAプラグインなしで、高度なフォロー設定が可能"
		},{
			"tit":"ミリ秒単位の迅速なコピー速度",
			"tips":"自分で手を動かす必要がなく、迅速に取引シグナルをコピー可能"
		},{
			"tit":"5次元データ分析",
			"tips":"5次元データ分析モデルを導入"
		},{
			"tit":"AIスクリーニングツール",
			"tips":"世界中の優秀なシグナルの中から、取引戦略に適したシグナルを簡単に検索"
		},{
			"tit":"透明性の高い取引情報開示",
			"tips":"すべての取引履歴と実績をレビュー可能"
		}],
		"btn":"コミュニティにログイン"
	},
	"pages3":{
		"title":"シグナル紹介",
		"text":"収益率",
		"text1":"最大ドローダウン",
		"text2":"シャープレシオ",
		// "text3":"損益率",
		"text3":"プロフィットファクター",
		"text4":"無料",
		"text5":"フォロー",
		"btn":"シグナル一覧"
	},
	"pages4":{
		"title":"シグナル提供者向け報酬プログラム",
		"num":"100ドル/月",
		"num1":"300ドル/月",
		"num2":"1,000ドル/月",
		"num3":"5,000ドル/月",
		"num4":"10,000ドル/月",
		"text1":"運用資産残高",
		"text2":"フォロワー数",
		"name":"初級トレーダー",
		"name1":"中級トレーダー",
		"name2":"上級トレーダー",
		"name3":"スタートレーダー",
		"name4":"レジェンドトレーダー",
		"tips":"'+1% 管理資金/年 (毎月支払い)",
		"btn":"今すぐ参加"
	},
	"pages5":{
		"title":"サービスプラン",
		"text":"24時間日本語サポート",
		"text1":"EBCマーケティングキャンペーンへの参加",
		"text2":"VPSスポンサーシップ",
		"text3":"投資参考書籍のプレゼント",
		"text4":"オーダーフロー分析ツールへのアクセス",
		"text5":"EBCオリジナルノベルティ",
		"text6":"実績上位への表示",
		"text7":"当社海外オフィス訪問",
		"text8":"メディア宣伝サポート",
		"text9":"ブランド提携",
		"text10":"ファンド立上げにおけるアドバイザリーサービス",
		"btn":"コミュニティにログイン"
	},
	"QA": {  
		"title": "コピートレードに関するよくある質問",  
		"Q1": "1. コピートレードとは？",  
		"A1": "コピートレードは、経験豊富なトレーダー（シグナルプロバイダー）の取引をフォローする投資戦略です。取引をコピーすることで、実績のあるトレーダーの戦略を学ぶことができ、自分の取引に応用することができます。",  
		"Q2": "2. コピートレードの仕組みは？",  
		"A2": "コピートレードのプラットフォーム上でコピーしたいシグナルプロバイダーを選択し、そのトレードを自動的にコピーします。つまり、ご自身でマーケットを常に監視することなく、コピー元のトレーダーのように取引をすることができるのです。",  
		"Q3": "3. コピートレードのプラットフォームを利用するメリットは？",  
		"A3": "実績のある取引戦略へのアクセス、市場分析にかかる時間の節約、より安定したリターンの可能性など、いくつかのメリットがあります。また、お客様の取引経験を向上させる便利な取引ツールも提供しています。",  
		"Q4": "4. コピートレーダーはどのようなツールを利用できますか？",  
		"A4": "コピートレーダーは、高度な分析モデル、トレードシグナルアラート、パフォーマンス追跡、他のトレーダーと市況分析や取引のヒントを意見交換できるコミュニティフォーラムなど、さまざまなツールにアクセスできます。",  
		"Q5": "5. コピートレードにはどのようなリスクがありますか？",  
		"A5": "コピートレードには多くのメリットがありますが、市場のボラティリティやパフォーマンスの悪いトレーダーをフォローしてしまう可能性などのリスクも伴います。そのため、包括的なリスク管理ツールを活用することが大切です。",  
		"Q6": "6. コピートレード戦略をカスタマイズできますか？",  
		"A6": "可能です。ほとんどのコピートレード・プラットフォームでは、戦略をカスタマイズすることができます。フォローするトレーダーを選択し、お客様の利益目標とリスク許容度に合うように、投資制限やフォロー方法、取引数量の割合、方向性などを設定することができます。"  
	},
	"box-name" : "copytrading-body jp",
}