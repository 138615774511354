<template>
	<div :class="[$t('PageNavigate.classLang'), $t('common.fontFamily'), $t('common.lang'), $t('common.type')]">
		<page-navigate></page-navigate>
		<full-page :options="options" ref="fullpage">
			<section class="index-banner-list section">
				<banner-img></banner-img>
			</section>
			<section id="section0" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 1 }">
					<div class="section-bg">
						<div class="index-section0"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="section0-content">
								<div class="index-section0-box">
									<div class="index-section-sub-title" hasLeftAnimate>
										{{ $t("Index.全球百万美金交易大赛Ⅱ") }}
									</div>
									<div class="index-section-main-title " :class="$t('common.fontFamilyTitle')"
										hasLeftAnimate>
										{{ $t("Index.免费复制冠军收益") }}
									</div>

									<a :href="signupUrl" class="index-btn index-btn-padding" hasLeftAnimate>{{
										$t("Index.立即参赛") }}</a>
								</div>
								<div class="rank-list" hasRightAnimate>
									<div class="rank-list-tabs">

										<div class="rank-list-tab"
											:class="{ 'rank-list-tab-active': rankListIndex == '1' }"
											@click="changeRank(1)">{{ $t('win3.青训营') }}</div>
										<div class="rank-list-tab"
											:class="{ 'rank-list-tab-active': rankListIndex == '2' }"
											@click="changeRank(2)">{{ $t('win3.梦之队') }}</div>
									</div>
									<div class="rank-list-table">
										<div class="rank-list-th">
											<div class="rank-list-td"></div>
											<div class="rank-list-td">{{ $t('win3.昵称') }}</div>
											<div class="rank-list-td">{{ $t('win3.账号') }}</div>
											<div class="rank-list-td">
												<span v-if="rankListIndex == '2'">{{ $t('win3.收益额') }}</span>
												<span v-else>{{ $t('win3.收益率') }}</span>

											</div>
											<div class="rank-list-td"></div>
										</div>
										<div class="rank-list-tr" v-for="(item, index) in rankListData" :key="index">
											<div class="rank-list-td">
												<img v-if="item.Rank == 1" width="32px"
													src="@/assets/image/win/rank1.png" alt="" />
												<img v-if="item.Rank == 2" width="32px"
													src="@/assets/image/win/rank2.png" alt="" />
												<img v-if="item.Rank == 3" width="32px"
													src="@/assets/image/win/rank3.png" alt="" />
												<span v-if="item.Rank != 1 && item.Rank != 2 && item.Rank != 3">{{
													item.Rank }}</span>
											</div>
											<div class="rank-list-td">
												{{ item.Nickname }}
											</div>
											<div class="rank-list-td"> {{ item.AccountNumber }}
											</div>
											<div class="rank-list-td">
												<span v-if="rankListIndex == '2'">{{ item.Profit }}</span>
												<span v-else>{{ item.ProfitRate }}</span>

											</div>
											<div class="rank-list-td">
												<a :href="signupUrl" class="gendan-btn" target="_blank">{{
													$t('Index.免费复制') }} </a>
											</div>
										</div>
										<div class="buy-box">
											<a :href="signupUrl" class="gendan-btn" target="_blank"> {{ $t('Index.免费复制')
											}} </a>
										</div>
									</div>
									<div class="rank-list-tips" hasRightAnimate
										v-html="$t('Index.该页面仅展示收益表现排名前5的交易账号')">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
			<section id="section1" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 2 }">
					<div class="section-bg">
						<div class="index-section1"> </div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between">
								<div class="left">
									<h3 class="index-section-title " :class="$t('common.fontFamilyTitle')">
										{{ $t("Index.连接全球热门交易产品") }}
									</h3>
									<div class="index-section-desc">
										{{ $t("Index.探索一个充满机遇和竞争优势的世界") }}
									</div>
									<div class="index-section-tags">
										<a href="/tradingproduct" class="index-section-tag"><span
												class="iconfont icon-Vector"></span>{{ $t("Index.衍生品交易") }}</a>
										<a href="/tradingproduct" class="index-section-tag"><span
												class="iconfont icon-Vector"></span>{{ $t("Index.期货CFDs") }}</a>
										<a href="/index-cfds" class="index-section-tag"><span
												class="iconfont icon-Vector"></span>{{ $t("Index.全球股票指数CFDs") }}</a>
										<a href="/tradingproduct" class="index-section-tag"><span
												class="iconfont icon-Vector"></span>{{ $t("Index.美国股票CFDs") }}</a>
									</div>
								</div>
								<div class="right">
									<div class="index-section1-box" hasRightAnimate>
										<div class="index-section1-tabs">
											<div v-for="(item, index) in $t('Index.tabList')" class="index-section1-tab"
												:class="{ 'active': listIndex == index }"
												@click="handleClick(index, $event)" v-html="item">
											</div>
										</div>
										<div class="index-section1-list">
											<div class="index-section1-list-thead">
												<div class="index-section1-list-tr">
													<div class="index-section1-list-th"></div>
													<div class="index-section1-list-th">{{ $t("Index.买入") }}</div>
													<div class="index-section1-list-th">{{ $t("Index.出售") }}</div>

													<div class="index-section1-list-th">{{ $t("Index.点差") }}</div>
													<div class="index-section1-list-th"></div>
												</div>
											</div>
											<div class="index-section1-list-tbody">
												<div class="index-section1-list-tr"
													v-for="(item, index) in showProducts" :key="index">
													<div class="index-section1-list-td">
														<template v-if="item.listIndex == 0">
															<img class='pc' :src="getSrc(item.displaySymbol, '.png')"
																alt="" />
															<img class='mobile mobile-icon'
																:src="getSrc(item.displaySymbol, '_m.png')" alt="" />
														</template>
														<template v-if="item.listIndex != 0">
															<img :src="getSrc(item.displaySymbol, '.png')" alt="" />
														</template>

														{{ item.displaySymbol }}
													</div>
													<div class="index-section1-list-td ask-price-value "
														:class="item.askClassName">
														{{ item.ask }}
													</div>
													<div class="index-section1-list-td bid-price-value "
														:class="item.bidClassName">
														{{ item.bid }}
													</div>

													<div class="index-section1-list-td spread-value">{{ item.spread }}
													</div>
													<a class="buy" rel="nofollow"
														href="https://client.ebccrm.com/signup">{{
															$t("Index.立即交易")
														}}</a>
												</div>

											</div>
											<div class="buy-box">
												<a class="buy" rel="nofollow" href="https://client.ebccrm.com/signup">{{
													$t("Index.立即交易")
												}}</a>
											</div>
										</div>
									</div>

									<div class="index-section-btn-box" hasRightAnimate>
										<a class="index-btn" href="/tradingproduct">{{ $t("Index.了解交易产品") }}
											<span class="iconfont icon-chevron_right"></span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="section2" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 3 }">
					<div class="section-bg">
						<div class="index-section2"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between">
								<div class="left">
									<h3 class="index-section-title " :class="$t('common.fontFamilyTitle')">
										{{ $t("Index.全球顶级监管") }}
									</h3>
									<div class="index-section-desc">
										{{ $t("Index.EBC金融集团旗下各公司") }}
									</div>
									<div class="index-section-tags">
										<a href="https://register.fca.org.uk/s/firm?id=0014G00002Vq7yNQAR"
											class="index-section-tag" target="_blank"><span
												class="iconfont icon-Vector"></span>FCA</a>
										<a href="https://www.cima.ky/search-entities-cima" class="index-section-tag"
											target="_blank"><span class="iconfont icon-Vector"></span>CIMA</a>
										<a href="https://connectonline.asic.gov.au/RegistrySearch/faces/landing/ProfessionalRegisters.jspx?_adf.ctrl-state=yw3eguyzq_166"
											class="index-section-tag" target="_blank"><span
												class="iconfont icon-Vector"></span>ASIC</a>
									</div>
								</div>
								<div class="right">
									<div class="index-section-cards">
										<div class="index-section-card">
											<img src="../assets/image/Home/英国FCA监管.png" alt="" />
											<div class="index-section-card-info">
												<h4 class="p1">{{ $t("Index.英国FCA监管") }}</h4>
												<p class="p2 bottom-line">EBC Financial Group (UK) Ltd</p>
												<p class="p3 bottom-line mh-109">
													<span class="p3-label">{{ $t("Index.授权及监管于") }}</span><br
														class="pc" />{{
															$t("Index.英国金融行为监管局 (FCA)") }}
												</p>
												<p class="p3 bottom-line">
													<span class="p3-label">{{ $t("Index.监管号") }}</span><br
														class="pc" />927552
												</p>
											</div>
										</div>
										<div class="index-section-card">
											<img src="../assets/image/Home/开曼CIMA监管.png" alt="" />
											<div class="index-section-card-info">
												<h4 class="p1">{{ $t("Index.开曼CIMA监管") }}</h4>
												<p class="p2 bottom-line">
													EBC Financial Group (Cayman) Ltd
												</p>
												<p class="p3 bottom-line mh-109">
													<span class="p3-label">{{ $t("Index.授权及监管于") }}</span><br
														class="pc" />{{
															$t("Index.开曼群岛金融管理局(CIMA)") }}
												</p>
												<p class="p3 bottom-line">
													<span class="p3-label">{{ $t("Index.监管号") }}</span><br
														class="pc" />2038223
												</p>
											</div>
										</div>
										<div class="index-section-card">
											<img src="../assets/image/Home/澳洲ASIC监管.png" alt="" />
											<div class="index-section-card-info">
												<h4 class="p1">{{ $t("Index.澳洲ASIC监管") }}</h4>
												<p class="p2 bottom-line">
													EBC Financial Group (Australia) Pty Ltd
												</p>
												<p class="p3 bottom-line mh-109">
													<span class="p3-label">{{ $t("Index.授权及监管于") }}</span><br
														class="pc" />{{
															$t("Index.澳大利亚证券和投资委员会") }}
													(ASIC) 
												</p>
												<p class="p3 bottom-line">
													<span class="p3-label">{{ $t("Index.监管号") }}</span><br
														class="pc" />500991
												</p>
											</div>
										</div>
									</div>
									<div class="index-section-btn-box" hasRightAnimate>
										<a class="index-btn" href="/about-us">{{ $t("Index.了解EBC官方") }}<span
												class="iconfont icon-chevron_right"></span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="section3" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 4 }">
					<div class="section-bg">
						<div class="index-section3"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between flex-column">
								<div class="left">
									<h3 class="index-section-title " :class="$t('common.fontFamilyTitle')">
										{{ $t("Index.全面保障 安心以致") }}
									</h3>
									<div class="index-section-desc">
										{{ $t("Index.EBC将资金安全视为首要原则") }}
									</div>
								</div>
								<div class="right" hasRightAnimate>
									<div class="index-security-bottoms">
										<div class="index-security-bottom">
											<a href="/LearningCenterInfo?id=LEARN-196-367"><img
													src="../assets/image/Home/index-security-bottom1.svg" alt="" />
											</a>
										</div>
										<div class="index-security-bottom">
											<a href="/LearningCenterInfo?id=LEARN-770-2FF"><img
													src="../assets/image/Home/index-security-bottom2.svg" alt="" /></a>
										</div>
										<div class="index-security-bottom">
											<a href="/LearningCenterInfo?id=LEARN-369-368"><img
													src="../assets/image/Home/index-security-bottom3.svg" alt="" />
											</a>
										</div>
										<div class="index-security-bottom">
											<a href="/LearningCenterInfo?id=LEARN-2C9"><img
													src="../assets/image/Home/index-security-bottom4.svg" alt="" /></a>
										</div>
									</div>
									<div class="index-section-btn-box flex-left">
										<a class="index-btn" href="/security">{{ $t("Index.了解安全保障") }}<span
												class="iconfont icon-chevron_right"></span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="section4" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 5 }">
					<div class="section-bg">
						<div class="index-section4"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between  flex-column">
								<div class="left">
									<h3 class="index-section-title " :class="$t('common.fontFamilyTitle')">
										{{ $t("Index.致力于让交易者成为盈家") }}
									</h3>
									<div class="index-section-desc">
										{{ $t("Index.EBC每个引以为傲的数据背后") }}
									</div>
								</div>
								<div class="right" hasRightAnimate>
									<div class="index-section4-infos">
										<div class="index-section4-info">
											<p class="p1 cn-font-family-title">
												<span>$</span><span id="number1">{{ $t("Index.number1") }} </span>
												<template v-if="$t('Index.langFlag') == 'en'"><span>{{ $t("Index.亿")
												}}</span></template>
												<template v-if="$t('Index.langFlag') != 'en'">{{ $t("Index.亿")
												}}</template>

											</p>
											<h4 class="p2" v-html='$t("Index.月均交易量")'></h4>
										</div>
										<div class="index-section4-line"></div>
										<div class="index-section4-info">
											<p class="p1 cn-font-family-title">
												<span id="number2">{{ $t("Index.number2") }} </span>
												<template v-if="$t('Index.langFlag') == 'en'"><span>{{ $t("Index.万")
												}}</span></template>
												<template v-if="$t('Index.langFlag') != 'en'">{{ $t("Index.万")
												}}</template>
											</p>
											<h4 class="p2" v-html='$t("Index.全球注册用户数")'></h4>
										</div>
										<div class="index-section4-line"></div>
										<div class="index-section4-info">
											<p class="p1 cn-font-family-title">
												<span>$</span><span id="number3">{{ $t("Index.number3") }} </span>
												<template v-if="$t('Index.langFlag') == 'en'"><span>{{ $t("Index.万")
												}}</span></template>
												<template v-if="$t('Index.langFlag') != 'en'">{{ $t("Index.万")
												}}</template>
											</p>
											<h4 class="p2" v-html='$t("Index.2024年第四季度为交易订单优化增益")'></h4>
										</div>
										<div class="index-section4-line"></div>
										<div class="index-section4-info">
											<p class="p1 cn-font-family-title">
												<span id="number4">{{ $t("Index.number4") }} </span>
												<template v-if="$t('Index.langFlag') == 'en'"><span>{{ $t("Index.+")
												}}</span></template>
												<template v-if="$t('Index.langFlag') != 'en'">{{ $t("Index.+")
												}}</template>
											</p>
											<h4 class="p2" v-html='$t("Index.业务覆盖国家/地区")'></h4>
										</div>
									</div>
									<div class="index-section-btn-box">
										<a class="index-btn" href="/about-us">{{ $t("Index.了解全球视野") }}<span
												class="iconfont icon-chevron_right"></span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="section5" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 6 }">


					<div class="section-bg">
						<div class="index-section5"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between s5">
								<div class="left">
									<h3 class="index-section-title " :class="$t('common.fontFamilyTitle')">
										{{ $t("Index.85%以上") }}
										<br />
										{{ $t("Index.的订单成交于更优价格") }}
									</h3>
									<div class="index-section-desc">
										{{ $t("Index.EBC交易黑盒") }}
									</div>
									<div class="index-section5-banner mobile">
										<img src="../assets/image/Home/index-section5-banner.png" alt="" />
									</div>

									<div class="index-section5-list">
										<div class="index-section5-item">
											<p class="p1"><span id="number5">1000</span>+</p>
											<p class="p2">{{ $t("Index.每秒聚合订单") }}</p>
										</div>
										<div class="index-section5-item">
											<p class="p1"><span id="number6">20</span>ms</p>
											<p class="p2">{{ $t("Index.极速订单执行") }}</p>
										</div>
										<div class="index-section5-item">
											<p class="p1"><span id="number7">98.75</span>%</p>
											<p class="p2">{{ $t("Index.数据传输稳定高达") }}</p>
										</div>
										<div class="index-section5-item">
											<p class="p1"><span id="number8">0.0</span>pips</p>
											<p class="p2">{{ $t("Index.行级RAW ECN点差") }}</p>
										</div>
									</div>
									<div class="index-section-btn-box  mobile-show-flex">
										<a class="index-btn" href="/technology">{{ $t("Index.了解金融科技") }}<span
												class="iconfont icon-chevron_right"></span></a>
									</div>
								</div>
								<div class="right pc" hasRightAnimate>
									<div class="index-section5-wrapper">
										<div class="index-section5-banner">
											<img src="../assets/image/Home/index-section5-banner.png" alt="" />
										</div>
									</div>
									<div class="index-section-btn-box">
										<a class="index-btn" href="/technology">{{ $t("Index.了解金融科技") }}<span
												class="iconfont icon-chevron_right"></span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="section6" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 7 }">
					<div class="section-bg">
						<div class="index-section6"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between">
								<div class="left">
									<h3 class="index-section-title " :class="$t('common.fontFamilyTitle')">
										{{ $t("Index.EBC全球合作伙伴") }}
									</h3>
									<div class="index-section-desc">
										{{ $t("Index.EBC与25+国际权威银行及非银机构建立长期稳定的合作关系") }}
									</div>
								</div>
								<div class="right" hasRightAnimate>
									<div class="index-section6-list">
										<div class="index-section6-item">
											<img class="img-f1" src="../assets/image/Home/index-section6-friend1.svg"
												alt="" /><img class="img-f1"
												src="../assets/image/Home/index-section6-friend1_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f2" src="../assets/image/Home/index-section6-friend2.svg"
												alt="" /><img class="img-f2"
												src="../assets/image/Home/index-section6-friend2_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f3" src="../assets/image/Home/index-section6-friend3.svg"
												alt="" /><img class="img-f3"
												src="../assets/image/Home/index-section6-friend3_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f4" src="../assets/image/Home/index-section6-friend4.svg"
												alt="" /><img class="img-f4"
												src="../assets/image/Home/index-section6-friend4_a.svg" alt="" />
										</div>

										<div class="index-section6-item">
											<img class="img-f5" src="../assets/image/Home/index-section6-friend5.svg"
												alt="" /><img class="img-f5"
												src="../assets/image/Home/index-section6-friend5_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f6" src="../assets/image/Home/index-section6-friend6.svg"
												alt="" /><img class="img-f6"
												src="../assets/image/Home/index-section6-friend6_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f7" src="../assets/image/Home/index-section6-friend7.svg"
												alt="" /><img class="img-f7"
												src="../assets/image/Home/index-section6-friend7_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f8" src="../assets/image/Home/index-section6-friend8.svg"
												alt="" /><img class="img-f8"
												src="../assets/image/Home/index-section6-friend8_a.svg" alt="" />
										</div>

										<div class="index-section6-item">
											<img class="img-f9" src="../assets/image/Home/index-section6-friend9.svg"
												alt="" /><img class="img-f9"
												src="../assets/image/Home/index-section6-friend9_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f10" src="../assets/image/Home/index-section6-friend10.svg"
												alt="" /><img class="img-f10"
												src="../assets/image/Home/index-section6-friend10_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f11" src="../assets/image/Home/index-section6-friend11.svg"
												alt="" /><img class="img-f11"
												src="../assets/image/Home/index-section6-friend11_a.svg" alt="" />
										</div>
										<div class="index-section6-item">
											<img class="img-f12" src="../assets/image/Home/index-section6-friend12.svg"
												alt="" /><img class="img-f12"
												src="../assets/image/Home/index-section6-friend12_a.svg" alt="" />
										</div>
									</div>
									<div class="index-section-btn-box">
										<a class="index-btn" href="/about-us">{{ $t("Index.了解更多") }}<span
												class="iconfont icon-chevron_right"></span></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="section7" class="index-section-wrapper section">
				<div :class="{ 'section-animate': pageIndex == 8 }">
					<div class="section-bg">
						<div class="index-section7"></div>
					</div>
					<div class="index-section">
						<div class="index-section-inner">
							<div class="flex-between">
								<div class="right index-honor-list-box">
									<div class="index-honor-list over-hidden">
										<div class="swiper-wrapper">
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-2DC')"
													:title="$t('Index.十大最受欢迎券商')">
													<img src="../assets/image/Home/index-section7-award5.png" alt="" />
													<p>{{ $t("Index.十大最受欢迎券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-30-363')"
													:title="$t('Index.最透明交易券商')">
													<img src="../assets/image/Home/index-section7-award6.png" alt="" />
													<p>{{ $t("Index.最透明交易券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-559-34F')"
													:title="$t('Index.最受好评券商')">
													<img src="../assets/image/Home/index-section7-award7.png" alt="" />
													<p>{{ $t("Index.最受好评券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-486-3D7')"
													:title="$t('Index.卓越执行券商')">
													<img src="../assets/image/Home/index-section7-award8.png" alt="" />
													<p>{{ $t("Index.卓越执行券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-518-4C3')"
													:title="$t('Index.全球最佳交易体验奖')">
													<img src="../assets/image/Home/index-section7-award9.png" alt="" />
													<p>{{ $t("Index.全球最佳交易体验奖") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-647-43B')"
													:title="$t('Index.最受信赖券商')">
													<img src="../assets/image/Home/index-section7-award1.png" alt="" />
													<p>{{ $t("Index.最受信赖券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-647-43B')"
													:title="$t('Index.最佳CFD券商')">
													<img src="../assets/image/Home/index-section7-award2.png" alt="" />
													<p>{{ $t("Index.最佳CFD券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-2DC')"
													:title="$t('Index.全球最佳ECN券商')">
													<img src="../assets/image/Home/index-section7-award3.png" alt="" />
													<p>{{ $t("Index.全球最佳ECN券商") }}</p>
												</a>
											</div>
											<div class="swiper-slide">
												<a href="javascript:;" @click="jumPage('LEARN-2E5')"
													:title="$t('Index.全球新锐券商')">
													<img src="../assets/image/Home/index-section7-award4.png" alt="" />
													<p>{{ $t("Index.全球新锐券商") }}</p>
												</a>
											</div>
										</div>
										<div class="swiper-button-prev swiper-banner-prev mobile"></div>
										<div class="swiper-button-next swiper-banner-next mobile"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="section fp-auto-height">
				<page-footer @up="handlerUp"></page-footer>
			</section>
		</full-page>
	</div>
</template>
<script>
import WOW from 'wowjs';
import md5 from 'js-md5';
import 'swiper/css/swiper.css'
import Swiper from 'swiper'
import {
	mapState
} from "vuex";
import { method } from 'lodash';
import { create } from 'js-md5';



// 连接全球热门交易产品 tabs
// 比较大小返回对应类名
function compareValues(value1, value2, className = "") {
	// 尝试将两个值转换为数字
	const num1 = Number(value1);
	const num2 = Number(value2);

	// 检查转换后的值是否都是有效的数字
	if (!isNaN(num1) && !isNaN(num2)) {
		if (num1 >= num2) {
			return "green";
		} else if (num1 < num2) {
			return "red";
		} else {
			// 两者相等的情况
			return className;
		}
	}

	// 如果转换后不是有效数字，则返回空字符串
	return "";
}
// 传入ask,bid,返回点差Spread
function getSpread(t, e) {
	var o = /^\+?[1-9][0-9]*$/,
		r = 0,
		d = 0,
		c = 0;
	return (
		o.test(t) || (d = t.toString().split(".")[1].length),
		o.test(e) || (c = e.toString().split(".")[1].length),
		d > c
			? 1 === d
				? (r = 10 * t - 10 * e)
				: 2 === d
					? (r = 100 * t - 100 * e)
					: 3 === d
						? (r = 1e3 * t - 1e3 * e)
						: 4 === d
							? (r = 1e4 * t - 1e4 * e)
							: 5 === d && (r = 1e5 * t - 1e5 * e)
			: 1 === c
				? (r = 10 * t - 10 * e)
				: 2 === c
					? (r = 100 * t - 100 * e)
					: 3 === c
						? (r = 1e3 * t - 1e3 * e)
						: 4 === c
							? (r = 1e4 * t - 1e4 * e)
							: 5 === c && (r = 1e5 * t - 1e5 * e),
		r.toFixed(0)
	);
}
import { mapGetters } from 'vuex';
export default {

	data() {
		return {
			signupUrl: "https://client.myebc.co/signup-c/",
			rankListIndex: 1,
			rankListData: [],
			options: {
				licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
				// normalScrollElements: ".index-banner-list",
				onLeave: this.onSectionLeave,
				slideSelector: '.slide',
				loopHorizontal: true,
				controlArrows: false,
				scrollOverflow: true,// 内容超过满屏时是否显示滚动条，需要jquery.slimscroll插件
				responsiveWidth: 996,
			},
			forex: {
				"EURUSD": {
					chadian: "",
					bid_price: "0.00",
					ask_price: "0.00",
					num: "",
					percentage: 0
				},
				"XAUUSD": {
					chadian: "",
					bid_price: "0.00",
					ask_price: "0.00",
					num: "",
					percentage: 0
				},

				"U30USD": {
					chadian: "",
					bid_price: "0.00",
					ask_price: "0.00",
					num: "",
					percentage: 0
				},
			},
			XAGUSDask: "",
			EURUSDask: "",
			U30USDDask: "",
			pageIndex: -1,
			// 下标
			listIndex: 0,
			// 衍生品交易 Forex：EUR/USD, GBP/USD, USD/JPY, AUD/USD
			// 贵金属及能源 Commodities：XAG/USD, XAU/USD, XBR/USD, XNG/USD
			// 全球股指CFDs IndexCFDs：SPXUSD, NASUSD, U30USD, D30EUR
			// 美国股票CFDs StockCFDs：Apple, Tesla, Amazon, Microsoft
			allProducts: [
				{
					displaySymbol: "EURUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "GBPUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "USDJPY",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "AUDUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Forex",
					listIndex: 0,
				},
				{
					displaySymbol: "XAUUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "XAGUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "XBRUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "XNGUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "Commodities",
					listIndex: 1,
				},
				{
					displaySymbol: "U30USD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "NASUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "SPXUSD",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "D30EUR",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "IndexCFDs",
					listIndex: 2,
				},
				{
					displaySymbol: "AAPL",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
				{
					displaySymbol: "TSLA",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
				{
					displaySymbol: "AMZN",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
				{
					displaySymbol: "MSFT",
					ask: "-",
					bid: "-",
					spread: "-",
					bidClassName: "",
					askClassName: "",
					type: "StockCFDs",
					listIndex: 3,
				},
			]
		}
	},
	computed: {
		...mapGetters([
			// 'signupUrl',
		]),
		showProducts() {
			return this.allProducts.filter(el => {
				return el.listIndex == this.listIndex
			})
		}
	},
	created() {
		this.getPortfolio();
		this.getListData();
		// if(this.isMobile()){
		// 	this.options.autoScrolling=false;
		// 	this.options.fitToSection=false;
		// }else{
		// 	this.options.autoScrolling=true;
		// 	this.options.fitToSection=true;
		// }
	},
	mounted() {
		this.initHover();
		this.createdRankData(1);

	},
	methods: {
		changeRank(index) {
			this.rankListIndex = index;
			this.createdRankData(index);
		},
		createdRankData(group) {
			//
			// https://api.appsuat.com/crm/openApi/win2025/queryAllWin2025List
			fetch(
				"https://api.eappback.com/crm/openApi/win2025/queryAllWin2025List",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						contestGroup: group,
					}),
				}
			)
				.then((response) => {
					if (!response.ok) throw new Error("Network response was not ok");
					return response.json();
				})
				.then((data) => {
					console.log("queryAllWin2025List", data);
					if (data && data.code == 200) {
						if (group == 2) {
							let result = data.data
								.sort((item1, item2) => {
									return item1.rank - item2.rank;
								})
								.map((el) => {
									return {
										id: el.uuid,
										Rank: el.rank,
										Nickname: el.nickName,
										Server: el.serverName,
										AccountNumber: el.account,
										Profit: el.profit.toFixed(2),

									};
								});
							result = result.filter((el) => {
								return el.Rank <= 5;
							});
							this.rankListData = result;

						}
						if (group == 1) {
							let result = data.data
								.sort((item1, item2) => {
									return item1.rank - item2.rank;
								})
								.map((el) => {
									return {
										id: el.uuid,
										Rank: el.rank,
										Nickname: el.nickName,
										Server: el.serverName,
										AccountNumber: el.account,
										ProfitRate: (el.yields * 100).toFixed(2) + "%",

									};
								});
							result = result.filter((el) => {
								return el.Rank <= 5;
							});
							this.rankListData = result;

						}
					}
				})
				.catch((error) =>
					console.error("There was a problem with the fetch operation:", error)
				);
		},
		getSrc(src, end) {
			return require('../assets/image/Home/icons/icon_' + src + end)
		},
		getPortfolio() {
			let time = new Date().getMilliseconds()
			this.$http('/ebc/api/getToken/run', {
				date: time,
				CheckSum: md5(time + "YISENCE")
			}).then(res => {
				let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
				this.init(url)
			})
		},
		//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
		init(url) {
			this.ws = new WebSocket(url)

			//监听是否连接成功
			this.ws.onopen = () => {
				//连接成功则发送一个数据
				this.ws.send('连接成功');
			}

			//接听服务器发回的信息并处理展示
			this.ws.onmessage = (data) => {
				if (data.data == 'Ws connected success！' || data.data == '连接成功') return
				let info = JSON.parse(data.data)
				if (info.tick.length == 0) return
				info.tick.forEach(o => {
					o.chadian = this.getCount(o.ask_price * 1, o.bid_price * 1)
				})
				this.updateTabOneDocument({
					ask: info.tick[0].ask_price,
					bid: info.tick[0].bid_price,
					displaySymbol: info.symbol,
				});
			}

			//监听连接关闭事件
			this.ws.onclose = () => {
				//监听整个过程中websocket的状态
				console.log('ws连接状态：' + this.ws.readyState);
			}

			//监听并处理error事件
			this.ws.onerror = function (error) {
				console.log(error);
			}
		},
		getCount(idx1, idx2) {
			const r = /^\+?[1-9][0-9]*$/; // 正整数
			// 如果判断不符合正则，则不是正整数不能提交
			let num = 0;
			let a1 = 0
			let a2 = 0
			if (!r.test(idx1)) {
				a1 = idx1.toString().split(".")[1].length;
			}
			if (!r.test(idx2)) {
				a2 = idx2.toString().split(".")[1].length;
			}
			if (a1 > a2) {
				if (a1 === 1) {
					num = idx1 * 10 - idx2 * 10
				} else if (a1 === 2) {
					num = idx1 * 100 - idx2 * 100
				} else if (a1 === 3) {
					num = idx1 * 1000 - idx2 * 1000
				} else if (a1 === 4) {
					num = idx1 * 10000 - idx2 * 10000
				} else if (a1 === 5) {
					num = idx1 * 100000 - idx2 * 100000
				}
			} else {
				if (a2 === 1) {
					num = idx1 * 10 - idx2 * 10
				} else if (a2 === 2) {
					num = idx1 * 100 - idx2 * 100
				} else if (a2 === 3) {
					num = idx1 * 1000 - idx2 * 1000
				} else if (a2 === 4) {
					num = idx1 * 10000 - idx2 * 10000
				} else if (a2 === 5) {
					num = idx1 * 100000 - idx2 * 100000
				}
			}
			return num.toFixed(0);
		},
		tear(item) {
			let data = JSON.stringify(item)
			return data.split('.')
		},
		initHover() {
			if (!this.isMobile()) {
				this.swiper = new Swiper('.index-honor-list', {
					lazy: {
						loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
						loadPrevNextAmount: 2 // 默认为1，设置在延迟加载图片时，提前多少个slide
					},
					zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
						maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
						minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
						toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
					},
					// navigation: { // 如果需要前进后退按钮
					// 	nextEl: '.swiper-button-next',
					// 	prevEl: '.swiper-button-prev'
					// },
					scrollbar: { // 如果需要滚动条
						el: '.swiper-scrollbar',
						hide: true // 滚动条是否自动隐藏，默认false
					},
					slidesPerView: 4,
					mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
					direction: 'horizontal', // 滑动方向
					speed: 6000, // 切换速度，自动滑动开始到结束的时间
					grabCursor: false, // 悬浮时鼠标样式切换
					spaceBetween: 60,
					centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
					effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
					cubeEffect: { // cube效果参数
						slideShadows: false, // 是否开启slide投影，默认true
						shadow: false, // 是否开启投影，默认true
						shadowOffset: 20, // 投影距离。默认 20，单位px。
						shadowScale: 1 // 投影缩放比例。默认0.94。
					},
					// autoplay: true, // 启动自动切换，等同于以下设置
					autoplay: {
						delay: 0,
						disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
						waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
					},
					loop: true // 循环模式选项
				})
				// 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
				this.swiper.el.onmouseover = function () { // 鼠标覆盖停止自动切换
					this.swiper.autoplay.stop()
				}
				this.swiper.el.onmouseout = function () { // 鼠标离开开始自动切换
					this.swiper.autoplay.start()
				}
			} else {
				this.swiper = new Swiper('.index-honor-list', {
					lazy: {
						loadPrevNext: true, // 默认false情况下swiper只加载当前slide的图片，其他图片不加载，设置为true后，swiper会提前加载下一个slide的图片
						loadPrevNextAmount: 1 // 默认为1，设置在延迟加载图片时，提前多少个slide
					},
					zoom: { // 开启缩放功能，双击slide会放大/缩小，并且在手机端可双指触摸缩放
						maxRatio: 3, // 默认3，设置缩放的最大放大比例，如果要在单个slide设置放大比例，可以在其上添加data-swiper-zoom="3"
						minRatio: 2, // 默认为1，最小缩放焦距（缩小倍率）
						toggle: true // 默认为true，是否允许双击缩放，为false时，不允许双击缩放，只允许手机端触摸缩放
					},
					navigation: { // 如果需要前进后退按钮
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					scrollbar: { // 如果需要滚动条
						el: '.swiper-scrollbar',
						hide: true // 滚动条是否自动隐藏，默认false
					},
					slidesPerView: 1,
					spaceBetween: 50,
					centeredSlides: true,
					mousewheel: false, // 是否开启鼠标滚轮控制swiper切换 ，默认false
					direction: 'horizontal', // 滑动方向
					speed: 700, // 切换速度，自动滑动开始到结束的时间
					grabCursor: false, // 悬浮时鼠标样式切换
					centerInsufficientSlides: true, // 当slide总数小于slidesPerView时，slide居中
					effect: 'slide', // slide的切换效果，默认为'slide'位移切换，'fade'淡入，'cube'方块，'coverflow'3d流，'flip'3d翻转，'cards'卡片式，'creative'创意性
					cubeEffect: { // cube效果参数
						slideShadows: false, // 是否开启slide投影，默认true
						shadow: false, // 是否开启投影，默认true
						shadowOffset: 20, // 投影距离。默认 20，单位px。
						shadowScale: 1 // 投影缩放比例。默认0.94。
					},
					// autoplay: true, // 启动自动切换，等同于以下设置
					// autoplay: {
					// 	delay: 0,
					// 	disableOnInteraction: false, // 用户操作之后，是否禁止autoplay，默认true，操作包括触碰，拖动，点击pagination
					// 	waitForTransition: true // 是否等待过渡完成，再开始自动切换的计时，默认true
					// },
					loop: true // 循环模式选项
				})
				// 6.6.2之前的版本需要通过代码实现此功能；开启后，鼠标置于swiper时暂停自动切换，离开时回复自动切换
				this.swiper.el.onmouseover = function () { // 鼠标覆盖停止自动切换
					this.swiper.autoplay.stop()
				}
				this.swiper.el.onmouseout = function () { // 鼠标离开开始自动切换
					this.swiper.autoplay.start()
				}
			}
		},
		isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			return flag;
		},
		jumPage(id) {
			this.$router.push({
				name: 'LearningCenterInfo',
				query: {
					id: id
				}
			})
		},
		onSectionLeave(index, nextIndex, direction) {
			console.log("nextIndex.index", nextIndex.index)
			// 当一个新部分被加载后触发
			if (this.isMobile()) {
				this.pageIndex = 0
			} else {
				this.pageIndex = nextIndex.index
			}
			console.log("this.pageIndex", this.pageIndex)
			// console.log(nextIndex.index);
			// 数字跑动
			if (nextIndex.index == 5) {
				let number1 = this.$t("Index.number1") - 0
				let number1Text = this.$t("Index.number1Text")
				let number2 = this.$t("Index.number2") - 0
				let number2Text = this.$t("Index.number2Text")
				let number3 = this.$t("Index.number3") - 0
				let number3Text = this.$t("Index.number3Text")
				let number4 = this.$t("Index.number4") - 0
				let number4Text = this.$t("Index.number4Text")
				let langFlag = this.$t("Index.langFlag")
				$({
					countNum: 0,
				}).animate(
					{
						countNum: number1,
					},
					{
						duration: 2200,
						easing: "linear",
						step: function () {
							$("#number1").eq(0).text(Math.floor(this.countNum));
						},
						complete: function () {
							$("#number1").eq(0).text(number1Text);
						},
					}
				);
				$({
					countNum: 0,
				}).animate(
					{
						countNum: number2,
					},
					{
						duration: 2200,
						easing: "linear",
						step: function () {
							$("#number2").eq(0).text(Math.floor(this.countNum));
						},
						complete: function () {
							$("#number2").eq(0).text(number2Text);
						},
					}
				);
				$({
					countNum: 0,
				}).animate(
					{
						countNum: number3,
					},
					{
						duration: 2200,
						easing: "linear",
						step: function () {
							if (langFlag && langFlag == "en") {
								$("#number3")
									.eq(0)
									.text((this.countNum / 100).toFixed(2));
							} else {
								$("#number3").eq(0).text(Math.floor(this.countNum));
							}
						},
						complete: function () {
							$("#number3").eq(0).text(number3Text);
						},
					}
				);
				$({
					countNum: 0,
				}).animate(
					{
						countNum: number4,
					},
					{
						duration: 2200,
						easing: "linear",
						step: function () {
							$("#number4").eq(0).text(Math.floor(this.countNum));
						},
						complete: function () {
							$("#number4").eq(0).text(number4Text);
						},
					}
				);
			}
			if (nextIndex.index == 6) {
				$({
					countNum: 0,
				}).animate(
					{
						countNum: 1000,
					},
					{
						duration: 1400,
						easing: "linear",
						step: function () {
							$("#number5").eq(0).text(Math.floor(this.countNum));
						},
						complete: function () {
							$("#number5").eq(0).text("1,000");
						},
					}
				);
				$({
					countNum: 0,
				}).animate(
					{
						countNum: 20,
					},
					{
						duration: 1400,
						easing: "linear",
						step: function () {
							$("#number6").eq(0).text(Math.floor(this.countNum));
						},
						complete: function () {
							$("#number6").eq(0).text(this.countNum);
						},
					}
				);
				$({
					countNum: 0,
				}).animate(
					{
						countNum: 98.75,
					},
					{
						duration: 1400,
						easing: "linear",
						step: function () {
							$("#number7").eq(0).text(Math.floor(this.countNum));
						},
						complete: function () {
							$("#number7").eq(0).text(this.countNum);
						},
					}
				);
				$({
					countNum: 60,
				}).animate(
					{
						countNum: 0,
					},
					{
						duration: 1400,
						easing: "linear",
						step: function () {
							console.log(
								"Math.floor(this.countNum)",
								Math.floor(this.countNum / 10)
							);
							$("#number8")
								.eq(0)
								.text((this.countNum / 10).toFixed(1));
						},
						complete: function () {
							$("#number8").eq(0).text("0.0");
						},
					}
				);
			}
		},
		scrollToSection(sectionIndex) {
			console.log("sectionIndex", sectionIndex, this.$refs.fullpage.api)
			// 检查 fullpage 是否已经初始化
			if (this.$refs.fullpage && this.$refs.fullpage.api) {
				console.log("in", sectionIndex)
				// 使用 moveTo 方法滚动到指定的 section
				this.$refs.fullpage.api.moveTo(sectionIndex); // 注意：索引从1开始
			}
		},
		handlerUp() {
			if (!this.isMobile()) {
				this.scrollToSection(1)
			}
		},
		handleClick(index, event) {
			if (index == this.listIndex) {
				return;
			}
			this.listIndex = index;
		},
		// 更新单条数据元素
		updateTabOneDocument(newValue) {
			// console.log("updateTabOneDocument", newValue)
			let flag = false;
			let el = {};
			this.allProducts = this.allProducts.map((oldValue) => {
				if (newValue.displaySymbol == oldValue.displaySymbol) {
					flag = true;
					oldValue.askClassName = compareValues(
						newValue.ask,
						oldValue.ask,
						oldValue.askClassName
					);
					oldValue.bidClassName = compareValues(
						newValue.bid,
						oldValue.bid,
						oldValue.bidClassName
					);
					oldValue.ask = newValue.ask;
					oldValue.bid = newValue.bid;
					oldValue.spread = getSpread(oldValue.ask, oldValue.bid);
					el = oldValue;
				}
				return oldValue;
			});

		},
		// 获取历史数据列表
		getListData() {
			$.ajax({
				type: "GET",
				url: "https://www.ebccfd.com/portal/api/getSymbolAll",
				success: (res) => {
					console.log("/api/symbol/all res", res)
					let data = res.rows;
					this.createAllDataMeta(data);
				},
				fail: (err) => {
					console.log("/api/symbol/all err", err)
				}
			})
		},
		// 创建所有数据
		createAllDataMeta(data) {
			data.map((newValue) => {
				this.allProducts = this.allProducts.map((oldValue) => {
					if (newValue.displaySymbol == oldValue.displaySymbol) {
						oldValue.askClassName = compareValues(newValue.ask, oldValue.ask);
						oldValue.bidClassName = compareValues(newValue.bid, oldValue.bid);
						oldValue.ask = newValue.ask;
						oldValue.bid = newValue.bid;
						oldValue.spread = getSpread(oldValue.ask, oldValue.bid);
					}
					return oldValue;
				});
			});
		}
	}

}
</script>

<style lang="scss" scoped>
@font-face {
	font-family: "Financier Display";
	src: url("../assets/font/FinancierDisplay-Bold.otf") format("opentype");
}

.index-section-title[hasLeftAnimate] {
	text-align: center;
}

.fp-auto-height {
	background-color: #232222;
}

.th-font-family-title {
	font-weight: 800 !important;
}

.pc {
	visibility: visible;
}

.mobile {
	visibility: hidden;
}

body {
	background-color: #f8f8f8;
}

.fp-tableCell {
	position: relative;
}

.flex-left {
	justify-content: flex-start;
}

/* Styling Our Menu */
#fullpageMenu {
	position: fixed;
	top: calc(50% - 160px);
	left: calc(50% - 702px);
	z-index: 70;
	padding: 0;
	margin: 0;
	display: flex;
	gap: 6px;
}

#fullpageMenu[column] {
	flex-direction: column;
}

#fullpageMenu a {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 20px;
	width: 20px;
	color: #000;
}



/* #fullpageMenu .icon-dot,
.banner-pagination .iconfont {
  
} */
#fullpageMenu .icon-actived {
	display: none;
}

#fullpageMenu .active .icon-dot {
	display: none;
}

#fullpageMenu .active .icon-actived {
	display: inline-block;
}

#fullpageMenu .iconfont,
.banner-pagination .iconfont {
	transition: all 0.3s;
}

/* #fullpageMenu a:hover .iconfont,
.banner-pagination .iconfont:hover {
  transform: scale(1.8);
} */
/* #fullpageMenu a:hover .icon-actived,
.banner-pagination .icon-actived:hover {
  transform: scale(1.8);
} */

.menu-hidden {
	opacity: 0;
}

.buy-box {
	display: none;
}

.swiper-banner-next,
.swiper-news-next,
.swiper-next-next {
	background-image: url(../assets/image/Home/index-arrow-right.svg);
	right: 10px !important;
	left: auto !important;
	top: 50% !important;
	width: 22px;
	height: 44px;
	background-size: 80%;
	background-repeat: no-repeat;
	/* opacity: 0.3; */
}

.swiper-banner-prev,
.swiper-news-prev,
.swiper-next-prev {
	background-image: url(../assets/image/Home/index-arrow-left.svg);
	left: 10px !important;
	right: auto !important;
	top: 50% !important;
	width: 22px;
	height: 44px;
	background-size: 80%;
	background-repeat: no-repeat;
	/* opacity: 0.3; */
}

.swiper-news-next,
.swiper-news-prev {
	visibility: hidden;
}

.index-banner-list {
	position: relative;
}

.index-banner-list .swiper-slide {
	width: 100%;
	height: calc(100vh - 88px);
}

.flex-between {
	position: relative;
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	/* gap: 80px 20px; */
}

.flex-column {
	flex-direction: column;
	gap: 100px;
}

.flex-between .left {
	flex-shrink: 0;
	width: 25.64%;
	display: flex;
	flex-direction: column;
	gap: 26px;
}

.flex-between .right {
	position: relative;
	flex-shrink: 0;
	width: 62.97%;
}

/* .cn .flex-between .right {
  width: 58.97%;
} */
.cn .s5 .left {
	width: 39.74%;
}

.s5 .left {
	width: 46.74%;
}

.cn .s5 .right {
	width: 43.58%;
}

.s5 .right {
	width: 43.58%;
}

.flex-column .left {
	width: 43%;
}

.flex-column .right {
	width: auto !important;
}

.align-center {
	align-self: center;
}

.over-hidden {
	overflow: hidden;
}

.index-section-wrapper {
	position: relative;
	overflow: hidden;
}

/* .index-section-wrapper:nth-child(2n + 1) {
  background: #f9f6f2;
} */
.section-bg {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: -1;
}

.section-bg>div {
	width: 100%;
	height: 100%;
}


.index-section1-box {
	display: flex;
	flex-direction: column;
}

.index-section1-tabs {
	position: relative;
	display: flex;
	align-items: flex-end;
	height: 61px;
}

.index-section1-tab {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 180px;
	height: 49px;
	padding: 8px 12px;
	flex-shrink: 0;
	border-radius: 10px 10px 0px 0px;
	background: #f9f6f2;
	box-shadow: 8px -4px 10px 0px rgba(0, 0, 0, 0.05);
	color: #6a6967;
	font-size: 20px;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	transition: all 0.27s;
}

.index-section1-tab:nth-child(1) {
	z-index: 1004;
}

.index-section1-tab:nth-child(2) {
	z-index: 1003;
}

.index-section1-tab:nth-child(3) {
	z-index: 1002;
}

.index-section1-tab:nth-child(4) {
	z-index: 1001;
}

.index-section1-tabs .active {
	height: 61px;
	background: #fff;
	color: #9f734c;
	z-index: 1007;
}

.index-section1-list {
	width: 100%;
	min-height: 392px;
	border-radius: 0px 10px 10px 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
	padding: 0 60px 8px;
}

.index-section1-list-thead .index-section1-list-tr {
	display: flex;
	color: #b2afad;
	font-size: 14px;
	font-weight: 400;
	line-height: 1em;
	padding-top: 30px;
	padding-bottom: 5px;
}

.index-section1-list-tr+.index-section1-list-tr {
	border-top: 1px solid #e6ddcf;
}

.index-section1-list-tr>div:nth-child(1) {
	width: 22.9%;
}

.index-section1-list-tr>div:nth-child(2) {
	width: 22%;
	text-align: center;
}

.index-section1-list-tr>div:nth-child(3) {
	width: 21.7%;
	text-align: center;
}

.index-section1-list-tr>div:nth-child(4) {
	width: 17.2%;
	text-align: center;
}

.index-section1-list-tr>a,
.index-section1-list-tr>div:nth-child(5) {
	width: 15.9%;
}

.index-section1-list-tbody .index-section1-list-tr {
	display: flex;
	align-items: center;
	color: #6a6967;
	font-family: Gilroy;
	font-size: 22px;
	font-weight: 400;
	line-height: 33px;
	padding: 24px 0;
}

.index-section1-list-tbody img {
	width: 36px;
	height: 36px;
}

.mobile-icon {
	width: 38px !important;
	height: 25px !important;
}

.index-section1-list-tbody .index-section1-list-tr>div:nth-child(1) {
	display: flex;
	gap: 12px;
	align-items: center;
	color: #5d2333;
	font-family: Gilroy;
	font-size: 20px;
	font-weight: 700;
	line-height: 27px;
}

.buy {
	display: flex;
	width: 100%;
	height: 100%;
	/* padding: 8px 23px 10px 24px; */
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 4px;
	border: 1px solid #9f734c;
	color: #9f734c;
	font-size: 16px;
	font-weight: 500;
	transition: all 0.4s;
}

.buy:hover {
	color: #fff;
	background-color: #9f734c;
}

.index-section1 {
	background: url(../assets/image/Home/index-section1-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transform: scale(1.05);
	opacity: 0.7;
}

.index-section2 {
	background: url(../assets/image/Home/index-section2-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.7;
}

.index-section3 {
	background: url(../assets/image/Home/index-section3-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.7;
}

.index-section4 {
	background: url(../assets/image/Home/index-section4-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.7;
}

.index-section5 {
	background: url(../assets/image/Home/index-section5-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.7;
}

.index-section6 {
	background: url(../assets/image/Home/index-section6-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.7;
}

.index-section7 {
	background: url(../assets/image/Home/index-section7-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transform: scale(1.05);
	opacity: 0.7;
}

.index-section8 {
	background: url(../assets/image/Home/index-section8-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	opacity: 0.3;
}

.index-section0 {
	background: url("../assets/image/Home/index-section0-bg.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	transform: scale(1.05);
}

.section-animate .index-section1,
.section-animate .index-section0,
.section-animate .index-section7 {
	animation-name: bganimateToBig;
	animation-duration: 1.4s;
	z-index: -1;
}

.section-animate .index-section2,
.section-animate .index-section3,
.section-animate .index-section4,
.section-animate .index-section5,
.section-animate .index-section6,
.section-animate .index-section8 {
	animation-name: bganimateToSmall;
	animation-duration: 1.2s;
	z-index: -1;
	/* animation-delay: 0.6s; */
}

@keyframes bganimateToBig {
	0% {
		transform: scale(1);
		/* opacity: 0.7; */
	}

	40% {
		transform: scale(1);
		/* opacity: 0.7; */
	}

	100% {
		transform: scale(1.05);
		/* opacity: 0.3; */
	}
}

@keyframes bganimateToSmall {
	0% {
		transform: scale(1.05);
		/* opacity: 0.7; */
	}

	40% {
		transform: scale(1.05);
		/* opacity: 0.7; */
	}

	100% {
		transform: scale(1);
		/* opacity: 0.3; */
	}
}

.section-animate [hasLeftAnimate],
.section-animate .left {
	animation-name: leftanimate;
	animation-duration: 1.4s;
}

.section-animate [hasRightAnimate] {
	animation-name: rightanimate;
	animation-duration: 1.4s;
}

.section-animate .index-section-card:nth-child(1),
.section-animate .index-section1-planes li:nth-child(1) {
	animation-name: rightanimate;
	animation-duration: 1.4s;
}

.section-animate .index-section-card:nth-child(2),
.section-animate .index-section1-planes li:nth-child(2) {
	animation-name: rightanimate2;
	animation-duration: 1.5s;
}

.section-animate .index-section-card:nth-child(3),
.section-animate .index-section1-planes li:nth-child(3) {
	animation-name: rightanimate3;
	animation-duration: 1.6s;
}

@keyframes leftanimate {
	0% {
		transform: translateX(-1000px);
	}

	15% {
		transform: translateX(-1000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate {
	0% {
		transform: translateX(2000px);
	}

	15% {
		transform: translateX(2000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate2 {
	0% {
		transform: translateX(2000px);
	}

	30% {
		transform: translateX(2000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate3 {
	0% {
		transform: translateX(2000px);
	}

	45% {
		transform: translateX(2000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate4 {
	0% {
		transform: translateX(2000px);
	}

	80% {
		transform: translateX(2000px);
	}

	100% {
		transform: translateX(0);
	}
}



.ar .section-animate .left {
	animation-name: leftanimateAr;
	animation-duration: 1.4s;
}

.ar .section-animate [hasRightAnimate] {
	animation-name: rightanimateAr;
	animation-duration: 1.4s;
}

.ar .section-animate .index-section-card:nth-child(1),
.ar .section-animate .index-section1-planes li:nth-child(1) {
	animation-name: rightanimateAr;
	animation-duration: 1.4s;
}

.ar .section-animate .index-section-card:nth-child(2),
.ar .section-animate .index-section1-planes li:nth-child(2) {
	animation-name: rightanimate2Ar;
	animation-duration: 1.5s;
}

.ar .section-animate .index-section-card:nth-child(3),
.ar .section-animate .index-section1-planes li:nth-child(3) {
	animation-name: rightanimate3Ar;
	animation-duration: 1.6s;
}

@keyframes leftanimateAr {
	0% {
		transform: translateX(1000px);
	}

	15% {
		transform: translateX(1000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimateAr {
	0% {
		transform: translateX(-2000px);
	}

	15% {
		transform: translateX(-2000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate2Ar {
	0% {
		transform: translateX(-2000px);
	}

	30% {
		transform: translateX(-2000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate3Ar {
	0% {
		transform: translateX(-2000px);
	}

	45% {
		transform: translateX(-2000px);
	}

	100% {
		transform: translateX(0);
	}
}

@keyframes rightanimate4Ar {
	0% {
		transform: translateX(-2000px);
	}

	80% {
		transform: translateX(-2000px);
	}

	100% {
		transform: translateX(0);
	}
}

.back .left,
.back .right {
	transform: translateX(0);
}

.index-banner-list .swiper-button-prev:after,
.index-banner-list .swiper-rtl .swiper-button-next:after,
.index-honor-list .swiper-button-prev:after,
.index-honor-list .swiper-rtl .swiper-button-next:after,
.news-list .swiper-button-prev:after,
.news-list .swiper-rtl .swiper-button-next:after {
	content: "";
}

.index-banner-list .swiper-button-next:after,
.index-banner-list .swiper-rtl .swiper-button-prev:after,
.index-honor-list .swiper-button-next:after,
.index-honor-list .swiper-rtl .swiper-button-prev:after,
.news-list .swiper-button-next:after,
.news-list .swiper-rtl .swiper-button-prev:after {
	content: "";
}

.index-section {
	display: flex;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	margin: 0 auto;
	max-width: 1800px;
	padding: 0 128px 88px 128px;
}

.index-section-inner {
	/* min-height: 540px; */
}

.index-section-type {
	color: #888888;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 22.5px;
}

.index-section-title {
	color: #a52933 !important;
	font-size: 36px;
	font-style: normal;
	font-weight: 900;
	line-height: normal;
	/* line-height: 36px; */
}
.index-section-main-title {
  color: #a52933;
  text-align: center;
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: 1.2;
  margin-bottom: 10px;
}
.index-section-sub-title {
	color: #a52933;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 1.1;
}

.cn .index-section-main-title {
	font-size: 40px;
}

.cn .index-section-sub-title {
	font-size: 20px;
}

.id .index-section-main-title,
.ru .index-section-main-title,
.ug .index-section-main-title {
	font-size: 30px;
}

.vi .index-section-main-title {
	font-size: 28px;
}

@media (max-width: 1920px) {

	.index-section-main-title {
		font-size: 34rem;
	}

	.index-section-sub-title {
		font-size: 20rem;
	}

	.cn .index-section-main-title {
		font-size: 40rem;
	}

	.cn .index-section-sub-title {
		font-size: 20rem;
	}

	.id .index-section-main-title,
	.ru .index-section-main-title,
	.ug .index-section-main-title {
		font-size: 30rem;
	}

	.vi .index-section-main-title {
		font-size: 28rem;
	}

}

.kr .index-section-title {
	font-weight: 700;
}

.index-section-tags {
	margin-top: 13px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* flex-wrap: wrap; */
	gap: 20px;
}

.index-section-tag {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	padding: 9px 17px;
	border-radius: 4px;
	border: 0.75px solid #b2afad;
	background: #fff;
	color: #5d2333;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	transition: all 0.3s;
}

.index-section-tag .icon-Vector {
	color: #a52933;
	/* background-color: #fff;  */
}

.index-section-tag:hover {
	color: #fff;
	background-color: #9f734c;
}

.index-section-tag:hover .icon-Vector {
	color: #fff;
	/* background-color: #9f734c; */
}

.index-section-desc {
	color: #666;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
}

.index-btn {
	// color: #999;
	// font-size: 16px;
	// font-style: normal;
	// font-weight: 400;
	// line-height: 1em;
	display: flex;

	padding: 10px 10px 10px 16px;
	border-radius: 5px;
	background: #A52933;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1em;
	transition: all 0.3s;
}

.index-btn-padding {
	padding: 12px 32px;
}

.index-btn-white {
	color: #fff;
}

.index-btn:hover {
	// color: #9f734c;
	color: #fff;
	background: #5D2333;
}

.index-section-btn-box .icon-chevron_right,
.index-section-btn-box .icon-chevron_left-copy {
	font-size: 16px;
}

.index-section1-planes {
	display: flex;
	gap: 30px;
	flex-shrink: 0;
}

.index-section1-planes li {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 34px 21px;
	width: calc(33.3% - 20px);
	font-size: 16px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
}

.index-section1-planes .name {
	color: #5d2333;
	font-family: Gilroy;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 1em;
}

.index-section1-planes-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.index-section1-planes-row1 {
	padding-bottom: 30px;
	border-bottom: 1px solid #e6ddcf;
}

.float {
	color: #6a6967;
	font-family: Gilroy;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1em;
}

.green {
	color: #51ad25 !important;
}

.red {
	color: #ff4b4e !important;
}

.index-section1-planes-row2 {
	margin-top: 30px;
}

.index-section1-planes .bid,
.index-section1-planes .ask {
	display: inline-block;
	margin: 0;
	padding: 7px 16px;
	border-radius: 4px;
	background: #9f734c;
	color: #fff;
	text-align: center;
	font-family: Gilroy;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 1em;
}

.bid-price,
.ask-price {
	margin: 0 !important;
	font-family: Gilroy;
	font-size: 21px;
	font-style: normal;
	font-weight: 400;
	line-height: 31.5px;
}

.bid-price span,
.ask-price span {
	font-size: 30px;
}

.spread {
	margin: 30px auto;
	display: inline-block;
	padding: 7px 16px;
	border-radius: 4px;
	border: 1px solid #e6ddcf;
	color: #9f734c;
	text-align: center;
	font-family: Gilroy;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 18px;
	min-width: 50%;
	z-index: 9;
	background: #fff;
}

.spread-line {
	width: 100%;
	height: 1px;
	background-color: #e6ddcf;
	transform: translateY(-46px);
}

.index-section-planes-btns {
	display: flex;
	justify-content: space-between;
	gap: 9px;
	margin-top: 45px;
}

.index-section-planes-btns a {
	flex-grow: 1;
	text-align: center;
	border-radius: 4px;
	border: 1px solid #a52933;
	padding: 7px;
	color: #a52933;
	font-family: Gilroy;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 1em;
	transition: all 0.3s;
}

.index-section-planes-btns a:hover {
	background-color: #a52933;
	color: #fff;
}

.index-section-btn-box {
	display: flex;
	justify-content: flex-end;
	/* margin: 0 auto; */
	/* max-width: 1560px; */
	margin-top: 40px;
}

.index-section-cards {
	display: flex;
	gap: 30px;
}

.index-section-card {
	width: calc(33.3% - 20px);
	/* max-width: 35%; */
	flex-grow: 1;
	flex-shrink: 0;
	padding: 21px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
}

.index-section-card>img {
	/* width: 75px; */
	height: 60px;
}

.index-section-card-info>.p1 {
	margin-top: 25px;
	color: #A52933;
	font-family: Gilroy;
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: 1em;
}

.jp .index-section-card-info>.p1,
.vi .index-section-card-info>.p1 {
	min-height: 90px;
}

.th .index-section-card-info>.p1,
.es .index-section-card-info>.p1,
.ug .index-section-card-info>.p1,
.ru .index-section-card-info>.p1,
.ar .index-section-card-info>.p1 {
	min-height: 60px;
}

.vi .index-section-card-info>.p1 {
	font-family: "Noto Sans SC", serif;
}

.th .index-section-card-info>.p1 {
	font-family: "Noto Sans Thai", serif;
}

.index-section-card-info>.p2 {
	color: #666;
	font-family: Gilroy;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	margin-top: 38px;
	padding-bottom: 18px;
	min-height: 62px;
}

.jp .index-section-card-info>.p2 {
	min-height: 71px;
}

.bottom-line {
	border-bottom: 1px solid #e6ddcf;
}

.index-section-card-info>.p3 {
	padding: 14px 0;
	color: #666;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.index-section-card-info>.p3>.p3-label {
	display: inline-block;
	color: #999;
	margin-bottom: 8px;
}

.index-security-bottoms {
	display: flex;
	gap: 52px;
}

.index-security-bottom {
	padding: 21px;
	width: calc(25% - 38px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
}

.index-security-bottoms a {
	position: relative;
	//width: 74.29%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.index-security-bottoms a img {
	/* position: absolute;
  left: 0;
  top: 0; */
	width: 100%;
}

.index-section4-infos {
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 40px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
	gap: 20px;
}

.index-section4-info {
	align-self: flex-start;
}

/* .en .index-section4-infos{
  gap: 0;
  justify-content: space-between;
}
.en .index-section4-info{
  width: 20%;
} */
.index-section4-info .p1 {
	display: flex;
	align-items: baseline;
	background: linear-gradient(91deg, #ff4b4e 2.2%, #cc333f 96.77%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 35px;
	font-style: normal;
	font-weight: 900;
	/* padding:0 8%; */
}

.cn .index-section4-info .p1 {
	padding: 0;
}

.index-section4-info .p1 span {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-family: "Financier Display";
	font-size: 70px;
	font-style: normal;
	font-weight: 700;
}

.index-section4-line {
	width: 1px;
	height: 39px;
	flex-shrink: 0;
	background: #b2afad;
}

.index-section4-info .p2 {
	color: #666;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	margin-top: 10px;
	/* padding:0 8%; */
}

.cn .index-section4-info .p2 {
	padding: 0;
}

.index-section5-wrapper {
	display: flex;
	gap: 72px;
}

.index-section5-list {
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	margin-top: 40px;
	gap: 20px;
}

.hi .index-section5-item,
.es .index-section5-item {
	width: calc(25% - 10px);
}

.index-section5-banner {
	padding: 18px 40px;
	border-radius: 10px;
	opacity: 0.7;
	background: #fff;
	box-shadow: 0px 10px 22px 0px rgba(0, 0, 0, 0.08);
}

/* .index-section5-item{
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;
} */
.index-section5-item .p1 {
	color: #9f734c;
	font-family: "Financier Display";
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 1em;
}

.index-section5-item .p1 span {
	font-size: 40px;
}

.index-section5-item .p2 {
	margin-top: 14px;
	color: #6a6967;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 1em;
}

.index-section6-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	gap: 70px 60px;
	padding: 50px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
	/*  */
}

.index-section6-item {
	/* width: 25%; */
	position: relative;
	height: 55px;
	width: 153px;
}

.index-section6-item img {
	height: 100%;
	/* height: 55px;
  width: 153px; */
}

.index-section6-item img:first-child {
	display: block;
}

.index-section6-item img:last-child {
	display: none;
}

.index-section6-item:hover img:first-child {
	display: none;
}

.index-section6-item:hover img:last-child {
	display: block;
}

.index-section .index-honor-list-box {
	position: relative;
	/* height: 288px; */
	padding: 0 50px;
	width: 100% !important;
}

/* .index-honor-list-left {
  position: absolute;
  width: 100px;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(245, 245, 245, 0.9),
    rgba(245, 245, 245, 0)
  );
  z-index: 999;
}
.index-honor-list-right {
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(245, 245, 245, 0.9),
    rgba(245, 245, 245, 0)
  );
  z-index: 999;
} */
.index-section .news-list-box {
	width: 100% !important;
}

.index-honor-list p {
	margin-top: 24px;
	color: #5d2333;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px;
	/* 175% */
}

.index-honor-list .swiper-slide {
	width: auto;
}

.index-honor-list a {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.index-honor-list img {
	/* height: 228px; */
	width: auto;
}

.news-list {
	margin: 0 !important;
}

.news-li {
	padding: 18px 20px;
	border-radius: 5px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.08);
}

.news-item-time {
	color: #b2afad;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	/* line-height: 18px; */
	margin-bottom: 20px;
}

.news-list h3 {
	margin-top: 25px;
	/* min-height: 75px; */
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: 50px;
}

.news-list h3 a {
	color: #666;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 25px;
}

.news-list p {
	margin-top: 19px;
	color: #888;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	/* 150% */

	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.swiper-wrapper {
	/* height: calc(100vh - 86px) !important; */
}

.index-honor-list .swiper-wrapper {
	-webkit-transition-timing-function: linear;
	/*之前是ease-out*/
	-moz-transition-timing-function: linear;
	-ms-transition-timing-function: linear;
	-o-transition-timing-function: linear;
	transition-timing-function: linear;
}

/* .figure-bg2-1{
  position: absolute;
  width: 800px;
  left: -400px;
  bottom: -305px;
} */
.mh-133 {
	min-height: 109px;
}

@media (max-width: 1600px) {
	.index-section1-tab {
		width: 140px;
		font-size: 16px;
	}

	.index-section {
		max-width: 1440px;
		padding: 0 80px 88px 80px;
	}

	/* .cn .flex-between .right {
    width: 65.97%;
  } */
	.flex-between .right {
		width: 65.97%;
	}

	.cn .s5 .left {
		width: 42.74%;
	}

	.s5 .left {
		width: 42.74%;
	}

	.cn .s5 .right {
		width: 50.58%;
	}

	.s5 .right {
		width: 50.58%;
	}

	/* .index-section4-infos {
    padding-left: 100px;
  } */
	.mh-109 {
		min-height: 109px;
	}
}


.section0-content {
	position: relative;
	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.index-section0-box {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 14px;
	/* width: 30.86%; */
	width: 40%;
	height: 500px;
	background: url("../assets/image/Home/index-section9-left-bg.png");
	background-size: 85% auto;
	background-position: center;
	background-repeat: no-repeat;
}

.rank-list {
	/* width: 46.29%; */
	width: 54%;
}

.rank-list-tabs {
	position: relative;
	display: flex;
	align-items: flex-end;
	height: 61px;
	/* gap: 5px; */
}

.rank-list-tab {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 180px;
	height: 49px;
	padding: 8px 12px;
	flex-shrink: 0;
	border-radius: 10px 10px 0px 0px;
	background: #f9f6f2;
	box-shadow: 8px -4px 10px 0px rgba(0, 0, 0, 0.05);
	color: #6a6967;
	font-size: 20px;
	font-weight: 400;
	line-height: normal;
	cursor: pointer;
	transition: all 0.27s;
}

.rank-list-tab-active {
	background: #fff;
	color: #5d2333;
	height: 61px;
}

/* .rank-list-tab-active{
  
} */
.rank-list-table {
	display: flex;
	flex-direction: column;
	min-height: 400px;
	width: 100%;
	padding: 20px 40px 8px;
	border-radius: 0px 10px 10px 10px;
	background: #fff;
	box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
}

.ar .index-section1-list {
	border-radius: 10px 0px 10px 10px;
}

.ar .rank-list-table {
	border-radius: 10px 0px 10px 10px;
}

.rank-list-table-body {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.rank-list-th {
	display: flex;
	color: #91908e;
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: normal;
}

.rank-list-tr {
	display: flex;
	color: #232222;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
	line-height: 1em;
	padding: 18px 0;
}

.rank-list-tr+.rank-list-tr {
	border-top: 1px solid #e9e8e7;
}

.rank-list-th .rank-list-td,
.rank-list-tr .rank-list-td {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20%;
	text-align: center;
	flex-shrink: 0;
}

.rank-list-th .rank-list-td:nth-child(2),
.rank-list-tr .rank-list-td:nth-child(2) {
	width: 34%;
}

.rank-list-th .rank-list-td:first-child,
.rank-list-tr .rank-list-td:first-child {
	width: 7%;
}

.rank-list-tr .rank-list-td:first-child {
	font-family: "Financier Display";
	font-size: 20px;
}


.rank-list-th .rank-list-td:last-child,
.rank-list-tr .rank-list-td:last-child {
	width: 18%;
}

.rank-list-tr>.rank-list-td:nth-child(4) {
	color: #a52933;
	font-weight: 700;
}

.rank-list-tips {
	margin-top: 30px;
	color: #b2afad;
	/* text-align: center; */
	font-size: 12px;
	font-weight: 500;
	line-height: normal;
}

.gendan-btn {
	display: flex;
	padding: 10px 17px;
	justify-content: center;
	align-items: center;
	color: #a52933;
	text-align: center;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 1em;
	border-radius: 4px;
	border: 1px solid #a52933;
	transition: all 0.3s;
}

.en .gendan-btn {
	font-size: 12px;
}

.gendan-btn:hover {
	background-color: #a52933;
	color: #fff;
}

@media (max-width: 1440px) {
	.mh-133 {
		min-height: 133px;
	}

	#fullpageMenu {
		left: 34px;
	}

	.index-section {
		max-width: 1440px;
		padding: 0 60px 88px 60px;
	}

	.index-section-card-info>.p1 {
		font-size: 24px;
	}

	.index-section6-item {
		height: 44px;
	}

	.index-section-title {
		font-size: 30px;
	}

	.index-section1-planes {
		gap: 18px;
	}

	.index-section-cards {
		gap: 18px;
	}

	.index-section1-planes li {
		padding: 24px 18px;
	}

	.bid-price,
	.ask-price {
		font-size: 18px;
	}

	.bid-price span,
	.ask-price span {
		font-size: 24px;
	}

	.jp .index-section-card-info>.p1,
	.vi .index-section-card-info>.p1 {
		min-height: 48px;
	}

	.th .index-section-card-info>.p1,
	.es .index-section-card-info>.p1,
	.ug .index-section-card-info>.p1,
	.ru .index-section-card-info>.p1,
	.ar .index-section-card-info>.p1 {
		min-height: 48px;
	}

	.index-section5-item .p1 span {
		font-size: 32px;
	}

	.index-section5-item .p2 {
		font-size: 14px;
	}

	.index-security-bottoms {
		gap: 32px;
	}

	.index-security-bottom {
		// padding: 14px;
	}
}

@media (max-width: 1280px) {


	.index-section1-tab {
		width: 120px;
		font-size: 15px;
	}

	.mh-133 {
		min-height: 117px;
	}

	.mh-109 {
		min-height: 75px;
	}

	#fullpageMenu {
		left: 32px;
	}

	.index-section-card-info>.p1 {
		font-size: 18px;
	}

	.index-section-card-info>.p2 {
		font-size: 13px;
	}

	.index-section-card-info>.p3 {
		font-size: 13px;
	}

	.jp .index-section-card-info>.p1,
	.vi .index-section-card-info>.p1 {
		min-height: 36px;
	}

	.index-security-bottoms {
		gap: 18px;
	}



	.index-security-bottom {
		// padding: 10px 0;
	}

	.index-section6-item {
		height: 38px;
	}

	.index-section5-list {
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 6px;
	}

	.index-section5-item {
		width: calc(50% - 10px);
	}

	.hi .index-section5-item,
	.es .index-section5-item {
		width: calc(50% - 10px);
	}

	.index-section5-item .p2 {
		margin-top: 4px;
		margin-bottom: 20px;
	}

	.index-section6-list {
		gap: 50px 36px;
		padding: 28px;
	}

	.index-section4-infos {
		padding: 20px;
		gap: 20px;
	}

	.index-section4-info .p1 {
		font-size: 30px;
	}

	.index-section4-info .p1 span {
		font-size: 55px;
	}

	.index-section4-info .p2 {
		font-size: 16px;
	}
}

.mobile-show-flex {
	margin-top: 0 !important;
	display: none;
}

@media (max-width: 996px) {
	.index-section-sub-title {
		font-size: 28rem;
	}

	.cn .index-section-main-title {
		font-size: 66rem;
	}

	.cn .index-section-sub-title {
		font-size: 36rem;
	}

	.th .index-section-sub-title,
	.es .index-section-sub-title {
		font-size: 24rem;
	}

	.pt .index-section-sub-title {
		font-size: 22rem;
	}

	.vi .index-section-main-title {
		font-size: 48rem;
	}

	.es .index-section-main-title {
		font-size: 40rem;
	}

	.pt .index-section-main-title {
		font-size: 36rem;
	}

	.jp .index-section-main-title {
		font-size: 52rem;
	}

	.mobile-show-flex {
		display: flex;
	}

	.index-section4-infos {
		padding: 0;
		flex-direction: column;
		box-shadow: none;
		background-color: unset;
		gap: 22px;
	}

	.index-section4-info {
		display: flex;
		flex-direction: column;
		padding: 20px 22px;
		justify-content: center;
		align-items: center;
		width: 100%;
		border-radius: 10px;
		background: #fff;
		box-shadow: 0px 10px 18px 0px rgba(0, 0, 0, 0.05);
	}

	/* .en .index-section4-infos{
    gap: 22px;
    justify-content: space-between;
  }
  .en .index-section4-info{
    width: 100%;
  } */


	.index-section4-line {
		display: none;
	}

	.index-section4-info .p1 {
		font-size: 30px;
	}

	.index-section4-info .p2 {
		text-align: center;
		font-size: 14px;
		margin-top: 5px;
	}

	.pc {
		visibility: hidden;
	}

	.mobile {

		visibility: visible;
	}

	::v-deep .iScrollVerticalScrollbar {
		display: none;
	}

	::v-deep .section,
	::v-deep .fp-tableCell,
	::v-deep .fp-scrollable {
		height: auto !important;
	}

	::v-deep .banner-li__bg {
		height: calc(100vh - 80px) !important;
		width: 100%;
	}

	.index-section1-list-tbody img {
		width: 32px;
		height: 32px;
	}

	.index-section1-list-thead .index-section1-list-tr {
		/* margin-top: 22px; */
	}

	.buy-box {
		display: flex !important;
		justify-content: flex-end;
		margin-top: 16px;
		/* margin-bottom: 28px; */
	}

	.buy {
		height: 28px;
		width: 103px;
		font-size: 14px;
		/* display: inline-flex; */
	}

	.index-section1-tab {
		width: auto;
		min-width: 20%;
		max-width: 23%;
		font-size: 14px;
		line-height: normal;
		text-align: center;
	}

	.en .index-section1-tab {
		font-size: 12px;
		max-width: 26%;
	}

	.index-section1-list {
		padding: 0 16px 32px;
	}

	.index-section1-list-tbody .index-section1-list-tr {
		font-size: 16px;
		padding: 12px 0;
		align-items: flex-end;
		line-height: 1em;
	}

	.index-section1-list-th,
	.index-section1-list-td {
		text-align: center;
	}

	.index-section1-list-tbody .index-section1-list-tr>div:nth-child(1) {
		flex-direction: column;
		align-items: flex-start;
		font-size: 16px;
		gap: 2px;
		line-height: 1em;
	}

	.index-section1-list-tr>div:nth-child(1) {
		width: 28%;
	}

	.index-section1-list-tr>div:nth-child(2) {
		width: 28%;
	}

	.index-section1-list-tr>div:nth-child(3) {
		width: 28%;
	}

	.index-section1-list-tr>div:nth-child(4) {
		width: 16%;
	}

	.index-section1-list-tr>a,
	.index-section1-list-tr>div:nth-child(5) {
		display: none;
		width: 0;
	}

	.mh-133 {
		min-height: auto;
	}

	.mh-109 {
		min-height: auto;
	}



	.index-section1 {
		background: url(../assets/image/Home/index-section1-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		transform: scale(1.05);
	}

	.index-section2 {
		background: url(../assets/image/Home/index-section2-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.index-section3 {
		background: url(../assets/image/Home/index-section3-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.index-section4 {
		background: url(../assets/image/Home/index-section4-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.index-section5 {
		background: url(../assets/image/Home/index-section5-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.index-section6 {
		background: url(../assets/image/Home/index-section6-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.index-section7 {
		background: url(../assets/image/Home/index-section7-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.index-section8 {
		background: url(../assets/image/Home/index-section8-bg-m.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	#fullpageMenu {
		display: none;
	}

	.swiper-news-next,
	.swiper-news-prev {
		visibility: visible;
	}

	/* .index-section-desc {
    font-size: 14px;
  } */
	.index-btn {
		font-size: 14px;
	}

	.banner-pagination {
		bottom: 44px !important;
	}

	.index-section {
		padding: 60px 28px;
		height: auto;
	}

	.flex-between {
		flex-direction: column;
		gap: 60px;
	}

	#USDJPY {
		display: none;
	}

	.flex-between .left {
		width: 100%;
		gap: 30px;
	}

	.cn .flex-between .left {
		width: 100%;
	}

	.flex-between .right {
		width: 100%;
	}

	.index-section1-planes {
		gap: 26px;
	}

	.index-section1-planes li {
		padding: 21px 18px;
		width: calc(50% - 13px);
		flex-shrink: 0;
	}

	.index-section1-planes .name {
		font-size: 14px;
	}

	.float {
		font-size: 14px;
	}

	.jp .index-section-card-info>.p1,
	.vi .index-section-card-info>.p1 {
		min-height: auto;
	}

	.th .index-section-card-info>.p1,
	.es .index-section-card-info>.p1,
	.ug .index-section-card-info>.p1,
	.ru .index-section-card-info>.p1,
	.ar .index-section-card-info>.p1 {
		min-height: auto;
	}

	.index-section1-planes-row1 {
		padding-bottom: 18px;
	}

	.index-section1-planes-row2 {
		margin-top: 18px;
	}

	.index-section1-planes .bid,
	.index-section1-planes .ask {
		font-size: 12px;
		padding: 5px 7px;
	}

	.bid-price,
	.ask-price {
		font-size: 14px;
	}

	.bid-price span,
	.ask-price span {
		font-size: 20px;
	}

	.spread {
		font-size: 12px;
	}

	.index-section-btn-box {
		margin-top: 45px;
	}

	.index-section-cards {
		flex-direction: column;
	}

	.index-section-card {
		padding: 13px 12px;
		max-width: none;
		width: 100%;
		display: flex;
		gap: 15px;
		border-radius: 5px;
	}

	.index-section-card>img {
		height: 40px;
		flex-shrink: 0;
	}

	.index-section-card-info>.p1 {
		margin-top: 0;
		font-size: 16px;
	}

	.index-section-card-info>.p2 {
		margin-top: 12px;
		min-height: auto;
		padding-bottom: 8px;
		font-size: 14px;
		color: #666;
		border-bottom: 1px solid #e6ddcf;
	}

	.bottom-line {
		border: none;
	}

	.index-section-card-info>.p3 {
		padding: 8px 0 0 0;
		font-size: 12px;
	}

	.index-section-card-info>.p3>.p3-label {
		margin-bottom: 0;
		color: #666;
	}

	.index-security-bottom2 {
		border-radius: 0 0 0 22px;
	}

	.index-security-bottom3 {
		border-radius: 0 22px 0 0;
	}

	.index-section-tags {
		position: relative;
		flex-direction: row;
		flex-wrap: wrap;
		/* order: 1; */
		gap: 10px;
		margin-top: 2px;
	}

	.index-section-planes-btns {
		margin-top: 30px;
	}

	.index-section-tags>a {
		/* width: calc(50% - 18px); */
		font-size: 14px;
	}

	.index-section-planes-btns a {
		font-size: 14px;
	}

	.index-security-bottoms {
		flex-wrap: wrap;
		gap: 20px 10px;
	}

	.index-security-bottoms>div {
		/* min-height: 103px; */
		width: calc(50% - 5px);
		border-radius: 5px;
	}

	.index-security-bottoms a {
		width: 90%;
	}

	.index-section4-infos {
		gap: 48px;
		background: transparent;
		padding-left: 0;
	}

	.ar .index-section4-infos {
		padding-right: 0;
	}

	.index-section4-info {
		width: 100%;
		/* border-radius: 0 !important; */
		gap: 15px;
		min-height: auto;
	}

	.index-section4-info .p1 {
		font-size: 40px;
		line-height: 1em;
	}

	.index-section4-info .p2 {
		font-size: 18px;
		line-height: 1em;
	}

	.index-section4-info-unit {
		font-size: 28px;
	}

	.index-section5-wrapper {
		flex-direction: column;
		gap: 25px;
	}

	.index-section5-item {
		width: calc(50% - 18px);
	}

	.index-section5-banner {
		margin-top: 30px;
		border-radius: 5px;
		padding: 18px 24px;
	}

	.index-section5-item .p2 {
		font-size: 16px;
		margin-top: 8px;
	}

	.index-section6-list {
		gap: 42px 24px;
		padding: 36px 15px;
	}

	.index-section6-item {
		height: auto;

		width: calc(33% - 16px);
	}

	.news-item-time {
		/* font-size: 12px; */
		margin-bottom: 15px;
	}

	.news-list-box {
		/* padding: 0 40px; */
		overflow: hidden;
	}

	.news-list h3 {
		margin-top: 15px;
		font-size: 15px;
		line-height: 21px;
	}

	.news-list p {
		margin-top: 28px;
		/* font-size: 12px;
    line-height: 18px; */
	}

	.index-honor-list .swiper-slide {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.index-honor-list img {
		height: auto;
	}

	.swiper-banner-prev {
		width: 11px;
		/* height: 22px;
    left: 0 !important; */
	}

	.swiper-banner-next {
		width: 11px;
		height: 22px;
		/*right: 0 !important; */
	}

	#section7 {
		padding: 31px 0;
	}

	.rank-list-tab {
		min-width: 90px;
		padding: 8px 12px;
		font-size: 14px;
		max-width: 45%;
		text-align: center;
	}

	.section0-content {
		flex-direction: column;
	}

	.index-section0-box {
		width: 100%;
		height: auto;
		min-height: 258px;
	}

	.rank-list {
		width: 100%;
	}

	.rank-list-table {
		padding: 32px 16px;
		min-height: auto;
	}

	.rank-list-th .rank-list-td:first-child,
	.rank-list-tr .rank-list-td:first-child {
		font-size: 18px;
		width: 12%;
		display: flex;
	}

	.rank-list-th .rank-list-td,
	.rank-list-tr .rank-list-td {
		display: block;
		width: 27%;
	}

	.rank-list-td img {
		width: 26px !important;
	}

	.rank-list-tr .rank-list-td {
		line-height: normal;
	}

	.rank-list-td:last-child {
		display: none;
	}

	.rank-list-tr .rank-list-td:nth-child(2) {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.rank-list-tr {
		align-items: center;
		font-size: 14px;
		padding: 12px 0;
		min-height: 56px;
	}

	.rank-list-tips {
		text-align: center;
	}

	.en .gendan-btn,
	.gendan-btn {
		font-size: 14px;
		height: 28px;
	}

	.en .rank-list-th {
		font-size: 12px;
	}
}
</style>

<style>
@media (max-width: 1280px) {
	.en .index-section0-box .index-section-title {
		font-size: 28rem;
	}

	.en .index-section0-box .index-section-title .sub-title {
		font-size: 12px;
	}

	.en .index-section0-box .index-section-title span {
		font-size: 30px;
	}

	.th .index-section0-box .index-section-title {
		font-size: 27rem;
	}

	.kr .index-section0-box .index-section-title {
		font-size: 42rem;
	}

	.jp .index-section0-box .index-section-title {
		font-size: 46rem;
	}
}
</style>