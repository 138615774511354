export default {
	"pages1":{
		"title":"Cộng đồng Copy Trading thông minh EBC",
		"tips":"Trong trái tim mỗi người giao dịch đều có một giấc mơ làm anh hùng",
		"text":"Lợi thế cộng đồng Copy Trading",
		"text1":"Hiển thị nguồn tín hiệu",
		"text2":"Chương trình trả lương",
		"text3":"Phần thưởng thêm",
		"text4":"Đăng nhập cộng đồng Copy Trading"
	},
	"pages2":{
		"title":"Lợi thế cộng đồng Copy Trading",
		"list":[{
			"tit":"Chế Độ Sao Chép \"Linh Hoạt\" Hơn",
			"tips":"Không Cần Sử Dụng Plug-In EA Để Đạt Được Theo Dõi Hai Chiều, Điều Chỉnh Linh Hoạt Phương Thức Sao Chép Và Tận Hưởng Các Giao Dịch Tuỳ Chỉnh."
		},{
			"tit":"Tốc độ Copy Trading \"cấp mili giây\"",
			"tips":"Dựa Trên Giao Thức Truyền Thông Cơ Bản Của MT, Nó Kết Nối Hiệu Quả Các Tài Khoản Giao Dịch Và Các Bên Gửi Tín Hiệu, Đồng Thời Phản Hồi Ngay Lập Tức Các Lệnh Mili Giây."
		},{
			"tit":"Phân Tích Dữ Liệu Năm Chiều \"Chuyên Nghiệp\" Hơn",
			"tips":"Giới thiệu Công cụ Chỉ báo Biểu đồ Năm chiều Độc Quyền, hệ thống xếp hạng quỹ đạt đến một tầm cao mới và tín hiệu chất lượng cao có sẵn chỉ bằng một cú chạm."
		},{
			"tit":"Trợ Lý AI \"Thông Minh\" Hơn",
			"tips":"Lọc Tín Hiệu Mục Tiêu Chỉ Bằng Một Cú Nhấp Chuột Dựa Trên Tùy Chọn Đặt Hàng Và Thu Thập Tất Cả Các Chiến Lược Giao Dịch Hàng Đầu Thế Giới."
		},{
			"tit":"Hiển Thị Giao Dịch \"Minh Bạch\" Hơn",
			"tips":"Có Thể Truy Tìm Lịch Sử Lệnh Của Các Nguồn Tín Hiệu. Tất Cả Các Chi Tiết Giao Dịch Đều Được Trình Bày Trong Nháy Mắt Và Bạn Có Thể Đánh Giá Và Học Hỏi Từ Các Bậc Thầy Giao Dịch."
		}],
		"btn":"Đăng nhập cộng đồng Copy Trading"
	},
	"pages3":{
		"title":"Hiển thị nguồn tín hiệu",
		"text":"Tỉ lệ lợi nhuận",
		"text1":"Mức rút tối đa",
		"text2":"Hệ số Sharpe",
		// "text3":"Tỉ lệ Lãi/Lỗ",
		"text3":"Hệ số lợi nhuận",
		
		"text4":"Miễn phí",
		"text5":"Đặt mua",
		"btn":"Xem thêm các tín hiệu"
	},
	"pages4":{
		"title":"Chương trình trả lương",
		"num":"Thưởng 100$/tháng",
		"num1":"Thưởng 300$/tháng",
		"num2":"Thưởng 1000$/tháng",
		"num3":"Thưởng 5000$/tháng",
		"num4":"Thưởng 10000$/tháng",
		"text1":"Quản lý quỹ",
		"text2":"Lượng tài khoản sao chép",
		"name":"Tân binh",
		"name1":"Ưu tú",
		"name2":"Quán quân",
		"name3":"Star",
		"name4":"Huyền thoại",
		"tips":"1% Quản lý quỹ mỗi năm (Trả tiền hàng tháng)",
		"btn":"Tham gia ngay"
	},
	"pages5":{
		"title":"Tặng thêm giải thưởng",
		"text":"Phục Vụ 24*7",
		"text1":"Tham Gia Chiến Lược Tiếp Thị EBC",
		"text2":"Tài Trợ VPS",
		"text3":"Tài Trợ Sách Giao Dịch",
		"text4":"Phần Mềm Giao Dịch Dòng Lệnh",
		"text5":"Gói Quà Tặng EBC",
		"text6":"Hiển Thị Tín Hiệu Hàng Đầu",
		"text7":"Tham Quan Văn Phòng Tại Nước Ngoài",
		"text8":"Hỗ Trợ Truyền Thông",
		"text9":"Hợp Tác Chiến Lược",
		"text10":"Dịch Vụ Tư Vấn Phát Hành Sản Phẩm Quỹ Nước Ngoài",
		"btn":"Đăng nhập cộng đồng Copy Trading"
	},
	"QA": {  
		"title": "Copy Trading FAQ",  
		"Q1": "1. Copy Trading là gì?",  
		"A1": "Copy trading là việc sao chép tự động các chiến lược giao dịch của những nhà đầu tư khác thông qua hệ thống copy trading. Bạn có thể tận dụng các quyết định của các nhà giao dịch có kinh nghiệm, giảm bớt áp lực theo dõi thị trường và tăng khả năng sinh lời.",  
		"Q2": "2. Copy Trading hoạt động như thế nào?",  
		"A2": "Copy trading cho phép bạn tự động sao chép các lệnh giao dịch của những nhà đầu tư khác thông qua hệ thống copy trading. Khi họ mở hoặc đóng một vị thế, tài khoản của bạn sẽ thực hiện các hành động tương tự ngay lập tức, giúp bạn không cần phải liên tục theo dõi thị trường. Nền tảng sẽ tự động xử lý tất cả mọi lệnh dựa trên các thiết lập của bạn.",  
		"Q3": "3. Lợi ích khi sử dụng Copy Trading là gì?",  
		"A3": "Bằng cách sao chép chiến lược từ những nhà giao dịch có kinh nghiệm, bạn không cần phải tự phân tích thị trường. Chỉ cần thiết lập chiến lược copy trading, bạn có thể sao chép các lệnh từ những nhà giao dịch thành công, giúp tăng tính ổn định lợi nhuận của mình.",  
		"Q4": "4. Các công cụ có sẵn cho các nhà giao dịch Copy Trading là gì?",  
		"A4": "Nền tảng cung cấp các công cụ như tín hiệu giao dịch, phân tích dữ liệu đa chiều, lịch sử lệnh giao dịch và xếp hạng nhà giao dịch. Những công cụ này giúp bạn nắm bắt xu hướng thị trường, chia sẻ chiến lược và tối ưu hóa giao dịch của mình.",  
		"Q5": "5. Rủi ro của Copy Trading là gì?",  
		"A5": "Copy trading có thể gặp phải rủi ro như biến động thị trường và việc sao chép những nhà giao dịch có hiệu suất kém. Việc lựa chọn cẩn thận các nhà giao dịch phù hợp và sử dụng công cụ quản lý rủi ro có thể giúp giảm thiểu tổn thất tiềm ẩn.",  
		"Q6": "6. Tôi có thể tùy chỉnh chiến lược Copy Trading của mình không?",  
		"A6": "EBC cho phép người dùng tùy chỉnh các chiến lược copy trading cá nhân, giúp bạn xây dựng phương pháp giao dịch phù hợp với mục tiêu và sở thích của mình."  
	},
	"box-name" : "copytrading-body en-copytrading-body en",
}