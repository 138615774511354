export default {
	"security-class-name": "security-en-box",
	"security-page1": {
		"text1": "世界トップレベルの金融規制",
		"text2": "保有ライセンス",
	},
	"security-page2": {
		"text1": "万全の保障体制",
		"text2": "最大85,000ポンドまで補償",
		"text3": "EBC Financial Group (UK) のお客様は英国FSCS（金融サービス補償スキーム）から最大85,000ポンドの補償を受けることができます。FSCSは発足以来、補償額を引き上げ続けています。毎年、5億から6億ポンド、総額では55億ポンドの補償金支払い実績があります。",
		"text4": "専用法人口座で資金を分別管理",
		"text5": "EBC Financial Group (UK) はバークレイズ銀行が口座開設条件として最低年間売上650万ポンド、厳格な財務監査およびバックグラウンドチェックを課している法人口座を有しています。CASS要件に従い、EBC Financial Group (UK)は顧客資金を信託所管を通じてカストディ口座に保管し、安全性を確保しています。",
		"text6": "最大20,000ユーロの補償",
		"text7": "The Financial Commissionは世界初の独立した対外紛争解決（EDR）機関であり、厳格で透明性の高い開かれた審理メカニズムを通じて、紛争が迅速かつ公正に解決されることを保証します。The Financial Commissionはこれまで、5,100万ドル以上を払い戻し、約1万件の紛争を処理してきました。弊社は補償基金のメンバーでもあり、申し立て一件につき最大2万ユーロの補償を提供しています。",
		"text8": "専門職業賠償責任（PI）保険―最高200万豪ドルまでの補償",
		"text9": "EBC Financial Group（Australia） は、ロイズ・オブ・ロンドンの最高200万オーストラリアドルまでの専門職業賠償責任（PI）保険に加入しました。この保険はリスクの軽減を目的としており、金融サービスの提供に際して業務上の不注意、過失または不作為により発生する顧客の損失および請求に対して支払われます。この保険は、EBC Financial Group（Australia）の規制遵守への取組みを示すものとなります。",
		"text10": "もっと詳しく知る",
	},
}