export default {
  交易领薪计划: "Chương trình Phát triển Quản lý Quỹ",
  为了鼓励交易者提升交易水平:
    `Đây là chương trình tăng trưởng tài sản được thiết kế dành riêng cho các nhà giao dịch xuất sắc! `,
  加入交易社区: "Thưởng hàng tháng được trao dựa trên số vốn mà nhà giao dịch quản lý, với mức thưởng có thể lên tới 18% mỗi năm.",
  即刻发起交易信号: "Cung cấp tín hiệu ngay",
  无限奖励月月拿: "Phần thưởng không giới hạn hàng tháng",
  优享11个专属大礼: "Nhận 11phần quà ưu đãi đặc biệt",
  即刻加入: "THAM GIA NGAY",
  交易员等级奖励机制: `Cơ chế thưởng theo cấp độ nhà giao dịch`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC cung cấp 5 cấp độ thưởng dựa trên số lượng người theo dõi và số tiền quỹ, không giới hạn số lượng vị thế giao dịch, chỉ dựa vào mức độ nổi tiếng trong ngành.",
  "/月": "/tháng",
  精英交易员: "Trader mới nổi",
  // 管理资金量: "Quản lý quỹ",
  // 跟随账户数: "Số lượng người theo dõi",
  管理资金量: "AUM",
  跟随账户数: "Followers",
  大师交易员: "Trader ưu tú",
  冠军交易员: "Trader vô địch",
  明星交易员: "Trader ngôi sao",
  资金管理费: "1% Quản lý quỹ mỗi năm",
  传奇交易员: "Trader huyền thoại",
  多层次的增值服务: "Lợi ích giá trị gia tăng",
  EBC提供多达11项增值服务:
    "EBC cung cấp tới 11 lợi ích giá trị gia tăng, tích hợp lưu lượng và tài nguyên truyền thông để tăng cường ảnh hưởng của tín hiệu.",
  "精英/大师交易员": "Mới nổi/Ưu tú",

  "7×24小时优先客服": "Phục vụ 24*7",
  金融投资经典书籍礼遇: "Sách Đầu Tư Miễn Phí",
  专属VPS赞助: "Tài trợ VPS Độc Quyền",
  订单流软件1年使用权: "Quyền sử dụng Order Flow 1 năm",
  EBC定制大礼包: "Gói Quà Tặng EBC Đặc Biệt",
  信号优先置顶展示: "Hiển Thị Tín Hiệu Hàng Đầu",
  受邀参观海外办公室: "Tham quan văn phòng tại nước ngoài",
  巴萨球星联名礼包: "Bộ Quà Tặng Cầu Thủ Ngôi Sao FC Barcelona",
  巴萨赛场传奇之旅: "Trải Nghiệm Sân Vận Động Barcelona Huyền Thoại",
  品牌战略合作: "Hợp Tác Thương Hiệu Chiến Lược",
  全球金融资源管家服务: "Dịch Vụ Quản Lý Tài Nguyên Tài Chính Toàn Cầu",
  五维指标评价系统: "Hệ thống đánh giá năm chiều",
  为交易员信号提供公开透明的评估:
    "Cung cấp đánh giá minh bạch và công khai cho tín hiệu giao dịch của các trader, giúp thể hiện rõ phong cách giao dịch và tăng độ nổi tiếng, thu hút nhiều người theo dõi hơn nữa.",
  收益率: "Tỉ lệ lợi nhuận",
  历史收益率: "Tỷ lệ lợi nhuận lịch sử, càng cao càng tốt.",
  最大回撤: "Mức rút tối đa",
  衡量风控能力: "Đánh giá khả năng quản lý rủi ro, càng thấp thì càng an toàn.",
  获利系数: "Hệ số lợi nhuận",
  利润因子: "Hệ số lợi nhuận, càng cao khả năng sinh lời càng mạnh.",
  夏普比率: "Tỷ lệ Sharpe",
  每份风险的收益: "Lợi nhuận trên mỗi đơn vị rủi ro, càng cao thì càng tốt.",
  标准差: "Độ lệch chuẩn",
  收益波动率: "Biến động lợi nhuận, càng thấp càng ổn định.",
  以上数据仅供参考: "*Dữ liệu trên chỉ mang tính tham khảo.",
  活动须知: "Những điều cần biết:",
  list: [
    {
      label: "1. Cách đánh giá cấp bậc",
      value: [
        "Quản lý quỹ: Là tổng số tiền của tài khoản theo dõi cộng với số tiền tự có của tài khoản giao dịch (tín hiệu giao dịch), tất cả được tính theo số dư.",
        "Số lượng người theo dõi: Là số tài khoản EBC theo dõi tín hiệu giao dịch của tài khoản đó. Nếu một tài khoản tạo nhiều tài khoản giao dịch và theo dõi cùng một tín hiệu, thì được tính là 1 người theo dõi.",
        "Cấp độ nhà giao dịch được cập nhật hàng ngày. Nếu đáp ứng yêu cầu tối thiểu vào mỗi ngày trong tháng, phần thưởng của nhà giao dịch sẽ được kích hoạt.",
      ],
    },
    {
      label: "2. Quy tắc tài khoản theo dõi",
      value: [
        "Số dư theo dõi ≥500 USD được coi là một tài khoản theo dõi hợp lệ.",
        "Mỗi tài khoản giao dịch có thể theo dõi tối đa 10 tài khoản tín hiệu.",
        "Số tiền theo dõi cho mỗi tài khoản giao dịch = Số dư tài khoản theo dõi / Số lượng tín hiệu theo dõi.",
      ],
    },
    {
      label: "3. Phân phối phần thưởng",
      value: [
        "Phần thưởng sẽ được tính và phát vào tuần đầu tiên của tháng sau, dựa theo cấp độ tối thiểu đạt được trong tháng trước.",
        "Thưởng hàng tháng = (Quỹ quản lý trung bình hằng ngày × Tỷ lệ phí quản lý quỹ) ÷ 12",
        "Tất cả các phần thưởng sẽ được gửi vào tài khoản giao dịch, người nhận giải tự chịu trách nhiệm rút thưởng.",
        "Quyền giải thích cuối cùng của chương trình thuộc về EBC.",
      ],
    },
  ],
  年化资金管理费: "Phí quản lý quỹ hàng năm",
};
