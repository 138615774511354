export default {
  股息调整: "股息調整",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "了解您在股票和指數差價合約交易中的股息調整和分配機制",
  了解指数差价合约: "了解指數差價合約",
  了解股票差价合约: "了解股票差價合約",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "股息是上市公司從利潤或保留資金中定期分配給股東的資金，當股票派發股息時，相關股票和指數差價合約產品也會進行相應的股息調整。",
  除息日与交易优化: "除息日與交易優化",
  指数差价合约的除息日由指数提供商根据成分股设定:
    "指數差價合約的除息日是由指數提供者根據成分股的除息日設定的，美股差價合約則依據公司股息公告，持多頭將獲得股息，持空頭則需支付股息。提前了解這些日期有助於優化收益管理。",
  股息调整明细: "股息調整明細",
  差价合约交易中相关产品的股息数据:
    "差價合約交易中相關產品的股息數據，以多頭（買入）股息和空頭（賣出）股息分開顯示",
  交易产品: "Trading Products",
  多头股息: "Long Dividends",
  空头股息: "Short Dividends",
  除息日: "Ex-dividend Dates",
  即刻交易: "即時交易",
  股息的常见问题: "股息的常見問題",
  qaList: [
    {
      q: "1、什麼是股息和除息日？",
      a: [
        "股息是上市公司從利潤或保留資金中定期分配給股東的資金，當股票派發股息時，相關股票和指數差價合約產品也會進行相應的股息調整。",
        "指數差價合約的除息日是由指數提供者根據成分股的除息日設定的，也是股息發放日。",
      ],
    },
    {
      q: "2. 股息調整的時間",
      a: [
        "指數差價合約股息調整通常會在除息日的05:00-06:00（GMT+3）反映在您的MT4/MT5交易帳號中，並標註為「Dividend」。",
      ],
    },
    {
      q: "3. 股息對指數交易的影響",
      a: [
        "持有多頭倉位（買入）：股息調整將存入您的MT4/MT5帳號。",
        "持有空頭部位（賣出）：股息調整將從您的MT4/MT5帳號扣除。",
      ],
    },
    {
      q: "4. 股息調整的計算公式",
      a: [
        "多頭部位（買入）計算公式：手數 × 合約規格 × 多頭股息金額（以指數計價貨幣計算）",
        "空頭部位（賣出）計算公式：手數 × 合約規格 × 空頭股息金額（以指數計價貨幣計算）",
      ],
    },
    {
      q: "5. 股息調整計算範例",
      a: [
        "以SPXUSD為例，假設其除息日為2024年10月11日，多頭股息金額為 0.050美元，空頭股息金額為-0.050美元。如果您在除息日前一天(10月10日)收盤時持有1標準手的SPXUSD多頭部位（買入），則股息調整的計算如下：",

        "股息調整 = 1（標準手） × 100（合約規格） × 0.050美元（多頭股息金額） = 5美元",
        "在除息日的05:00-06:00（GMT+3），5美元將存入您的MT4/MT5帳號，備註為「Dividend」。",

        "如果您持有空頭部位（賣出），5美元將從您的MT4/MT5帳號中扣除，備註為「Dividend」。 ",
      ],
    },
    {
      q: "6. 注意事項",
      a: [
        "股息調整僅適用於指數和股票差價合約交易，並會根據持有的部位類型（多頭或空頭）相應調整帳號餘額，股息金額及其影響可能因具體指數和市場條件而異。",
        "如有任何疑問，請聯絡您的客戶經理或查看我們的官方網站以獲取更多資訊。 ",
        "如有任何疑问，请联系您的客户经理或查看我们的官方网站获取更多信息。",
      ],
    },
  ],
};
