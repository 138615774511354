export default {
	"pages1":{
		"title":"EBC Intelligent Copy Trading Platform",
		"tips":"Every Trader has a Dream of Becoming a Hero.",
		"text":"Advantages of Copy Trading",
		"text1":"Top Trading Signal Display",
		"text2":"Trade Rewards Plan",
		"text3":"Membership Benefits",
		"text4":"Register Now"
	},
	"pages2":{
		"title":"Why Choose EBC?",
		"list":[{
			"tit":"Flexible Copy Trading",
			"tips":"Easily start and customise copy trading without an EA plugin.Flexibly adjust your copy trading.Master customised trading."
		},{
			"tit":"Millisecond Response",
			"tips":"Execute trades instantly with advanced MT protocol, ensuring a seamless social trading experience.Seamlessly connect your trading account with signal providers."
		},{
			"tit":"Five Dimensions of Professional Data Analysis",
			"tips":"Introducing Exclusive Five-Dimensional Chart Indicators,Fund rating system reaches new heights.Quality signals are on the horizon."
		},{
			"tit":"AI-assisted<br/>Tools",
			"tips":"Discover top strategies with our AI-driven, one-click signal screening, perfect for optimising your copy trading."
		},{
			"tit":"Transparent Trading",
			"tips":"Benefit from clear, detailed trading records for better learning and trust. EBC ensures full transparency for copy traders.Comprehensive overview of operational details.Experience and learn from the master traders."
		}],
		"btn":"Register Now"
	},
	"pages3":{
		"title":"Top Trading Signal Display",
		"text":"Profit Rate",
		"text1":"Maximum Drawdown ",
		"text2":"Sharpe Ratio",
		"text3":"Profit Factor",
		// "text3":"Ratio P/L",
		"text4":"free",
		"text5":"Copy Now",
		"btn":"More Signals"
	},
	"pages4":{
		"title":"Trade Rewards Plan",
		"num":"$100 per month",
		"num1":"$300 per month",
		"num2":"$1,000 per month",
		"num3":"$5,000 per month",
		"num4":"$10,000 per month",
		"text1":"AUM",
		"text2":"Follwing Traders",
		"name":"beginning",
		"name1":"good",
		"name2":"excellent",
		"name3":"star",
		"name4":"legend",
		"tips":"1% AUM per annum (paid monthly)",
		"btn":"Join Now"
	},
	"pages5":{
		"title":"Membership Benefits",
		"text":"7x24 customer service",
		"text1":"EBC marketing campaign involvement",
		"text2":"VPS sponsorship",
		"text3":"investing books sponsorship ",
		"text4":"one-year subscription of order flow trading software",
		"text5":"customised rewards package",
		"text6":"signal displayed at the top",
		"text7":"a visit to EBC overseas offices",
		"text8":"shared media resoruce",
		"text9":"co-branding",
		"text10":"offshore fund launch advisory service ",
		"btn":"Register Now"
	},
	"QA": {  
		"title": "Copy Trading FAQ",  
		"Q1": "1. What is Copy Trading?",  
		"A1": "Copy trading refers to automatically replicating the strategies of other traders through a copy trading system. You can leverage the decisions of experienced traders, reduce the pressure of market monitoring, and increase potential profits.",  
		"Q2": "2. How does Copy Trading Work?",  
		"A2": "Copy trading allows you to automatically replicate the orders of other traders through the copy trading system. When they open or close a position, your account will execute the same actions simultaneously, eliminating the need to constantly monitor the market. The platform handles everything automatically based on your settings.",  
		"Q3": "3. What are the Benefits of Using a Copy Trading Platform?",  
		"A3": "By following the strategies of skilled traders, you don't need to analyse the market yourself. Simply set up your copy trading strategy, and you can replicate the orders of top-performing traders, enhancing the stability of your returns.",  
		"Q4": "4. What Tools Are Available for Copy Traders?",  
		"A4": "The platform provides tools such as trading signals, five-dimensional data analysis, historical orders, and trader rankings. These tools help you understand market trends, share strategies, and optimize your trading.",  
		"Q5": "5. What are the Risks of Copy Trading?",  
		"A5": "Copy trading carries risks such as market volatility and the possibility of following underperforming traders. Selecting the right traders and using risk management tools can help minimise potential losses.",  
		"Q6": "6. Can I Customise My Copy Trading Strategy?",  
		"A6": "Yes, EBC allows users to customise personalised copy trading strategies."  
	},
	"box-name" : "copytrading-body en-copytrading-body en",
}