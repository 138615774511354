export default {
  logo:require("@/assets/image/win/logo_pt.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "Desafio de Trading de Um Milhão de Dólares II da EBC",
  免费复制冠军交易: "Copie as operações do campeão gratuitamente",
  共赴巴萨颁奖盛典: "Comemore sua vitória em Barcelona",
  EBC全球百万美元交易大赛: `O Desafio de Trading de Um Milhão de Dólares da EBC retorna para unir os melhores operadores do mundo em uma batalha de habilidade e estratégia. Como parceiro oficial do FC Barcelona, temos orgulho de trazer o espírito do futebol para a arena de trading, celebrando a paixão sem limites e a verdadeira excelência. Além do maior prêmio de todos os tempos, os vencedores também receberão um convite exclusivo para uma cerimônia de premiação no lendário estádio do FC Barcelona para desfrutar de uma experiência única na vida.`,
  大赛奖池: "Conjunto de prêmios",
  距开赛: "Contagem regressiva",
  距结束:"Termina Em",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replicar as melhores negociações com apenas um clique",
  公开透明: "Transparente",
  观摩冠军交易技巧: "Domine as técnicas de trading dos campeões",
  推荐有礼: "Recompensas por recomendação",
  推荐有奖上不封顶: "Ganhe US$ 300 por recomendação, sem limites",
  双赛道机制: "Duas categorias diferentes de desafios",
  梦之队: "Esquadrão dos Sonhos",
  梦之队HTML: "Esquadrão dos Sonhos",
  以交易场的传控技术角逐百万: "Mostre sua maestria em trading e ganhe US$ 1.000.000",
  青训营: "Estrelas em Ascensão",
  青训营HTML: "Estrelas em Ascensão",
  "像天才新星释放天赋「一球成名」": "Negocie como uma estrela, libere seu talento.",
  冠军: "Campeão",
  亚军: "Vice-campeão",
  季军: "3° lugar",
  第四至五名: "4° - 5° lugar",
  第六至十名: "6° - 10° lugar",
  "$1,000,000交易账号或$200,000现金": "Conta de trading de US$ 1.000.000 ou US$ 200.000 em dinheiro",
  立即参赛: "Registre-se Agora",
  巴萨荣耀之旅: "O caminho do campeão para o FC Barcelona",
  每笔订单都是攻守博弈的艺术: `Toda negociação é um equilíbrio delicado de ataque e defesa. Cada decisão que você toma pode ser um gol que muda o jogo nos mercados. Orgulho, glória e uma experiência inesquecível no FC Barcelona aguardam os vencedores do Desafio de Trading de Um Milhão de Dólares da EBC.`,
  邀您赴巴萨训练营: "Jogue Como os Profissionais",
  亲身体验冠军的致胜之旅: "Experimente uma clínica do Barça Academy e aprenda o que é necessário para se tornar um campeão de futebol.",
  于巴萨博物馆为您加冕: "Escreva Sua Lenda",
  巴萨颁奖典礼: "Entre na história do Barça com um passeio imersivo pelo museu e, em seguida, comemore seu legado comercial em uma cerimônia de premiação no museu. ",
  巴萨绿茵场现场观赛: "Experiência VIP em jogos em casa",
  亲临VIP坐席见证球星逐梦诺坎普: "Veja os astros do FC Barcelona buscarem a vitória e dominarem a liga, ao vivo e de perto.",
  大赛推荐官: "Programa de Recomendação",
  交易即享推荐奖励: "Negocie e ganhe US$ 300 por recomendação, sem limites.",
  "/人": " / pessoa",
  "3步推荐": "3 Passos Simples: Recomende, Negoceie e Ganhe!",
  推荐好友: "Recomende Amigos",
  好友交易: "Trading com Amigos",
  赢得奖金: "Ganhe Prêmios",
  立即推荐: "Recomende Agora",
  实时赛况: "Tabela de classificação em tempo real",
  排名: "Classificação",
  昵称: "Apelido",
  账号: "Número da Conta",
  收益额: "Lucro",
  收益率: "Taxa de Lucro (%)",
  奖金: "Prêmio",
  跟单: "Copy Trading",
  暂未开赛: "Em breve, fique atento",
  该页面仅展示收益表现排名前10的交易账号:
    "*Esta página exibe apenas as dez principais contas de trading classificadas por desempenho de lucro, com classificações em USD. As atualizações ocorrem a cada hora",
  赛事进程: "Cronograma do Evento",
  推荐开始: "Início do Programa de Recomendação",
  "2025年2月22日":"2025.2.22",
  比赛开始: "Início do Desafio",
  "2025年3月1日":"2025.3.1",
  推荐结束: "Término do Período de Recomendação",
  "2025年5月23日":"2025.5.23",
  比赛结束: "Término do Desafio",
  "2025年5月30日":"2025.5.30",
  颁奖典礼: "Cerimônia de Premiação",
  "2025年":"2025",
  赛组规则: "Regras do Evento",
  类别: "Categorias",
  账号要求: "Requisitos da Conta",
  "新交易账号，且账号余额≥$500": "Uma nova conta de trading com um saldo inicial ≥ US$ 500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "Nova conta de trading com um saldo entre US$ 10.000 e US$ 200.000.",
  排名规则: "Regras da Classificação",
  以收益率排名为评判标准: "As classificações são determinadas com base na taxa de retorno",
  "收益率=期末净值/账号累积入金": "Taxa de Lucro = (Lucro Líquido / Depósito Acumulado) * 100%",
  以收益额排名为评判标准: "As classificações são determinadas com base no resultado líquido",
  "收益额=期末净值+出金-入金": "Resultado Líquido = Capital Próprio + Saques - Depósitos",
  持仓限制: "Limites de Posição",
  每个参赛账号最多持有100手仓位: "Cada conta participante pode manter uma posição máxima de 100 lotes.",
  无持仓限制: "Sem limite de posição",
  参赛条款: "Termos do Desafio",
  参赛须知: "Diretrizes do Desafio",
  section6List: [
    {
      t: "1. Duração do Evento",
      c: [
        "1 de março de 2025, 01:00:00 (hora MT4/MT5) - 30 de maio de 2025, 23:59:59 (hora MT4/MT5)",
      ],
    },
    {
      t: "2. Requisitos da Conta",
      c: [
        "A conta deve ser listada como uma conta de sinal por meio da ferramenta Copy Trading da EBC para permitir que outras pessoas copiem as negociações gratuitamente.",
        "Os participantes podem usar várias contas para competir, mas somente a conta com o melhor desempenho será considerada para classificação.",
        "Para garantir a imparcialidade do evento, a participação está aberta exclusivamente para contas STD e PRO; contas PAMM e islâmicas não são elegíveis.",
      ],
    },
    {
      t: "3. Regras de Recompensa",
      c: [
        "Prêmios: Se o campeão escolher o prêmio da conta de trading de US$ 1.000.000, o limite máximo de perda será de US$ 200.000. Outros prêmios serão concedidos com base nas classificações.",
        
      ],
    },
    {
      t: "4. Ações Proibidas",
      c: [
        "A negociação de arbitragem entre contas para aumentar os lucros é estritamente proibida. Quaisquer violações, incluindo posições entre contas, operações de hedge ou abuso de cotações, levarão à desqualificação.",
        "Os pedidos causados por fatores incontroláveis, como falha de hardware do servidor ou erros de precificação, serão considerados inválidos.",
      ],
    },
    {
      t: "5. Isenção de Responsabilidade",
      c: [" A EBC Financial Group se reserva o direito de interpretação final para este evento."],
    },
  ],

  参赛流程: "Processo de desafio",
  注册开户: "Registrar conta",
  注册并开设新交易账号: "Registre-se e abra uma nova conta de trading",
  报名参赛: "Registre-se para o desafio",
  在用户中心报名参赛: "Registre-se para o desafio no centro de usuários",
  参赛成功: "Registro bem-sucedido",
  参赛成功查看实时赛况: "Depois de se registrar com sucesso no desafio, você poderá ver seu desempenho em tempo real",
  往届回顾: "Revisão das edições anteriores",
  查看更多: "Veja mais",
  常见问题: "Perguntas frequentes",
  qaList: [
    {
      t: '1. "Conta" vs. "Número da conta"',
      c: [
        "Conta: Refere-se à conta do centro de usuários (UID) da EBC. Cada usuário só pode ter uma conta depois de concluir a autenticação de nome real.",
        "Número da conta: Refere-se à conta de trading MT. Várias contas de trading podem ser criadas sob a mesma conta EBC para fins de trading.",
      ],
    },
    {
      t: "2. Como posso confirmar meu status de inscrição no evento?",
      c: ["Após o registro bem-sucedido, sua conta de competição e a classificação atual serão exibidas na página do evento no portal do usuário."],
    },
    {
      t: "3. Posso participar com várias contas?",
      c: [
        "Você pode participar do evento com várias contas, mas cada uma dessas contas só pode ser registrada em uma categoria.",
      ],
    },
    {
      t: "4. Posso reivindicar prêmios de ambas as categorias?",
      c: ["Sim, você pode reivindicar recompensas de ambas as categorias, desde que cumpra as regras e conclua os dois desafios com êxito."],
    },
    {
      t: "5. Posso participar do desafio se já tiver uma conta MT4?",
      c: [
        "Sim, desde que a conta MT4 tenha sido criada em ou após 22 de fevereiro de 2025, às 01:00:00 (horário MT4/MT5) e não tenha histórico de trading anterior.",
      ],
    },
    {
      t: "6. Depois de me juntar à categoria Estrelas em Ascensão, depositar mais de 10.000 dólares mudará a minha categoria de competição?",
      c: ["Não, a categoria se baseia no registro no momento da inscrição, e alterações posteriores nos fundos não afetarão o grupo."],
    },
    {
      t: "7. Como verificar a classificação do desafio?",
      c: [
        "As classificações são atualizadas a cada hora (não de hora em hora) e podem ser visualizadas na página de atividade do evento no portal do usuário.",
      ],
    },
    {
      t: "8. Posso depositar ou sacar fundos durante o evento?",
      c: [
        "Sim, você pode depositar e sacar fundos. Os saques não afetarão sua classificação; no entanto, os depósitos totais para o Dream Squad são limitados a $200.000. Ultrapassar esse limite resultará em desclassificação.",
      ],
    },
    {
      t: "9. Como faço para solicitar meu prêmio por recomendação?",
      c: [
        "A recompensa por recomendação será creditada no seu saldo de bônus 30 dias após o usuário convidado concluir a primeira negociação. Você poderá então solicitar um saque, e a recompensa será processada após a aprovação.",
      ],
    },
    {
      t: "10. Por que os usuários convidados não podem transferir seus agentes?",
      c: [
        "Para garantizar la equidad, los usuarios invitados sólo pueden unirse a través del enlace de recomendación y no se les asigna un agente específico.",
      ],
    },
    {
      t: "11. As informações da minha conta de trading no concurso serão tornadas públicas?",
      c: [
        "Sim, suas negociações serão visíveis na comunidade de copy trading. Outros usuários que não estão participando do desafio poderão seguir e copiar suas estratégias de trading.",
      ],
    },
    {
      t: "12. Minha conta de trading é uma conta provedora na competição. Posso usar essa conta para copy trading de outros?",
      c: [
        "Para garantir a equidade, as contas de trading provedoras não estão autorizadas a usar a função de copy trading. No entanto, você pode criar uma nova conta de trading para acessar esse recurso.",
      ],
    },
    {
      t: "13. E se dois participantes tiverem a mesma taxa de lucro e o mesmo lucro?",
      c: [
        "Se dois participantes alcançarem a mesma taxa de lucro ou o mesmo valor de lucro, a classificação será determinada pelo volume de trading, sendo que o participante com o maior volume de trading terá uma classificação mais alta.",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000:"$30.000",
  $10000:"$10.000",
  $5000:"$5.000",
  $3000:"$3.000",
  $2000:"$2.000",
  $1000:"$1.000",
  rankValueList: [
    "Conta de trading de US$ 1.000.000 ou US$ 200.000 em dinheiro",
    "$30.000",
    "$10.000",
    "$5.000",
    "$5.000",
    "$3.000",
    "$3.000",
    "$3.000",
    "$3.000",
    "$3.000",
  ],
  rankValueList2: [
    "$30.000",
    "$5.000",
    "$3.000",
    "$2.000",
    "$2.000",
    "$1.000",
    "$1.000",
    "$1.000",
    "$1.000",
    "$1.000",
  ],
  ebclink:"https://www.ebc.com/pt/win1"
};
