export default {
	"security-class-name": "security-cn-box",
	"security-page1": {
		"text1": "全球顶级监管",
		"text2": "查看牌照",
	},
	"security-page2": {
		"text1": "全面保障",
		"text2": "享FSCS最高8.5万英镑的赔付保障",
		"text3": "EBC拥有FCA最高级别的全监管牌照，是全球极少数可以开设FCA流动性清算账户的券商，为每一位FCA用户，提供最高8.5万英镑的赔付保障。自成立至今， FSCS 不断提高赔偿基数，预估每年赔付5-6亿英镑，已赔付55亿英镑以上。",
		"text4": "巴克莱银行最高级别Corporate Banking Account",
		"text5": "EBC拥有巴克莱银行最高级别企业账户，该账户要求企业营业额和存款达到650万英镑以上，且须要通过巴克莱一系列严格的财务审计和背景调查。EBC英国严格执行CASS规定，通过特定信托函独立托管投资者资金，保障资金安全与独立。",
		"text6": "提供高达20000欧元的额外赔付保障",
		"text7": "The Financial Commission作为全球首个独立的外部争议解决（EDR）机构，通过其严格、透明和公开的审理机制，保证交易争议的快速公正解决。迄今已累计赔付超过5100万美元，并处理了近万起争端。EBC加入其赔偿基金，为每位合作伙伴提供最高20000欧元的额外赔偿保障。",
		"text8": "200万澳元专业责任险",
		"text9": "EBC Financial Group (Australia)向英国劳合社购买保额高达200万澳元的专业责任险。PI保险旨在降低与金融服务相关的风险，投保方在服务期间由疏忽、失误或遗漏造成的客户损失和索赔，保险公司将全额赔付。这是EBC Financial Group (Australia)严守监管要求和诚信禀赋的有效保障。",
		"text10": "了解更多",
	},
}