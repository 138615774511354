export default {
  在EBC交易赢梅西签名限量球衣PC: `<h1 >เทรดกับ EBC ลุ้นรับเสื้อบอลลิมิเต็ดพร้อมลายเซ็นเมสซี่</h1>`,
  在EBC交易赢梅西签名限量球衣Mobile: `<h1 >เทรดกับ EBC ลุ้นรับเสื้อบอลลิมิเต็ดพร้อมลายเซ็นเมสซี่</h1>`,
  立即参与: "ลงทะเบียนตอนนี้",
  亲笔签名并装裱: "ลายเซ็นพร้อมกรอบ",
  "莱昂内尔·梅西": "ลิโอเนล เมสซี่",
  巴塞罗那官方: "เสื้อบอลบาร์เซโลนาฤดูกาล 2018-19",
  赛季主场球衣: "",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "เสื้อบอล ลิโอเนล เมสซี เซ็นลายเซ็นกับมือที่กรุงปารีส เมื่อวันที่ 28 ม.ค. 2023 พร้อมใบรับรองจากสโมสรบาร์เซโลนา เพื่อยืนยันว่าเป็นลายเซ็นของจริง 100% จำนวนจำกัด ลงทะเบียนก่อนได้ก่อน—ห้ามพลาดเสื้อบอลจากนักเตะระดับโลก!",
  亲笔签名: "เซ็นเองกับมือ (รุ่นสะสมพร้อมกรอบ)",
  "莱昂内尔·梅西、": "ลิโอเนล เมสซี่ & ",
  "路易斯·苏亚雷斯": "หลุยส์ ซัวเรซ",
  "巴萨2016-17赛季主场球衣": "ชุดเหย้าบาร์เซโลนา ฤดูกาล 2016-17",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名: `เสื้อบอลจากสองตำนานบาร์เซโลนาอย่าง ลิโอเนล เมสซี และ หลุยส์ ซัวเรซ โดยเมสซี่เซ็นในวันที่ 29 ก.ย. 2023 ที่กรุงบัวโนสไอเรส ประเทศอาร์เจนตินา และ ซัวเรซ เซ็นในวันที่ 12 ต.ค. 2023 ที่มอนเตวิเดโอ ประเทศอุรุกวัย
<br/>
พร้อมใบรับรองจาก Icons.com รับประกันลายเซ็นแท้ 100% ลงทะเบียนเปิดบัญชีก่อนมีสิทธิ์ก่อน เสื้อมีจำนวนจำกัด`,
  "拉明·亚马尔": "ลามีน ยามาล",
  "巴萨2024-25赛季主场球衣": "ชุดเหย้าบาร์เซโลนา ฤดูกาล 2024-25",
  这件巴萨球衣印有亚马尔的名字和球号: `เสื้อบอลบาร์เซโลนา พร้อมสกรีนชื่อและหมายเลข ยามาล นักเตะดาวรุ่งที่มีความสามารถ ด้วยวัยเพียง 16 ปี ยามาลสามารถทำลายหลายสถิติของสโมสร และเขายังเป็นตัวแทนความหวังในอนาคตของสโมสรบาร์เซโลนา<br/>เสื้อบอลตัวนี้ไม่ใช่แค่ของสะสมธรรมดา แต่อาจเป็นตำนานคนใหม่ของบาร์เซโลนา`,
  活动时间: "ระยะเวลากิจกรรม:",
  "活动时间:": "ระยะเวลากิจกรรม:",
  "2025年3月1日": "1 ม.ค. - 31 ม.ค. 2025",
  活动规则: "เงื่อนไข:",
  交易量PK:
    "ปริมาณการเทรด: บัญชีเทรดที่มียอดการเทรดสูงสุดในแต่ละเดือน (ยกเว้นหุ้นสหรัฐ) จะได้รับเสื้อบอลลายเซ็นเมสซี่",
  入金量PK:
    "ยอดฝากเงิน: บัญชีเทรดที่มียอดฝากสุทธิสูงสุดใน 1 เดือน จะได้รับเสื้อบอล ลามีน ยามาล",
  活动条款: "ข้อกำหนด: ",
  list: [
    "กิจกรรมนี้เปิดให้เข้าร่วมได้ทั่วโลก โดยนักเทรด EBC จะได้รับการลงทะเบียนเข้าร่วมอัตโนมัติ เริ่มตั้งแต่เดือนมีนาคมนี้ ",
    "แต่ละบัญชีมีสิทธิ์รับ 1 รางวัลเท่านั้น",
    "ประกาศผู้ชนะผ่านเว็บไซต์ทางการของ EBC และแพลตฟอร์ม X เท่านั้น ภายใน 7 วันทำการหลังสิ้นสุดกิจกรรม ผู้ชนะจะได้รับติดต่อผ่านทางอีเมล",
    "EBC จะทำการตรวจสอบบัญชีที่เข้าร่วมกิจกรรมอย่างละเอียด หากพบว่าบัญชีใดไม่เป็นไปตามนโยบายความปลอดภัย หรือทำผิดกฎ รวมถึง หลายบัญชีเทรดที่สร้างจากบัญชีเดียวกัน จะถือว่าเป็นบัญชีซ้ำและไม่มีสิทธิ์ได้รับรางวัล",
    "EBC Financial Group มีสิทธิ์เปลี่ยนข้อกำหนดหรือเงื่อนไขกิจกรรมนี้ โดยไม่ต้องแจ้งให้ทราบล่วงหน้า",
    "EBC Financial Group ขอสงวนสิทธิ์ในการอธิบายกิจกรรมนี้ หากมีคำถามหรือข้อสงสัย โปรดติดต่อฝ่ายบริการลูกค้า",
  ],
};
