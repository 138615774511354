export default {
  logo:require("@/assets/image/win/logo_zh.svg"),
  EBC: "EBC",
  全球百万美金交易大赛Ⅱ: "全球百萬美金交易大賽Ⅱ",
  免费复制冠军交易: "免費複製冠軍交易",
  共赴巴萨颁奖盛典: "共赴巴薩頒獎盛典",
  EBC全球百万美元交易大赛: `EBC全球百萬美金交易大賽，匯聚全球頂尖交易者。<br class="mobile" />作為巴塞隆納足球俱樂部官方合作夥伴，<br
                        class="mobile" />我們將足球哲學融入金融賽場，傳承巴薩卓越、熱愛的力量。<br class="mobile" />無論您是操盤巨星，還是交易新秀，都可以免費複製冠軍收益。<br
                        class="mobile" />大賽優勝者將受邀前往巴薩參加頒獎典禮，<br class="mobile" />見證交易賽場與綠茵聖地的傳奇。`,
  大赛奖池: "大賽獎池",
  距开赛: "距開賽",
  距结束: "距結束",
  一键跟单: "一鍵跟單",
  免费复制冠军收益: "免費複製冠軍收益",
  公开透明: "公開透明",
  观摩冠军交易技巧: "觀摩冠軍交易技巧",
  推荐有礼: "推薦有禮",
  推荐有奖上不封顶: "推薦有獎不設上限",
  双赛道机制: "大賽首創雙賽道機制",
  梦之队: "夢之隊",
  梦之队HTML: "<span>夢</span><span>之</span><span>隊</span>",
  以交易场的传控技术角逐百万: "以交易場的傳控技術角逐百萬",
  青训营: "青訓營",
  青训营HTML: "<span>青</span><span>訓</span><span>營</span>",
  "像天才新星释放天赋「一球成名」": "像天才新星釋放天賦「一球成名」",
  冠军: "冠軍",
  亚军: "亞軍",
  季军: "季軍",
  第四至五名: "第四至五名",
  第六至十名: "第六至十名",
  "$1,000,000交易账号或$200,000现金": "$1,000,000交易帳號或$200,000現金",
  立即参赛: "立即參賽",
  巴萨荣耀之旅: "巴薩榮耀之旅",
  每笔订单都是攻守博弈的艺术: `每筆訂單都是攻守博弈的藝術，<br class="mobile" />每個決策都將締造資本市場的史詩級射門。`,
  邀您赴巴萨训练营: "邀您赴巴薩訓練營",
  亲身体验冠军的致胜之旅: "親身體驗冠軍的致勝之旅",
  于巴萨博物馆为您加冕: "於巴薩博物館為您加冕",
  巴萨颁奖典礼: "傳奇加冕典禮，見證交易榮光",
  巴萨绿茵场现场观赛: "巴薩綠茵場現場觀賽",
  亲临VIP坐席见证球星逐梦诺坎普: "親臨VIP坐席見證球星逐夢諾坎普",
  大赛推荐官: "大賽推薦官",
  交易即享推荐奖励: "交易即享推薦獎勵 不設上限",
  "/人": "/人",
  "3步推荐": "3步推薦 交易立享",
  推荐好友: "推薦好友",
  好友交易: "好友交易",
  赢得奖金: "贏得獎金",
  立即推荐: "立即推薦",
  实时赛况: "即時賽況",
  排名: "排名",
  昵称: "暱稱",
  账号: "帳號",
  收益额: "收益額",
  收益率: "收益率",
  奖金: "獎金",
  跟单: "跟單",
  暂未开赛: "暫未開賽，敬請期待。",
  该页面仅展示收益表现排名前10的交易账号:
    "*該頁面僅展示收益表現排名前十的交易帳號，排名貨幣單位為美金（USD），每小時更新",
  赛事进程: "賽事進程",
  推荐开始: "推薦開始",
  "2025年2月22日":"2025.2.22",
  比赛开始: "比賽開始",
  "2025年3月1日":"2025.3.1",
  推荐结束: "推薦結束",
  "2025年5月23日":"2025.5.23",
  比赛结束: "比賽結束",
  "2025年5月30日":"2025.5.30",
  颁奖典礼: "頒獎典禮",
  "2025年":"2025",
  赛组规则: "賽組規則",
  类别: "類別 ",
  账号要求: "帳號要求",
  "新交易账号，且账号余额≥$500": "新交易帳號，且帳號餘額≥$500。",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "新交易帳號，且$10,000 ≤ 帳號餘額 ≤ $200,000。",
  排名规则: "排名規則",
  以收益率排名为评判标准: "以收益率排名為評判標準",
  "收益率=期末净值/账号累积入金": "收益率 = （收益額/累計入金）*100%",
  以收益额排名为评判标准: "以收益額排名爲評判標準",
  "收益额=期末净值+出金-入金": "收益額=期末淨值+出金-入金",
  持仓限制: "持倉限制",
  每个参赛账号最多持有100手仓位: "每個參賽帳號最多持有100手倉位。",
  无持仓限制: "無持倉限制",
  参赛条款: "參賽條款",
  参赛须知: "參賽須知",
  section6List: [
    {
      t: "1．活動時間",
      c: [
        "2025年3月1日 01:00:00（MT4/MT5時間）至2025年5月30日 23:59:59（MT4/MT5時間）",
      ],
    },
    {
      t: "2．帳號要求",
      c: [
        "參賽帳號需在交易社群中發布信號，並允許免費跟隨。",
        "參賽者可使用多個帳號參賽，但僅按表現最優的帳號計入排名。",
        "為確保比賽公平，僅限STD與PRO帳號參賽，PAMM帳號及伊斯蘭帳號不具備參賽資格。",
      ],
    },
    {
      t: "3．獎勵規則",
      c: [
        "冠軍獎勵：若冠軍選擇100萬美金交易帳號獎勵，最大虧損限額為20萬美金。",
        "其他獎項均按照排名進行獎勵。",
      ],
    },
    {
      t: "4．禁止行為",
      c: [
        "嚴禁透過帳號間套利交易提高收益，任何透過多帳號交叉持倉、避險交易及濫用報價等違規行為，都將取消參賽資格。",
        "因伺服器硬體故障、報價錯誤等不可控因素導致的訂單視為無效。",
      ],
    },
    {
      t: "5．最終解釋權",
      c: [" EBC金融集團擁有本次活動最終解釋權。"],
    },
  ],

  参赛流程: "參賽流程",
  注册开户: "註冊開戶",
  注册并开设新交易账号: " 註冊並開設新交易帳號",
  报名参赛: "報名參賽",
  在用户中心报名参赛: "在用戶中心報名參賽",
  参赛成功: "參賽成功",
  参赛成功查看实时赛况: "參賽成功後可查看即時賽況",
  往届回顾: "歷屆回顧",
  查看更多: "查看更多",
  常见问题: "常見問題",
  qaList: [
    {
      t: "1．「帳戶」與「帳號」的區別",
      c: [
        "帳戶：指EBC用戶中心帳戶（UID），每位用戶經過實名認證後只能擁有一個帳戶。",
        "帳號：MT交易帳號，每個EBC帳戶可創建多個交易帳號用於入金和交易。",
      ],
    },
    {
      t: "2．如何確認參賽成功？",
      c: ["參賽成功後，用戶中心大賽活動頁面會顯示參賽帳號及當前排名。"],
    },
    {
      t: "3．是否可以多帳號參賽？",
      c: [
        "同一用戶可分別在兩個賽組使用多個帳號參賽，但每個帳號只能參加一個賽組。",
      ],
    },
    {
      t: "4．兩個賽組同時獲獎是否可同時領獎？",
      c: ["可同時領取兩個賽組的獎勵，前提是兩個組別均合規完成比賽並獲獎。"],
    },
    {
      t: "5．已有MT4帳號可否參賽？",
      c: [
        "2025年2月22日 01:00:00（MT4/MT5時間）後開設的，且沒有歷史交易記錄的MT帳號。",
      ],
    },
    {
      t: "6．參賽青訓營組後，再次入金超過1萬美金，會修改參賽組別嗎？",
      c: ["不會，參賽組別以參賽報名時為準，後續資金變動不影響組別。"],
    },
    {
      t: "7．如何查看比賽排名？",
      c: [
        "排名每小時更新（非整點更新），可在用戶中心大賽活動頁查看自己的排名。",
      ],
    },
    {
      t: "8．比賽期間能否出入金？",
      c: [
        "可以出入金，出金不影響排名。夢之隊累計入金上限$200,000，超限將取消參賽資格。",
      ],
    },
    {
      t: "9．如何申請領取邀請獎勵？",
      c: [
        "邀請獎勵將在受邀者符合條件的帳戶完成第一筆開倉交易後的30天內發放至獎金餘額。屆時，您可申請提現，審核通過後獎勵入帳。",
      ],
    },
    {
      t: "10．為何「被邀請者」不能轉移代理？",
      c: [
        "為確保公平，「被邀請者」需透過推薦鏈接參與且不能指定代理。",
      ],
    },
    {
      t: "11. 參賽帳號的交易資訊是否會被公開？",
      c: [
        "參賽帳號將作為公開訊號在跟單社群進行展示，未參賽帳號可複製其交易策略。",
      ],
    },
    {
      t: "12. 參賽帳號可否使用跟單功能？",
      c: [
        "為確保公平，參賽帳號不可使用跟單；您可新建帳號體驗此功能。",
      ],
    },
    {
      t: "13.參賽者獲利率或獲利額相同，排名如何計算？",
      c: [
        "若參賽者獲利率或獲利額相同，則依交易量高低排序。",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000:"$30,000",
  $10000:"$10,000",
  $5000:"$5,000",
  $3000:"$3,000",
  $2000:"$2,000",
  $1000:"$1,000",
  rankValueList: [
    "$1,000,000交易帳號或$200,000現金",
    "$30,000",
    "$10,000",
    "$5,000",
    "$5,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
    "$3,000",
  ],
  rankValueList2: [
    "$30,000",
    "$5,000",
    "$3,000",
    "$2,000",
    "$2,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
    "$1,000",
  ],
  ebclink:"https://www.ebc.com/zh/win1",
  第一届大赛冠军:"第一屆大賽冠軍",
  第一届大赛亚军:"第一屆大賽亞軍",
  第一届大赛季军:"第一屆大賽季軍"
};
