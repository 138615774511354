export default {
  在EBC交易赢梅西签名限量球衣PC: `在<span class="ebc">EBC</span>交易赢梅西签名限量球衣`,
  在EBC交易赢梅西签名限量球衣Mobile: `<p class="p1">在<span class="ebc">EBC</span>交易</p>
                        <p class="p2">赢梅西签名</p>
                        <p class="p3">限量球衣</p>`,
  立即参与: "立即参与",
  亲笔签名并装裱: "亲笔签名并装裱",
  "莱昂内尔·梅西": "莱昂内尔·梅西",
  巴塞罗那官方: "巴塞罗那官方",
  赛季主场球衣: "2018-19 赛季主场球衣",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名，球衣附带巴塞罗那俱乐部官方认证证书，确保签名100%真实可靠。限量珍藏，先到先得，它将是您收藏的一件珍贵体育文化遗产。 ",
  亲笔签名: "亲笔签名（英雄典藏框装裱版）",
  "莱昂内尔·梅西、": "莱昂内尔·梅西、",
  "路易斯·苏亚雷斯": "路易斯·苏亚雷斯",
  "巴萨2016-17赛季主场球衣": "巴萨 2016-17 赛季主场球衣",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名:
    "这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名，他们分别在不同的私人签名会上为这件球衣签名。梅西于2023年9月29日在迈阿密首先签名，随后苏亚雷斯于2023年10月12日在巴塞罗那完成签名。球衣附带Icons.com官方认证证书，确保签名100%真实可靠。世界独特的体育珍品，限量发售，先到先得。 ",
  "拉明·亚马尔": "拉明·亚马尔",
  "巴萨2024-25赛季主场球衣": "巴萨 2024-25 赛季主场球衣",
  这件巴萨球衣印有亚马尔的名字和球号:
    "这件巴萨球衣印有亚马尔的名字和球号，代表着巴萨新一代天才的崛起。16岁就打破多项俱乐部纪录的亚马尔，不仅传承了巴萨的足球基因，更象征着巴萨的未来。它不仅是一件球迷收藏品，更见证巴萨新传奇的诞生。 ",
  活动时间: "活动时间：",
  "活动时间:": "活动时间：",
  "2025年3月1日": "2025年3月1日 - 2025年3月31日",
  活动规则: "活动规则：",
  交易量PK:
    "交易量PK:单月交易量最大的用户（不包含美股）即可获取梅西亲签球衣一件",
  入金量PK: "入金量PK:单月净入金（存款）最高用户即可获取亚马尔球衣一件",
  活动条款: "活动条款： ",
  list: [
    "EBC投资者3月开始自动参与此活动，本活动针对全球开放",
    "此次活动奖品不可重复叠加，一个账户最多只能获取一次奖励  ",
    "奖励将于活动结束后的7个工作日在EBC官网跟EBCX官方账号公布，我们将通过邮件通知获奖客户。  ",
    "EBC团队将对所有参加活动账户进行全面审查，不符合安全政策的账户将不计入奖励计算。违规行为包括但不限于在同一设备上开设多账户，这些账户将被视为重复账户，不具备奖励资格。  ",
    "EBC 金融集团有权修改本次活动的条款。若有更改，恕不另行通知。  ",
    "EBC 金融集团保留本次活动的最终解释权。如有任何疑问，请联系客服团队。",
  ],
};
