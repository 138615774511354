export default {
  交易领薪计划: "基金經理成長計劃",
  为了鼓励交易者提升交易水平:
    "這是一項專為優秀交易者打造的資產增值計劃！根據交易員的管理資金量發放月度獎金，獎金比例高達年化 18%！",
  加入交易社区: "",
  即刻发起交易信号: "即時發起交易訊號",
  无限奖励月月拿: "無限獎勵月月拿",
  优享11个专属大礼: "優享11個專屬大禮",
  即刻加入: "即時加入",
  交易员等级奖励机制: `交易員等級獎勵機制`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC基於跟隨用戶數和管理資金量提供五級獎勵，不限制交易手數，僅以市場認可度為衡量標準",
  "/月": "/月",
  精英交易员: "菁英交易員",
  管理资金量: "管理資金量",
  跟随账户数: "跟隨用戶數",
  大师交易员: "大師交易員",
  冠军交易员: "冠軍交易員",
  明星交易员: "明星交易員",
  资金管理费: "+1%資金管理費",
  传奇交易员: "傳奇交易員",
  多层次的增值服务: "多層次的加值服務",
  EBC提供多达11项增值服务:
    "EBC提供多達11項加值服務，整合流量和媒體資源，全面提升訊號影響力。",
  "精英/大师交易员": "精英/大師",

  "7×24小时优先客服": "7*24客服",
  金融投资经典书籍礼遇: "金融投資經典書籍禮遇",
  专属VPS赞助: "專屬VPS贊助",
  订单流软件1年使用权: "訂單流軟體1年使用權",
  EBC定制大礼包: "EBC訂製大禮包",
  信号优先置顶展示: "訊號優先置頂展示",
  受邀参观海外办公室: "受邀參觀海外辦公室",
  巴萨球星联名礼包: "巴薩球星聯名禮包",
  巴萨赛场传奇之旅: "巴薩賽場傳奇之旅",
  品牌战略合作: "品牌策略合作",
  全球金融资源管家服务: "全球金融資源管家服務",
  五维指标评价系统: "五維指標評估系統",
  为交易员信号提供公开透明的评估:
    "為交易員訊號提供公開透明的評估，展現交易風格，提升曝光率，從而吸引更多跟隨者。",
  收益率: "收益率",
  历史收益率: "歷史收益率，值越高越好。",
  最大回撤: "最大回撤",
  衡量风控能力: "衡量風控能力，越低風險越小。",
  获利系数: "獲利係數",
  利润因子: "利潤因子，越高獲利能力越強",
  夏普比率: "夏普比率",
  每份风险的收益: "每份風險的收益，越高性價比越高。",
  标准差: "標準差",
  收益波动率: "收益波動率，越低越穩定。",
  以上数据仅供参考: "*以上數據僅供參考。",
  活动须知: "活動須知",
  list: [
    {
      label: "1.等級評定",
      value: [
        "管理資金量：跟隨帳號資金量+交易員（交易訊號）帳號自有資金量，資金量皆以餘額計算。",
        "跟隨用戶數：指跟隨此訊號的EBC帳戶數，同一帳戶建立多個交易帳號跟隨相同訊號，記為1個跟隨用戶。",
        "交易員等級每日更新，自然月內每個自然日均滿足最低達標，即可啟動交易員獎勵。",
      ],
    },
    {
      label: "2.跟隨規則",
      value: [
        "跟隨金額≥500USD，計爲1個有效跟隨賬號。",
        "每個交易帳號最多跟隨10個交易員（交易訊號）。",
        "每个交易帳號跟随金額=跟隨帳號餘額/跟隨訊號數。",
      ],
    },
    {
      label: "3.獎勵發放",
      value: [
        "次月第一週計算併發放上月獎勵，按上月達標的最低等級計算獎勵。",
        "月獎金=（日均管理資金×資金管理費率）÷12",
        "所有獎勵發放至交易帳號，得獎者自行提領。",
        "活動最終解釋權歸主辦方EBC所有。",
      ],
    },
  ],
  年化资金管理费: "年化資金管理費",
};
