export default {
  多元活动连接全球市场: "전 세계로 뻗어나가는 다양한 활동",
  共享全球顶级资源与学术支持:
    "글로벌 자원과 학술적 지원으로 여러분의 투자를 응원합니다.",
  了解更多: "더 알아보기",
  敬请期待: "기대해 주세요!",
  每个认真交易的人:
    "거래를 열심히 하는 사람은 모두 열심을 다한 대우를 받을 가치가 있다",
  activityListMetaData: [
    {
      label: "시장 활동",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_kr.png"),
          title: "EBC 글로벌 실거래 대회 Ⅱ",
          subtitle: "챔피언의 트레이딩 무료로 카피하기",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        // {
        //   id: "2-10",
        //   img: require("@/assets/image/activityList/messi_kr.png"),
        //   title: "EBC 트레이딩 대회에서 우승자는 메시가 친필로 사인한 한정판 유니폼을 획득합니다.",
        //   subtitle: "이벤트 시간:2025년3월1일 - 2025년3월31일",
        //   url: "/trade-for-messi",
        //   time: "",
        // },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7_kr.png"),
          title: "EBC, 트레이딩뷰와 제휴해 글로벌 리소스 연결",
          subtitle:
            "기간 한정 트레이딩뷰 특전 받고 글로벌 시장 트렌드 실시간으로 파악하자!",
          url: "/TradingView",
          time: "",
        },
        

        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_en.png"),
          title: "창작자 인센티브 프로그램",
          subtitle: "여러분만의 경제 콘텐츠로 보너스를 받으세요!",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-1",
          img: require("@/assets/image/activityList/banner2_1_en.png"),
          title: "백만달러 거래 대회",
          subtitle: "총 상금 100만 달러, 트레이딩의 제왕을 찾아서",
          url: "/win1",
          time: "",
        },
        {
          id: "2-4",
          img: require("@/assets/image/activityList/banner2_4_kr.png"),
          title: "EBC 펀드 매니저 양성 프로그램",
          subtitle:
            "본 프로그램은 우수한 트레이더를 양성하고 지원하는 것을 목적으로 하며, 매니징 중인 자금 규모에 따라 월별 보너스가 지급되며, 최대 연 18%의 보너스율이 적용됩니다. ",
          url: "/pay",
          time: "",
        },
      ],
    },
    {
      label: "브랜드 관련",
      list: [
        {
          id: "3-4",
          img: require("@/assets/image/activityList/banner3_4_kr.png"),
          title: "EBC, iFX EXPO Latin America 2025 참가",
          subtitle:
            "EBC가 다이아몬드 스폰서 자격으로 라틴 아메리카에서 개최되는 iFX EXPO 2025에 참가합니다. 글로벌 금융 시장에서 투자 기회를 모색하기 위한 여정의 길목에서 여러분을 기다리겠습니다. ",
          url: "",
          time: "9-10 April 2025",
        },
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_kr.png"),
          title: "Highlights: EBC at Mexico Money Expo 2025",
          subtitle:
            "EBC는 멕시코에서 열리는 Money Expo 2025에서 플래티넘 스폰서가 될 것입니다! 멕시코에서 열리는 이 최고의 거래 이벤트에서 핀테크의 새로운 트렌드를 탐색하고 새로운 거래 기회를 발견하십시오!",
          url: "/LearningCenterInfo?id=LEARN-782-60B",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_kr.png"),
          title: "지속 가능성 유지: 경제 성장과 기후 회복력의 균형",
          subtitle: `"경제학자들이 실제로 하는 일" 은 최근 세계 이슈를 중심으로 경제학 관점으로 정책 개선과 경제 발전 가능성을 논의합니다.`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_kr.png"),
          title: "EBC 금융 그룹 - 옥스퍼드 대학교 공동 세미나",
          subtitle: `이번 오프라인 세미나는 2024년 11월 14일 14:30 (GMT)에 옥스퍼드 크라이스트처치 칼리지에서 개최되며, 온라인으로 동시 생중계됩니다.`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
