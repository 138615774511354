<template>

    <div id="page-navigate" class="navigate-wrapper"
        :class="[$t('PageNavigate.classLang'), $t('common.fontFamily'), { 'blank': blankType }]">
        <div class="navigate-box">
            <div class="navigate-logo">
                <a href="/" class="navigate-logo-img">
                    <img v-if="blankType" class="navigate-logo-img" src="../../assets/image/navigate/logo_light.svg"
                        alt="" />

                    <img v-else class="navigate-logo-img" src="../../assets/image/navigate/logo.svg" alt="" />
                </a>
                <a id="menu" href="javascript:;"><span class="iconfont icon-navigate-menu"></span></a>
            </div>
            <div id="linkBox" class="navigate-links navigate-link-box-close">
                <div id="linkList" class="navigate-menu-list">

                    <div class="navigate-menu-item current-group">
                        <div class="navigate-menu-item-top">
                            <a href="/" class="navigate-menu-item-top-link current-link">
                                {{ indexName }}
                            </a>
                        </div>
                    </div>
                    <div class="navigate-menu-item">
                        <div class="navigate-menu-item-top">
                            <a href="javascript:;" class="navigate-menu-item-top-link" @click="toScroll('section2f')">
                                {{ $t('win3.大赛奖池') }}
                            </a>
                        </div>
                    </div>
                    <div class="navigate-menu-item">
                        <div class="navigate-menu-item-top">
                            <a href="javascript:;" class="navigate-menu-item-top-link" @click="toScroll('section4')">
                                {{ $t('win3.实时赛况') }}
                            </a>
                        </div>
                    </div>
                    <div class="navigate-menu-item">
                        <div class="navigate-menu-item-top">
                            <a href="javascript:;" class="navigate-menu-item-top-link" @click="toScroll('section5')">
                                {{ $t('win3.赛事进程') }}
                            </a>
                        </div>
                    </div>
                    <div class="navigate-menu-item">
                        <div class="navigate-menu-item-top">
                            <a href="javascript:;" class="navigate-menu-item-top-link" @click="toScroll('section6')">
                                {{ $t('win3.赛组规则') }}
                            </a>
                        </div>
                    </div>
                </div>
                <div class="navigate-entry">
                    <div class="navigate-entry-link">
                        <!-- <a :href="FCAUrl" class="nav-link">{{ $t('PageNavigate.FCA开户') }}</a> -->
                        <a :href="signupUrl">{{ $t('PageNavigate.立即开户') }}</a>
                        <a :href="loginUrl">{{ $t('PageNavigate.登录') }}</a>
                    </div>
                    <div class="navigate-help">


                        <div ref="languageBox" class="navigate-menu-item">
                            <div class="navigate-menu-item-top">
                                <a class="navigate-menu-item-top-link" href="javascript:;"><span
                                        class="iconfont icon-navigate-language"></span></a>
                            </div>
                            <div class="navigate-menu-item-content mobile-lang-selector">
                                <div id="langSelector" class="navigate-menu-item-content-box">
                                    <div class="navigate-menu-item-content-value l-w-14">
                                        <a v-if="hasLan('en')" :class="{ 'current-lang': language == 'en' }"
                                            @click="changeLange('en')" href="javascript:;">English</a>
                                        <a v-if="hasLan('ja')" :class="{ 'current-lang': language == 'ja' }"
                                            @click="changeLange('ja')" href="javascript:;">Japanese · 日本語</a>
                                        <a v-if="hasLan('ko')" :class="{ 'current-lang': language == 'ko' }"
                                            @click="changeLange('ko')" href="javascript:;">Korean · 한국어</a>
                                        <a v-if="hasLan('id')" :class="{ 'current-lang': language == 'id' }"
                                            @click="changeLange('id')" href="javascript:;">Indonesian · Bahasa
                                            Indonesia</a>


                                    </div>
                                    <div class="navigate-menu-item-content-value l-w-23">
                                        <a v-if="hasLan('zh')" :class="{ 'current-lang': language == 'zh' }"
                                            @click="changeLange('zh')" href="javascript:;">Simplified Chinese ·
                                            简体中文</a>
                                        <a v-if="hasLan('ct')" :class="{ 'current-lang': language == 'ct' }"
                                            @click="changeLange('ct')" href="javascript:;">Traditional Chinese ·
                                            繁體中文</a>

                                        <a v-if="hasLan('pt')" :class="{ 'current-lang': language == 'pt' }"
                                            @click="changeLange('pt')" href="javascript:;">Portuguese · Português</a>
                                    </div>
                                    <div class="navigate-menu-item-content-value l-w-18">
                                        <a v-if="hasLan('th')" :class="{ 'current-lang': language == 'th' }"
                                            @click="changeLange('th')" href="javascript:;">Thai
                                            · ภาษาไทย</a>
                                        <a v-if="hasLan('vn')" :class="{ 'current-lang': language == 'vn' }"
                                            @click="changeLange('vn')" href="javascript:;">Vietnamese · Tiếng
                                            Việt</a>
                                        <a v-if="hasLan('es')" :class="{ 'current-lang': language == 'es' }"
                                            @click="changeLange('es')" href="javascript:;">Spanish · Español</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navigate-mask mobile"></div>
            </div>
        </div>
    </div>

</template>
<script>
import { mapGetters } from 'vuex';

export default {
    inject: ['reload'], //注入App里的reload方法
    props: {
        langList: {
            type: Array,
            default: () => {
                return ['zh', 'ct', 'en', 'ja', 'id', 'ko', 'th', 'vn', 'es', 'pt']
            }
        },
        blankType: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            indexName: this.$t('PageNavigate.headlist')[0].text
        }
    },
    computed: {

        ...mapGetters([
            'language',
            'FCAUrl',
            'signupUrl',
            'loginUrl',
        ])
    },
    created() {

    },

    mounted() {



        // 添加移入移出事件
        let navigateMenuItemTopList = document.getElementsByClassName(
            "navigate-menu-item-top"
        );
        let pageNavigate = document.getElementById("page-navigate");
        let navigateMenuItemList =
            pageNavigate.getElementsByClassName("navigate-menu-item");
        for (let i = 0; i < navigateMenuItemTopList.length; i++) {
            navigateMenuItemTopList[i].addEventListener("mouseenter", function (e) {
                for (let j = 0; j < navigateMenuItemList.length; j++) {
                    if (e.target.parentNode == navigateMenuItemList[j]) {
                        e.target.parentNode.classList.add(
                            "navigate-menu-item-content-selected"
                        );
                    } else {
                        navigateMenuItemList[j].classList.remove(
                            "navigate-menu-item-content-selected"
                        );
                    }
                }
            });
        }
        // 清除选中展开样式
        function clearNavigateMenuItemList() {
            for (let i = 0; i < navigateMenuItemList.length; i++) {
                navigateMenuItemList[i].classList.remove(
                    "navigate-menu-item-content-selected"
                );
            }
        }
        document
            .getElementsByClassName("navigate-entry-link")[0]
            .addEventListener("mouseenter", function () {
                clearNavigateMenuItemList();
            });

        pageNavigate.addEventListener("mouseleave", function () {
            clearNavigateMenuItemList();
        });

        // 手机端操作
        // 切换菜单栏
        let menuBtn = document.getElementById("menu");
        menuBtn.addEventListener("click", function (e) {
            linkBox.classList.toggle("navigate-link-box-close");
        });
        // 点击空白处关闭菜单栏
        let navigateMask = document.getElementsByClassName("navigate-mask")[0];
        navigateMask.addEventListener("click", function (e) {
            linkBox.classList.toggle("navigate-link-box-close");
        });
        //
        let navigateMenuItemTopLinkList = document.getElementsByClassName(
            "navigate-menu-item-top-link"
        );

        for (let i = 0; i < navigateMenuItemTopLinkList.length; i++) {
            navigateMenuItemTopLinkList[i].addEventListener("click", function (e) {
                if (e.target.nodeName === "SPAN") {
                    e.target.parentNode.parentNode.parentNode.classList.toggle(
                        "navigate-link-selected"
                    );
                } else {
                    e.target.parentNode.parentNode.classList.toggle("navigate-link-selected");
                }
            });
        }
    },
    methods: {
        changeLange(language) {
            this.$store.commit("setLanguage", language);
            this.$refs.languageBox.classList.remove('navigate-menu-item-content-selected');
            this.reload();
        },
        hasLan(language) {
            return this.langList.includes(language)
        },
        toScroll(el) {
            let e = document.getElementsByClassName(el)[0];
            e.scrollIntoView();
        }
    }
}
</script>
<style lang="scss" scoped>
@import url("../../assets/css/page-navigate.css");

.blank {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    transition: background 0.3s;
}

.blank:hover {
    background: rgba(0, 0, 0, 1);
}

.blank .navigate-menu-item-content {
    background: rgba(0, 0, 0, 1);
}

.navigate-entry {
    gap: 4rem;
}

.navigate-help {
    width: 100rem;
    justify-content: right;
}

@media (max-width: 1200px) {
    .navigate-logo-img {
        height: 30px;
    }

    .blank .navigate-menu-list,
    .blank .navigate-entry {
        background: rgba(0, 0, 0, 1);
    }
}
</style>