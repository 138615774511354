export default {
  多元活动连接全球市场: "多元活动连接全球市场",
  共享全球顶级资源与学术支持: "共享全球顶级资源与学术支持，助您盈在投资起点",
  了解更多: "了解更多",
  敬请期待: "敬请期待",
  每个认真交易的人: "每个认真交易的人，都值得被认真对待",
  activityListMetaData: [
    {
      label: "积分商城",
      list: [
        {
          id: "1-1",
          img: require("@/assets/image/activityList/banner1_1.png"),
          title: "EBC积分商城 认真对待每份期待",
          subtitle: "积分畅兑梦想豪车 、巴萨周边、热门单品",
          url: "https://ebcpoints.com/html/ebc/index.html",
          time: "",
          target: "_blank",
        },
      ],
    },
    {
      label: "市场活动",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9.png"),
          title: "EBC全球百万美金交易大赛Ⅱ",
          subtitle: "免费复制冠军收益",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        // {
        //   id: "2-10",
        //   img: require("@/assets/image/activityList/messi.png"),
        //   title: "在EBC交易赢梅西签名限量球衣",
        //   subtitle: "活动时间：2025年3月1日 - 2025年3月31日",
        //   url: "/trade-for-messi",
        //   time: "",
        // },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7.png"),
          title: "EBC携手Trading view连接全球资源",
          subtitle: "限时领Trading view会员权益 全球市场动态即时掌控",
          url: "/TradingView",
          time: "",
        },

        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3.png"),
          title: "创作者激励计划",
          subtitle: "创作原创金融观点，专享额外奖励",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-4",
          img: require("@/assets/image/activityList/banner2_4.png"),
          title: "基金经理成长计划",
          subtitle:
            "这是一项专为优秀交易者打造的资产增值计划！根据交易员的管理资金量发放月度奖金，奖金比例高达年化18%！",
          url: "/pay",
          time: "",
        },
        {
          id: "2-5",
          img: require("@/assets/image/activityList/banner2_5.png"),
          title: "免费领取订单流",
          subtitle: "助您夺回交易世界的主动权",
          url: "/orderflow/",
          time: "",
        },
        {
          id: "2-1",
          img: require("@/assets/image/activityList/banner2_1.png"),
          title: "百万美金交易大赛",
          subtitle: "悬赏百万美金  寻找交易之王",
          url: "/win1",
          time: "",
        },
      ],
    },
    {
      label: "品牌动态",
      list: [
        {
          id: "3-4",
          img: require("@/assets/image/activityList/banner3_4.png"),
          title: "EBC将出席拉美iFX EXPO 2025",
          subtitle:
            "作为钻石赞助商，EBC金融集团即将亮相拉丁美洲iFX EXPO 2025，期待与您在这场全球规模最大、最具影响力的展会中相见，共探全球视野下的投资机遇。",
          url: "",
          time: "2025年4月9日-10日",
        },
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3.png"),
          title: "EBC亮相墨西哥Money Expo 2025",
          subtitle:
            "EBC将作为铂金赞助商，亮相墨西哥Money Expo 2025！在这场墨西哥顶级的交易盛会中，共同探索金融科技新趋势，发现交易新机遇！",
          url: "/LearningCenterInfo?id=LEARN-782-60B",
          time: "2025年2月26-27日",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1.png"),
          title: "如何利用气候经济学，挖掘投资机遇",
          subtitle: `"经济学家究竟在做什么" 结合时下热议话题，探讨如何利用经济学推动政客的改进，促进经济繁荣。`,
          url: "/oxford",
          time: "2024年11月14日",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2.png"),
          title: "EBC联合牛津大学研讨会",
          subtitle: `本次线下研讨会将于11月14日14:30 (GMT)在牛津大学基督教会学院展开，线上同步直播，并向全球投资者开放。`,
          url: "/oxford1",
          time: "2024年3月6日",
        },
      ],
    },
  ],
};
