export default {
  在EBC交易赢梅西签名限量球衣PC: `<h1>Trade tại EBC và giành áo đấu kèm chữ ký của Messi</h1>`,
  在EBC交易赢梅西签名限量球衣Mobile: `<h1>Trade tại EBC và giành áo đấu kèm chữ ký của Messi</h1>`,
  活动时间: "Thời gian:",
  "活动时间:": "Thời gian:",
  "2025年3月1日": "01.03.2025 - 31.03.2025",
  立即参与: "THAM GIA NGAY",
  亲笔签名并装裱: "Chữ ký tay đóng kèm khung",
  "莱昂内尔·梅西": "Lionel Messi",
  巴塞罗那官方: "Áo đấu của FC Barcelona mùa giải 2018-19",
  赛季主场球衣: "",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "Áo đấu phiên bản giới hạn mang chữ ký của Lionel Messi được ký tay tại một sự kiện riêng ở Paris vào ngày 28 tháng 1 năm 2023. Nó đi kèm với chứng nhận xác thực chính thức từ FC Barcelona, đảm bảo tính hợp pháp của chữ ký. Đây là một món quà quý giá cho bộ sưu tập của bạn, ai nhanh tay sẽ nhận được trước — đừng bỏ lỡ cơ hội sở hữu nó!",
  亲笔签名: "Chữ ký (Phiên bản khung bảo quản kỷ niệm của các huyền thoại)",
  "莱昂内尔·梅西、": "Lionel Messi,",
  "路易斯·苏亚雷斯": " Luis Suárez",
  "巴萨2016-17赛季主场球衣": "Áo đấu của FC Barcelona mùa giải 2016-17",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名:
    "Chiếc áo đấu huyền thoại này đã được hai huyền thoại bóng đá, Lionel Messi và Luis Suárez, ký tặng tại các sự kiện ký tặng riêng. Messi đã ký trước vào ngày 29 tháng 9 năm 2023 tại Buenos Aires, tiếp theo là Suárez vào ngày 12 tháng 10 năm 2023 tại Montevideo. Áo đi kèm với chứng nhận xác thực từ Icons.com, đảm bảo độ tin cậy của chữ ký. Đây là một món quà quý giá cho bộ sưu tập của bạn, ai nhanh tay sẽ nhận được trước — đừng bỏ lỡ cơ hội sở hữu nó! ",
  "拉明·亚马尔": "Lamine Yamal",
  "巴萨2024-25赛季主场球衣": "Áo đấu của FC Barcelona mùa giải 2024-25",
  这件巴萨球衣印有亚马尔的名字和球号:
    "Chiếc áo đấu mang tên và số áo của Yamal, thể hiện sự trỗi dậy của thế hệ tài năng mới tại Barcelona. Ở tuổi 16, Yamal đã phá vỡ nhiều kỷ lục của câu lạc bộ, không chỉ kế thừa gen bóng đá của Barcelona mà còn là biểu tượng cho tương lai của câu lạc bộ. Đây không chỉ là một món đồ sưu tập dành cho người hâm mộ, mà còn là chứng nhân cho sự ra đời của một huyền thoại mới của Barcelona.",
  活动规则: "Quy tắc hoạt động:",
  交易量PK:
    "PK khối lượng giao dịch: Khách hàng có khối lượng giao dịch lớn nhất trong tháng (không bao gồm giao dịch chứng khoán Mỹ) sẽ nhận được một chiếc áo đấu có chữ ký của Messi.",
  入金量PK:
    "PK desposit: Khách hàng có số tiền nạp ròng cao nhất trong tháng sẽ nhận được một chiếc áo đấu của Yamal.",
  活动条款: "Điều khoản hoạt động:",
  list: [
    "Các nhà giao dịch EBC sẽ tự động tham gia sự kiện này bắt đầu từ tháng 3. Đây là sự kiện toàn cầu.",
    "Mỗi tài khoản chỉ đủ điều kiện nhận một phần thưởng duy nhất. ",
    "Người chiến thắng sẽ được công bố trên trang web chính thức của EBC và tài khoản X của EBC trong vòng bảy ngày làm việc kể từ ngày kết thúc sự kiện. Người chiến thắng cũng sẽ nhận được email thông báo.",
    "EBC sẽ tiến hành kiểm tra toàn diện các tài khoản tham gia sự kiện. Các tài khoản không tuân thủ chính sách bảo mật sẽ không được tính vào giải thưởng. Các hành vi bao gồm mở nhiều tài khoản trên cùng một thiết bị sẽ được xem là tài khoản trùng lặp và không đủ điều kiện nhận giải thưởng.",
    "EBC Financial Group có quyền sửa đổi các điều khoản của sự kiện mà không cần thông báo trước.",
    "EBC Financial Group có quyền đưa ra quyết định cuối cùng. Mọi thắc mắc, vui lòng liên hệ với bộ phận hỗ trợ khách hàng.",
  ],
};
