export default {
  在EBC交易赢梅西签名限量球衣PC: `<h1>メッシのユニフォームを獲得しよう！</h1>`,
  在EBC交易赢梅西签名限量球衣Mobile: `<h1>メッシのユニフォームを獲得しよう！</h1>`,
  立即参与: "今すぐ参加",
  亲笔签名并装裱: "直筆サイン入り",
  "莱昂内尔·梅西": "リオネル・メッシ",
  巴塞罗那官方: "FCバルセロナの2018-2019シーズンのホームユニフォーム",
  赛季主场球衣: "",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "このユニフォームはメッシ選手が2023年1月28日にパリで行われたサイン会でサインしたものです。FCバルセロナの公式証明書をお付けして数量限定でプレゼントいたします。",
  亲笔签名: "直筆サイン入り（フレーム入り）",
  "莱昂内尔·梅西、": "リオネル・メッシ、",
  "路易斯·苏亚雷斯": "ルイス・スアレス",
  "巴萨2016-17赛季主场球衣": "FCバルセロナの2016-2017シーズンのユニフォーム",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名:
    "このユニフォームにはメッシとスアレスという伝説的な2人の選手がサインしており、別々のプライベートサイン会でそれぞれサインしています。メッシは2023年9月29日にマイアミで、スアレスは2023年10月12日にバルセロナでサインしました。ユニフォームにはIcons.comの公式証明書が付属しています。数量限定です。",
  "拉明·亚马尔": "ラミン・ヤマル",
  "巴萨2024-25赛季主场球衣":
    "FCバルセロナの2024-2025シーズンのホームユニフォーム",
  这件巴萨球衣印有亚马尔的名字和球号:
    "このユニフォームにはヤマル選手の名前とボール番号が刻印されており、FCバルセロナの新たな天才の台頭を表しています。16歳でクラブ記録を塗り替えたヤマルは、FCバルセロナの栄光を受け継いでいるだけでなく、その未来を象徴しています。コレクションであるだけでなく、FCバルセロナの新たな伝説の誕生を告げるものです。",
  活动时间: "キャンペーン期間",
  "活动时间:": "キャンペーン期間：",
  "2025年3月1日": "2025年3月1日（土）-　2025年3月31日（月）",
  活动规则: "プレゼント",
  交易量PK: `取引量PK戦<br/>
            キャンペーン期間中の取引量が最大となる顧客(米国個別株を除く)にはメッシのユニフォームを進呈いたします。`,
  入金量PK: `入金額PK戦<br/>
            キャンペーン期間中の純入金額が最大の顧客にはアマルのユニフォームを進呈いたします。`,
  活动条款: "利用規約",
  list: [
    "参加登録は不要です。弊社にご登録いただいているお客様は3月から自動的にこのキャンペーンに登録されます。このキャンペーンは全世界のトレーダーを対象としています。",
    "賞品は1つのアカウントにつき1回までしか獲得できず、重複して受取ることはできません。",
    "結果はイベント終了後7営業日以内に弊社公式サイトと弊社公式Xアカウントで発表され、受賞者にはメールにてお知らせします。",
    "弊社取引規約並びにセキュリティポリシーに反する口座はキャンペーンの対象外となります。違反行為には同一デバイスで複数のアカウントを開設することなどが含まれますが、これに限りません。",
    "本キャンペーンは予告なく規約およびルールを変更する場合がございます。",
    "本イベントの最終解釈権はEBCに帰属します。ご不明な点等ございましたら、お気軽に弊社カスタマーサポートcs.jp@ebc.comまでお問い合わせください。",
  ],
};
