export default {
  fontFamily: "en-font-family",
  classLang: "en",
  headlist: [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Trading",
      menu: [
        {
          tit: "Account & Conditions",
          list: [
            {
              text: "Trading Accounts",
              url: "/TradingAccount",
            },
            {
              text: "Trading Products",
              url: "/tradingproduct",
            },
            {
              text: "Leverage & Margin",
              url: "/leverage-margin",
            },
            {
              text: "Deposit & Withdrawals",
              url: "/funding",
            },
            {
              text: "Dividend",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "Trading Tools",
          list: [
            {
              text: "MT4 Download",
              url: "/MT4",
            },
            {
              text: "MT5 Download",
              url: "/MT5",
            },
            {
              text: "MT4 Trading Tools",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "Market Analysis",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "EBC Institute",
      menu: [
        {
          list: [
            {
              text: "Learning Center",
              url: "/LearningCenter",
            },
            {
              text: "Data Center",
              url: "/lab",
            },
          ],
        },
      ],
    },
    
    {
      text: "About EBC",
      tips: "",
      img: "55",
      menu: [
        {
          tit: "About EBC",
          list: [
            {
              text: "Why EBC",
              url: "/about-us",
            },
            {
              text: "Sustainability & Impact",
              url: "/ESG",
            },
            {
              text: "FAQ",
              url: "https://support.ebchelp.com/hc/en-001",
            },
            {
              text: "Activities",
              url: "/activity",
            },
          ],
        },
        {
          tit: "Partners",
          list: [
            {
              text: "FC Barcelona",
              url: "/FCB",
            },
            {
              text: "Affiliate Programmes",
              url: "/affiliate-programmes",
            },
            {
              text: "Institutional Services",
              url: "/service",
            },
            {
              text: "Liquidity Solutions",
              url: "/technology",
            },
          ],
        },
      ],
    },
    {
      text: "Trading Challenge",
      url: "/million-dollar-challenge-2",
    },
    {
      text: "Copy Trading",
      url: "https://ebcfinsns.co/",
    },
  ],
  FCA开户: "FCA Account",
  立即开户: "Register",
  登录: "Login",
  官方验证: "Official Channel Verification",
  帮助中心: "FAQ",
  帮助中心Url: "https://support.ebchelp.com/hc/en-001",
};
