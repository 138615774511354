export default {
	"pages1":{
		"title":"EBC 지능형 청구 커뮤니티",
		"tips":"모든 거래원의 마음속에는 영웅의 꿈이 있다.",
		"text":"주문 커뮤니티의 이점",
		"text1":"신호원 전시",
		"text2":"거래원 보상 계획",
		"text3":"부가 가치 호례",
		"text4":"계좌 개설"
	},
	"pages2":{
		"title":"주문 커뮤니티의 이점",
		"list":[{
			"tit":"보다 유연한 주문 모드",
			"tips":"EA 플러그인을 사용하여 양방향 연결 필요 없음<br />유연한 주문 방식 조정<br />맞춤형 거래를 하다."
		},{
			"tit":"밀리초 수준 주문 속도",
			"tips":"기본 MT 통신 프로토콜 기반<br />거래 계좌와 신호자를 효과적으로 연결하다<br />즉각적인 응답"
		},{
			"tit":"전문 데이터 분석의 5섯가지 차원",
			"tips":"독점적인 5차원 차트지표소개<br>펀드 등급 평가 체계는 새로운 길을 개척하며<br>양질의 신호를 만들어 냅니다"
		},{
			"tit":"더 스마트한AI 선택 도우미",
			"tips":"주문 선호도에 따라<br />원클릭으로 대상 신호 필터링<br />세계 최고 수준의 거래 전략이 주머니에 가득 차 있다"
		},{
			"tit":"더 투명한 거래 전시",
			"tips":"신호원 내역 주문 추적 가능<br />조작 세부 사항을 한눈에 볼 수 있다.<br />교역 대가의 수필을 배우다."
		}],
		"btn":"주문 커뮤니티 로그인"
	},
	"pages3":{
		"title":"신호원 전시",
		"text":"수익률",
		"text1":"최대 복귀",
		"text2":"샤프 비율",
		// "text3":"거래 승률",
		"text3":"이익 계수",
		"text4":"무료",
		"text5":"지금 구독",
		"btn":"추가 신호 보기"
	},
	"pages4":{
		"title":"거래원 보상 계획",
		"num":"$100/월 보상",
		"num1":"$300/월 보상",
		"num2":"$1,000/월 보상",
		"num3":"$5,000/월 보상",
		"num4":"$10,000/월 보상",
		"text1":"자금을 관리하다",
		"text2":"고객량 추종",
		"name":"신예 거래원",
		"name1":"엘리트 트레이더",
		"name2":"챔피언 트레이더",
		"name3":"스타 트레이더",
		"name4":"전설적인 거래원",
		"tips":"+1% 관리 펀드/년(매월 지급)",
		"btn":"즉시 계획에 가입하다."
	},
	"pages5":{
		"title":"부가 가치 호례",
		"text":"7*24 고객 서비스",
		"text1":"EBC 마케팅 상호 작용",
		"text2":"VPS 후원",
		"text3":"거래 서적 협찬",
		"text4":"주문 흐름 사용권",
		"text5":"EBC 선물",
		"text6":"신호 고정 디스플레이",
		"text7":"해외 사무실 견학",
		"text8":"미디어 지원",
		"text9":"전략적 협력",
		"text10":"해외 펀드 상품 발행 컨설턴트 서비스",
		"btn":"주문 커뮤니티 로그인"
	},
	"QA": {  
		"title": "카피 트레이딩 FAQ",  
		"Q1": "1. 카피 트레이딩이란 무엇인가요?",  
		"A1": "카피 트레이딩은 경험이 많은 트레이더의 거래 방식을 따라하는 투자 전략입니다. 베테랑 트레이더의 전략을 복사해 자신의 거래에 적용해 보면서 다양한 성공 전략과 거래 기법을 배울 수 있습니다.",  
		"Q2": "2. 카피 트레이딩 작동 방식이 어떻게 되나요?",  
		"A2": "카피 트레이딩은 전문 투자자의 거래를 자동으로 복사해 플랫폼에서 전문 투자자의 트레이딩을 재현하는 방식입니다. 이러한 기능으로 계속 시장을 모니터링하지 않고도 전문적인 지식과 전략을 활용해 잠재적인 수익률을 높일 수 있습니다.",  
		"Q3": "3. 카피 트레이딩 플랫폼은 어떤 이점이 있나요?",  
		"A3": "카피 트레이딩 플랫폼은 검증된 트레이딩 전략을 제시해 주어 시장을 분석할 시간을 절약하고 보다 일관된 수익률을 얻을 수 있습니다. 또 이러한 플랫폼은 간접적인 트레이딩 경험을 쌓을 수 있는 유용한 트레이딩 도구 중 하나입니다.",  
		"Q4": "4. 카피 트레이딩은 어떤 도구를 사용하나요?",  
		"A4": "고급 데이터 분석, 거래 신호 알림, 성추적 및 커뮤니티 포럼 등 다양한 도구를 사용할 수 있습니다. 이러한 도구들로 트레이더는 다양한 인사이트와 전략을 공유할 수 있습니다.",  
		"Q5": "5. 카피 트레이딩엔 어떤 리스크가 있나요?",  
		"A5": "시장 변동성이란 리스크와 실적이 부진한 트레이더를 만날 수 있다는 위험이 있습니다. 따라서 종합적인 위험 관리 도구를 제공하는 플랫폼을 사용하는 것이 중요합니다.",  
		"Q6": "6. 카피 트레이딩 전략을 지정할 수 있나요?",  
		"A6": "예. 대부분의 카피 트레이딩 플랫폼에서는 맞춤형 전략 기능을 제공합니다. 여기에는 특정 트레이더 선택, 투자 한도 설정, 재무 목표와 위험 감수 능력에 맞는 위험 매개변수 조정 기능 포함합니다."  
	},
	"box-name" : "copytrading-body kr",
}