export default {
	"pages1":{
		"title":"สังคมแห่งการ CopyTrade แบบอัจฉริยะของ EBC",
		"tips":"นักเทรดทุกคนล้วนแต่มีนักเทรดไอดอลอยู่ในใจ ",
		"text":"ข้อดีของการมาร่วมอยู่ในสังคมเรา",
		"text1":"การแสดงสัญญาณการเทรด",
		"text2":"แผนการให้รางวัลผู้นำเทรด",
		"text3":"รางวัลเพิ่มเติม",
		"text4":"เข้าสู่ระบบ"
	},
	"pages2":{
		"title":"ข้อดีของการมาร่วมอยู่ในสังคมเรา",
		"list":[{
			"tit":"รูปแบบการ Copy Trade ที่ยืดหยุ่นได้",
			"tips":"เริ่มการ CopyTrade โดยไม่ต้องใช้ติดตั้งปลั๊กอิน EA <br />และมีการปรับการตั้งค่าที่ยืดหยุ่น"
		},{
			"tit":"การตอบสนองระดับมิลลิวินาที",
			"tips":"การเทรดที่เชื่อมต่อบัญชีการซื้อขายเข้ากับสัญญาณ<br />ของผู้นำเทรดผ่านระบบ MT Protocol ได้<br />อย่างมีประสิทธิภาพและรวดเร็ว"
		},{
			"tit":"วิเคราะห์ข้อมูล 5 มิติ",
			"tips":"แนะนำ 5 ตัวชี้วัด<br/>ระบบจัดลำดับกองทุนแบบใหม่<br/>คัดสรรสัญญาณคุณภาพ"
		},{
			"tit":"เครื่องมือช่วยเหลือ AI อัจฉริยะ",
			"tips":"ระบบการคัดกรองสัญญาณด้วยคลิกเดียว<br />ตามความต้องการของคุณ เพื่อค้นหานักเทรดระดับแนวหน้า"
		},{
			"tit":"การเปิดเผยข้อมูลการเทรดที่โปร่งใสตรวจสอบได้",
			"tips":"สามารถเห็นประวัติการซื้อย้อนหลังของผู้นำเทรดได้อย่างละเอียด<br />เพื่อเป็นการเรียนรู้สำหรับผู้คัดลอกสัญญาณไปในตัวด้วย"
		}],
		"btn":"ลงทะเบียน"
	},
	"pages3":{
		"title":"การแสดงสัญญาณการเทรด",
		"text":"อัตราผลตอบแทน",
		"text1":"Drawdown สูงสุด",
		"text2":"Sharp Ratio ",
		// "text3":"อัตรส่วน กำไร/ขาดทุน ",
		text3:"อัตราตัวคูณกำไร",
		"text4":"ฟรี",
		"text5":"สมัครตอนนี้ ",
		"btn":"สัญญาณเพิ่มเติม"
	},
	"pages4":{
		"title":"แผนการให้รางวัลผู้นำเทรด",
		"num":"100 USD ต่อเดือน",
		"num1":"300 USD ต่อเดือน",
		"num2":"1,000 USD ต่อเดือน",
		"num3":"5,000 USD ต่อเดือน",
		"num4":"10,000 USD ต่อเดือน",
		"text1":"ยอดเงินรวมทั้งหมด",
		"text2":"จำนวนผู้ติดตาม",
		"name":"การเริ่ิ่มต้น",
		"name1":"ดี",
		"name2":"ดีเยี่ยม",
		"name3":"ดาวเด่น",
		"name4":"ระดับเทพ",
		"tips":"กองทุนที่จัดการ +1%/ปี (จ่ายเป็นรายเดือน)",
		"btn":"สมัครเลย"
	},
	"pages5":{
		"title":"รางวัลชนะเลิศ",
		"text":"บริการตลอด 24 ชม. 7 วัน ",
		"text1":"มีส่วนร่วมในแคมเปญการตลาดของ EBC",
		"text2":"การสนับสนุน VPS",
		"text3":"มอบหนังสือด้านการลงทุนให้",
		"text4":"สิทธิ์สมาชิก 1 ปี ในการใช้งานโปรแกรมเทรด Order Flow ",
		"text5":"แพ็คเกจรางวัลที่กําหนดเอง",
		"text6":"สัญญาณเทรดที่แสดงด้านบน",
		"text7":"เยี่ยมชมสํานักงานในต่างประเทศของ EBC",
		"text8":"เครื่องมือการสื่อสารด้านการตลาดที่ใช้ร่วมกัน",
		"text9":"การทำแบรนด์ร่วมกัน ",
		"text10":"บริการที่ปรึกษาการเปิดกองทุนต่างประเทศ",
		"btn":"เข้าสู่ระบบ"
	},
	"QA": {  
		"title": "คำถามที่พบบ่อย",  
		"Q1": "1.Copy Trading คืออะไร?",  
		"A1": "Copy Trading คือการใช้ระบบอัตโนมัติในการคัดลอกกลยุทธ์การเทรดของนักเทรดคนอื่นๆ ซึ่งจะช่วยลดความยุ่งยากในการวิเคราะห์ และเพิ่มโอกาสทำกำไร",  
		"Q2": "2.Copy Trading ทำงานอย่างไร?",  
		"A2": "ระบบ Copy Trading จะทำให้คุณสามารถคัดลอกคำสั่งซื้อขายจากนักเทรดที่ติดตามโดยอัตโนมัติ เมื่อพวกเขาเปิดหรือปิดคำสั่งซื้อขาย บัญชีของคุณก็จะเปิดหรือปิดตามคำสั่งนั้นทันที และระบบจะทำงานอัตโนมัติตามที่คุณตั้งค่าไว้",  
		"Q3": "3. ข้อดีของการใช้ระบบ Copy Trading？",  
		"A3": "สามารถคัดลอกกลยุทธ์การเทรดของนักเทรดเก่งๆ ได้มากมาย โดยไม่ต้องวิเคราะห์ตลาดเอง เพียงแค่ตั้งค่าระบบ Copy Trading ก็สามารถคัดลอกออเดอร์จากนักเทรดที่มีผลงานดี เพื่อรับผลตอบแทนอย่างมั่นคง",  
		"Q4": "4.นักเทรดที่ใช้ระบบ Copy Trading สามารถใช้เครื่องมืออะไรได้บ้าง?",  
		"A4": "แพลตฟอร์ม EBC มอบเครื่องมือวิเคราะห์ต่างๆ เช่น สัญญาณการเทรด, ข้อมูลวิเคราะห์ 5 มิติ, ประวัติคำสั่งซื้อขาย และการจัดอันดับของนักเทรด ซึ่งจะช่วยให้คุณเข้าใจสภาพตลาด แบ่งปันกลยุทธ์ และช่วยเพิ่มประสิทธิภาพการเทรดของคุณ",  
		"Q5": "5.ความเสี่ยงของการใช้ระบบ Copy Trading",  
		"A5": "Copy Trading มีความเสี่ยงจากความผันผวนของตลาดและความเสี่ยงจากการเลือกติดตามนักเทรดที่มีผลการเทรดไม่ดี การเลือกนักเทรดที่พอร์ตมั่นคง และการใช้เครื่องมือจัดการความเสี่ยง สามารถลดความเสียหายที่อาจเกิดขึ้นได้",  
		"Q6": "6. สามารถปรับแต่งกลยุทธ์ Copy Trading ได้หรือไม่?",  
		"A6": "ได้, แพลตฟอร์ม EBC รองรับให้ผู้ใช้งานสามารถปรับแต่งกลยุทธ์การเทรดแบบ Copy Trading ได้อย่างอิสระและเป็นส่วนตัว"  
	},
	"box-name" : "copytrading-body en-copytrading-body en",
}