export default {
  股息调整: "Dividend Adjustments",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "Understand the dividend adjustment and allocation mechanism in your stock and index CFDs trading.",
  了解指数差价合约: "Learn Index CFDs",
  了解股票差价合约: "Learn Stock CFDs",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "Dividends are funds regularly distributed to shareholders from publicly listed companies' profits or retained earnings. When stocks pay dividends, related stock and index CFDs products also undergo corresponding dividend adjustments.",
  除息日与交易优化: "Ex-dividend Dates and Trading Optimisations",
  指数差价合约的除息日由指数提供商根据成分股设定:
    "The ex-dividend date for index CFDs is set by the index provider based on when the underlying stocks go ex-dividend. If you hold a long position, you’ll receive dividends; if you’re short, you’ll need to pay them. Knowing these dates in advance can help you manage your yields effectively.",
  股息调整明细: "Dividend Adjustment Details",
  差价合约交易中相关产品的股息数据:
    "The dividend data for related products in CFDs trading is displayed separately for long (buy) and short (sell) dividends.",
  交易产品: "Trading Products",
  多头股息: "Long Dividends",
  空头股息: "Short Dividends",
  除息日: "Ex-dividend Dates",
  即刻交易: "Trade Now ",
  股息的常见问题: "Dividend FAQ",
  qaList: [
    {
      q: "1. What are dividends and ex-dividend dates?",
      a: [
        "Dividends are funds regularly distributed to shareholders from the profits or retained earnings of publicly listed companies. When stocks pay dividends, related stock and index CFDs products will also undergo corresponding dividend adjustments.",
        "The ex-dividend date for index CFDs is set by the index provider based on the ex-dividend dates of the component stocks, which is also the dividend payment date.",
      ],
    },
    {
      q: "2. Timing of dividend adjustments",
      a: [
        "Dividend adjustments for index CFDs typically reflect in your MT4/MT5 trading account between 05:00 and 06:00 (GMT+3) on the ex-dividend date, labeled as 'Dividend.'",
      ],
    },
    {
      q: "3. Impacts of dividends on index trading",
      a: [
        "Holding a long position (buy): Dividend adjustments will be credited to your MT4/MT5 account.",
        "Holding a short position (sell): Dividend adjustments will be deducted from your MT4/MT5 account.",
      ],
    },
    {
      q: "4. Calculation formulas for dividend adjustments",
      a: [
        "Long position (buy) calculation formula: Lots × Contract Size × Long Dividend Amount (calculated in index currency).",
        "Short position (sell) calculation formula: Lots × Contract Size × Short Dividend Amount (calculated in index currency).",
      ],
    },
    {
      q: "5. Example of dividend adjustment calculation",
      a: [
        "For example, with SPXUSD, assuming the ex-dividend date is 11 October 2024, the long dividend amount is 0.050 USD, and the short dividend amount is -0.050 USD.If you hold a long position (buy) of 1 standard lot of SPXUSD at the close on the day before the ex-dividend date (10 October 2024), the calculation for the dividend adjustment is as follows:",

        "Dividend adjustment = 1 (standard lot) × 100 (contract size) × 0.050 USD (long dividend amount) = 5 USD.",

        "On the ex-dividend date, between 05:00 and 06:00 (GMT+3), 5 USD will be credited to your MT4/MT5 account, noted as 'Dividend.'",

        "If you hold a short position (sell), 5 USD will be deducted from your MT4/MT5 account, noted as 'Dividend.'",
      ],
    },
    {
      q: "6. Important Details ",
      a: [
        "Dividend adjustments apply only to index and stock CFDs trading and will adjust the account balance based on the position type (long or short). The dividend amounts and their impacts may vary depending on specific indices and market conditions. For any inquiries, please contact your account manager or visit our official website for more information.",
      ],
    },
  ],
};
