export default {
  "box-name": "en-account-page",
  "screen0": {
    "title": "Where Your Goals Begin",
    "desc": "เรามีบัญชีเทรดที่หลากหลาย</br>เหมาะสำหรับการเทรดทุกรูปแบบ"
  },
  "screen1": {
    "btn": "เปิดบัญชีเทรดทันที",
   "title1": "บัญชี premium",
    "title2": "บัญชี Pro",
    "line11": "1.1 pips",
    "line13": "0.0 pips",
    "line99": "ไม่จำกัด",
    "line100": "50USD",
    "5000USD":"5000USD",
    "list": [
      "สเปรดเริ่มต้น",
      "ค่าคอมมิชชั่น",
      "เลเวอเรจสูงสุด",
      "ล๊อตต่ำสุด",
      "ล๊อตสูงสุด",
      // "เงินฝากต่ำสุด",
      "Margin Level",
      "ขีดจำกัดระยะห่างสำหรับคำสั่งซื้อที่รอดำเนินการ",
      "อนุญาตให้ล๊อคตำแหน่บ",
      "อนุญาตให้ใช้ EAs ",
      "การสนับสนุนออนไลน์ตลอด 24 ชั่วโมง 5 วันทำการ",
      "ผู้จัดการสำหรับลูกค้าโดยเฉพาะ",
      "แนะนำบัญชีสำหรับ",
      "เปิดบัญชี"
    ]
  },
  "screen2": {
    "title": "เป็นส่วนหนึ่งของหลายล้านคนทั่วโลก",
    "desc": "3 ขั้นตอนง่ายๆ ในการเปิดบัญชี",
    "list": [{
      "title": "ลงทะเบียนเปิดบัญชี",
      "desc": "การส่งข้อมูลพื้นฐานบนหน้าการสมัครออนไลน์การันตีความปลอดภัยและไม่ยุ่งยาก"
    }, {
      "title": "การส่งข้อมูลส่วนบุคคล",
      "desc": "ใช้เวลาเพียง 1 นาทีก็เสร็จสมบูรณ์"
    }, {
      "title": "การฝากเงินและการเปิดใช้งาน",
      "desc": "ฝากเงินขั้นต่ำ USD 200 เพื่อเปิดใช้งานบัญชีของคุณทันที"
    }],
    "btn1": "ลองบัญชีเงินสมมุติฟรี",
    "btn2": "เปิดบัญชีเงินจริง →",
    "yuyan": "en"
  },
  "screen3": {
    "pay": "ตัวเลือกการฝากและถอนเงิน"
  },
  "screen4": {
    "enTitle": "ทำให้การเทรดของคุณง่ายกว่าที่เคย",
    "title": "ประสบการณ์ที่ไม่เคยมีมาก่อนในการจัดการการเทรด ",
    "desc": "เราทำให้เรื่องยากเป็นเรื่องง่าย เราเชื่อว่าความเรียบง่ายและความเป็นระเบียบเป็นปัจจัยที่สำคัญที่สุดในการบรรลุผลกำไรในระยะยาวในทุกตลาด EBC นำเสนอระบบ CRM เพื่อให้การซื้อขายของคุณมีการจัดการที่ดี",
    "imgUrl": "en"
  },
	"screen5": {
		"title": "บัญชีธุรกรรม",
		"register-btn": "เปิดบัญชีตอนนี้",
		"text1": "เลือกประเภทบัญชี",
		"text2": "EBC รวบรวมประเภทบัญชีที่หลากหลายเหมาะสำหรับนักเทรดทุกรูปแบบ เราพร้อมมอบประสบการณ์การเทรดที่ยืดหยุ่นและมีประสิทธิภาพให้แก่นักเทรดทุกท่าน",
		"text3": "บัญชีมาตรฐาน STD",
		"text4": "เหมาะสำหรับนักลงทุนและผู้ค้าทุนรายย่อยทุกราย",
		"text5": "เริ่มต้นสเปรดต่ำสุด <i>1.1</i> ทำให้การเทรดของคุณคุ้มค่ามากขึ้น",
		"text6": "เพลิดเพลินกับการทำธุรกรรมพร้อมส่วนลดค่าธรรมเนียม เพื่อต้นทุนที่โปร่งใสมากขึ้น",
		"text7": "ฝากเงินเร็วสุดใน <i>1</i> นาที",
		"text8": "บัญชีมืออาชีพ PRO ",
		"text9": "เหมาะสำหรับนักลงทุนมืออาชีพและนักเทรดระดับสูง",
		"text10": "มอบสภาพแวดล้อมการเทรดแบบ  <i>0</i>  สเปรด เหมาะกับนักเทรดมืออาชีพที่ต้องการต้นทุนการทำธุรกรรมขั้นต่ำ",
		"text11": "เพียง <i>$6</i>/lot ให้ข้อได้เปรียบด้านต้นทุนและความลึกเทียบได้กับบัญชีระดับสถาบัน",
		"text12": "ฝากเงินเร็วสุดใน <i>1</i> นาที",
		"text13": "เปรียบเทียบรายละเอียดของแต่ละบัญชี",
		"text14": "รายละเอียดความแตกต่างของบัญชีแต่ละประเภทจะช่วยให้คุณตัดสินใจเลือกบัญชีที่เหมาะกับกลยุทธ์ของตัวเองได้ง่ายขึ้น",
		"text15": "หมวดหมู่",
		"text16": "แนะนำบัญชีสำหรับ",
		"text17": "นักลงทุนทุกท่าน",
		"text18": "นักลงทุนมืออาชีพ",
		"text19": "เปิดบัญชีมาตรฐาน STD",
		"text20": "เปิดบัญชีมืออาชีพ PRO",
		"text21": "ข้อได้เปรียบ EBC",
		"text22": "ไม่ว่าคุณจะเลือกบัญชีมาตรฐาน STD หรือบัญชีมืออาชีพ PRO คุณจะได้เพลิดเพลินไปกับข้อได้เปรียบของแพลตฟอร์ม",
		"text23": "เปิดบัญชีด้วย 3 ขั้นตอนง่ายๆ",
		"text24": "เปิดบัญชีง่ายๆด้วย 3 ขั้นตอน พร้อมให้บริการผู้ใช้งานทุกรูปแบบ เริ่มต้นเส้นทางการลงทุนได้อย่างสะดวกและรวดเร็ว",
		"text25": "ดาวน์โหลดแพลตฟอร์ม",
		"text26": "เครื่องมือ MT4 เป็นแพลตฟอร์มการซื้อขายแลกเปลี่ยนเงินตราต่างประเทศชั้นนำระดับโลก เป็นที่รู้จักในด้านเครื่องมือสร้างกราฟที่ยอดเยี่ยม ตัวชี้วัดทางเทคนิคที่หลากหลาย และฟังก์ชันการซื้อขายอัตโนมัติไม่ว่าคุณจะใช้การวิเคราะห์เชิงลึกบน PC การซื้อขายแบบ real-time บนโทรศัพท์มือถือ หรือการตรวจสอบ real-time บนเว็บไซต์ เครื่องมือ MT4 สามารถรับประกันได้ว่าคุณจะได้รับประสบการณ์การซื้อขายที่ราบรื่นและยืดหยุ่น",
		"text27": "ดาวน์โหลดตอนนี้",
	}
}
