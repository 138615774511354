export default {
  fontFamily: "en-font-family",
  classLang: "en",
  headlist: [
    {
      text: "Inicio",
      url: "/",
    },
  ],
  立即开户: "Crear Cuenta",
  登录: "Iniciar Sesión",
};
