export default {
  linkList: [
    {
      title: "계정 및 조건",
      list: [
        {
          label: "거래 계정",
          link: "/TradingAccount",
        },
        {
          label: "거래 제품",
          link: "/tradingproduct",
        },
        {
          label: "레버리지 마진",
          link: "/leverage-margin",
        },
        {
          label: "입출금",
          link: "/funding",
        },
        {
          label: "피제수",
          link: "/dividend",
        },
      ],
    },
    {
      title: "트레이딩 도구",
      list: [
        {
          label: "MT4 다운로드",
          link: "/MT4",
        },
        {
          label: "MT5 다운로드",
          link: "/MT5",
        },
        {
          label: "MT4 거래 도구",
          link: "/tools",
        },
        {
          label: "PAMM",
          link: "/PAMM",
        },
        {
          label: "마케팅 분석",
          link: "/market-analysis",
        },
      ],
    },
    {
      title: "EBC 연구원",
      list: [
        {
          label: "교육 중심",
          link: "/LearningCenter",
        },
        {
          label: "데이터 중심",
          link: "/lab",
        },
      ],
    },
    {
      title: "최신 이벤트",
      list: [
        {
          label: "시장 활동",
          link: "/activity?index=0",
        },
        {
          label: "브랜드 관련",
          link: "/activity?index=1",
        },
      ],
    },
    {
      title: "EBC 소개",
      list: [
        {
          label: "WHY EBC",
          link: "/about-us",
        },
        {
          label: "법률 문서",
          link: "/LegalDocumen",
        },
        {
          label: "Sustainability & Impact",
          link: "/ESG",
        },
        {
          label: "FAQ",
          link: "https://support.ebchelp.com/hc/en-001",
        },
        {
          label: "공식 채널 인증",
          link: "/official-verification",
        },
      ],
    },
    {
      title: "협력 모드",
      list: [
        {
          label: "FC 바르셀로나",
          link: "/FCB",
        },
        {
          label: "임팩트 얼라이언스",
          link: "/affiliate-programmes",
        },
        {
          label: "기관 서비스",
          link: "/service",
        },
        {
          label: "기반 기술",
          link: "/technology",
        },
      ],
    },
    
  ],
  context: `
      <p>
        <span class="page-footer-intro-blod">
          EBC Financial Group은 다음과 같은 법인 그룹이 공유하는 공동
          브랜드입니다.<br />
        </span>
        EBC Financial Group(SVG) LLC는 세인트빈센트 그레나딘 금융 서비스
        당국(SVGFSA)의 승인을 받았으며 회사 등록 번호는 353 LLC 2020이며 등록
        주소는 Euro House, Richmond Hill Road, Kingstown, VC0100, St. Vincent
        and the Grenadines입니다.
       
      </p>
      <p>
        <span class="page-footer-intro-blod">기타 관련 엔티티:<br /></span>
        EBC Financial Group (UK) Limited는 영국 금융감독원(Financial Conduct
        Authority)의 허가와 규제를 받습니다. 라이선스 번호: 927552. 웹 사이트 :
        <a target="_blank" href="https://www.ebcfin.co.uk" rel="nofollow"
          >www.ebcfin.co.uk</a
        >
        <br />
        EBC Financial Group (Cayman) Limited는 케이맨 제도 통화 당국(라이선스
        번호: 2038223)의 허가 및 규제를 받습니다. 웹 사이트:
        <a target="_blank" href="https://www.ebcgroup.ky" rel="nofollow"
          >www.ebcgroup.ky</a
        >
        <br />
        EBC Financial (MU) Limited 모리셔스 금융서비스위원회(FSC)의 허가 및
        규제를 받는 금융기관입니다. (라이선스 번호: GB24203273) 등록 주소: 3rd
        Floor, Standard Chartered Tower, Cybercity, Ebene, 72201, Republic of
        Mauritius 본 기관의 웹사이트는 개별적으로 운영됩니다.
        <br />
        EBC Financial Group (Comoros) Limited 코모로 연방 앙주앙
        자치섬(Autonomous Island of Anjouan) 해외 금융 관리국의 허가를 받은
        금융기관입니다. (라이선스 번호: L 15637/EFGC) 등록 주소: Hamchako,
        Mutsamudu, Autonomous Island of Anjouan, Union of Comoros
        <br />
        EBC Financial Group (Australia) Pty Ltd (기업 등록 번호: 619 073 237)
        호주 증권투자위원회(ASIC)의 허가 및 규제를 받는 금융기관입니다. (규제
        번호: 500991) EBC Financial Group (SVG) LLC의 관계사로, 두 개의 독립적인
        법인은 개별적으로 운영 및 관리됩니다. 본 웹사이트에서 제공하는 금융 상품
        및 서비스는 호주 법인이 제공하는 것이 아니며, 해당 법인의 책임 범위에
        포함되지 않습니다.
      </p>
      <p>
        <span class="page-footer-intro-blod"> 회사 주소</span>
        The Leadenhall Building, 122 Leadenhall Street, London, United Kingdom,
        EC3V 4AB. 이메일 주소 :cs@ebc.com . 전화번호: +44 20 3376 9662
      </p>
      <p>
        <span class="page-footer-intro-blod">면책 조항:</span>EBC Financial
        Group은 암호화폐 활동에 참여하지 않으며 암호화폐와 관련된 CFD 서비스를
        제공하지 않습니다. 당사가 제공하는 금융 서비스는 전통적인 금융 상품으로
        제한됩니다. EBC의 이름으로 암호화폐 관련 서비스를 추천하는 것은 오해의
        소지가 있으며 승인되지 않은 무당도용임을 알립니다. 과거의 실적이 미래의
        실적을 나타내는 것은 아니며, 이 웹사이트의 내용은 정보 제공의 목적으로만
        사용되며 투자 조언을 포함하지 않습니다.
      </p>
      <p>
        <span class="page-footer-intro-blod">지역 제한:</span>EBC는 다음 지역의
        거주자에게 서비스를 제공하지 않습니다. 아프가니스탄, 벨라루스, 미얀마,
        캐나다, 중앙아프리카공화국, 콩고, 쿠바, 콩고민주공화국, 에리트레아,
        아이티, 이란, 이라크, 레바논, 리비아, 말레이시아, 말리,
        북한(조선민주주의인민공화국), 러시아, 소말리아, 수단, 남수단, 시리아,
        우크라이나(크림반도, 도네츠크 및 루한스크 지역 포함), 미국, 베네수엘라,
        예멘.자세한 내용은 FAQ(자주 묻는 질문)을 참고해 주세요.
        <br />
        웹사이트 내 모든 스페인어 콘텐츠는 라틴아메리카 국가를 대상으로 하며, EU
        및 스페인에는 해당 사항이 없습니다.
        <br />
        웹사이트의 모든 포르투갈어 콘텐츠는 브라질 및 아프리카 지역을 대상으로
        하며, EU 및 포르투갈에는 해당 사항이 없습니다.
      </p>
      <p>
        <span class="page-footer-intro-blod">규정 준수 공개:</span>당사의
        웹사이트는 전 세계에서 볼 수 있으며 특정 법인을 대상으로 하지 않습니다.
        귀하의 권리와 의무는 귀하의 관할권에 있는 규제 당국 및 해당 기관에 의해
        결정됩니다. 현지 법률 및 규정은 사이트에 게시된 문서 및 정보에 대한
        액세스, 다운로드, 배포, 공유 또는 사용을 제한하거나 금지할 수 있습니다.
      </p>
      <p>
        <span class="page-footer-intro-blod">위험 경고:</span
        >차액결제거래(CFD)는 복잡한 금융 상품이며 레버리지로 인해 빠르게 돈을
        잃을 위험이 높습니다. 마진 거래는 높은 수준의 위험을 수반하며 모든
        투자자에게 적합하지 않을 수 있습니다. 외환 및 CFD 거래를 결정하기 전에
        거래 목표, 경험 수준 및 위험 성향을 신중하게 고려해야 합니다. 초기 거래
        자본의 일부 또는 전부의 손실을 입을 가능성이 있습니다. 투자 결정을
        내리기 전에 독립적인 조언을 구하고 관련된 위험을 완전히 이해했는지
        확인하는 것을 권장합니다. 거래 전엔 관련 위험 공시 문구를 주의 깊게
        읽으십시오.
      </p>
  `,
};
