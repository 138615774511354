export default {
  交易领薪计划: "Trading Rewards Plan",
  为了鼓励交易者提升交易水平:
    "This rewards plan is designed specifically for exceptional traders!",
  加入交易社区:
    " Monthly bonuses are awarded based on the capital managed by the trader, with bonus rates reaching up to 18% per year.",
  即刻发起交易信号: "Trade Now ",
  无限奖励月月拿: "Unlimited Monthly Rewards",
  优享11个专属大礼: "Enjoy 11 Exclusive Gifts",
  即刻加入: "Join Now",
  交易员等级奖励机制: `Trader Level Rewards`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC offers five reward levels based on follower numbers and funds, without limiting the number of trades, and solely measures by market recognition.",
  "/月": "/per month",
  精英交易员: "Elite",
  管理资金量: "AUM",
  跟随账户数: "Followers",
  大师交易员: "Master",
  冠军交易员: "Champion",
  明星交易员: "All Star",
  资金管理费: "1% Management Fee",
  传奇交易员: "Legend",
  多层次的增值服务: "Multi-tiered Value-added Services",
  EBC提供多达11项增值服务:
    "We offer up to 11 value-added services, integrating traffic and media resources to comprehensively enhance signal influence.",
  "精英/大师交易员": "Elite/Master",
  "7×24小时优先客服": "24/7 Customer Service",
  金融投资经典书籍礼遇: "Free Investment Book Collection",
  专属VPS赞助: "Exclusive VPS Sponsorship",
  订单流软件1年使用权: "1-Year Access to Order Flow Software",
  EBC定制大礼包: "EBC Premium Gift Set",
  信号优先置顶展示: "Provider Display",
  受邀参观海外办公室: "Invitation to Visit International Offices",
  巴萨球星联名礼包: "FC Barcelona Star Player Gift Collection",
  巴萨赛场传奇之旅: "Legendary Barcelona Stadium Experience",
  品牌战略合作: "Strategic Brand Partnership",
  全球金融资源管家服务: "Global Financial Resource Management Services",
  五维指标评价系统: "Five-Dimensional Indicator Evaluation System",
  为交易员信号提供公开透明的评估:
    "Provides an open and transparent evaluation for trader signals, showcasing trading style, increasing exposure, and attracting more followers.",
  收益率: "Profit Rate",
  历史收益率: "Historical return rate; the higher, the better.",
  最大回撤: "Maximum Drawdown",
  衡量风控能力: "Measures risk control capability; the lower, the better.",
  获利系数: "Profit Factor",
  利润因子: "Profit factor; the higher, the stronger the profitability.",
  夏普比率: "Sharpe ratio",
  每份风险的收益: "Returns per unit of risk; the higher, the better.",
  标准差: "Standard Deviation",
  收益波动率: "Measures volatility; the lower, the more stable.",
  以上数据仅供参考: "*The above data is for reference only.",
  活动须知: "Activity Guidelines",
  list: [
    {
      label: "1. Tier Evaluation",
      value: [
        "AUM = Followed Funds + Trader’s Own Funds (as a Trading Signal Provider), calculated based on account balance.",
        "Follower Count: The number of unique copy trading Follower to a single Provider. If a follower has more than one trading accounts following the same provider, they are counted as one follower.",
        "The trader tier is updated daily. If the minimum requirement is met on each calendar day within the calendar month, the trader's rewards will be activated.",
      ],
    },
    {
      label: "2. Follower Rules",
      value: [
        "For a follower account to be valid, it must copy your trades with at least $500 USD invested.",
        "Each trading account can follow up to a maximum of 10 Providers",
        "The follower amount for each trading account = Follower Account Balance / Number of Follow Signals.",
      ],
    },
    {
      label: "3. Reward Distribution",
      value: [
        "Rewards are calculated and sent in the first week of every month, based on your lowest qualifying level from the previous month.",
        "Monthly Bonus = (Average Daily Managed Funds × Fund Management Fee Rate) ÷ 12",
        "Rewards go directly to your trading account, and you can withdraw the funds anytime.",
        "Disclaimer:EBC Financial Group retains the final decision-making for interpreting the rules and outcomes of this event.",
      ],
    },
  ],
  年化资金管理费: "Annual Management Fee",
};
