<template>
    <div class="QA-list">
        <div class="QA-item" v-for="(item, index) in data" :key="index" 
            :class="{ 'QA-actived': index == current }">
            <div class="QA-title" @click="checkout(index)">{{ item.q }}</div>
            <div class="QA-content">
                <p class="QA-li" v-for="(a, aIndex) in item.a" :key="aIndex">
                    {{ a }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            default: []
        }
    },
    data() {
        return {
            current: -1
        }
    },
    methods: {
        checkout(index) {
            if (index == this.current) {
                this.current = -1
            } else {
                this.current = index
            }
        }
    }
}
</script>

<style scoped>
.QA-list {
    width: 1024rem;
    margin: 0 auto;
    margin-top: 108rem;
}

.QA-item {
    padding: 22rem;
}

.QA-item:hover {
    box-shadow: 0 12px 18px -6px rgba(34, 56, 101, 0.12);
}

.QA-title {
    position: relative;
    color: #232222;
    font-size: 18rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    cursor: pointer;
    padding-right: 20rem;
}

.QA-title::after {
    position: absolute;
    right: 0;
    content: "\e61a";
    color: #949699;
    font-family: "iconfont" !important;
    font-size: 16rem;
    transition: all 0.3s;
}

.ar .QA-title {
    padding-left: 20rem;
    padding-right: 0;
}

.ar .QA-title::after {
    right: unset;
    left: 0;
}

.QA-actived .QA-title::after {
    transform: rotate(180deg);
}

.QA-content {
    margin-top: 12rem;
    display: none;
}
.QA-actived .QA-content{
    display: block;
}

.QA-li {
    position: relative;
    color: #6a6967;
    font-size: 18rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28rem;
    padding-left: 28rem;
}

.QA-li::before {
    content: "";
    position: absolute;
    left: calc(14rem - 2rem);
    top: calc(14rem - 2rem);
    width: 4rem;
    height: 4rem;
    background-color: #6a6967;
    border-radius: 50%;
}

.ar .QA-li {
    padding-left: 0;
    padding-right: 28rem;
}

.ar .QA-li::before {
    left: unset;
    right: calc(14rem - 2px);
}

@media (max-width: 996px) {

    .QA-list {
        width: 100%;
        margin-top: 70rem;
    }

    .QA-item:hover {
        box-shadow: none;
    }

    .QA-title {
        font-size: 22rem;
    }

    .QA-li {
        font-size: 20rem;
        line-height: 30rem;
    }
}
</style>