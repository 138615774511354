export default {
  logo:require("@/assets/image/win/logo_es.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "Desafío de Trading del Millón de Dólares de EBC II",
  免费复制冠军交易: `Copie las operaciones del <br/> campeón de forma gratuita.`,
  共赴巴萨颁奖盛典: `Celebre su victoria en <br/> Barcelona.`,
  EBC全球百万美元交易大赛: `El Desafío de Trading del Millón de Dólares de EBC regresa para reunir a los mejores traders del mundo en una batalla de habilidad y estrategia. Como socios oficiales del FC Barcelona, nos orgullece de llevar el espíritu del fútbol al mundo del trading, promoviendo la excelencia y la pasión que caracterizan a este club. Además de contar con el premio mayor más grande hasta la fecha, los ganadores recibirán una invitación exclusiva para asistir a la ceremonia de premiación en los emblemáticos campos del FC Barcelona, donde vivirán una experiencia única en la vida.`,
  大赛奖池: "Pozo de premio",
  距开赛: "Cuenta regresiva",
  距结束:"Termina Em",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replique las mejores operaciones con un solo clic",
  公开透明: "Transparencia",
  观摩冠军交易技巧: "Domine las técnicas de trading de los campeones",
  推荐有礼: "Recompensas por recomendación",
  推荐有奖上不封顶: "Recompensas ilimitadas por cada recomendación",
  双赛道机制: "Dos categorías diferentes del Desafío ",
  梦之队: "Dream Squad",
  梦之队HTML: "Dream Squad",
  以交易场的传控技术角逐百万: "Demuestre su dominio en el trading y gane $1.000.000",
  青训营: "Estrellas Emergentes",
  青训营HTML: "Estrellas Emergentes",
  "像天才新星释放天赋「一球成名」": "Opere como una estrella, muestra tu verdadero talento",
  冠军: "Campeón",
  亚军: "Subcampeón",
  季军: "Tercer lugar",
  第四至五名: "4° - 5° lugar",
  第六至十名: "6° - 10° lugar",
  "$1,000,000交易账号或$200,000现金": "Cuenta de trading de $1.000.000 o $200.000 en efectivo",
  立即参赛: "Inscríbase Ahora",
  巴萨荣耀之旅: "El camino del campeón hacia el FC Barcelona",
  每笔订单都是攻守博弈的艺术: `Cada operación representa un delicado equilibrio entre estrategia de ataque y defensa. Cada decisión puede marcar un hito decisivo en los mercados. El orgullo, la gloria y una experiencia inolvidable en el FC Barcelona esperan a los ganadores del Desafío de Trading del Millón de Dólares de EBC.`,
  邀您赴巴萨训练营: "Juegue como los profesionales",
  亲身体验冠军的致胜之旅: "Experimente en primera persona el camino hacia la victoria",
  于巴萨博物馆为您加冕: "Escriba su leyenda",
  巴萨颁奖典礼: "Adéntrese en la historia del Barça a través de una visita inmersiva al museo y celebre su legado en el trading en una ceremonia de premiación en el mismo museo",
  巴萨绿茵场现场观赛: "Experiencia VIP en los partidos locales",
  亲临VIP坐席见证球星逐梦诺坎普: "Vea a las estrellas del FC Barcelona buscar la victoria y dominar la liga, en vivo y en directo",
  大赛推荐官: "Programa de Recomendación",
  交易即享推荐奖励: "¡Opere y gane recompensas por recomendación, sin límites!",
  "/人": "/ persona",
  "3步推荐": "3 pasos sencillos: ¡recomiende, opere y gane!",
  推荐好友: "Recomiende amigos",
  好友交易: "Trading con amigos",
  赢得奖金: "Gane premios",
  立即推荐: "Recomienda ahora",
  实时赛况: "Tabla de clasificación en tiempo real",
  排名: "Puesto",
  昵称: "Nombre",
  账号: "Cuenta",
  收益额: "Ganancia",
  收益率: "Tasa de ganancia  (%)",
  奖金: "Premio",
  跟单: "Copy Trading",
  暂未开赛: "Próximamente, estén atentos",
  该页面仅展示收益表现排名前10的交易账号:
    "*Esta página muestra exclusivamente las diez mejores cuentas de trading clasificadas según su rendimiento en ganancias, con una tabla de clasificación en USD que se actualiza cada hora.",
  赛事进程: "Cronograma del evento",
  推荐开始: "Inicio del programa de recomendación",
  "2025年2月22日":"2025.2.22",
  比赛开始: "Inicio del desafio ",
  "2025年3月1日":"2025.3.1",
  推荐结束: "Finalización del Programa de Recomendación",
  "2025年5月23日":"2025.5.23",
  比赛结束: "Finalización del Desafío",
  "2025年5月30日":"2025.5.30",
  颁奖典礼: "Ceremonia de Premiación",
  "2025年":"2025",
  赛组规则: "Reglas del evento",
  类别: "Categorías",
  账号要求: "Requisitos de la cuenta",
  "新交易账号，且账号余额≥$500": "Una cuenta de trading nueva con un saldo inicial ≥$500",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "Una cuenta de trading nueva con un saldo inicial de $10.000 ≤ $200.000",
  排名规则: "Reglas de la clasificación",
  以收益率排名为评判标准: "Las posiciones se determinan en función de la Tasa de Ganancia.",
  "收益率=期末净值/账号累积入金": "Tasa de Ganancia= (Ganancia neta / Depósito acumulado)*100%",
  以收益额排名为评判标准: "Las posiciones se determinan en función de la ganancia neta",
  "收益额=期末净值+出金-入金": "Ganancia = Patrimonio final + Retiros - Depósitos",
  持仓限制: "Limitación de Posiciones:",
  每个参赛账号最多持有100手仓位: "Cada cuenta participante puede mantener como máximo 100 posiciones abiertas",
  无持仓限制: "Sin límites de posiciones",
  参赛条款: "Términos del evento",
  参赛须知: "Normas del evento",
  section6List: [
    {
      t: "1. Fecha del evento",
      c: [
        "Duración del evento: 1 de marzo de 2025, 01:00:00 (hora MT4/MT5) - 30 de mayo de 2025, 23:59:59 (hora MT4/MT5)",
      ],
    },
    {
      t: "2. Requisitos de la Cuenta",
      c: [
        "La cuenta debe estar registrada como una cuenta de señales a través de la herramienta de Copy Trading de EBC para permitir que otros copien operaciones de forma gratuita.",
        "Los participantes pueden utilizar múltiples cuentas en la competencia, pero solo se tomará en cuenta la cuenta con el mejor rendimiento para la clasificación.",
        "Para garantizar la equidad, solo podrán participar las cuentas STD y PRO; cuentas PAMM e Islámicas no son elegibles para participar.",
      ],
    },
    {
      t: "3. Reglas de Premiación",
      c: [
        "Premio para el ganador: Si el campeón opta por una cuenta de trading de 1 millón de dólares como premio, el límite máximo de pérdida será de 200 mil dólares. Los demás premios se asignarán en función de la posición obtenida.",
        
      ],
    },
    {
      t: "4. Conductas Prohibidas",
      c: [
        "Está estrictamente prohibido aumentar los beneficios mediante operaciones de arbitraje entre cuentas. Cualquier comportamiento ilegal, como mantener posiciones cruzadas a través de múltiples cuentas, realizar operaciones de cobertura o abusar de las cotizaciones, resultará en la descalificación del participante.",
        "Los pedidos causados por factores incontrolables, como fallos de hardware del servidor y errores de cotización, se considerarán inválidos.",
      ],
    },
    {
      t: "5. Descargo de responsabilidad",
      c: ["EBC Financial Group se reserva el derecho de tomar la decisión final sobre este evento"],
    },
  ],

  参赛流程: "Pasos para participar en el evento",
  注册开户: "Registrar una cuenta",
  注册并开设新交易账号: "Regístrese y abra una cuenta nueva de trading ",
  报名参赛: "Inscripción al evento",
  在用户中心报名参赛: "Inscríbase en el Desafío en el centro de usuarios",
  参赛成功: "Registro exitoso",
  参赛成功查看实时赛况: "Una vez registrado, siga su rendimiento en tiempo real",
  往届回顾: "Edición anterior",
  查看更多: "Ver más",
  常见问题: "Preguntas Frecuentes",
  qaList: [
    {
      t: "1. La differencia entre «Cuenta» y «Número de cuenta»",
      c: [
        "Cuenta: Se refiere a la cuenta del Centro de Usuarios de EBC (UID), cada usuario puede tener solo una cuenta tras la verificación de identidad.",
        "Número de cuenta: Se refiere a la cuenta MT trading. Se pueden crear varias cuentas de trading bajo la misma cuenta EBC para fines de trading.",
      ],
    },
    {
      t: "2. ¿Cómo se puede confirmar el estado de mi registro en el evento?",
      c: ["Una vez completado el registro con éxito, su cuenta y su posición actual serán visibles en la página del evento dentro del portal de usuarios."],
    },
    {
      t: "3. ¿Se permite participar con múltiples cuentas?",
      c: [
        "Puede participar en el evento con varias cuentas, pero cada una de ellas sólo puede inscribirse en una categoría.",
      ],
    },
    {
      t: "4. ¿Se pueden reclamar los premios de ambas categorías?",
      c: ["Sí, se pueden reclamar recompensas de ambas categorías siempre que se cumplan las normas y se completen ambos desafíos con éxito."],
    },
    {
      t: "5. ¿Se puede participar en el desafío si ya tengo una cuenta MT4?",
      c: [
        "Sí, siempre que la cuenta MT4 se haya creado a partir del 22 de febrero de 2025, a las 01:00:00 (hora MT4/MT5) y no tenga historial de trading previo.",
      ],
    },
    {
      t: "6. ¿Se modificará la categoría de participación si, después de unirse al grupo de Estrellas Emergentes, se realiza un nuevo depósito superior a 10,000 USD?",
      c: ["No, su categoría se determina en el momento del registro. Los cambios de fondo posteriores no afectarán a la categoría."],
    },
    {
      t: "7. ¿Cómo puedo verificar mi ranking?",
      c: [
        "El ranking se actualiza cada hora (no exactamente en punto) y puede ver su posición en la página de evento en el centro de usuarios.",
      ],
    },
    {
      t: "8. ¿Puedo depositar o retirar fondos durante el evento?",
      c: [
        "Sí, puedes depositar y retirar fondos. Los retiros no afectarán tu clasificación; sin embargo,  el depósito total para Dream Squad tiene un límite de $200,000. Si excedes este límite, serás descalificado.",
      ],
    },
    {
      t: "9. ¿Cómo se puede reclamar la recompensa por recomendación?",
      c: [
        "La recompensa por recomendación se acreditará en su saldo de bonificación 30 días después de que el usuario invitado complete su primera operación. Posteriormente, podrá solicitar su retiro, y la recompensa se procesará una vez aprobada.",
      ],
    },
    {
      t: "10. ¿Por qué los usuarios invitados no pueden cambiar a sus agentes durante el evento?",
      c: [
        "Para garantizar la equidad, los usuarios invitados sólo pueden unirse a través del enlace de recomendación y no se les asigna un agente específico.",
      ],
    },
    {
      t: "11. ¿Se hará pública la Información de mi cuenta de trading del concurso?",
      c: [
        "Sí, sus operaciones de trading serán visibles en la comunidad de copy trading. Los usuarios que no participan en el desafío pueden seguir y copiar sus estrategias de trading.",
      ],
    },
    {
      t: "12. Mi cuenta de trading es una cuenta de proveedor en el concurso. ¿Puedo utilizar esta cuenta para copiar operaciones de otros?",
      c: [
        "Para garantizar la equidad, no se permite que las cuentas de trading de proveedor utilicen la función de copy trading. Sin embargo, puedes crear una nueva cuenta de trading para acceder a esta función.",
      ],
    },
    {
      t: "13. ¿Qué sucede si dos participantes tienen la misma tasa de beneficio y ganancia?",
      c: [
        "Si dos participantes logran la misma tasa de beneficio o el mismo monto de ganancia, el ranking se determinará por el volumen de trading, y el participante con el volumen más alto tendrá el rango más alto.",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000:"$30.000",
  $10000:"$10.000",
  $5000:"$5.000",
  $3000:"$3.000",
  $2000:"$2.000",
  $1000:"$1.000",
  rankValueList: [
    "Cuenta de trading de $1.000.000 o $200.000 en efectivo",
    "$30.000",
    "$10.000",
    "$5.000",
    "$5.000",
    "$3.000",
    "$3.000",
    "$3.000",
    "$3.000",
    "$3.000",
  ],
  rankValueList2: [
    "$30.000",
    "$5.000",
    "$3.000",
    "$2.000",
    "$2.000",
    "$1.000",
    "$1.000",
    "$1.000",
    "$1.000",
    "$1.000",
  ],
  ebclink:"https://www.ebc.com/es/win1"
};
