export default {
  交易领薪计划: "基金经理成长计划",
  为了鼓励交易者提升交易水平:
    "这是一项专为优秀交易者打造的资产增值计划！",
  加入交易社区: "根据交易员的管理资金量发放月度奖金，奖金比例高达年化18%！",
  即刻发起交易信号: "即刻发起交易信号",
  无限奖励月月拿: "无限奖励月月拿",
  优享11个专属大礼: "优享11个专属大礼",
  即刻加入: "即刻加入",
  交易员等级奖励机制: `交易员等级奖励机制`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC基于跟随用户数和管理资金量提供五档奖励，不限制交易手数，仅以市场认可度为衡量标准",
  "/月": "/月",
  精英交易员: "精英交易员",
  管理资金量: "管理资金量",
  跟随账户数: "跟随用户数",
  大师交易员: "大师交易员",
  冠军交易员: "冠军交易员",
  明星交易员: "明星交易员",
  资金管理费: "+1%资金管理费",
  传奇交易员: "传奇交易员",
  多层次的增值服务: "多层次的增值服务",
  EBC提供多达11项增值服务:
    "EBC提供多达11项增值服务，整合流量和媒体资源，全面提升信号影响力。",
  "精英/大师交易员": "精英/大师交易员",

  "7×24小时优先客服": "7×24小时优先客服",
  金融投资经典书籍礼遇: "金融投资经典书籍礼遇",
  专属VPS赞助: "专属VPS赞助",
  订单流软件1年使用权: "订单流软件1年使用权",
  EBC定制大礼包: "EBC定制大礼包",
  信号优先置顶展示: "信号优先置顶展示",
  受邀参观海外办公室: "受邀参观海外办公室",
  巴萨球星联名礼包: "巴萨球星联名礼包",
  巴萨赛场传奇之旅: "巴萨赛场传奇之旅",
  品牌战略合作: "品牌战略合作",
  全球金融资源管家服务: "全球金融资源管家服务",
  五维指标评价系统: "五维指标评价系统",
  为交易员信号提供公开透明的评估:
    "为交易员信号提供公开透明的评估，展示交易风格，提升曝光率，从而吸引更多跟随者。",
  收益率: "收益率",
  历史收益率: "历史收益率，值越高越好。",
  最大回撤: "最大回撤",
  衡量风控能力: "衡量风控能力，越低风险越小。",
  获利系数: "获利系数",
  利润因子: "利润因子，越高盈利能力越强",
  夏普比率: "夏普比率",
  每份风险的收益: "每份风险的收益，越高性价比越高。",
  标准差: "标准差",
  收益波动率: "收益波动率，越低越稳定。",
  以上数据仅供参考: "*以上数据仅供参考。",
  活动须知: "活动须知",
  list: [
    {
      label: "1.等级评定",
      value: [
        "管理资金量：跟随账号资金量+交易员（交易信号）账号自有资金量，资金量均以余额计算。",
        "跟随用户数：指跟随该信号的EBC账户数，同一账户创建多个交易账号跟随同一信号，记为1个跟随用户。",
        "交易员等级每日更新，自然月内每个自然日均满足最低达标，即可激活交易员奖励。",
      ],
    },
    {
      label: "2.跟随规则",
      value: [
        "跟随金额≥500USD，计为1个有效跟随账号。",
        "每个交易账号最多跟随10个交易员（交易信号）。",
        "每个交易账号跟随金额=跟随账号余额/跟随信号数。",
      ],
    },
    {
      label: "3.奖励发放",
      value: [
        "次月第一周计算并发放上月奖励，按上月达标的最低等级计算奖励。",
        "月奖金=（日均管理资金×资金管理费率）÷12",
        "所有奖励发放至交易账号，获奖者自行提取。",
        "活动最终解释权归主办方EBC所有。",
      ],
    },
  ],
  年化资金管理费: "年化资金管理费",
};
