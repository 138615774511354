export default {
  fontFamily: "th-font-family",
  classLang: "th",
  headlist: [
    {
      text: "หน้าหลัก",
      url: "/",
    },
    {
      text: "การซื้อขาย",
      menu: [
        {
          tit: "บัญชีและเงื่อนไข",
          list: [
            {
              text: "บัญชีธุรกรรม",
              url: "/TradingAccount",
            },
            {
              text: "ซื้อขายสินค้า",
              url: "/tradingproduct",
            },
            {
              text: "เลเวอเรจมาร์จิ้น",
              url: "/leverage-margin",
            },
            {
              text: "ฝากและถอนเงิน",
              url: "/funding",
            },
            {
              text: "เงินปันผล",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "เครื่องมือการซื้อขาย",
          list: [
            {
              text: "ดาวน์โหลด MT4",
              url: "/MT4",
            },
            {
              text: "ดาวน์โหลด MT5",
              url: "/MT5",
            },
            {
              text: "เครื่องมือการซื้อขาย MT4",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "การวิเคราะห์ตลาด",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "EBCสถาบัน",
      menu: [
        {
          tit: "",
          list: [
            {
              text: "ศูนย์การเรียนรู้",
              url: "/LearningCenter",
            },
            {
              text: "ศูนย์ข้อมูล",
              url: "/lab",
            },
          ],
        },
      ],
    },
    
    {
      text: "เกี่ยวกับ EBC",
      menu: [
        {
          tit: "เกี่ยวกับ EBC",
          list: [
            {
              text: "WHY EBC",
              url: "/about-us",
            },
            {
              text: "Sustainability & Impact",
              url: "/ESG",
            },
            {
              text: "FAQ",
              url: "https://support.ebchelp.com/hc/en-001",
            },
            {
              text: "เหตุการณ์ล่าสุด",
              url: "activity",
            },
          ],
        },
        {
          tit: "โหมดความร่วมมือ",
          list: [
            {
              text: "สโมสรบาร์เซโลนา",
              url: "/FCB",
            },
            {
              text: "อิทธิพลที่มีต่อพันธมิตร",
              url: "/affiliate-programmes",
            },
            {
              text: "บริการสถาบัน",
              url: "/service",
            },
            {
              text: "เทคโนโลยีพื้นฐาน",
              url: "/technology",
            },
          ],
        },
      ],
    },
    {
      text: "Trading Challenge",
      url: "/million-dollar-challenge-2",
    },
  ],
  FCA开户: "บัญชี FCA",
  立即开户: "เปิดบัญชีฟรี",
  登录: "เข้าสู่ระบบ",
  官方验证: "ตรวจสอบเว็บไซต์",
  帮助中心: "คำถามที่พบบ่อย",
  帮助中心Url: "https://support.ebchelp.com/hc/en-001",
};
