export default {
	"box-name": "en-account-page",
	"screen0": {
		"title": "Where Your Goals Begin",
		"desc": "Chúng tôi cung cấp nhiều loại tài khoản giao dịch phù hợp với các phong cách giao dịch và tất cả các cấp độ kinh nghiệm."
	},
	"screen1": {
		"btn": "Đăng ký ngay",
		"title1": "Spread tiêu chuẩn",
		"title2": "Tài khoản Standard",
		"line11": "1.1 pips",
		"line13": "0.0 pips",
		"line99": "Unlimited",
		"line100": "100USD",
		"5000USD":"5000USD",
		"list": [
			"Spread tiêu chuẩn",
			"Hoa hồng",
			"Đòn bẩy tối đa",
			"Lots tối thiểu",
			"Lots tối đa",
			// "Số tiền nạp tối thiểu",
			"Mức ký quỹ",
			"Giới hạn khoảng cách lệnh chờ",
			"Cho phép Khóa Kho",
			"Cho phép EA",
			"Hỗ trợ trực tuyến 24/5",
			"CSKH riêng chuyên nghiệp",
			"Tài khoản thích hợp cho",
			"Mở tài khoản"
		]
	},
	"screen2": {
		"title": "",
		"desc": "3 bước đơn giản để mở tài khoản",
		"list": [{
			"title": "Đăng ký tài khoản",
			"desc": "Gửi thông tin cơ bản, đơn giản an toàn"
		}, {
			"title": "Gửi thông tin cá nhân",
			"desc": "Chỉ mất 1 phút để hoàn thành."
		}, {
			"title": "Gửi tiền và kích hoạt",
			"desc": "Đặt một khoản tiền gửi tối thiểu là 200 USD để kích hoạt tài khoản của bạn ngay lập tức."
		}],
		"btn1": "Dùng thử bản demo miễn phí",
		"btn2": "Mở một tài khoản thực",
		"yuyan": "vn"
	},
	"screen3": {
		"pay": "Deposit & Withdrawal Options"
	},
	"screen4": {
		"enTitle": "Your Trading Solution Has Been Never <span style='color: #800000;'>Easier",
		"title": "Trải nghiệm mới quản lý giao dịch",
		"desc": "Biến phức tạp thành đơn giản, chúng tôi tin rằng sự đơn giản kèm có trật tự là yếu tố quan trọng nhất để có được lợi nhuận lâu dài ở bất kỳ thị trường tài chính nào. EBC cung cấp cho khách hàng một hệ thống quản lý CRM, giúp giao dịch của bạn thuận tiện hơn.",
		"imgUrl": "en"
	},
	"screen5": {
		"title": "Tài khoản giao dịch",
		"register-btn": "Mở tài khoản ngay",
		"text1": "Vui lòng lựa chọn tài khoản phù hợp với bạn",
		"text2": "EBC cung cấp nhiều loại tài khoản khác nhau, phù hợp với nhiều nhà giao dịch khác nhau, đảm bảo trải nghiệm giao dịch linh hoạt và hiệu quả.",
		"text3": "Tài khoản Standard",
		"text4": "Thích hợp cho tất cả các nhà đầu tư và nhà giao dịch vốn thấp",
		"text5": "Bắt đầu với mức chênh lệch thấp tới  <i>1.1</i> giúp giao dịch của bạn tiết kiệm chi phí hơn",
		"text6": "Tận hưởng ưu đãi  <i>0</i> % phí giao dịch, chi phí minh bạch hơn",
		"text7": "Kích hoạt ngay, nạp tiền chỉ trong <i>1</i> phút",
		"text8": "Tài khoản Professional",
		"text9": "Thích hợp cho nhà đầu tư chuyên nghiệp và  người giao dịch cao cấp",
		"text10": "Cung cấp môi trường giao dịch với spread bằng  <i>0</i> , phù hợp cho các nhà giao dịch chuyên nghiệp đang tìm kiếm chi phí giao dịch tối thiểu",
		"text11": "Chỉ với <i>$6</i>/lot, mang lại lợi thế về chi phí và tính thanh toán với tài khoản doanh nghiệp",
		"text12": "Kích hoạt ngay, nạp tiền chỉ trong <i>1</i> phút",
		"text13": "So sánh chi tiết tài khoản",
		"text14": "Sự khác biệt chính giữa từng loại tài khoản giúp bạn chọn tài khoản phù hợp với chiến lược giao dịch của mình",
		"text15": "Loại",
		"text16": "Tài khoản thích hợp cho",
		"text17": "Toàn bộ nhà đầu tư",
		"text18": "Nhà đầu tư chuyên nghiệp",
		"text19": "Mở tài khoản Standard",
		"text20": "Mở tài khoản Professional",
		"text21": "Ưu thế EBC",
		"text22": "Cho dù bạn chọn tài khoản Standard hay tài khoản chuyên nghiệp Professional, bạn đều có thể tận hưởng những lợi thế cốt lõi của nền tảng",
		"text23": "3 bước đơn giản mở tài khoản",
		"text24": "Mở tài khoản dễ dàng với 3 bước, cùng sự hỗ trợ dịch vụ khách hàng đầy đủ, hãy nhanh chóng bắt đầu hành trình giao dịch của bạn",
		"text25": "Tải nền tảng giao dịch ngay",
		"text26": "MT4 là nền tảng giao dịch ngoại hối hàng đầu thế giới, được biết đến với các công cụ biểu đồ tuyệt vời, chỉ báo kỹ thuật phong phú và chức năng giao dịch tự động, Cho dù bạn sử dụng phân tích chuyên sâu trên PC, giao dịch theo thời gian thực trên điện thoại di động hay theo dõi thời gian thực trên web, MT4 đảm bảo bạn có thể tận hưởng trải nghiệm giao dịch suôn sẻ và linh hoạt.",
		"text27": "Tải xuống ngay",
	}
}