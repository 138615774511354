export default {
  leftNav: [
    { id: "#Trading", name: "取引" },
    { id: "#Regulation", name: "金融規制" },
    { id: "#Security", name: "資金安全" },
    { id: "#Fintech", name: "テクノロジー" },
    { id: "#Black-Box", name: "ブラックボックス" },
    { id: "#Private-Room", name: "取引環境" },
    { id: "#Liquidity", name: "流動性" },
    { id: "#Honor", name: "受賞履歴" },
    //{ "id": "#News", "name": "ニュース" }
  ],
  pages1: {
    h1: "Trust & Respect",
    title: "すべてのトレーダーに、誠実な対応を。",
    btn: " 動画を視聴する ",
  },
  pages2: {
    title: "お客様に最適なFX取引プラットフォーム",
    title1: "幅広い取引商品と魅力的なスプレッドで取引優位性を探求します。",
    btn: "取引商品一覧はこちら",
    text: " 外国為替取引 ",
    text1: "コモディティCFD",
    text2: "株価指数CFD",
    text3: "個別株CFD",
  },
  pages3: {
    title: "トップレベルの金融ライセンス",
    title1:
      "EBC Financial Group 傘下の子会社は、各所在地域のライセンスを保有し、認可を受けています。",
    btn: "詳しくはこちら",
    text: "英国 FCA ライセンス",
    text1:
      "EBC Financial Group (UK) Ltdは、英国金融規制当局（FCA）の認可を受け、規制を遵守しています。登録番号：927552",
    text4: "ケイマン諸島金融庁（CIMA）ライセンス",
    text5:
      "EBC Financial Group (Cayman) Ltdはケイマン諸島金融庁（CIMA）の認可を受け、規制を遵守しています。登録番号：2038223",
    text2: "オーストラリアASICライセンス",
    text3:
      "EBC Financial Group (Australia) Pty Ltdは、オーストラリア証券投資委員会の認可を受け、規制を遵守しています。登録番号：500991",
  },
  pages4: {
    title: "テクノロジーで取引の可能性を広げる",
    title1:
      "東京、ロンドン、ニューヨークなどの主要金融取引市場に専用サーバーを設置しています。また、専用光回線を活用して、約定力を高めます。",
    btn: " 詳しくはこちら",
    text: "1秒あたりの最大注文数 ",
    text1: "迅速な約定スピード ",
    text2: "データ通信最大安定度 ",
    text3: "最低スプレッド 0.0pips",
  },
  pages5: {
    title: "85%以上の注文がより有利な価格で約定",
    title1:
      "EBCブラックボックスは独自のアルゴリズムを使うことによって、従来の取引システムと比べても、お客様にとってより有利な約定価格と高い約定率を実現可能になります。",
    btn: "詳しくはこちら",
  },
  pages6: {
    title: "EBCプライベートルーム",
    title1:
      "EBCの「Private Room」とは、高頻度取引者（HFT）や大口顧客向け専用の取引チャネルのことです。このサービスは、取引に相応の流動性を提供することによって、大きなスリッページや約定拒否が発生することなく、そのうえ有利な価格で約定されることもあります。",
    btn: "詳しくはこちら",
  },
  pages7: {
    title: "トップレベルの流動性",
    title1:
      "EBCは世界有数のプライムブローカーと長期的な関係を築いております。トップレベルの流動性プールと連携することで、お客様が安定した取引環境と競争力のあるスプレッド水準でお取引いただけます。",
    btn: "詳しくはこちら",
  },
  pages8: {
    btn: "その他のニュース",
  },
  pages9: {
    title: "安心の総合的プロテクション",
    title1:
      "EBC は資金の安全を最優先事項と考えています。 顧客の資金はバークレイズ銀行の分離保管口座に保管されます。 FCA、金融委員会、ロイズ オブ ロンドン、AON と協力することで、EBC は包括的で多層的なセキュリティ メカニズムを確立しました。",
    btn: "詳しくはこちら",
  },
  lang: "ja",

  连接全球热门交易产品: "お客様に最適なFX取引プラットフォーム",
  探索一个充满机遇和竞争优势的世界:
    "幅広い取引商品と魅力的なスプレッドで取引優位性を探求します。",
  衍生品交易: "外国為替取引",
  期货CFDs: "コモディティCFD",
  全球股票指数CFDs: "株価指数CFD",
  美国股票CFDs: "個別株CFD",
  "欧元/美元": "EURUSD",
  "黄金/美元": "XAUUSD",
  道琼斯工业指数: "U30USD",
  了解交易产品: "取引商品一覧はこちら",
  全球顶级监管: "トップレベルの金融ライセンス",
  EBC金融集团旗下各公司:
    "EBC Financial Group 傘下の子会社は、各所在地域のライセンスを保有し、認可を受けています。",
  授权及监管于: "保有ライセンス規制機関",
  监管号: "登録番号",
  英国FCA监管: "英国 FCA ライセンス",
  "英国金融行为监管局 (FCA)": "英国金融規制当局（FCA）",
  开曼CIMA监管: "ケイマン諸島金融庁（CIMA）ライセンス",
  "开曼群岛金融管理局(CIMA)": "ケイマン諸島金融庁（CIMA）",
  澳洲ASIC监管: "オーストラリアASICライセンス",
  澳大利亚证券和投资委员会: "オーストラリア証券投資委員会（ASIC） ",
  了解EBC官方: "詳しくはこちら",
  "全面保障 安心以致": "安心の総合的プロテクション",
  EBC将资金安全视为首要原则:
    "EBC は資金の安全を最優先事項と考えています。 顧客の資金はバークレイズ銀行の分離保管口座に保管されます。 FCA、金融委員会、ロイズ オブ ロンドン、AON と協力することで、EBC は包括的で多層的なセキュリティ メカニズムを確立しました。",
  了解安全保障: "詳しくはこちら",
  "连接全球 信赖首选": "世界水準の信頼性",
  EBC构建全方位服务生态:
    "当社は30年以上の経験を持つ専門家チームに支えられ、包括的なサービスエコシステムを構築してきました。世界中で展開している事業と活発で幅広いユーザーベースにより、当社は国際社会におけるリーダーとして高く評価されています。",
  全球注册用户数: "全世界での利用者数",
  日均交易订单量: "日次平均注文回数",
  "2024年第三季度为交易订单优化增益": "注文最適化後の増益（2024年度第3四半期）",
  多个: "+",
  "业务覆盖国家/地区": "サービス提供国/地域",
  了解全球视野: "詳しくはこちら",
  "85%以上": "「85％以上の注文がより良い価格で約定」",
  的订单成交于更优价格: "",
  EBC交易黑盒:
    "当社の取引ブラックボックスは、注文アルゴリズムを正確に最適化し、価格の最適化、注文執行率、アルゴリズムの安定性において従来の取引システムよりもはるかに優れています。",
  每秒聚合订单: "1秒あたりの最大注文数",
  极速订单执行: "迅速な約定スピード",
  数据传输稳定高达: "データ通信最大安定度",
  "行级RAW ECN点差": "最低スプレッド 0.0pips",
  了解金融科技: "詳しくはこちら",
  EBC全球合作伙伴: "グローバルパートナーシップ",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "当社は25以上の大手グローバル銀行およびノンバンクと長期的かつ安定的な関係を確立し、機関投資家向けの流動性とスプレッドを0.0Pipsから提供しています。さらに、金融サービスの枠を超えて、当社はオックスフォード大学との提携、WAGGGSとのパートナーシップ、平等で持続可能な社会を構築するための国連財団への協賛などを通じて、企業の社会的責任を積極的に果たしています。",
  了解更多: "さらに詳しく",
  十大最受欢迎券商: "人気ブローカーTOP10",
  最透明交易券商: "最も透明性の高いブローカー",
  最受好评券商: "最高評価のブローカー",
  卓越执行券商: "最良執行ブローカー賞",
  全球最佳交易体验奖: "グローバルベストトレーディングエクスペリエンス賞",
  最受信赖券商: "最も信頼されるFXブローカー賞",
  最佳CFD券商: "ベストCFDブローカー賞",
  全球最佳ECN券商: "グローバルベストECNブローカー",
  全球新锐券商: "グローバル新鋭ブローカー",
  tabList: [
    `Forex`,
    `Commodities`,
    `Index CFDs`,
    `Stock CFDs`,
  ],
  出售: "Ask",
  买入: "Bid",
  点差: "Spread",
  立即交易: "Trade",
  致力于让交易者成为盈家: "世界中のトレーダーをエンパワー",
  EBC每个引以为傲的数据背后:
    "EBCの優れた取引環境と信頼できる取引システムは世界100カ国以上のお客様をエンパワーメントしています。",
  月均交易量: "月次平均取引高（米ドル）",
  全球注册用户数: "全世界での利用者数",
  "2024年第四季度为交易订单优化增益": "注文最適化後の増益（2024年度第4四半期）",
  "业务覆盖国家/地区": "サービス提供国/地域",
  number1: "177.5",
  number1Text: "177.5",
  number2: "4",
  number2Text: "4",
  number3: "273",
  number3Text: "2.73",
  number4: "100",
  number4Text: "100",
  langFlag: "en",
  亿: "B+",
  万: "M+",
  "+": "+",
  全球百万美金交易大赛Ⅱ: "第2回EBC100万ドル取引大会",
  免费复制冠军收益: `上位の取引を無料でコピー`,
  免费复制:"今すぐコピ",
  立即参赛:"今すぐ口座開設",
  该页面仅展示收益表现排名前5的交易账号:`
  *このページでは、実績トップ5の取引口座のみを表示します。米ドルで表示され、1時間毎に自動更新されます。<br/>
*ご自身のランキング順位はマイページにログインしたあとに、ご確認いただけます。
  `
};
