export default {
  多元活动连接全球市场: "Diverse Events Connecting Global Markets",
  共享全球顶级资源与学术支持:
    "Access top global resources and academic support to kickstart your trading journey with confidence.",
  了解更多: "Learn More",
  敬请期待: "Stay tuned",
  每个认真交易的人: "Exceptional Brilliant Care for Every Committed Trader",
  activityListMetaData: [
    {
      label: "Market Activities",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_en.png"),
          title: "EBC's Million Dollar Trading Challenge II",
          subtitle: "Copy the Champion's Trades for Free",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        // {
        //   id: "2-10",
        //   img: require("@/assets/image/activityList/messi_en.png"),
        //   title: "Trade with EBC & Win a Limited Edition Messi Signed Jersey",
        //   subtitle: "Event Period: 1 March 2025 - 31 March 2025",
        //   url: "/trade-for-messi",
        //   time: "",
        // },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7_en.png"),
          title:
            "EBC Partners with TradingView to Connect You to Global Markets",
          subtitle:
            "Limited-time Offer: Unlock TradingView Membership Benefits and Stay on Top of Global Market Trends in Real-time",
          url: "/TradingView",
          time: "",
        },

        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_en.png"),
          title: "Creator Incentive Programme",
          subtitle:
            "Create Original Financial Insights and Enjoy Exclusive Rewards.",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-4",
          img: require("@/assets/image/activityList/banner2_4_en.png"),
          title: "Trading Rewards Plan",
          subtitle:
            "This rewards plan is designed specifically for exceptional traders! Monthly bonuses are awarded based on the capital managed by the trader, with bonus rates reaching up to 18% per year.",
          url: "/pay",
          time: "",
        },
        {
          id: "2-1",
          img: require("@/assets/image/activityList/banner2_1_en.png"),
          title: "1 Million Trading Contest ",
          subtitle: "Win 1 Million: Searching for Trading King.",
          url: "/win1",
          time: "",
        },
      ],
    },
    {
      label: "Brand Updates",
      list: [
        {
          id: "3-4",
          img: require("@/assets/image/activityList/banner3_4_en.png"),
          title: "EBC Joins the iFX EXPO LATAM 2025 Line-up",
          subtitle:
            "EBC Financial Group is proud to participate in iFX EXPO LATAM 2025 as a Diamond Sponsor, highlighting our commitment to global financial innovation. Join us to explore market trends, investment opportunities, and the future of trading.",
          url: "",
          time: "9-10 April 2025",
        },
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_en.png"),
          title: "Highlights: EBC at Money Expo Mexico 2025",
          subtitle:
            "EBC proudly participated as a Platinum Sponsor at Money Expo Mexico 2025, connecting with industry experts. Together, we explored fintech innovations and emerging trading opportunities.",
          url: "/LearningCenterInfo?id=LEARN-782-60B",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_en.png"),
          title:
            "How to Use Climate Economics to Explore Trading Opportunities",
          subtitle: `"What Do Economists Actually Do?" A Timely Discussion on Driving Policy Improvements and Economic Growth with Economics.`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_en.png"),
          title: "EBC & Oxford Collaboration for the Seminar",
          subtitle: `This Seminar will be held at Christ Church College, Oxford University, on 14 November 2024 at 14:30 (GMT), with live streaming available globally for all investors.`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
