export default {
  "box-name": "en-account-page",
  "screen0": {
    "title": "Where Your Goals Begin",
    "desc": "We offer a variety of trading accounts to suit different <br>trading styles and all levels of experience."
  },
  "screen1": {
    "btn": "Register Now",
    "title1": "Standard Account",
    "title2": "Professional Account",
    "line11": "1.1 pips",
    "line13": "0.0 pips",
    "line99": "Unlimited",
    "line100": "100USD",
    "list": [
      "Spread From",
      "Commission",
      "Max Leverage",
      "Min. Lots",
      "Max. Lots",
      // "Min. Deposit",
      "Maintenance Margin Level",
      "Distance Limit for Pending Orders",
      "Locked Position Allowed",
      "EAs Allowed",
      "24/5 Online Support",
      "Dedicated Client Manager",
      "Recommended for ",
      "Open an account"
    ]
  },
  "screen2": {
    "title": "Become one of millions around the world",
    "desc": "3 Easy Steps To Open An Account",
    "list": [{
      "title": "Account Registration",
      "desc": "Submit basic information on the safe and straight-forward online application page."
    }, {
      "title": "Submission of Personal Information",
      "desc": "Just takes 1 minute to complete."
    }, {
      "title": "Deposit and Activation",
      "desc": "Place a minimum deposit of USD 200 to activate your account instantly."
    }],
    "btn1": "Try a Free Demo",
    "btn2": "Open a Live Account →",
    "yuyan": "en"
  },
  "screen3": {
    "pay": "Deposit & Withdrawal Options"
  },
  "screen4": {
    "enTitle": "Your Trading Solution Has Never Been <span style='color: #800000;''> Easier</span>",
    "title": "Unprecedented experience in trading management",
    "desc": "Simplicity for sophistication. We firmly believe that simplicity and order are the most critical factors to achieve long-term profitability in any market. EBC offers a dedicated CRM system to keep your trading well organized.",
    "imgUrl": "en"
  },
	"screen5": {
		"title": "Trading Accounts",
		"register-btn": "Register Now",
		"text1": "Choose Your Account Type",
		"text2": "EBC offers a variety of account types to cater to different trading requirements.",
		"text3": "Standard Account",
		"text4": "Recommended for investors of all levels and small-scale traders.",
		"text5": "Enjoy spreads as low as <i>1.1</i> pips.",
		"text6": "Enjoy <i>0</i> commission and more transparent costs.",
		"text7": "Start with an initial deposit as low as <i>$100</i>.",
		"text8": "Professional Account",
		"text9": "Recommended for both professional investors and seasoned traders.",
		"text10": "Experience <i>0</i> spreads to minimize your trading costs.",
		"text11": "<i>$6</i> per lot fee, similar to costs and liquidity for corporate accounts.",
		"text12": "Minimum initial deposit: <i>$5000</i>.",
		"text13": "Simplifying Account Rules Comparison",
		"text14": "Key differences between account types to guide you in selecting the best fit for your trading strategy",
		"text15": "Type",
		"text16": "Recommended for",
		"text17": "All investors",
		"text18": "Professional investors",
		"text19": "Open A Standard Account",
		"text20": "Open A Professional Account",
		"text21": "EBC Advantages",
		"text22": "Either account enables you to benefit from our distinction.",
		"text23": "3 Easy Steps To Open An Account",
		"text24": "Open an account in just 3 simple steps, backed by our dedicated customer service team to support your trading journey promptly.",
		"text25": "Download App Now",
		"text26": "MT4 is the world's leading FX trading platform that features professional charting tools, techinical indicators and automated trading. You are guaranteed a quick and smooth experience through its app for PC, mobile or web.",
		"text27": "Download Now",
	}
}
