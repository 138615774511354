import Vue from "vue";
import Vuex from "vuex";
import request from "@/serve/index";
import i18n from "@/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Lan: "zh_CN",
    title: "简体中文",
    khUrl: "https://client.ebccrm.com/signup",
    loginUrl: "https://client.ebccrm.com/",
    mnUrl: "https://client.ebccrm.com/simulation",
    showModal: false,
    suffix: "",
    language: "", //当前语言 ['zh', 'ct', 'en', 'ja', 'ko', 'th', 'vn',]
  },
  mutations: {
    setPosition(state, val) {
      state.Lan = val;
      let parms;
      if (val === "zh_TW") {
        parms = "tw";
      } else if (val === "ja_JP") {
        parms = "jp";
      } else if (val === "weiwuer") {
        parms = "zh";
      } else if (val === "zh_CN") {
        parms = "zh";
      } else if (val === "ko_KR") {
        parms = "ko";
      } else if (val === "en_US") {
        parms = "en";
      } else if (val === "th_TH") {
        parms = "th";
      } else if (val === "vn") {
        parms = "vi";
      }
      state.khUrl = "https://client.ebccrm.com/signup?hl=" + parms;
      state.loginUrl = "https://client.ebccrm.com/?hl=" + parms;
      state.mnUrl = "https://client.ebccrm.com/simulation?hl=" + parms;
    },
    setTitle(state, val) {
      state.title = val;
    },
    setShowModal(state, val) {
      state.showModal = val;
    },
    setSuffix(state, val) {
      state.suffix = val;
    },
    getLanguage(state) {
      console.log("in getLanguage", localStorage.getItem("language"));
      if (state.language) {
        return state.language;
      }
      if (localStorage.getItem("language")) {
        return localStorage.getItem("language");
      } else {
        return "zh";
      }
    },
    setLanguage(state, value) {
      localStorage.setItem("language", value);
      state.language = value;
      // console.log("value", value);
      // console.log("i18n.locale", i18n.locale);
      if (i18n.locale != value) {
        i18n.locale = value;
      }
    },
  },
  actions: {
    checkoutLanguage(context, { route }) {
      if (context.state.language) {
      } else if (localStorage.getItem("language")) {
        context.commit("setLanguage", localStorage.getItem("language"));
      } else {
        request("/ebc/api/getIPInfo/run").then((res) => {
          console.log("checkoutLanguage res", res);
          if (res.data.data.country == "日本") {
            context.commit("setLanguage", "ja");
          } else if (res.data.data.country == "韩国") {
            context.commit("setLanguage", "ko");
          } else if (res.data.data.country == "美国") {
            context.commit("setLanguage", "en");
          } else if (res.data.data.country == "泰国") {
            context.commit("setLanguage", "th");
          } else if (res.data.data.country == "越南") {
            context.commit("setLanguage", "vn");
          } else if (res.data.data.country == "中国") {
            if (res.data.data.province == "台湾") {
              context.commit("setLanguage", "ct");
            } else {
              context.commit("setLanguage", "zh");
            }
          }
        });
      }
    },
  },
  modules: {},
  getters: {
    FCAUrl(state) {
      return "/FCA";
    },
    signupUrl(state) {
      return "https://client.myebc.co/signup-c/?hl=" + state.language;
    },
    loginUrl(state) {
      // https://client.ebccrm.com/
      return "https://client.ebccrm.com/signin-c?hl=" + state.language;
    },
    language(state) {
      return state.language;
    },
  },
});
