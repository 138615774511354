export default {
  logo: require("@/assets/image/win/logo_id.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "EBC's Million Dollar Trading Challenge II",
  免费复制冠军交易: "Salin Perdagangan Juara Secara Gratis",
  共赴巴萨颁奖盛典: "Rayakan Kemenangan Anda di Barcelona",
  EBC全球百万美元交易大赛: `Kompetisi Trading Global EBC senilai satu juta dolar mengumpulkan trader terbaik dari seluruh dunia. Sebagai mitra resmi FC Barcelona, kami menghadirkan semangat sepak bola ke dunia trading, menginspirasi keunggulan dan dedikasi. Baik Anda trader berpengalaman atau pemula, Anda bisa meniru keuntungan para juara. Pemenang akan diundang ke acara penghargaan di Barcelona untuk merasakan perpaduan antara kejayaan trading dan warisan sepak bola.`,
  大赛奖池: "Total Hadiah",
  距开赛: "Hitung Mundur",
  距结束:"Berakhir Pada",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Replikasi Trading Terbaik Dengan Satu Klik",
  公开透明: "Transparansi",
  观摩冠军交易技巧: "Kuasai Teknik Trading Sang Juara",
  推荐有礼: "Ajak Teman dan Dapatkan Hadiah",
  推荐有奖上不封顶: "Dapatkan $300 untuk setiap Teman yang diajak Tanpa Batas!",
  双赛道机制: "Dua Kategori Tantangan yang Berbeda",
  梦之队: "Tim Impian",
  梦之队HTML: "Tim Impian",
  以交易场的传控技术角逐百万:
    "Tunjukkan keahlian trading Anda dan raih hadiah $1.000.000! ",
  青训营: "Bintang Baru",
  青训营HTML: "Bintang Baru",
  "像天才新星释放天赋「一球成名」":
    "Trading seperti bintang, tunjukkan bakat terbaik Anda! ",
  冠军: "Juara 1 ",
  亚军: "Juara 2",
  季军: "Juara 3",
  第四至五名: "Juara 4-5",
  第六至十名: "Juara 6-10",
  "$1,000,000交易账号或$200,000现金":
    "Akun Trading 1.000.000 USD atau Uang Tunai 200.000 USD ",
  立即参赛: "Gabung Sekarang",
  巴萨荣耀之旅: "Jalan Sang Juara menuju FC Barcelona",
  每笔订单都是攻守博弈的艺术: `Setiap trade adalah keseimbangan sempurna antara serangan dan pertahanan. Setiap keputusan yang Anda buat bisa menjadi gol penentu di pasar. Kebanggaan, kejayaan, dan pengalaman tak terlupakan bersama FC Barcelona menanti para pemenang EBC Million Dollar Trading Challenge`,
  邀您赴巴萨训练营: "Bermain Seperti Profesional",
  亲身体验冠军的致胜之旅:
    "Rasakan perjalanan para juara dan pelajari apa yang dibutuhkan untuk meraih kemenangan! ",
  于巴萨博物馆为您加冕: "Ciptakan Kisah Suksesmu",
  巴萨颁奖典礼:
    "Jelajahi sejarah Barça dengan tur museum yang mendalam, lalu rayakan pencapaian trading Anda dalam acara penghargaan eksklusif di museum.",
  巴萨绿茵场现场观赛: "Pengalaman Menyaksikan Pertandingan VIP FC Barcelona",
  亲临VIP坐席见证球星逐梦诺坎普:
    "Saksikan bintang-bintang FC Barcelona mengejar kemenangan dan mendominasi liga, langsung dari dekat.",
  大赛推荐官: "Program Referal",
  交易即享推荐奖励: "Trading & Dapatkan $300 Per Referral Tanpa Batas!",
  "/人": " / Orang",
  "3步推荐":
    "3 Langkah Sederhana: Referensikan, Perdagangkan, dan Dapatkan Keuntungan!",
  推荐好友: "Rekomendasikan ke Teman Anda",
  好友交易: "Bertransaksi dengan Teman",
  赢得奖金: "Menangkan Hadiah",
  立即推荐: "Rekomendasikan Sekarang",
  实时赛况: "Papan Peringkat Real Time",
  排名: "Peringkat ",
  昵称: "Nama Panggilan",
  账号: "Nomor Akun",
  收益额: "Keuntungan",
  收益率: "Rasio Keuntungan (%)",
  奖金: "Hadiah",
  跟单: "Copy Trading",
  暂未开赛: "Segera Hadir, Nantikan",
  该页面仅展示收益表现排名前10的交易账号:
    "*Halaman ini hanya menampilkan sepuluh akun trading teratas yang diperingkat berdasarkan performa keuntungan, dengan papan peringkat dalam USD dan diperbarui setiap jam",
  赛事进程: "Timeline Acara",
  推荐开始: "Program Referensi Dimulai",
  "2025年2月22日": "2025.2.22",
  比赛开始: "Tantangan Dimulai",
  "2025年3月1日": "2025.3.1",
  推荐结束: "Periode Referensi Berakhir",
  "2025年5月23日": "2025.5.23",
  比赛结束: "Tantangan Berakhir",
  "2025年5月30日": "2025.5.30",
  颁奖典礼: "Upacara Penghargaan",
  "2025年": "2025",
  赛组规则: "Aturan Tantangan",
  类别: "Kategori",
  账号要求: "Ketentuan Akun",
  "新交易账号，且账号余额≥$500":
    "Akun trading baru dengan saldo lebih dari $500.",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "Akun trading baru dengan saldo antara $10.000 hingga $200.000.",
  排名规则: "Ketentuan Peringkat",
  以收益率排名为评判标准: "Peringkat ditentukan berdasarkan Tingkat Profit",
  "收益率=期末净值/账号累积入金":
    "Tingkat Profit = (Laba Bersih / Total Deposit Kumulatif)*100%",
  以收益额排名为评判标准:
    "Peringkat ditentukan berdasarkan Keuntungan Bersih. ",
  "收益额=期末净值+出金-入金":
    "Keuntungan Bersih = Ekuitas Akhir + Penarikan - Deposit ",
  持仓限制: "Batas Posisi",
  每个参赛账号最多持有100手仓位:
    "Setiap akun yang berpartisipasi dapat menahan posisi maksimum sebanyak 100 lot.",
  无持仓限制: "Tidak ada batas posisi",
  参赛条款: "Syarat Acara",
  参赛须知: "Pedoman Acara",
  section6List: [
    {
      t: "1. Durasi Event",
      c: [
        "Periode Kompetisi: 1 Maret 2025, 01:00:00 (waktu MT4/MT5) - 30 Mei 2025, 23:59:59 (waktu MT4/MT5)",
      ],
    },
    {
      t: "2. Persyaratan Akun",
      c: [
        "Akun harus terdaftar sebagai akun sinyal melalui alat Copy Trading EBC agar orang lain dapat menyalin perdagangan secara gratis.",
        "Peserta dapat menggunakan beberapa akun untuk berkompetisi, tetapi hanya akun dengan performa terbaik yang akan dipertimbangkan untuk peringkat.",
        "Untuk memastikan keadilan acara, partisipasi hanya terbuka untuk akun STD dan PRO; akun PAMM dan Islam tidak memenuhi syarat.",
      ],
    },
    {
      t: "3. Aturan Hadiah",
      c: [
        "Hadiah Juara: Jika juara memilih hadiah akun trading 1.000.000 USD, batas kerugian maksimum adalah 200.000 USD.",
        "Hadiah lain akan diberikan berdasarkan peringkat.",
      ],
    },
    {
      t: "4. Tindakan yang Dilarang",
      c: [
        "Perdagangan arbitrase antara akun untuk meningkatkan keuntungan dilarang keras. Pelanggaran apa pun, termasuk posisi lintas akun, perdagangan lindung nilai, atau penyalahgunaan kuotasi, akan mengakibatkan diskualifikasi.",
        "Pesanan yang disebabkan oleh faktor di luar kendali, seperti kegagalan perangkat keras server atau kesalahan harga, akan dianggap tidak valid.",
      ],
    },
    {
      t: "5. Penafian",
      c: ["EBC Financial Group berhak dalam keputusan akhir untuk event ini."],
    },
  ],

  参赛流程: "Proses Acara",
  注册开户: "Registrasi Akun",
  注册并开设新交易账号: "Daftar dan Buka Akun Perdagangan Baru",
  报名参赛: "Pendaftaran Acara",
  在用户中心报名参赛: "Daftar untuk Tantangan di Portal Pengguna",
  参赛成功: "Registrasi Sukses",
  参赛成功查看实时赛况:
    "Setelah Terdaftar, Pantau Performa Anda Secara Waktu Nyata",
  往届回顾: "Edisi Sebelumnya",
  查看更多: "Lihat Lebih Lanjut",
  常见问题: "Pertanyaan yang sering muncul",
  qaList: [
    {
      t: `1. "Akun" vs "Nomor Akun"`,
      c: [
        "Akun: Mengacu pada akun pusat pengguna (UID) EBC. Setiap pengguna hanya dapat memiliki satu akun setelah menyelesaikan otentikasi nama asli.",
        "Nomor Akun: Merujuk pada akun trading MT. Beberapa akun trading dapat dibuat di bawah akun EBC yang sama untuk tujuan trading.",
      ],
    },
    {
      t: "2. Bagaimana Cara Saya Mengonfirmasi Status Pendaftaran untuk Acara Ini?",
      c: [
        "Setelah berhasil mendaftar, akun kompetisi Anda dan peringkat saat ini akan muncul di halaman acara di portal pengguna.",
      ],
    },
    {
      t: "3. Apakah Saya Bisa Mengikuti Acara dengan Beberapa Akun?",
      c: [
        "Pengguna dapat berpartisipasi dengan beberapa akun di setiap grup tantangan, tetapi setiap akun hanya dapat berpartisipasi dalam satu grup tantangan.",
      ],
    },
    {
      t: "4. Bisakah saya klaim hadiah untuk kedua kategori?",
      c: [
        "Ya, Anda dapat mengklaim hadiah dari kedua kategori, asalkan tantangan telah diselesaikan sesuai dengan aturan.",
      ],
    },
    {
      t: "5. Apakah Saya Bisa Mengikuti Tantangan Jika Saya Sudah Memiliki Akun MT4?",
      c: [
        "Ya, asalkan akun MT4 dibuat pada atau setelah 22 Februari 2025, pukul 01:00:00 (waktu MT4/MT5), dan tidak memiliki riwayat trading sebelumnya. (Waktu disesuaikan dengan GMT +7).",
      ],
    },
    {
      t: "Setelah bergabung dalam kategori Bintang yang Bersinar, apakah melakukan deposit lebih dari $10.000 akan mengubah kategori kompetisi saya?",
      c: [
        "Tidak, kategori ini didasarkan pada pendaftaran pada saat mendaftar, dan perubahan dana selanjutnya tidak akan memengaruhi grup.",
      ],
    },
    {
      t: "7. Bagaimana cara cek peringkat?",
      c: [
        "Peringkat diperbarui setiap jam (bukan tepat pada jam) dan dapat dilihat di halaman aktivitas acara di portal pengguna.",
      ],
    },
    {
      t: "8. Bisakah saya deposit atau withdraw dana ketika tantangan berlangsung?",
      c: [
        "Ya, Anda bisa deposit dan tarik dana. Penarikan tidak akan memengaruhi peringkat Anda. Namun, total deposit untuk Dream Squad dibatasi hingga $200.000. Jika melebihi batas ini, Anda akan didiskualifikasi.",
      ],
    },
    {
      t: "9. Bagaimana Cara Saya Mengklaim Hadiah Referensi Saya?",
      c: [
        "Hadiah referal akan dikreditkan ke saldo bonus Anda 30 hari setelah pengguna yang diundang menyelesaikan transaksi pertama mereka. Anda kemudian dapat mengajukan penarikan, dan hadiah akan diproses setelah disetujui.",
      ],
    },
    {
      t: '10. Mengapa Pengguna yang Diundang Tidak Bisa Memindahkan Agennya?',
      c: [
        "Agar adil bagi semua, pengguna yang diundang hanya dapat bergabung melalui tautan referensi dan tidak dapat memilih atau memindahkan agen tertentu.",
      ],
    },
    {
      t: "11. Apakah Informasi Akun Trading Saya dalam Kompetisi Akan Dipublikasikan?",
      c: [
        "Ya, transaksi trading Anda akan terlihat di komunitas copy trading. Pengguna lain yang tidak mengikuti kompetisi dapat mengikuti dan menyalin strategi trading Anda.",
      ],
    },
    {
      t: "12. Akun trading saya terdaftar sebagai Provider di kompetisi. Apakah saya bisa menyalin trading dari orang lain?",
      c: [
        "Untuk memastikan kompetisi tetap adil, akun trading Provider tidak bisa menggunakan fitur copy trade. Tapi, Anda bisa membuat akun trading baru untuk mengakses fitur ini.",
      ],
    },
    {
      t: "13. Bagaimana jika dua peserta memiliki profit dan persentase profit yang sama?",
      c: [
        "Jika dua peserta mencapai tingkat profit atau jumlah profit yang sama, peringkat akan ditentukan berdasarkan volume trading. Peserta dengan volume trading lebih tinggi akan mendapatkan peringkat yang lebih tinggi.",
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000: "$30,000",
  $10000: "$10,000",
  $5000: "$5,000",
  $3000: "$3,000",
  $2000: "$2,000",
  $1000: "$1,000",
  rankValueList: [
    "Akun Trading 1.000.000 USD atau Uang Tunai 200.000 USD",
    "$30.000",
    "$10.000",
    "$5.000",
    "$5.000",
    "$3.000",
    "$3.000",
    "$3.000",
    "$3.000",
    "$3.000",
  ],
  rankValueList2: [
    "$30.000",
    "$5.000",
    "$3.000",
    "$2.000",
    "$2.000",
    "$1.000",
    "$1.000",
    "$1.000",
    "$1.000",
    "$1.000",
  ],
  ebclink:"https://www.ebc.com/id/win1"
};
