export default {
  交易领薪计划: "EBC 펀드 매니저 양성 프로그램",
  为了鼓励交易者提升交易水平:
    "본 프로그램은 우수한 트레이더를 양성하고 지원하는 것을 목적으로 하며, 매니징 중인 자금 규모에 따라 월별 보너스가 지급되며, 최대 연 18%의 보너스율이 적용됩니다. ",
  加入交易社区: "",
  即刻发起交易信号: "거래 시그널 공유",
  无限奖励月月拿: "월간 무제한 보너스",
  优享11个专属大礼: "11가지 혜택",
  即刻加入: "바로 신청하기",
  交易员等级奖励机制: `거래자 등급별 보상 제도`,
  EBC基于跟随人数和资金提供五档奖励:
    "팔로워 수와 자금에 따라 5가지 등급의 보상을 제공합니다. 거래 랏 수는 제한하지 않으며, 등급 측정은 시장 수용도를 기준으로 합니다. ",
  "/月": "/월",
  精英交易员: "초보 트레이더",
  管理资金量: "관리자금",
  跟随账户数: "팔로우 수",
  大师交易员: "엘리트 트레이더",
  冠军交易员: "챔피언 트레이더",
  明星交易员: "스타 트레이더",
  资金管理费: "1% 관리 수수료",
  传奇交易员: "레전드 트레이더",
  多层次的增值服务: "다양한 서비스",
  EBC提供多达11项增值服务:
    "본 프로그램은 보상 외 최대 11가지 부가가치 서비스를 제공합니다. 트래픽 및 미디어 자원을  통합하여 시그널의 영향력 증대를 지원합니다.",
  "精英/大师交易员": "엘리트/지도자",

  "7×24小时优先客服": "7x24시간 온라인 고객 상담 서비스",
  金融投资经典书籍礼遇: "무료 투자 서적 모음",
  专属VPS赞助: "독적점인 VPS 후원",
  订单流软件1年使用权: "주문 흐름 소프트웨어에 대한 1년 액세스 권한",
  EBC定制大礼包: "EBC 프리미엄 선물 세트",
  信号优先置顶展示: "공급자 표시",
  受邀参观海外办公室: "해외 사무소 방문 초대",
  巴萨球星联名礼包: "FC 바르셀로나 스타 선수 선물 컬렉션",
  巴萨赛场传奇之旅: "전설적인 바르셀로나 스타디움 체험",
  品牌战略合作: "전략적  브랜드 파트너십",
  全球金融资源管家服务: "글로벌 재무 자원 관리 서비스",
  五维指标评价系统: "5차원 지표 평가 시스템",
  为交易员信号提供公开透明的评估:
    "공정하고 투명한 평가를 위해 트레이더의 시그널과 거래 스타일을 공개합니다. 시그널에 대한 인지도를 높이고 더 많은 팔로워를 확보할 수 있습니다.",
  收益率: "수익률",
  历史收益率: "과거 거래의 수익률 측정, 수치가 높을수록 좋습니다.",
  最大回撤: "최대 손실",
  衡量风控能力: "리스크 관리 능력 측정, 수치가 낮을수록 리스크가 낮습니다.",
  获利系数: "이익 계수",
  利润因子: "이익계수가 높을수록 수익성이 더 강해집니다. ",
  夏普比率: "샤프 비율",
  每份风险的收益: "위험 단위당 수익을 측정, 수치가 높을 수록 좋습니다.",
  标准差: "스탠다드 편차",
  收益波动率: "변동성 측정, 수치가 낮을수록 안정적입니다.",
  以上数据仅供参考: "*위의 데이터는 참고용입니다.",
  活动须知: "안내 사항",
  list: [
    {
      label: "1. 등급 평가",
      value: [
        "관리 중인 자금 금액: 팔로우 계좌의 자금 금액 + 거래자(거래 시그널) 계정이 소유한 자금 금액은 잔액을 기준으로 계산됩니다.",
        "팔로우 수: 신호를 따르는 EBC 계정의 수를 의미하며, 동일한 계정에 동일한 신호를 따르기 위해 여러 거래 계정이 생성된 경우 1명의 팔로우로 계산됩니다.",
        "트레이더 등급은 매일 갱신되며, 한 달 동안 매일 최소 기준을 충족한 경우, 트레이더 보상이 활성화됩니다.",
      ],
    },
    {
      label: "2. 팔로워 규정",
      value: [
        "팔로워 금액이 500USD 이상인 경우, 1개의 유효 팔로우 계정으로 간주됩니다.",
        "각 거래 계좌는 최대 10명의 트레이더(거래 신호)를 팔로우할 수 있습니다.",
        "각 거래 계정의 팔로워 금액 = 팔로워 계정 잔액 ÷ 팔로우한 신호 수",
      ],
    },
    {
      label: "3. 리워드 지급",
      value: [
        "다음 달 첫 주에 전월 보상을 계산하여 지급합니다. 전월 기준을 충족한 최소 레벨에 따라 보상이 계산됩니다.",
        "월 보너스 = (일 평균 운용 자금 × 운용 수수료율) ÷ 12",
        "모든 보상은 거래 계좌로 지급되며, 수상자는 스스로 인출할 수 있습니다.",
        "이 이벤트의 최종 해석 권한은 주최측인 EBC에 있습니다.",
      ],
    },
  ],
  年化资金管理费: "펀드 매니지먼트 연간 수수료",
};
