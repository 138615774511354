export default {
  交易领薪计划: "โปรแกรมมาสเตอร์",
  为了鼓励交易者提升交易水平:
    "โปรแกรมมาสเตอร์ ออกแบบมาให้นักเทรดมืออาชีพ ปล่อยสัญญาณเทรด พร้อมรับรางวัลเงินเดือนตามปริมาณเงินทุน โดยมีอัตราโบนัสสูงสุด 18% ต่อปี",
  加入交易社区:
    "",
  即刻发起交易信号: "ปล่อยสัญญาณเทรด",
  无限奖励月月拿: "รางวัลเงินเดือนไม่จำกัด",
  优享11个专属大礼: "ของขวัญพิเศษ 11 รายการ",
  即刻加入: "เข้าร่วมตอนนี้",
  交易员等级奖励机制: `รางวัลตามระดับมาสเตอร์`,
  EBC基于跟随人数和资金提供五档奖励:
    "EBC มีระบบรางวัล 5 ระดับ พิจารณาจากจำนวนผู้ติดตามและจำนวนเงินทุน โดยไม่จำกัดจำนวนครั้งในการเทรด และใช้เกณฑ์วัดผลจากการเป็นที่ยอมรับในตลาดเท่านั้น",
  "/月": "/เดือน",
  精英交易员: "ระดับเริ่มต้น",
  // 管理资金量: "สินทรัพย์ภายใต้การจัดการ",
  // 跟随账户数: "จำนวนผู้ติดตาม",
  管理资金量: "AUM",
  跟随账户数: "Followers",
  大师交易员: "ระดับกลาง",
  冠军交易员: "ระดับสูง",
  明星交易员: "ระดับมืออาชีพ",
  资金管理费: "ค่าธรรมเนียมจัดการ 1%",
  传奇交易员: "ระดับตำนาน",
  多层次的增值服务: "บริการเสริมหลากหลายระดับ",
  EBC提供多达11项增值服务:
    "EBC สนับสนุนบริการเสริมถึง 11 รายการ เพื่อเพิ่มการรับรู้ และสร้างตัวตนให้กับเทรดเดอร์",
  "精英/大师交易员": "ระดับเริ่มต้น/ระดับกลาง",

  "7×24小时优先客服": "ฝ่ายบริการลูกค้า 24/7",
  金融投资经典书籍礼遇: "รับฟรีหนังสือลงทุน",
  专属VPS赞助: "ให้การสนับสนุน VPS",
  订单流软件1年使用权: "ใช้งานเครื่องมือ Order Flow ฟรี 1 ปี",
  EBC定制大礼包: "ของขวัญสุดพิเศษจาก EBC",
  信号优先置顶展示: "ปักหมุดสัญญาณเทรด",
  受邀参观海外办公室: "เยี่ยมชมสํานักงาน EBC สาขาต่างประเทศ",
  巴萨球星联名礼包: "ของขวัญจากสโมสรบาร์เซโลนา",
  巴萨赛场传奇之旅: "ทัวร์สนามแข่งสโมสรบาร์เซโลนา",
  品牌战略合作: "ความร่วมมือกลยุทธ์แบรนด์",
  全球金融资源管家服务: "บริการทรัพยากรการเงินระดับโลก",
  五维指标评价系统: "วิเคราะห์ตัวชี้วัด 5 มิติ",
  为交易员信号提供公开透明的评估:
    "เปิดเผยสัญญาณเทรดอย่างโปร่งใส แสดงสไตล์การเทรด เพิ่มการรับรู้ และดึงดูดผู้ติดตามมากขึ้น",
  收益率: "อัตราผลกำไร",
  历史收益率: "อัตราผลกำไรในอดีต ยิ่งสูงยิ่งดี",
  最大回撤: "อัตราขาดทุนสูงสุด",
  衡量风控能力: "ตัวชี้วัดความสามารถควบคุมความเสี่ยง ยิ่งต่ำยิ่งดี",
  获利系数: "อัตราตัวคูณกำไร",
  利润因子: "ตัวขี้วัดกลยุทธ์ทำกำไร ยิ่งสูงการทำกำไรยิ่งแกร่ง",
  夏普比率: "อัตราผลตอบแทนต่อความเสี่ยง",
  每份风险的收益: "ตัวชี้วัดผลตอบแทนต่อความเสี่ยง ยิ่งสูงยิ่งคุ้มค่า",
  标准差: "อัตราความผันผวนของผลตอบแทน",
  收益波动率: "ตัวชี้วัดความผันผวน ยิ่งต่ำความผันผวนยิ่งน้อย",
  以上数据仅供参考: "*ข้อมูลข้างต้นใช้สำหรับการอ้างอิงเท่านั้น",
  活动须知: "ข้อควรระวัง",
  list: [
    {
      label: "1. การคำนวณระดับรางวัล",
      value: [
        "สินทรัพย์ภายใต้การจัดการ: จำนวนเงินในบัญชีผู้ติดตาม + จำนวนเงินในบัญชีมาสเตอร์ โดยจำนวนเงินทั้งหมดจะคำนวณจากยอดคงเหลือ",
        "จำนวนผู้ติดตาม: นับจากบัญชีของผู้ใช้งาน ซึ่งหาก 1 บัญชีสร้างหลายบัญชีเทรด เพื่อติดตามสัญญาณเดียวกัน จะนับเป็น 1 บัญชีเท่านั้น ",
        "อันดับของมาสเตอร์จะมีการอัปเดตทุกวัน หากภายในระยะเวลาหนึ่งเดือนนับตามปฏิทินมาสเตอร์สามารถทำรายได้ถึงเกณฑ์ขั้นต่ำ มาสเตอร์จะรับสิทธิ์เปิดใช้งานเงินโบนัส",
      ],
    },
    {
      label: "2. เงื่อนไขสำหรับผู้ติดตาม",
      value: [
        "จำนวนเงินในบัญชีของผู้ติดตาม ≥ 500 USD จึงจะนับเป็นบัญชีผู้ติดตามที่มีผลต่อการคำนวนระดับเงินรางวัล",
        "แต่ละบัญชีสามารถติดตามได้สูงสุด 10 สัญญาณมาสเตอร์",
        "จำนวนเงินในแต่ละบัญชีผู้ติดตาม = ยอดคงเหลือบัญชีผู้ติดตาม / จำนวนมาสเตอร์ที่ติดตาม",
      ],
    },
    {
      label: "3. การมอบรางวัลเงินเดือน",
      value: [
        "คำนวณเงินรางวัลทุกสัปดาห์แรกของเดือนถัดไป และดำเนินการจ่ายเงินรางวัลของเดือนที่ผ่านมา โดยคำนวณรางวัลตามระดับต่ำสุดที่ได้มาตรฐานในเดือนที่ผ่านมา",
        "โบนัสรายเดือน = (ยอดเงินจัดการเฉลี่ยรายวัน × อัตราค่าธรรมเนียมการจัดการเงิน) ÷ 12",
        "รางวัลทั้งหมดจะถูกโอนเข้าบัญชีเทรด ผู้ได้รับรางวัลสามารถถอนเงินเองได้",
        `คำชี้แจงผู้ถือสิทธิ์:
EBC Financial Group มีสิทธิ์ในการตีความและตัดสิน ทุกกรณีที่เกี่ยวข้องกับกิจกรรมนี้`,
      ],
    },
  ],
  年化资金管理费: "ค่าธรรมเนียมการจัดการ (รายปี)",
};
