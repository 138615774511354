export default {
  fontFamily: "en-font-family",
  classLang: "en",
  headlist: [
    {
      text: "Beranda",
      url: "/",
    },
  ],
  // FCA开户: "FCA開戶",
  立即开户: "Daftar",
  登录: "Masuk",
  // 官方验证: "官方驗證",
};
