export default {
  fontFamily: "ja-font-family",
  classLang: "ja",
  headlist: [
    {
      text: "ホーム",
      url: "/",
    },
    {
      text: "取引",
      menu: [
        {
          tit: "取引関連",
          list: [
            {
              text: "取引口座",
              url: "/TradingAccount",
            },
            {
              text: "取引商品",
              url: "/tradingproduct",
            },
            {
              text: "レバレッジ・証拠金",
              url: "/leverage-margin",
            },
            {
              text: "入出金",
              url: "/funding",
            },
            {
              text: "CFD配当金",
              url: "/dividend",
            },
          ],
        },
        {
          tit: "取引ツール",
          list: [
            {
              text: "MT4 ダウンロード",
              url: "/MT4",
            },
            {
              text: "MT5 ダウンロード",
              url: "/MT5",
            },
            {
              text: "MT4 取引ツール",
              url: "/tools",
            },
            {
              text: "PAMM",
              url: "/PAMM",
            },
            {
              text: "市場分析",
              url: "/market-analysis",
            },
          ],
        },
      ],
    },
    {
      text: "EBC研究所",
      menu: [
        {
          tit: "",
          list: [
            {
              text: "学習センター",
              url: "/LearningCenter",
            },
            {
              text: "データセンター",
              url: "/lab",
            },
          ],
        },
      ],
    },
    
    {
      text: "EBCについて",
      menu: [
        {
          tit: "EBCについて",
          list: [
            {
              text: "WHY EBC",
              url: "/about-us",
            },
            {
              text: "サステナビリティ",
              url: "/ESG",
            },
            {
              text: "よくある質問",
              url: "https://support.ebchelp.com/hc/ja-jp",
            },
            {
              text: "キャンペーン",
              url: "/activity",
            },
          ],
        },
        {
          tit: "パートナー",
          list: [
            {
              text: "FCバルセロナ",
              url: "/FCB",
            },
            {
              text: "パートナーシップ",
              url: "/affiliate-programmes",
            },
            {
              text: "流動性サービス",
              url: "/service",
            },
            {
              text: "フィンテック",
              url: "/technology",
            },
          ],
        },
      ],
    },

    {
      text: "取引大会",
      url: "/million-dollar-challenge-2",
    },
  ],
  FCA开户: "FCAアカウント",
  立即开户: "口座開設",
  登录: "ログイン",
  官方验证: "公式チャンネルの認証",
  帮助中心: "FAQよくある質問",
  帮助中心Url: "https://support.ebchelp.com/hc/ja-jp",
};
