export default {
  多元活动连接全球市场: "Kết nối đa dạng hoạt động với thị trường toàn cầu",
  共享全球顶级资源与学术支持:
    "Tiếp cận nguồn lực hàng đầu thế giới và hỗ trợ học thuật để bắt đầu hành trình giao dịch đầy tự tin.",
  了解更多: "Tìm hiểu thêm",
  敬请期待: "Hãy theo dõi nhé!",
  每个认真交易的人:
    "Mỗi một nhà giao dịch nghiêm túc đều xứng đáng được coi trọng",
  activityListMetaData: [
    {
      label: "Hoạt động thị trường",
      list: [
        {
          id: "2-9",
          img: require("@/assets/image/activityList/banner2_9_vi.png"),
          title: "Cuộc Thi Giao Dịch Triệu Đô EBC II",
          subtitle: "Miễn phí sao chép giao dịch nhà vô địch",
          url: "/million-dollar-challenge-2",
          time: "",
        },
        // {
        //   id: "2-10",
        //   img: require("@/assets/image/activityList/messi_vi.png"),
        //   title: "Giao dịch cùng EBC và Giành áo đấu có chữ ký Messi phiên bản giới hạn",
        //   subtitle: "Thời gian: 01.03.2025 - 31.03.2025",
        //   url: "/trade-for-messi",
        //   time: "",
        // },
        {
          id: "2-7",
          img: require("@/assets/image/activityList/banner2_7_vi.png"),
          title: "EBC hợp tác cùng TradingView mở rộng mạng lưới tài nguyên toàn cầu",
          subtitle: "Ưu đãi có thời hạn: Nhận gói hội viên TradingView và theo dõi biến động thị trường theo thời gian thực",
          url: "/TradingView",
          time: "",
        },
        {
          id: "2-3",
          img: require("@/assets/image/activityList/banner2_3_en.png"),
          title: "Khuyến khích người sáng tạo",
          subtitle:
            "Sáng tạo góc nhìn tài chính độc đáo, nhận ngay phần thưởng độc quyền",
          url: "/cfa",
          time: "",
        },
        {
          id: "2-4",
          img: require("@/assets/image/activityList/banner2_4_vi.png"),
          title: "Chương trình Phát triển Quản lý Quỹ",
          subtitle:
            "Đây là chương trình tăng trưởng tài sản được thiết kế dành riêng cho các nhà giao dịch xuất sắc! Thưởng hàng tháng được trao dựa trên số vốn mà nhà giao dịch quản lý, với mức thưởng có thể lên tới 18% mỗi năm.",
          url: "/pay",
          time: "",
        },
      ],
    },
    {
      label: "Tin tức EBC",
      list: [
        {
          id: "3-4",
          img: require("@/assets/image/activityList/banner3_4_vi.png"),
          title: "EBC sẽ tham dự iFX EXPO LATAM 2025",
          subtitle:
            "Là nhà tài trợ kim cương, EBC Financial Group sẽ chính thức góp mặt tại iFX EXPO LATAM 2025 – sự kiện lớn nhất và có tầm ảnh hưởng nhất trong ngành tài chính toàn cầu. Chúng tôi mong chờ gặp gỡ bạn để cùng khám phá những cơ hội đầu tư trong bối cảnh thị trường quốc tế.",
          url: "",
          time: "9-10 April 2025",
        },
        {
          id: "3-3",
          img: require("@/assets/image/activityList/banner3_3_vi.png"),
          title: "Điểm đặc sắc của EBC tại Money Expo Mexico",
          subtitle:
            "EBC tự hào tham gia Money Expo Mexico 2025 với tư cách Nhà tài trợ Bạch kim, kết nối cùng các chuyên gia trong ngành. Cùng nhau, chúng tôi đã khám phá những đổi mới fintech và các cơ hội giao dịch tiềm năng.",
          url: "/LearningCenterInfo?id=LEARN-782-60B",
          time: "26-27 February 2025",
        },
        {
          id: "3-1",
          img: require("@/assets/image/activityList/banner3_1_vi.png"),
          title: "Cách tận dụng kinh tế học khí hậu để khám phá cơ hội đầu tư",
          subtitle: `Kết hợp với chủ đề nóng hiện nay, hội thảo "Các nhà kinh tế thực sự làm gì?" sẽ thảo luận cách ứng dụng kinh tế học để thúc đẩy cải tiến từ các chính trị gia, góp phần vào sự thịnh vượng kinh tế.`,
          url: "/oxford",
          time: "November 14, 2024",
        },
        {
          id: "3-2",
          img: require("@/assets/image/activityList/banner3_2_vi.png"),
          title: "Hội thảo EBC hợp tác cùng Đại học Oxford",
          subtitle: `Hội thảo lần này sẽ diễn ra vào lúc 14:30 (GMT) ngày 14/11 tại trường Đại học Oxford, Christ Church College. Sự kiện được phát trực tiếp và mở cửa cho các nhà đầu tư toàn cầu.`,
          url: "/oxford1",
          time: "March 6, 2024",
        },
      ],
    },
  ],
};
