export default {
  在EBC交易赢梅西签名限量球衣PC: `<h1>EBC 트레이딩 대회에서 우승자는 메시가 친필로 사인한 한정판 유니폼을 획득합니다. </h1>`,
  在EBC交易赢梅西签名限量球衣Mobile: `<h1>EBC 트레이딩 대회에서 우승자는 메시가 친필로 사인한 한정판 유니폼을 획득합니다. </h1>`,
  立即参与: "지금 가입하기 ",
  亲笔签名并装裱: "액자에 담긴 친필사인",
  "莱昂内尔·梅西": "Lionel Messi",
  巴塞罗那官方: "FC 바르셀로나의 2018-19 시즌 공식 홈  유니폼",
  赛季主场球衣: "",
  这件球衣由梅西于2023年1月28日在巴黎的一场私人签名会上亲笔签名:
    "이 유니폼은  2023년 1월 28일 파리에서 열린 개인 사인회에서 Messi가 직접 서명했으며, 서명이 100% 정품임을 보장하기 위해 공식 바르셀로나 클럽 인증서와 함께 제공됩니다.   소중한 스포츠 문화 유산이 될것이니 . 한정판을 선착순으로 소유할수 있는 기회를 놓치지 마십시오!",
  亲笔签名: "친필사인(히어로즈 컬렉터 에디션 프레임)",
  "莱昂内尔·梅西、": "Lionel Messi & ",
  "路易斯·苏亚雷斯": "Luis Suárez",
  "巴萨2016-17赛季主场球衣": "FC 바르셀로나의 2016-17 시즌 공식 홈 유니폼",
  这件球衣由两位传奇球星梅西和苏亚雷斯亲笔签名: `이 유니폼은 두명의 축구전설인 메시 (Messi)와  수아레스(Suarez)의 친필 사인이 담긴 매우 수집 가치가 높은 스포츠 아이템입니다. 메시는 2023년9월29일에 마이애미에서 열린 프라이빗 사인회에서 유니폼에 사인을 했고,이후 수아레스가 2023년10월12일 바르셀로나에서 열린 사인회에서 두번째 사인을 완성했습니다. 두 선수는 각기 다른회사와 장소에서 이 유니폼에 독특한 가치를  더했습니다.
<br/>유니폼에는 Icons.com의 공식 인증서가 있어 사인이 100%진품임을 보장합니다.이는 세계적으로 유일무이한 스포츠 컬렉션 아이템으로 한정수량으로 선착순 판매되며 수집과 투자 가치가 매우 높습니다.`,
  "拉明·亚马尔": "Lamine Yamal",
  "巴萨2024-25赛季主场球衣": "FC 바르셀로나의 2024-25 시즌 공식 홈 유니폼",
  这件巴萨球衣印有亚马尔的名字和球号: ` 야말의 이름과 등번호가 새겨진 이 바르셀로나 유니폼은 새로운 세대의 축구 재능의 출현을 상징합니다. 불과 16세의 나이에 야말은 이미 여러 클럽 기록을 깨뜨리며 바르셀로나 축구의 풍부한 유산과 미래를 상징하고 있습니다.
<br/>
이는 팬의 수집품일 뿐만 아니라 바르셀로나의 새로운 전설이 탄생했음을 알리는 증인이기도 합니다. `,
  活动时间: "이벤트 시간:",
  "活动时间:": "이벤트 시간:",
  "2025年3月1日": "2025년3월1일 - 2025년3월31일",
  活动规则: " 규칙:",
  交易量PK: `거래량 <br/>
월간 거래량(미국주식제외)이 가장 높은 사용자는Messi가 친필로 사인한 유니폼을 획득할수 있습니다.`,
  入金量PK: `"입금금액<br/>
월달 가장 높은 순입금 (보증금)가진 사용자는 Yamal 유니폼을 획득할수 있습니다."
  `,
  活动条款: "이용약관:",
  list: [
    "EBC 투자자는 3월부터 이 프로모션에 자동으로 참여하게 되며,이 프로모션은 전 세계에 오픈되여있습니다.",
    "이번 이벤트의 상품은 중복으로 받을수 없으며 하나의  계정으로 최대 하나의 상품만 받을수 있습니다.",
    "장금은 이벤트 종료 후 영업일 기준 7일 이내에 EBC 공식 홈페이지 및 EBCX 공식 계정에 공지되며, 당첨자에게는 이메일로 통보됩니다.",
    "EBC 팀은 모든 참여 계정에 대한 철저한 검토를 수행하며 보안 정책을 준수하지 않는 계정은 보상 계산에 포함되지 않습니다. 위반 행위에는 동일한 장치에서 여러 계정을 개설하는것이 포함되지만이에 제한되지 않으며, 이는 중복 계정으로 간주되어 보너스를 받을 수 없습니다.",
    "EBC Financial Group은 사전 통지 없이 이벤트 약관을 수정할 수 있는 권리를 보유합니다.",
    "EBC Financial Group은 이 이벤트에 대한 최종 해석 권한을 보유합니다. 궁금한 점이 있으시면 고객 서비스 팀에 문의하십시오.",
  ],
};
