export default {
  logo: require("@/assets/image/win/logo_vi.svg"),
  EBC: "",
  全球百万美金交易大赛Ⅱ: "Cuộc Thi Giao Dịch Triệu Đô EBC II",
  免费复制冠军交易: "Miễn phí sao chép giao dịch nhà vô địch",
  共赴巴萨颁奖盛典: "Ăn mừng chiến thắng tại Barcelona",
  EBC全球百万美元交易大赛: `Cuộc thi Giao dịch Triệu Đô EBC đã quay trở lại, quy tụ những nhà giao dịch tài năng từ khắp nơi trên thế giới. Là đối tác chính thức của FC Barcelona, chúng tôi tự hào đưa tinh thần bóng đá vào thế giới giao dịch, tôn vinh niềm đam mê và sự xuất sắc. Nhà vô địch sẽ nhận lời mời tham dự lễ trao giải tại Barcelona, nơi bạn sẽ được chứng kiến sự kết hợp huyền thoại giữa đấu trường giao dịch và sân cỏ.`,
  大赛奖池: "Tổng tiền thưởng",
  距开赛: "Bắt đầu sau",
  距结束:"Kết thúc sau",
  一键跟单: "Copy Trading",
  免费复制冠军收益: "Sao chép miễn phí lợi nhuận của nhà vô địch",
  公开透明: "Công Khai Và Minh Bạch",
  观摩冠军交易技巧: "Quan sát kỹ thuật giao dịch của nhà vô địch",
  推荐有礼: "Giới Thiệu Nhận Thưởng",
  推荐有奖上不封顶: "Nhận thưởng không giới hạn",
  双赛道机制: "Chứng minh bản thân trong hai bảng đấu",
  梦之队: "Bảng Tinh Anh",
  梦之队HTML: "Bảng Tinh Anh",
  以交易场的传控技术角逐百万:
    "Phô diễn kỹ năng giao dịch và giành giải thưởng 1.000.000 USD",
  青训营: "Bảng Tân Binh",
  青训营HTML: "Bảng Tân Binh",
  "像天才新星释放天赋「一球成名」":
    "Giao dịch như một ngôi sao, bung tỏa tài năng bản thân",
  冠军: "Quán Quân",
  亚军: "Á Quân",
  季军: "Hạng Ba",
  第四至五名: "Hạng Tư - Hạng Năm",
  第六至十名: "Hạng Sáu - Hạng Mười",
  "$1,000,000交易账号或$200,000现金":
    "Tài khoản giao dịch 1.000.000 USD hoặc 200.000 USD Tiền mặt",
  立即参赛: "THAM GIA NGAY",
  巴萨荣耀之旅: "Hành trình vinh quang đến Barcelona",
  每笔订单都是攻守博弈的艺术: `Mỗi giao dịch là sự cân bằng tinh tế giữa tấn công và phòng thủ. Mỗi quyết định bạn đưa ra có thể trở thành bàn thắng quyết định trên thị trường. Vinh quang đang chờ đón những người chiến thắng.`,
  邀您赴巴萨训练营: "Mời bạn tham gia trại huấn luyện của Barcelona",
  亲身体验冠军的致胜之旅:
    "Trải nghiệm trực tiếp hành trình chiến thắng của nhà vô địch",
  于巴萨博物馆为您加冕: "Viết nên huyền thoại của bạn tại bảo tàng Barcelona",
  巴萨颁奖典礼: "Lễ đăng quang huyền thoại, chứng kiến vinh quang giao dịch",
  巴萨绿茵场现场观赛: "Xem trận đấu trực tiếp tại sân cỏ Barcelona",
  亲临VIP坐席见证球星逐梦诺坎普:
    "Ngồi ghế VIP chứng kiến các ngôi sao chinh phục ước mơ tại SVĐ Camp Nou",
  大赛推荐官: "Đại Sứ Giới Thiệu",
  交易即享推荐奖励: "Giao dịch và kiếm phần thưởng giới thiệu không giới hạn!",
  "/人": " / người",
  "3步推荐": "3 bước đơn giản: Giới thiệu, giao dịch và kiếm tiền!",
  推荐好友: "Giới thiệu bạn bè",
  好友交易: "Bạn bè tham gia",
  赢得奖金: "Nhận thưởng",
  立即推荐: "GIỚI THIỆU NGAY",
  实时赛况: "Xếp hạng hiện tại",
  排名: "Xếp Hạng",
  昵称: "Biệt Danh",
  账号: "Tài Khoản",
  收益额: "Lợi Nhuận",
  收益率: "Tỷ Lệ Lợi Nhuận",
  奖金: "Thưởng",
  跟单: "Copy Trading",
  暂未开赛: "Sắp ra mắt, hãy theo dõi",
  该页面仅展示收益表现排名前10的交易账号:
    "*Trang này chỉ hiển thị 10 tài khoản giao dịch có hiệu suất lợi nhuận cao nhất, xếp hạng được tính bằng đơn vị tiền tệ USD và được cập nhật mỗi giờ.",
  赛事进程: "Tiến Trình Cuộc Thi",
  推荐开始: "Chương trình giới thiệu bắt đầu",
  "2025年2月22日": "22/02/2025",
  比赛开始: "Khởi Động Cuộc Thi",
  "2025年3月1日": "01/03/2025",
  推荐结束: "Kết Thúc Thời Gian Giới Thiệu",
  "2025年5月23日": "23/05/2025",
  比赛结束: "Kết Thúc Cuộc Thi",
  "2025年5月30日": "30/05/2025",
  颁奖典礼: "Lễ Trao Giải",
  "2025年": "Năm 2025",
  赛组规则: "Quy Tắc Tham Gia Cuộc Thi",
  类别: "Danh mục",
  账号要求: "Yêu Cầu Tài Khoản:",
  "新交易账号，且账号余额≥$500":
    "Tài khoản giao dịch mới, với số dư tài khoản ≥ 500 USD.",
  "新交易账号，且$10,000≤账号余额≤$200,000":
    "Tài khoản giao dịch mới, với số dư tài khoản trong khoảng từ 10.000 USD đến 200.000 USD.",
  排名规则: "Quy Tắc Xếp Hạng",
  以收益率排名为评判标准: "Xếp hạng theo tỷ lệ lợi nhuận:",
  "收益率=期末净值/账号累积入金":
    "Tỉ lệ lợi nhuận = (Lợi nhuận ròng/Tổng số tiền nạp)*100%",
  以收益额排名为评判标准: "Xếp hạng theo lợi nhuận ròng",
  "收益额=期末净值+出金-入金":
    "Lợi nhuận ròng = Vốn cuối kỳ + Số tiền rút - Số tiền nạp",
  持仓限制: "Giới Hạn Vị Thế",
  每个参赛账号最多持有100手仓位:
    "Mỗi tài khoản tham gia chỉ có thể nắm giữ tối đa 100 lots (vị thế).",
  无持仓限制: "Không hạn chế số lượng vị thế",
  参赛条款: "Điều Khoản Cuộc Thi",
  参赛须知: "Nguyên tắc cuộc thi:",
  section6List: [
    {
      t: "1. Thời gian sự kiện",
      c: [
        "Từ 01:00:00 ngày 01/03/2025 đến 23:59:59 ngày 30/05/2025 (giờ MT4/MT5).",
      ],
    },
    {
      t: "2. Yêu cầu tài khoản",
      c: [
        "Tài khoản tham gia phải được đăng làm tài khoản Master trên cộng đồng giao dịch, nhưng không bắt buộc mở cho phép theo dõi và không được sao chép tín hiệu từ tài khoản khác.",
        "Người chơi có thể sử dụng nhiều tài khoản để tham gia, nhưng chỉ tài khoản có kết quả tốt nhất được tính vào xếp hạng.",
        "Để đảm bảo tính công bằng, chỉ có tài khoản STD và PRO được tham gia; tài khoản PAMM và tài khoản Hồi giáo không đủ điều kiện tham gia cuộc thi.",
      ],
    },
    {
      t: "3. Quy tắc nhận thưởng",
      c: [
        "Nhà vô địch: Nếu chọn tài khoản giao dịch trị giá 1.000.000 USD, mức lỗ tối đa được phép là 200.000 USD.",
        "Các giải thưởng khác sẽ được phân theo bảng xếp hạng.",
      ],
    },
    {
      t: "4. Hành vi nghiêm cấm",
      c: [
        "Nghiêm cấm giao dịch chênh lệch giá giữa các tài khoản để tăng lợi nhuận. Mọi hành vi vi phạm, bao gồm vị thế chéo, giao dịch hedging hoặc lạm dụng báo giá đều sẽ bị loại bỏ.",
        "Các lệnh giao dịch bị ảnh hưởng bởi lỗi phần cứng máy chủ, báo giá sai hoặc yếu tố ngoài tầm kiểm soát sẽ không được tính.",
      ],
    },
    {
      t: "5. Quyền giải thích cuối cùng",
      c: [
        "EBC Financial Group sở hữu quyền giải thích cuối cùng đối với các quy định và kết quả của sự kiện.",
      ],
    },
  ],

  参赛流程: "Quy Trình Tham Gia",
  注册开户: "Đăng ký tài khoản",
  注册并开设新交易账号: "Đăng ký và mở tài khoản giao dịch mới",
  报名参赛: "Đăng ký tham gia cuộc thi",
  在用户中心报名参赛:
    "Đăng ký tham gia cuộc thi qua trang người dùng (User Center)",
  参赛成功: "Tham gia thành công",
  参赛成功查看实时赛况:
    "Sau khi tham gia thành công, bạn có thể theo dõi kết quả thi đấu trong thời gian thực.",
  往届回顾: "Nhìn Lại Mùa Trước",
  查看更多: "Xem thêm",
  常见问题: "Câu Hỏi Thường Gặp",
  qaList: [
    {
      t: `1. "Tài khoản" và "Số tài khoản" có gì khác nhau?`,
      c: [
        "Tài khoản: Là tài khoản trung tâm người dùng EBC (UID). Mỗi người dùng chỉ sở hữu một tài khoản sau khi xác minh danh tính.",
        "Số tài khoản: Là số tài khoản giao dịch MT. Một tài khoản EBC có thể tạo nhiều số tài khoản giao dịch để thực hiện nạp tiền và giao dịch.",
      ],
    },
    {
      t: "2. Làm thế nào để xác nhận đã tham gia thi đấu thành công?",
      c: [
        "Sau khi đăng ký thành công, trang sự kiện thi đấu trong trung tâm người dùng sẽ hiển thị tài khoản thi đấu cùng thứ hạng hiện tại của bạn, đồng thời bạn sẽ nhận được email xác nhận tham gia thành công.",
      ],
    },
    {
      t: "3. Có thể tham gia thi đấu bằng nhiều tài khoản không?",
      c: [
        "Người dùng được phép tham gia thi đấu ở hai bảng khác nhau bằng nhiều tài khoản, tuy nhiên mỗi tài khoản chỉ được tham gia một bảng đấu duy nhất.",
      ],
    },
    {
      t: "4. Có thể nhận thưởng ở cả hai bảng đấu nếu giành giải không?",
      c: [
        "Có, người tham gia có thể nhận thưởng ở cả hai bảng đấu, miễn là cả hai bảng đấu đều tuân thủ quy định, hoàn thành cuộc thi và đạt giải.",
      ],
    },
    {
      t: "5. Tài khoản MT4 hiện có thể tham gia thi đấu không?",
      c: [
        "Tài khoản tham gia thi đấu phải được tạo sau ngày 01/02/2025 và không có lịch sử giao dịch trước đó.",
      ],
    },
    {
      t: "6. Sau khi tham gia Bảng Tân Binh và nạp thêm 10.000 USD thì có được thay đổi bảng đấu hay không?",
      c: [
        "Không, bảng đấu sẽ được xác định theo thời điểm đăng ký tham gia. Các thay đổi về số dư sau đó không ảnh hưởng đến bảng đấu.",
      ],
    },
    {
      t: "7. Làm thế nào để xem xếp hạng trong cuộc thi?",
      c: [
        "Xếp hạng được cập nhật mỗi giờ (không cập nhật vào giờ chẵn), người dùng có thể xem xếp hạng của mình trên trang sự kiện thi đấu trong trung tâm người dùng.",
      ],
    },
    {
      t: "8. Trong suốt cuộc thi có thể nạp và rút tiền không?",
      c: [
        "Có thể nạp và rút, việc rút tiền sẽ không ảnh hưởng đến xếp hạng. Giới hạn nạp của bảng Tinh anh là 200.000 USD, nếu vượt quá sẽ bị huỷ bỏ tư cách tham gia cuộc thi.",
      ],
    },
    {
      t: "9. Làm thế nào để nhận thưởng giới thiệu?",
      c: [
        "Phần thưởng giới thiệu sẽ được ghi vào số dư tiền thưởng sau 30 ngày kể từ khi tài khoản của người được giới thiệu đáp ứng đủ điều kiện và hoàn thành giao dịch mở vị thế đầu tiên. Sau đó, bạn có thể gửi yêu cầu rút tiền. Khi yêu cầu được phê duyệt, phần thưởng sẽ được chuyển vào tài khoản của bạn.",
      ],
    },
    {
      t: "10. Tại sao người “được mời” không thể đổi IB?",
      c: [
        `Để đảm bảo tính công bằng, người "được mời" phải đăng ký qua liên kết giới thiệu và không thể chỉ định IB.`,
      ],
    },
    {
      t: "11. Các giao dịch của tài khoản tham gia có phải công khai không?",
      c: [
        `Các tài khoản tham gia sẽ phải công khai toàn bộ giao dịch trong cộng đồng Copy trading. Các tài khoản chưa tham gia có thể sao chép giao dịch của tài khoản tham gia.`,
      ],
    },
    {
      t: "12. Tài khoản tham gia có thể sử dụng chức năng copy trading không?",
      c: [
        `Để đảm bảo công bằng, tài khoản tham gia không thể sử dụng chức năng copy trading; bạn có thể tạo tài khoản mới để trải nghiệm chức năng này.`,
      ],
    },
    {
      t: "13. Nếu tỷ lệ lợi nhuận hoặc số lợi nhuận ròng của các thí sinh bằng nhau, thứ hạng sẽ được tính như thế nào?",
      c: [
        `Nếu tỷ lệ lợi nhuận hoặc số lợi nhuận ròng của các thí sinh bằng nhau, thứ hạng sẽ được tính dựa trên khối lượng giao dịch.`,
      ],
    },
  ],
  天: "d",
  时: "h",
  分: "m",
  秒: "s",
  $30000: "30.000 USD",
  $10000: "10.000 USD",
  $5000: "5.000 USD",
  $3000: "3.000 USD",
  $2000: "2.000 USD",
  $1000: "1.000 USD",
  rankValueList: [
    "Tài khoản giao dịch 1.000.000 USD hoặc 200.000 USD Tiền mặt",
    "30.000 USD",
    "10.000 USD",
    "5.000 USD",
    "5.000 USD",
    "3.000 USD",
    "3.000 USD",
    "3.000 USD",
    "3.000 USD",
    "3.000 USD",
  ],
  rankValueList2: [
    "30.000 USD",
    "5.000 USD",
    "3.000 USD",
    "2.000 USD",
    "2.000 USD",
    "1.000 USD",
    "1.000 USD",
    "1.000 USD",
    "1.000 USD",
    "1.000 USD",
  ],
  ebclink:"https://www.ebc.com/vi/win1",
  第一届大赛冠军:"Quán quân mùa I",
  第一届大赛亚军:"Á quân mùa I",
  第一届大赛季军:"Giải ba mùa I"
};
