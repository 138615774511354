export default {
  股息调整: "배당금 조정",
  了解您在股票和指数差价合约交易中的股息调整和分配机制:
    "주식 및 지수 차익 계약(CFD) 거래에서의 배당금 조정 및 분배 메커니즘",
  了解指数差价合约: "지수 CDF 알아보기",
  了解股票差价合约: "주식CFDs 알아보기 ",
  股息是上市公司从利润或保留资金中定期分配给股东的资金:
    "배당금은 상장 기업의  이익 또는 유보 이익 중 일부를 주주에게 정기적으로 분배하는 자금입니다. 주식 배당금을 지급할 때 관련 주식 및 지수 (CFD) 제품에서도 해당 배당금 조정이 이루어집니다.",
  除息日与交易优化: "배당락일과 최적화 거래",
  指数差价合约的除息日由指数提供商根据成分股设定:
    "지수 CFD의 배당락일은 기초 주식이 배당락되는 시점을 기준으로 지수 제공자가 설정합니다. 롱 포지션을 보유하고 있으면 배당금을 받을 수 있고, 숏 포지션인 경우 배당금을 지급해야 합니다. 이러한 배당락일을 미리 숙지하면 효과적인 수익 관리를 할 수 있습니다. ",
  股息调整明细: "배당금 조정 세부사항",
  差价合约交易中相关产品的股息数据:
    "CFD 거래에서 기초자산의 배당금은 매수(롱)와 매도(숏)로 구분하여 표시됩니다.",
  交易产品: "Trading Products",
  多头股息: "Long Dividends",
  空头股息: "Short Dividends",
  除息日: "Ex-dividend Dates",
  即刻交易: "거래하기",
  股息的常见问题: "배당금 관련 FAQ",
  qaList: [
    {
      q: "1. 배당금과 배당락일이 무엇인가요?",
      a: [
        "배당금은 상장 기업이 당사의 이익 또는 유보 이익 중 일부를 주주에게 정기적으로 분배하는 자금입니다. 주식 배당금을 지급할 때 관련 주식 및 지수 (CFD) 제품에서도 해당 배당금 조정이 이루어집니다.",
        "지수 CFD의 배당락일(배당금 지급일)은 지수 제공자가 구성 종목의 배당락일을 기준으로 설정합니다.",
      ],
    },
    {
      q: "2. 배당금 조정 시간은 언제인가요?",
      a: [
        "지수 CFD의 배당금 조정은 보통 배당락일의 11:00-12:00(GMT+9)에  MT4/MT5 계좌에 'Dividend'로 표시된 내역으로 반영됩니다. ",
      ],
    },
    {
      q: "3. 배당금과 지수 거래가 무슨 상관인가요?",
      a: [
        "지수 거래에서 매수(롱) 포지션일 경우, 배당금이 MT4/MT5 계좌에 입금됩니다.",
        "지수 거래에서 매도(숏) 포지션일 경우, 배당금이 MT4/MT5 계좌에서 차감됩니다.",
      ],
    },
    {
      q: "4. 배당금 조정은 어떻게 계산하나요?",
      a: [
        "매수 포지션(롱) 계산 공식: 랏 수 × 계약 규모 × 매수 배당금 금액 (지수 기준 통화로 계산)",
        "매도 포지션(숏) 계산 공식: 랏 수 × 계약 규모 × 매도 배당금 금액 (지수 기준 통화로 계산)",
      ],
    },
    {
      q: "5. 배당금 조정 계산 예시",
      a: [
        "SPXUSD를 예로 들면, 배당락일이 2024년 10월 11일이고 매수 배당금 금액이 0.050달러, 매도 배당금 금액이 -0.050달러라고 가정해 봅시다. 만약 배당락일 전날(10월 10이례에 1랏의 매수(롱) 포지션을 보유하고 있다면, 배당금 조정은 다음과 같이 계산합니다: ",

        "배당금 조정 = 1(랏수) × 100(계약 규모) × 0.050달러(매수 배당금 금액) = 5달러 ",

        "즉, 이 경우엔 배당락일의 11:00-12:00(GMT+9)에 MT4/MT5 계좌로 'Dividend'로 표기된 5달러가 입금됩니다.  ",

        "매도(숏) 포지션일 경우 반대로 'Dividend'로 표기된 5달러가 MT4/MT5 계좌에서 차감됩니다. ",
      ],
    },
    {
      q: "6. 주의사항",
      a: [
        "배당금 조정은 지수 및 주식 CFD 거래에만 적용되며, 보유한 포지션(매수 또는 매도)에 따라 계좌에 배당금이 입금 혹은 출금됩니다. 배당금은 각 거래 종목 및 시장 동향에 따라 변동될 수 있습니다.",

        "기타 문의사항이 있으시면 고객지원팀에 연락 하거나 당사 웹사이트를 참고해 주십시오. ",
      ],
    },
  ],
};
