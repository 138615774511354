export default {
  leftNav: [
    { id: "#Trading", name: "ซื้อขาย" },
    { id: "#Regulation", name: "การกำกับดูแล" },
    { id: "#Security", name: "ความปลอดภัย" },
    { id: "#Fintech", name: "เทคโนโลยีทางการเงิน" },
    { id: "#Black-Box", name: "กล่องดำธุรกรรม" },
    { id: "#Private-Room", name: "พื้นที่ซื้อขาย" },
    { id: "#Liquidity", name: "สภาพคล่อง" },
    { id: "#Honor", name: "ให้เกียรติ" },
    //{ "id": "#News", "name": "ข่าว" }
  ],
  pages1: {
    h1: "ความไว้วางใจและการนับถือ",
    title: "เทรดเดอร์ที่จริงจัง ต้องได้รับบริการที่จริงใจจากเรา",
    btn: " ดูวิดีโอฉบับเต็ม ",
  },
  pages2: {
    title: "สภาพคล่องระดับมืออาชีพที่ส่งผลต่อโลกการเทรดของคุณ",
    title1: "สัมผัสประสบการณ์ใหม่ ในโลกแห่งโอกาสและความได้เปรียบในการเทรด",
    btn: " ซื้อขายสินค้า ",
    text: " ฟอเร็กซ์ ",
    text1: " สินค้าโภคภัณฑ์ ",
    text2: " ดัชนี CFD ",
    text3: " หุ้น CFD ",
  },
  pages3: {
    title: "กฎระเบียบระดับสากล",
    title1:
      "บริษัทในเครือของ EBC Financial Group ได้รับการควบคุมและอนุญาตในเขตอำนาจศาลท้องถิ่นของตน",
    btn: "เข้าใจกฎระเบียบ",
    text: "FCA ในสหราชอาณาจักร",
    text1:
      "EBC Financial Group (UK) Ltd ได้รับอนุญาตและควบคุมโดยหน่วยงานทางการเงิน. หมายเลขอ้างอิง: 927552",
    text2: "ASIC ในออสเตรเลีย",
    text3:
      "บริษัท EBC Financial Group (Australia) Pty Ltd ถูกอนุญาตและควบคุมโดยสำนักงานคณะกรรมการกำกับหลักทรัพย์และการลงทุน ASIC. หมายเลขอ้างอิง:500991",
    text4: "CIMA (หมู่เกาะเคย์แมน)",
    text5:
      "EBC FINANCIAL GROUP (CAYMAN) LTD ได้รับอนุญาตและควบคุมโดยหน่วยงานด้านการเงินของหมู่เกาะเคย์แมน (CIMA). หมายเลขควบคุม: 2038223",
  },
  pages4: {
    title: "พลังขับเคลื่อน สู่ความสำเร็จ",
    title1:
      "ความเป็นมืออาชีพและการใช้เทคโนโลยีชั้นนำทำให้เกิดความเป็นไปได้ของการเทรดที่ไม่มีที่สิ้นสุด",
    btn: " สำรวจเทคโนโลยี",
    text: " การประมวลผล 1,000 คำสั่งต่อวินาท ",
    text1: " การดำเนินการตามคำสั่งที่รุนแรง ",
    text2: " ของเวลาทำงาน ",
    text3: " ค่าสเปรด 0 pip ของ ECN อยู่ในระดับเดียวกันกับธนาคารระดับโลก",
  },
  pages5: {
    title: "ออเดอร์คำสั่งซื้อขายจะได้รับราคาที่ดีกว่าถึง 85%",
    title1: "ที่บันทึกข้อมูลการเทรดใน EBC ที่เหมาะสมและแม่นยำ",
    btn: " กล่องดำธุรกรรม",
  },
  pages6: {
    title: "ห้องส่วนตัวของ EBC",
    title1:
      "ห้องค้าส่วนตัว (Private Room) จะปกปิดข้อมูลการซื้อขายบางส่วนเพื่อให้นักเทรดได้ราคาซื้อขายที่ได้เปรียบขึ้นและปกป้องจากการโดนผู้อื่นทำการเทรดตรงข้ามเรา",
    btn: " พื้นที่ซื้อขาย",
  },
  pages7: {
    title: "สภาพคล่อง",
    title1:
      "EBC ได้สร้างความสัมพันธ์ความร่วมมือระยะยาวและมั่นคงกับธนาคารระหว่างประเทศชั้นนำของโลก ช่วยให้คุณทำธุรกรรมได้อย่างง่ายดายด้วยต้นทุนที่ต่ำมาก",
    btn: "สภาพคล่อง EBC",
  },
  pages8: {
    btn: "ข่าวเพิ่มเติม",
  },
  pages9: {
    title: "คุ้มครองอย่างครอบคลุม เพื่อความสบายใจ",
    title1:
      "EBC ยึดถือความปลอดภัยของกองทุนเป็นอันดับแรก เงินทุนของลูกค้าจะถูกจัดเก็บในบัญชีของ Barclays Bank ที่อดำเนินการร่วมกับ FCA, Financial Commission, Lloyds of London และ AON เพื่อสร้างระบบการรักษาความปลอดภัยที่ครอบคลุมรอบด้าน",
    btn: "ความปลอดภัย",
  },
  lang: "en",

  连接全球热门交易产品: "เชื่อมต่อผลิตภัณฑ์ที่ได้รับความนิยมทั่วโลก",
  探索一个充满机遇和竞争优势的世界:
    "สัมผัสประสบการณ์ใหม่ ได้เปรียบทุกการแข่งขัน",
  衍生品交易: "ฟอเร็กซ์",
  期货CFDs: "สินค้าโภคภัณฑ์",
  全球股票指数CFDs: "ดัชนี CFD",
  美国股票CFDs: "หุ้น CFD",
  "欧元/美元": "EURUSD",
  "黄金/美元": "XAUUSD",
  道琼斯工业指数: "U30USD",
  了解交易产品: "ผลิตภัณฑ์เทรด",
  全球顶级监管: "ใบอนุญาตระดับสากล",
  EBC金融集团旗下各公司:
    "บริษัทในเครือของ EBC Financial Group ได้รับการควบคุมและมีใบอนุญาตในเขตอำนาจศาลท้องถิ่น",
  授权及监管于: "ใบอนุญาตและการกำกับดูแล",
  监管号: "หมายเลขควบคุม",
  英国FCA监管: "FCA (สหราชอาณาจักร)",
  "英国金融行为监管局 (FCA)": "The Financial Conduct Authority (FCA)",
  开曼CIMA监管: "CIMA (หมู่เกาะเคย์แมน)",
  "开曼群岛金融管理局(CIMA)": "The Cayman Islands Monetary Authority (CIMA)",
  澳洲ASIC监管: "ASIC (ออสเตรเลีย)",
  澳大利亚证券和投资委员会:
    "The Australian Securities and Investments Commission (ASIC)",
  了解EBC官方: "ตรวจสอบข้อมูลเพิ่มเติม",
  "全面保障 安心以致": "คุ้มครองอย่างครอบคลุม เพื่อความสบายใจ",
  EBC将资金安全视为首要原则:
    "EBC ให้ความสำคัญกับความปลอดภัยของเงินทุนเป็นหลัก โดยเงินทุนของผู้ใช้จะถูกจัดเก็บในบัญชี Barclays Bank ที่ดำเนินการร่วมกับ FCA, Financial Commission, Lloyds of London และ AON เพื่อสร้างระบบการรักษาความปลอดภัยอย่างครอบคลุม",
  了解安全保障: "ความปลอดภัย",
  "连接全球 信赖首选":
    "เราได้รับความไว้วางใจจากเทรดเดอร์ พร้อมเชื่อมต่อโลกเข้าไว้ด้วยกัน",
  EBC构建全方位服务生态:
    "EBC ให้บริการที่ครอบคลุมและมีทีมผู้เชี่ยวชาญที่มีประสบการณ์กว่า 30 ปี ในการดำเนินงานทั่วโลก นอกจากนี้ ฐานผู้ใช้งานที่กว้างขวาง ทำให้ EBC กลายเป็นผู้นำที่ได้รับการยอมรับในระดับสากล",
  全球注册用户数: "ผู้ใช้งานที่ลงทะเบียนจากทั่วโลก",
  日均交易订单量: "ยอดการทำธุรกรรมเฉลี่ยต่อวัน",
  "2024年第三季度为交易订单优化增益":
    "การปรับปรุงประสิทธิภาพคำสั่งซื้อในไตรมาส 3 ปี 2024",
  多个: "+",
  "业务覆盖国家/地区": "ประเทศและภูมิภาคที่ให้บริการ",
  了解全球视野: "มุมมองเชิงลึก",
  "85%以上": "ออเดอร์คำสั่งซื้อขายจะได้รับราคาที่ดีกว่าถึง 85%",
  的订单成交于更优价格: "",
  EBC交易黑盒:
    "EBC ใช้กล่องเก็บข้อมูลธุรกรรมในการซื้อขาย ซึ่งแม่นยำกว่าระบบเทรดแบบดั้งเดิม ทั้งในด้านราคา คำสั่งซื้อ และความเสถียรของอัลกอริธึม",
  每秒聚合订单: "ประมวลผลต่อวินาที",
  极速订单执行: "ความเร็วคำสั่งซื้อ",
  数据传输稳定高达: "ความเสถียรสูงถึง",
  "行级RAW ECN点差": "ค่าสเปรด RAW ECN ระดับ Interbank",
  了解金融科技: "สำรวจเทคโนโลยีการเงิน",
  EBC全球合作伙伴: "พันธมิตรระดับโลกของ EBC",
  "EBC与25+国际权威银行及非银机构建立长期稳定的合作关系":
    "EBC ร่วมมือกับธนาคารและสถาบันระดับโลกกว่า 25 แห่ง ทำให้เรามีสภาพคล่องระดับสถาบัน พร้อมกับสเปรดเริ่มต้นที่ 0.0 Pip เทียบเท่ากับธนาคารชั้นนำ นอกจากนี้ EBC ยังทำงานร่วมกับมหาวิทยาลัย Oxford, สมาคม WAGGGS และมูลนิธิองค์การสหประชาชาติ (UN Foundation) เพื่อส่งเสริมความรับผิดชอบต่อสังคมและสร้างสังคมอย่างเท่าเทียม",
  了解更多: "เรียนรู้เพิ่มเติม",
  十大最受欢迎券商: "โบรกเกอร์ยอดนิยม 10 อันดับแรก",
  最透明交易券商: "โบรกเกอร์ที่โปร่งใสมากที่สุด",
  最受好评券商: "โบรกเกอร์ที่ได้รับการรีวิวดีที่สุด",
  卓越执行券商: "โบรกเกอร์ที่มีการดำเนินการที่ยอดเยี่ยม",
  全球最佳交易体验奖: "โบรกเกอร์ที่มีประสิทธิภาพมากที่สุด",
  最受信赖券商: "โบรกเกอร์ที่เชื่อถือได้มากที่สุด",
  最佳CFD券商: "โบรกเกอร์ CFD ที่ดีที่สุด",
  全球最佳ECN券商: "โบรกเกอร์ ECN ที่ดีที่สุด",
  全球新锐券商: "โบรกเกอร์ดาวรุ่งระดับสากล",
  tabList: [`Forex`, `Commodities`, `Index CFDs`, `Stock CFDs`],
  出售: "Ask",
  买入: "Bid",
  点差: "Spread",
  立即交易: "Trade",
  致力于让交易者成为盈家: "ยกระดับนักเทรดให้ประสบความสำเร็จระดับโลก",
  EBC每个引以为傲的数据背后:
    "EBC ทุกความสำเร็จสะท้อนให้เห็นถึงความไว้วางใจที่นักเทรดกว่า 100 ประเทศทั่วโลกมีต่อ EBC เรามุ่งมั่นสนับสนุนทุกการเทรดของคุณ เปิดโอกาสให้นักเทรดได้เชื่อมต่อกับตลาดทั่วโลกได้เพียงคลิกเดียว",
  月均交易量: "ปริมาณการเทรดเฉลี่ยรายเดือน",
  全球注册用户数: "ผู้ใช้งานที่ลงทะเบียนจากทั่วโลก",
  "2024年第四季度为交易订单优化增益":
    "การปรับปรุงประสิทธิภาพคำสั่งซื้อในไตรมาส 4 ปี 2024",
  "业务覆盖国家/地区": "ประเทศและภูมิภาคที่ให้บริการ",
  number1: "177.5",
  number1Text: "177.5",
  number2: "4",
  number2Text: "4",
  number3: "273",
  number3Text: "2.73",
  number4: "100",
  number4Text: "100",
  langFlag: "en",
  亿: "B+",
  万: "M+",
  "+": "+",
  全球百万美金交易大赛Ⅱ: "แข่งเทรด EBC Million Dollar Trading Challenge II",
  免费复制冠军收益: `Copy Trade แชมป์ฟรี`,
  免费复制: "คัดลอก",
  立即参赛: "เข้าร่วมแข่งขัน",
  该页面仅展示收益表现排名前5的交易账号:
    "*ตารางนี้จะแสดงอันดับบัญชีที่มีผลกำไรสูงสุด 5 อันดับแรก และมีการอัปเดตรายชั่วโมง (หน่วยเงินที่ใช้ในการจัดอันดับคือ USD)",
};
