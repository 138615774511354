export default {
	"pages1":{
		"title":"EBC智能跟单社区",
		"tips":"每个交易员心中都有一个英雄梦",
		"text":"跟单社区的优势",
		"text1":"信号源展示",
		"text2":"交易员奖励计划",
		"text3":"增值豪礼",
		"text4":"登录社区"
	},
	"pages2":{
		"title":"跟单社区优势",
		"list":[{
			"tit":"“更灵活”<br />跟单模式 ",
			"tips":"无需使用EA插件实现双向跟随<br />灵活调整跟单方式<br />玩转定制化交易"
		},{
			"tit":"“毫秒级”<br />跟单速度",
			"tips":"基于MT底层通讯协议<br />有效连接交易账户和信号方<br />即刻响应如影随形"
		},{
			"tit":"“更专业”<br />五维数据分析",
			"tips":"引入独家五维图指标<br />基金评级体系迈向新高度<br />优质信号呼之欲出"
		},{
			"tit":"“更智能”<br />AI选择助手",
			"tips":"根据跟单偏好<br />一键筛选目标信号<br />全球顶级交易策略尽收囊中"
		},{
			"tit":"“更透明”<br />交易展示",
			"tips":"信号源历史订单可追溯<br />操作细节一览无遗<br />领略学习交易大师手笔"
		}],
		"btn":"登陆跟单社区"
	},
	"pages3":{
		"title":"信号源展示",
		"text":"收益率",
		"text1":"最大回撤",
		"text2":"夏普比率",
		// "text3":"交易胜率",
		"text3":"获利系数",
		"text4":"免费",
		"text5":"即刻订阅",
		"btn":"查看更多信号源"
	},
	"pages4":{
		"title":"交易员奖励计划",
		"num":"奖励 $100/月",
		"num1":"奖励  $300/月",
		"num2":"奖励  $1,000/月",
		"num3":"奖励  $5,000/月",
		"num4":"奖励  $10,000/月",
		"text1":"管理资金",
		"text2":"跟随用户数",
		"name":"新锐交易员",
		"name1":"精英交易员",
		"name2":"冠军交易员",
		"name3":"明星交易员",
		"name4":"传奇交易员",
		"tips":"+1%管理资金/年（按月付）",
		"btn":"即刻加入计划"
	},
	"pages5":{
		"title":"增值豪礼",
		"text":"7X24客服",
		"text1":"EBC营销互动",
		"text2":"VPS赞助",
		"text3":"交易书籍赞助",
		"text4":"订单流使用权",
		"text5":"EBC大礼包",
		"text6":"信号置顶展示",
		"text7":"参观海外办公室",
		"text8":"媒体扶持",
		"text9":"战略合作",
		"text10":"海外基金产品<br/>发行顾问服务",
		"btn":"登录跟单社区"
	},
	"QA":{
		"title" : "跟单社区常见问题",
		"Q1" : "1.什么是跟单交易？",
		"A1" : "跟单交易是指通过跟单系统自动复制其他交易者的策略。您可以借鉴经验丰富的交易者决策，减少市场监控压力，提高潜在收益。",
		"Q2" : "2.跟单交易如何运作？",
		"A2" : "跟单交易允许您通过系统自动复制其他交易者的订单。当他们开仓或平仓时，您的账户也会同步执行，无需时刻关注市场，平台会根据您的设置自动处理。",
		"Q3" : "3. 使用跟单交易平台有哪些优势？",
		"A3" : "跟随优秀交易员的策略，无需自己分析市场。只需设置好跟单策略，便可复制表现优异的交易者的订单，提升收益的稳定性。",
		"Q4" : "4.跟单交易者可以使用哪些工具？",
		"A4" : "平台提供交易信号、五维数据分析、历史订单和交易员等级等数据工具，帮助您了解市场动态，分享策略，优化交易。",
		"Q5" : "5.跟单交易有哪些风险？",
		"A5" : "跟单交易存在市场波动风险及跟随不佳交易者的风险。选择合适的交易者并使用风险管理工具，有助于减少潜在损失。",
		"Q6" : "6.我可以定制我的跟单交易策略吗？",
		"A6" : "可以，EBC平台支持用户定制个性化的跟单策略。",
	},
	"box-name" : "copytrading-body cn",
}