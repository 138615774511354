<template>
	<div class="product">
		<div class="flex table-list">
			<div class="flex-1" v-for="(itx, idx) in tabData" :key="idx" :class="{ 'active': idx === tabIndex }"
				@click="changTab(idx)">
				{{ itx }}
			</div>
			<div class="flex-1" style="border: none;"></div>
			<div class="flex-1" style="border: none;"></div>
		</div>
		<div class="product-box c-underline__top">
			<div class="product-box__nav flex">
				<div>Market</div>
				<div>Bid</div>
				<div>Bid Volume</div>
				<div>Spread</div>
				<div>Ask</div>
				<div>Ask Volume</div>
			</div>
			<div class="product-box__accordion" id="accordionExample" v-if="tabIndex === 0">
				<div class="product-box__accordion-item" v-for="(data, i) in forex" :key="i" v-if="data.datas != ''">
					<h2 class="accordion-header" :id="'heading_' + i">
						<div class="accordion-button" type="button" data-bs-toggle="collapse"
							:data-bs-target="'#collapse_' + i" aria-expanded="true" :class="{ 'collapsed': i !== 0 }"
							:aria-controls="'collapse_' + i">
							<div class="accordion-body-tr flex" v-for="(item, index) in data.datas" :key="index"
								v-if="index === 0">
								<div>{{ data.name }}</div>
								<div :class="{ 'red': item.chadian * 1 < 0 }">
									{{ item.bid_price }}
								</div>
								<div>{{ item.bid_size }}</div>
								<div>{{ item.chadian }}
								</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.ask_price }}
								</div>
								<div>{{ item.ask_size }}</div>
							</div>
						</div>
					</h2>
					<div :id="'collapse_' + i" class="accordion-collapse collapse" :class="{ 'show': i === 0 }"
						:aria-labelledby="'heading_' + i" data-bs-parent="#accordionExample">
						<div class="accordion-body">
							<div class="accordion-body-head flex c-tc">
								<div>layer</div>
								<div>Bid</div>
								<div>Bid Volume</div>
								<div>Spread</div>
								<div>Ask</div>
								<div>Ask Volume</div>
							</div>
							<div class="accordion-body-tr flex c-tc" v-for="(item, index) in data.datas" :key="index">
								<div>{{ index + 1 }}</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.bid_price }}
								</div>
								<div>{{ item.bid_size }}</div>
								<div>{{ item.chadian }}
								</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.ask_price }}
								</div>
								<div>{{ item.ask_size }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="product-box__accordion" id="accordionExample" v-if="tabIndex === 1">
				<div class="product-box__accordion-item" v-for="(data, i) in Indices" :key="i" v-if="data.datas != ''">
					<h2 class="accordion-header" :id="'heading_' + i">
						<div class="accordion-button" type="button" data-bs-toggle="collapse"
							:data-bs-target="'#collapse_' + i" aria-expanded="true" :class="{ 'collapsed': i !== 0 }"
							:aria-controls="'collapse_' + i">
							<div class="accordion-body-tr flex" v-for="(item, index) in data.datas" :key="index"
								v-if="index === 0">
								<div>{{ data.name }}</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.bid_price }}
								</div>
								<div>{{ item.bid_size }}</div>
								<div>{{ item.chadian }}
								</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.ask_price }}
								</div>
								<div>{{ item.ask_size }}</div>
							</div>
						</div>
					</h2>
					<div :id="'collapse_' + i" class="accordion-collapse collapse" :class="{ 'show': i === 0 }"
						:aria-labelledby="'heading_' + i" data-bs-parent="#accordionExample">
						<div class="accordion-body">
							<div class="accordion-body-head flex c-tc">
								<div>layer</div>
								<div>Bid</div>
								<div>Bid Volume</div>
								<div>Spread</div>
								<div>Ask</div>
								<div>Ask Volume</div>
							</div>
							<div class="accordion-body-tr flex c-tc" v-for="(item, index) in data.datas" :key="index">
								<div>{{ index + 1 }}</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.bid_price }}
								</div>
								<div>{{ item.bid_size }}</div>
								<div>{{ item.chadian }}
								</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.ask_price }}
								</div>
								<div>{{ item.ask_size }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="product-box__accordion" id="accordionExample" v-if="tabIndex === 2">
				<div class="product-box__accordion-item" v-for="(data, i) in Commodities" :key="i"
					v-if="data.datas != ''">
					<h2 class="accordion-header" :id="'heading_' + i">
						<div class="accordion-button" type="button" data-bs-toggle="collapse"
							:data-bs-target="'#collapse_' + i" aria-expanded="true" :class="{ 'collapsed': i !== 0 }"
							:aria-controls="'collapse_' + i">
							<div class="accordion-body-tr flex" v-for="(item, index) in data.datas" :key="index"
								v-if="index === 0">
								<div>{{ data.name }}</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.bid_price }}
								</div>
								<div>{{ item.bid_size }}</div>
								<div>{{ item.chadian }}
								</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.ask_price }}
								</div>
								<div>{{ item.ask_size }}</div>
							</div>
						</div>
					</h2>
					<div :id="'collapse_' + i" class="accordion-collapse collapse" :class="{ 'show': i === 0 }"
						:aria-labelledby="'heading_' + i" data-bs-parent="#accordionExample">
						<div class="accordion-body">
							<div class="accordion-body-head flex c-tc">
								<div>layer</div>
								<div>Bid</div>
								<div>Bid Volume</div>
								<div>Spread</div>
								<div>Ask</div>
								<div>Ask Volume</div>
							</div>
							<div class="accordion-body-tr flex c-tc" v-for="(item, index) in data.datas" :key="index">
								<div>{{ index + 1 }}</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.bid_price }}
								</div>
								<div>{{ item.bid_size }}</div>
								<div>{{ item.chadian }}
								</div>
								<div :class="{ 'red': item.chadian < 0 }">
									{{ item.ask_price }}
								</div>
								<div>{{ item.ask_size }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import md5 from 'js-md5';
export default {
	data() {
		return {
			ws: {},
			forex: [{
				'name': 'EURUSD',
				'datas': []
			}, {
				'name': 'AUDUSD',
				'datas': []
			}, {
				'name': 'GBPUSD',
				'datas': []
			}, {
				'name': 'NZDUSD',
				'datas': []
			}, {
				'name': 'USDCAD',
				'datas': []
			}, {
				'name': 'USDJPY',
				'datas': []
			}, {
				'name': 'USDCHF',
				'datas': []
			}, {
				'name': 'GBPAUD',
				'datas': []
			}, {
				'name': 'GBPJPY',
				'datas': []
			}],
			Indices: [{
				'name': '100GBP',
				'datas': []
			}, {
				'name': '200AUD',
				'datas': []
			}, {
				'name': '225JPY',
				'datas': []
			}, {
				'name': 'D30EUR',
				'datas': []
			}, {
				'name': 'F40EUR',
				'datas': []
			}, {
				'name': 'HSIHKD',
				'datas': []
			}, {
				'name': 'NASUSDD',
				'datas': []
			}, {
				'name': 'SPXUSD',
				'datas': []
			}, {
				'name': 'U30USD',
				'datas': []
			}],
			Commodities: [{
				'name': 'XAUUSD',
				'datas': []
			}, {
				'name': 'XAGUSD',
				'datas': []
			}, {
				'name': 'XBRUSD',
				'datas': []
			}, {
				'name': 'XTIUSD',
				'datas': []
			}, {
				'name': 'XNGUSD',
				'datas': []
			}],
			tabData: ["Forex", "Indices", "Commodities"],
			tabIndex: 0,
			selList: []
		}
	},
	created() {
		this.getPortfolio()
	},
	methods: {
		changTab(idx) {
			this.tabIndex = idx
		},
		getPortfolio() {
			let time = new Date().getMilliseconds()
			this.$http('/ebc/api/getToken/run', {
				date: time,
				CheckSum: md5(time + "YISENCE")
			}).then(res => {
				let url = "wss://pricecloud.eappservice.com/ws?token=" + res.data.data
				this.init(url)
			})
		},
		getCount(idx1, idx2) {
			const r = /^\+?[1-9][0-9]*$/; // 正整数
			// 如果判断不符合正则，则不是正整数不能提交
			let num = 0;
			let a1 = 0
			let a2 = 0
			if (!r.test(idx1)) {
				a1 = idx1.toString().split(".")[1].length;
			}
			if (!r.test(idx2)) {
				a2 = idx2.toString().split(".")[1].length;
			}
			if (a1 > a2) {
				if (a1 === 1) {
					num = idx1 * 10 - idx2 * 10
				} else if (a1 === 2) {
					num = idx1 * 100 - idx2 * 100
				} else if (a1 === 3) {
					num = idx1 * 1000 - idx2 * 1000
				} else if (a1 === 4) {
					num = idx1 * 10000 - idx2 * 10000
				} else if (a1 === 5) {
					num = idx1 * 100000 - idx2 * 100000
				}
			} else {
				if (a2 === 1) {
					num = idx1 * 10 - idx2 * 10
				} else if (a2 === 2) {
					num = idx1 * 100 - idx2 * 100
				} else if (a2 === 3) {
					num = idx1 * 1000 - idx2 * 1000
				} else if (a2 === 4) {
					num = idx1 * 10000 - idx2 * 10000
				} else if (a2 === 5) {
					num = idx1 * 100000 - idx2 * 100000
				}
			}
			return num.toFixed(0);
		},
		//init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
		init(url) {
			this.ws = new WebSocket(url)

			//监听是否连接成功
			this.ws.onopen = () => {
				//连接成功则发送一个数据
				this.ws.send('连接成功');
			}

			//接听服务器发回的信息并处理展示
			this.ws.onmessage = (data) => {
				if (data.data == 'Ws connected success！' || data.data == '连接成功') return
				let info = JSON.parse(data.data)
				info.tick.forEach(o => {
					o.chadian = this.getCount(o.ask_price * 1, o.bid_price * 1)
				})
				if (info.symbol == 'XAUUSD') {
					this.Commodities[0].datas = info.tick
				} else if (info.symbol == 'XAGUSD') {
					this.Commodities[1].datas = info.tick
				} else if (info.symbol == 'XBRUSD') {
					this.Commodities[2].datas = info.tick
				} else if (info.symbol == 'XTIUSD') {
					this.Commodities[3].datas = info.tick
				} else if (info.symbol == 'XNGUSD') {
					this.Commodities[4].datas = info.tick
				} else if (info.symbol == '100GBP') {
					this.Indices[0].datas = info.tick
				} else if (info.symbol == '200AUD') {
					this.Indices[1].datas = info.tick
				} else if (info.symbol == '225JPY') {
					this.Indices[2].datas = info.tick
				} else if (info.symbol == 'D30EUR') {
					this.Indices[3].datas = info.tick
				} else if (info.symbol == 'F40EUR') {
					this.Indices[4].datas = info.tick
				} else if (info.symbol == 'HSIHKD') {
					this.Indices[5].datas = info.tick
				} else if (info.symbol == 'NASUSU') {
					this.Indices[6].datas = info.tick
				} else if (info.symbol == 'SPXUSD') {
					this.Indices[7].datas = info.tick
				} else if (info.symbol == 'U30USD') {
					this.Indices[8].datas = info.tick
				} else if (info.symbol == 'EURUSD') {
					this.forex[0].datas = info.tick
				} else if (info.symbol == 'AUDUSD') {
					this.forex[1].datas = info.tick
				} else if (info.symbol == 'GBPUSD') {
					this.forex[2].datas = info.tick
				} else if (info.symbol == 'NZDUSD') {
					this.forex[3].datas = info.tick
				} else if (info.symbol == 'USDCAD') {
					this.forex[4].datas = info.tick
				} else if (info.symbol == 'USDJPY') {
					this.forex[5].datas = info.tick
				} else if (info.symbol == 'USDCHF') {
					this.forex[6].datas = info.tick
				} else if (info.symbol == 'GBPAUD') {
					this.forex[7].datas = info.tick
				} else if (info.symbol == 'GBPJPY') {
					this.forex[8].datas = info.tick
				}
			}

			//监听连接关闭事件
			this.ws.onclose = () => {
				//监听整个过程中websocket的状态
				console.log('ws连接状态：' + this.ws.readyState);
			}

			//监听并处理error事件
			this.ws.onerror = function (error) {
				console.log(error);
			}
		}
	}
}
</script>

<style>
.product {
	width: 1000px;
	margin: 50px auto;
	font-size: 16px;
}

.product .accordion-header {
	cursor: pointer
}

.product .table-list {
	width: 100%;
	border: none;
	position: relative;
	flex-direction: inherit;
	background: #949699;
}

.product .table-list .flex-1 {
	font-weight: 700;
	line-height: 40px;
	color: #ffffff;
	font-size: 14px;
	padding: 2px 12px;
	border-left: 1px solid #fff;
	border-right: 1px solid #fff;
	cursor: pointer;
	font-family: auto;
	text-align: center;
}

.product .table-list .flex-1.active {
	background-color: #3D0101;
	color: #ffffff;
}

.product-box__nav {
	/* margin-bottom: 4px; */
	font-size: 14px;
	font-weight: 700;
	line-height: 40px;
	color: #666666;
	padding: 0 12px;
	background-color: #F8F8F8;
}

.product-box__nav div {
	line-height: 40px;
	flex: 1;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-family: auto;
	text-align: center;
}

.product-box__accordion-item {
	background: #f7f7f7;
	border: none
}

.product-box__accordion-item .accordion-button {
	padding: 10px;
	font-size: 14px;
	background: #ffffff;
	color: #333
}

.product-box__accordion-item .accordion-body-head {
	width: 100%;
	padding: 12px 0;
	background-color: #949699;
	color: #f7f7f7
}

.product-box__accordion-item .accordion-body-head div {
	flex: 1
}

.product-box__accordion-header {
	margin-bottom: 0
}

.product-box .accordion-body-tr {
	width: 100%;
	padding: 12px 0;
	font-family: auto;
}

.product-box .accordion-body-tr div {
	flex: 1;
	text-align: center;
}

.product-box .accordion-body-tr div:nth-of-type(2) {
	color: #48ba13
}

.product-box .accordion-body-tr .red {
	color: #cc3221 !important
}

.product-box .accordion-body-tr div:nth-of-type(5) {
	color: #48ba13
}

.product .look-all {
	display: flex;
	justify-content: center;
	color: #8b8b8b;
	padding: 20px 0;
	font-size: 16px;
	margin-top: 30px;
}

.product .look-all span {
	padding-top: 5px;
	border-top: 1px solid #8b8b8b
}

.product .c-underline__top:after {
	/* background: #7d1614;
    height: 4px */
}

@media(max-width:995px) {
	.product {
		width: 100%;
	}

	.product .table-list .flex-1:nth-child(4),
	.product .table-list .flex-1:nth-child(5) {
		display: none;
	}

	.accordion-body {
		padding: 0;
	}

	.product-box__nav {
		font-size: 12px;
	}

	.product-box__accordion-item .accordion-button {
		font-size: 12px;
	}

	.accordion-body {
		font-size: 12px;
	}

	.page-screen-3 .problem-box .accordion .accordion-body {
		padding: 1rem 1.25rem;
	}

	.page-screen-3 .problem-box .accordion {
		padding-top: 0px;
	}

	.product .table-list .flex-1 {
		font-size: 13px;
	}

	.page-screen-3 .problem-box .accordion {
		margin-bottom: 50px;
	}

	.product .accordion-button:after {
		display: none;
	}

	.product-box__accordion-item .accordion-button {
		padding: 8px 0;
	}

	.page-screen-3 {
		padding-bottom: 100px;
	}

	.en-style2 .public-link2 {
		float: unset;
		margin-bottom: 16px;
	}

	.en-style2 .public-link {
		float: unset;
	}

	.en-style2 .t-sub3 .row .title {
		line-height: 24px;
	}

	.en-style2 .t-sub3 .row .desc {
		margin-top: 14px;
	}

	.en-style2 .product {
		margin-top: 30px;
	}

	.en-style2 .public-title {
		padding: 0 8%;
		line-height: 32px;
	}

	.en-style2 .page-screen-3 {
		padding-bottom: 70px;
	}

	.en-style2 .product {
		margin-bottom: 30px;
	}
}
</style>
